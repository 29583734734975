import React from "react";
import styled from "styled-components";
import Logo from "../Logo";

const Link = styled.a`
  cursor: pointer;
  padding: 12px 0px 5px 0px;
`;

const CustomLogo = styled(Logo)`
  width: 126px;
`;

const LogoLink: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => {
  return (
    <Link onClick={onClick}>
      <CustomLogo logoType="LARGE" />
    </Link>
  );
};

export default LogoLink;
