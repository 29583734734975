/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { geocodeByAddress } from "react-places-autocomplete";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash/debounce";
import Input from "./Input";

const Container = styled.div`
  padding-right: 16px;
  @media (max-width: 600px) {
    padding-right: 0;
  }
`;

const StyledInput = styled(Input)`
  background: white;
`;

const GEO = {
  STREET_NUMBER: "street_number",
  ROUTE: "route",
  LOCALITY: "locality",
  ADMIN_AREA_LEVEL_1: "administrative_area_level_1",
  COUNTRY: "country",
  POSTAL_CODE: "postal_code",
  LATITUDE: "lat",
  LONGITUDE: "lng",
  PLACE_ID: "place_id",
};

function getValueFromGeoResult({ src, key }) {
  const firstResult = src[0];

  if (key === GEO.LATITUDE) {
    return firstResult.geometry.location.lat();
  }
  if (key === GEO.LONGITUDE) {
    return firstResult.geometry.location.lng();
  }
  if (key === GEO.PLACE_ID) {
    return firstResult.place_id;
  }

  const info = firstResult.address_components.find(
    (it) => it.types.indexOf(key) >= 0
  );
  if (!info) {
    return undefined;
  }
  switch (key) {
    case GEO.STREET_NUMBER:
    case GEO.COUNTRY:
      return info.short_name;
    case GEO.ROUTE:
    case GEO.LOCALITY:
    case GEO.ADMIN_AREA_LEVEL_1:
    case GEO.POSTAL_CODE:
      return info.long_name;
    default:
      return undefined;
  }
}

class AddressInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      country: "France",
      error: false,
      valid: false,
      address1: this.props.value.address1 || undefined,
      address2: this.props.value.address2 || undefined,
      city: this.props.value.locality || undefined,
      postalCode: this.props.value.postal_code || undefined,
    };
  }

  geoLocateAddress = debounce(async (value) => {
    if (
      this.state.address1 &&
      this.state.country &&
      this.state.city &&
      this.state.postalCode &&
      this.state.postalCode.length === 5
    ) {
      const approximateAddress = `${this.state.address1} ${this.state.postalCode} ${this.state.city} ${this.state.country}`;
      console.log("searching for ", approximateAddress);
      let results;
      try {
        results = await geocodeByAddress(approximateAddress);
      } catch (e) {
        console.log("Could not geocode", e);
        this.setState({ error: true, valid: false });
        this.props.onChangeField(undefined);
        return;
      }
      if (!results || results.length < 0) {
        console.log("Invalid address!", approximateAddress);
        this.setState({ error: true, valid: false });
        this.props.onChangeField(undefined);
        return;
      }
      const fullAddress = `${this.state.address1}, ${this.state.postalCode} ${this.state.city} ${this.state.country}`;
      const addressObj = {
        text: fullAddress,
        address1: this.state.address1,
        address2: this.state.address2,
        additional_info: this.state.address2,
        street_number: getValueFromGeoResult({
          src: results,
          key: GEO.STREET_NUMBER,
        }),
        route: getValueFromGeoResult({
          src: results,
          key: GEO.ROUTE,
        }),
        locality: getValueFromGeoResult({
          src: results,
          key: GEO.LOCALITY,
        }),
        administrative_area_level_1: getValueFromGeoResult({
          src: results,
          key: GEO.ADMIN_AREA_LEVEL_1,
        }),
        country: getValueFromGeoResult({
          src: results,
          key: GEO.COUNTRY,
        }),
        postal_code: getValueFromGeoResult({
          src: results,
          key: GEO.POSTAL_CODE,
        }),
        lat: getValueFromGeoResult({
          src: results,
          key: GEO.LATITUDE,
        }),
        lng: getValueFromGeoResult({
          src: results,
          key: GEO.LONGITUDE,
        }),
        place_id: "",
      };
      this.props.onChangeField(addressObj);
      console.log("results", results);
      console.log("value", value);
      this.setState({ error: false, valid: true });
      return;
    }
    if (this.state.valid) {
      console.log("now invalid");
      this.setState({ error: true, valid: false });
      this.props.onChangeField(undefined);
    }
  }, 500);

  handleChange = async (component, value) => {
    this.setState({ [component]: value });
    this.geoLocateAddress(value);
  };

  render() {
    const { className, vendorId } = this.props;
    const { error } = this.state;
    return (
      <Container className={className}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledInput
              label="Numéro et nom de rue"
              fullWidth
              id={`address1_${vendorId}`}
              name={`address1_${vendorId}`}
              value={this.state.address1}
              onChange={(event) =>
                this.handleChange("address1", event.target.value)
              }
              required
              type="text"
              placeholder=""
              error={error !== undefined && error}
              withoutBorders={true}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              label="Complément d'adresse"
              fullWidth
              id={`address2_${vendorId}`}
              name={`address2_${vendorId}`}
              value={this.state.address2}
              onChange={(event) =>
                this.handleChange("address2", event.target.value)
              }
              type="text"
              placeholder=""
              error={error !== undefined && error}
              withoutBorders={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              label="Code postal"
              id={`postalCode_${vendorId}`}
              fullWidth
              name={`postalCode_${vendorId}`}
              value={this.state.postalCode}
              onChange={(event) =>
                this.handleChange("postalCode", event.target.value)
              }
              required
              type="number"
              placeholder=""
              pattern="[0-9]{10}"
              error={error !== undefined && error}
              withoutBorders={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              label="Ville"
              id={`city_${vendorId}`}
              fullWidth
              name={`city_${vendorId}`}
              value={this.state.city}
              onChange={(event) =>
                this.handleChange("city", event.target.value)
              }
              required
              type="text"
              placeholder=""
              error={error !== undefined && error}
              withoutBorders={true}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              fullWidth
              label="Pays"
              id={`country_${vendorId}`}
              name={`country_${vendorId}`}
              value={this.state.country}
              onChange={(event) =>
                this.handleChange("country", event.target.value)
              }
              required
              type="text"
              placeholder=""
              disabled
              error={error !== undefined && error}
              withoutBorders={true}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

AddressInput.propTypes = {
  className: PropTypes.string,
  onChangeField: PropTypes.func.isRequired,
  value: PropTypes.object,
};

AddressInput.defaultProps = {
  className: "",
  value: {},
};

export default AddressInput;
