import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const sizes = [
  50, 75, 100, 150, 190, 300, 360, 420, 480, 570, 640, 768, 960, 1152, 1280,
  1536, 2048, 2304, 2560, 3072, 3840,
];

const formats = {
  WEBP: {
    extension: "webp",
    contentType: "image/webp",
  },
  JPEG: {
    extension: "jpeg",
    contentType: "image/jpeg",
  },
};

const StyledImg = styled.img`
  object-fit: ${({ fit }) => fit};
  height: auto;
  width: 100%;
`;

function buildSrcSet(protocol, host, originalId, originalPath, size, format) {
  const prefixedPath = originalPath.replace("original", "generated/original");
  return `${protocol}//${host}/${prefixedPath}/${originalId}-${size}.${formats[format].extension} ${size}w`;
}

function parseURL(baseImgUrl) {
  const url = new URL(baseImgUrl);
  const originalFilename = url.pathname.substring(
    url.pathname.lastIndexOf("/") + 1
  );
  const originalId = originalFilename.substring(
    0,
    originalFilename.lastIndexOf(".")
  );
  const originalPath = url.pathname
    .slice(1)
    .replace(originalFilename, "")
    .slice(0, -1);

  return { protocol: url.protocol, host: url.host, originalPath, originalId };
}

function ResponsivePicture({
  className,
  height,
  containerStyle,
  baseImgUrl,
  sizeQuery,
  alt,
  fitType,
  ...props
}) {
  const { protocol, host, originalId, originalPath } = parseURL(baseImgUrl);
  const pictureStyle = {
    height,
    display: "flex",
    width: "100%",
    ...containerStyle,
  };

  return (
    <picture style={pictureStyle}>
      {" "}
      {/* So this works with styled components */}
      {Object.keys(formats).map((format) => (
        <source
          sizes={sizeQuery}
          type={formats[format].contentType}
          key={format}
          srcSet={sizes
            .map((size) =>
              buildSrcSet(
                protocol,
                host,
                originalId,
                originalPath,
                size,
                format
              )
            )
            .reduce((acc, current) => `${acc}, ${current}`)}
        />
      ))}
      <StyledImg
        fit={fitType}
        src={baseImgUrl}
        alt={alt}
        {...props}
        className={className}
      />
    </picture>
  );
}
ResponsivePicture.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  containerStyle: PropTypes.object,
  baseImgUrl: PropTypes.string.isRequired,
  sizeQuery: PropTypes.string,
  height: PropTypes.number,
  fitType: PropTypes.string,
};
ResponsivePicture.defaultProps = {
  className: "",
  sizeQuery: "100vw",
  height: "100%",
  containerStyle: {},
  fitType: "contain",
};

export default ResponsivePicture;
