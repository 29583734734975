import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import {
  SecondaryActionButton,
  withSecondaryIcon,
} from "./SecondaryActionButton";
import { Box, Typography } from "@material-ui/core";
const CloseIconSecondary = withSecondaryIcon("left")(CloseIcon);

export function ResultCounter({ count, hideClear, onClear, clearLabel }) {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography variant={"body2"}>
        {count} résultat{count > 1 ? "s" : ""}
      </Typography>
      {!hideClear && (
        <React.Fragment>
          <span>&nbsp;&nbsp;</span>
          <SecondaryActionButton onClick={onClear}>
            <CloseIconSecondary />
            {clearLabel}
          </SecondaryActionButton>
        </React.Fragment>
      )}
    </Box>
  );
}

ResultCounter.propTypes = {
  clearLabel: PropTypes.string,
  count: PropTypes.number.isRequired,
  hideClear: PropTypes.bool,
  onClear: PropTypes.func,
};

ResultCounter.defaultProps = {
  clearLabel: "Effacer les filtres",
};
