import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as RouterLink, useParams, withRouter } from "react-router-dom";

// Components
import Input from "../Common/Input";
import Button from "../Common/Button";
import Layout from "./Layout";

// Contexts
import { useApiConfig } from "../Context/ApiContext";

// Hooks
import { useHttpClient } from "../Hooks/useHttpClient";

// Styleds
const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;
const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

const UpdatePasswordPage = ({ history }) => {
  const { apiRoot } = useApiConfig();
  const httpClient = useHttpClient(apiRoot);
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [duplicatePassword, setDuplicatePassword] = useState("");
  const [passwordValide, setPasswordValide] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeDuplicatePassword = useCallback(
    (e) => {
      const text = e.target.value;
      if (text === password) {
        setPasswordValide(true);
      } else {
        setPasswordValide(false);
      }
      setDuplicatePassword(text);
    },
    [password]
  );

  const handleChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    setSuccess(null);
    setError(null);
    setLoading(true);
    try {
      await httpClient.post(`/user/update-password/${token}`, {
        password,
      });
      setSuccess("Super! Mot de passe mis à jour");
      setTimeout(() => history.push("/login"), 2000);
    } catch (error) {
      setError("Une erreur s'est produite");
    } finally {
      setPassword("");
      setDuplicatePassword("");
      setLoading(false);
    }
  }, [password, token, httpClient, history]);

  return (
    <Layout
      title="Mettre à jour le mot de passe"
      success={success}
      error={error}
    >
      <StyledInput
        type="password"
        label="Nouveau mot de passe"
        id="password1"
        name="password"
        required={true}
        fullWidth={true}
        value={password}
        onChange={handleChangePassword}
        error={!passwordValide}
        disabled={loading}
      />
      <StyledInput
        type="password"
        label="Répéter le mot de passe"
        id="duplicatePassword"
        name="duplicatePassword"
        required={true}
        fullWidth={true}
        value={duplicatePassword}
        onChange={handleChangeDuplicatePassword}
        error={!passwordValide}
        disabled={loading}
      />
      <StyledButton
        fullWidth={true}
        disabled={!password || !duplicatePassword || !passwordValide}
        onClick={handleSubmit}
        loading={loading}
      >
        Mettre à jour le mot de passe
      </StyledButton>
      <StyledButton
        fullWidth={true}
        styled="secondary"
        component={RouterLink}
        to="/login"
      >
        Retour
      </StyledButton>
    </Layout>
  );
};

UpdatePasswordPage.propTypes = {
  history: PropTypes.any.isRequired,
};

UpdatePasswordPage.defaultProps = {};

export default withRouter(UpdatePasswordPage);
