import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
  Grid,
  OutlinedInput,
  InputAdornment,
  withWidth,
  Hidden,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// helpers
import media from "../../Helpers/media";

// maps
import { AutoCompleteLocationInput } from "../../Common/AutoCompleteLocationInput";
import LoadScriptOnlyIfNeeded from "../../Common/LoadScriptOnlyIfNeeded";

// Components
import Button from "../../Common/Button";
import TopBar from "../../TopBar";

// consts
const librariesToLoad = ["places", "geometry"];
const API_KEY = "AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg";

const HeaderContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${media.down.md`
    left: 0;
    right: 0;
    transform: unset;
    top: 125px;
    padding: 10px;
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid ${({ theme }) => theme.palette.grey["100"]};
    box-shadow: 0px 5px 26px rgba(74, 74, 74, 0.03);
    border-radius: 8px;
  `}
  ${media.up.md`
    left: 10%;
    top: 215px !important;
    transform: unset;
  `}
  z-index: 999;
`;

const SearcherBox = styled.div`
  width: 825px;
  ${media.down.md`
    width: 100%;
  `}
  margin-right:100px;
`;

const Headline = styled.p`
  width: 690px;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
  ${media.down.md`
    width: 100%;
    font-size: 20px;
    line-height: 28px; 
    color: ${({ theme }) => theme.palette.grey[900]};
  `}
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: white;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
`;

const StyledSearchButton = styled(Button)`
  ${({ theme }) => `
    background: ${theme.palette.success.main};
    &:hover {
      background: ${theme.palette.secondary.dark};  
    }
  `};
  border-radius: 0px 8px 8px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  align-items: center;
  height: 100%;
  min-width: unset;
`;

const Header = ({ width, onSearch, onChange, onChangeProducer, value }) => {
  const [loaded, setLoaded] = useState(false);
  const change = (value) => {
    onChange(value);
  };

  const isMobile = useMemo(
    () => width === "xs" || width === "sm" || width === "md",
    [width]
  );

  let styles = {
    backgroundColor: "white",
    borderRadius: width === "xs" || width === "sm" ? "8px 0 0 8px" : 0,
  };

  return (
    <>
      <TopBar />

      <HeaderContent>
        <Headline>
          {isMobile
            ? "Découvrez vos producteurs, et commandez vos produits autour de chez vous"
            : "Découvrez vos producteurs, et commandez vos produits autour de chez vous"}
        </Headline>
        <SearcherBox>
          <LoadScriptOnlyIfNeeded
            id="script-loader"
            googleMapsApiKey={API_KEY}
            libraries={librariesToLoad}
            loadingElement={<div></div>}
            onLoad={() => setLoaded(true)}
          >
            {loaded && (
              <Grid container spacing={0}>
                <Hidden smDown>
                  <Grid item md={4}>
                    <StyledOutlinedInput
                      onChange={(e) => onChangeProducer(e.target.value)}
                      value={value.local_dokan_seller_search}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      placeholder="Rechercher par producteur..."
                    />
                  </Grid>
                </Hidden>
                <Grid item md={4} xs={10}>
                  <AutoCompleteLocationInput
                    value={value}
                    onChange={change}
                    styles={styles}
                    placeholder="Rechercher par ville..."
                  />
                </Grid>
                <Grid item md={4} xs={2}>
                  <StyledSearchButton
                    variant="contained"
                    color="primary"
                    onClick={() => onSearch(value)}
                  >
                    {isMobile ? <SearchIcon /> : "Rechercher"}
                  </StyledSearchButton>
                </Grid>
              </Grid>
            )}
          </LoadScriptOnlyIfNeeded>
        </SearcherBox>
      </HeaderContent>
    </>
  );
};

export default withWidth()(Header);
