import React from "react";
import styled from "styled-components";
import List, { ListData } from "./List";
import GranvillageBaseline from "../Assets/Images/granvillage-baseline.svg";
import Granvillage from "../Assets/Images/granvillage.svg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const SectionOne = styled(Grid)<{ mobileMode?: boolean }>`
  background-color: #16442a;
  justify-content: ${(props) => props.mobileMode && "center"};
  padding: 50px 20px;
`;

const SectionTwo = styled.div`
  background: ${(props) => props.theme.palette.grey[200]};
  text-align: center;
  padding: 15px 20px;
  p {
    font-weight: 700;
  }
  font-size: 14px;
  // TODO add this color to theme.palette
  color: #212529;
  border-bottom: 1px solid #cfd2d7;
`;

const SectionThree = styled(Grid)<{ mobileMode: boolean }>`
  row-gap: ${(props) => (props.mobileMode ? 10 : 0)}px;
  padding: ${(props) => (props.mobileMode ? "20px" : "0 36px")};
  min-height: 80px;
`;

const Logo = styled.img`
  align-self: center;
`;

const IconList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin: 0;
`;

const IconWrapper = styled.a`
  & > svg {
    height: 32px;
    width: 32px;
    color: rgb(22, 68, 42);
  }
`;

const CustomDivider = styled(Divider)`
  margin: 30px 0;
  background: white;
`;

const parseTupleToItem = ([desc, url]: string[]) => ({
  description: desc,
  url,
});

const SectionOneData: ListData[] = [
  {
    title: "Informations légales",
    tuples: [
      [
        "Mentions légales",
        "https://pp.granvillage.com/consommateurs/mentions-legales",
      ],
      [
        "Conditions Génerales d'utilisation",
        "https://pp.granvillage.com/consommateurs/cgu/",
      ],
      [
        "Conditions Génerales de vente",
        "https://pp.granvillage.com/consommateurs/cgv/",
      ],
      ["Gérer mes cookies", "/#"],
      ["Cookies", "https://pp.granvillage.com/consommateurs/cookies/"],
      [
        "Mes données personnelles",
        "https://pp.granvillage.com/consommateurs/donnees-personnelles/",
      ],
      [
        "Règles de référencement",
        "https://pp.granvillage.com/consommateurs/regles-de-referencement/",
      ],
    ],
  },
  {
    title: "À propos",
    tuples: [
      ["À propos", "https://pp.granvillage.com/consommateurs/a-propos"],
      [
        "Charte Granvillage",
        "https://pp.granvillage.com/consommateurs/charte-granvillage/",
      ],
      [
        "Qui sommes-nous ?",
        "https://pp.granvillage.com/consommateurs/qui-sommes-nous/",
      ],
      ["Blog", "https://pp.granvillage.com/blogs/"],
      ["Podcasts", "https://pp.granvillage.com/podcast/"],
      ["Newsletter", "https://pp.granvillage.com/#inscription-newsletter"],
      ["Groupama.fr", "https://www.groupama.fr/"],
    ],
    maxItemsPerColumn: 6,
  },
  {
    title: "Besoin d'aide ?",
    tuples: [
      ["Contactez-nous", "/contact/consommateur"],
      ["Devenez producteur", "https://pp.granvillage.com/producteurs/"],
      ["FAQ", "https://pp.granvillage.com/consommateurs/faq/"],
      [
        "Guide utilisateur",
        "https://pp.granvillage.com/app/uploads/2022/02/Guide_utilisateur_consommateur.pdf",
      ],
      ["Signaler un contenu", "/contact/consommateur"],
    ],
  },
].map(({ tuples, ...rest }) => ({
  ...rest,
  items: tuples.map(parseTupleToItem),
}));

const isLastItem = (arr: any[], idx: number) => idx + 1 === arr.length;

const GranvillageFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <SectionOne container mobileMode={isMobile}>
        {SectionOneData.map((props, idx, arr) => (
          <React.Fragment>
            <List
              {...props}
              mobileMode={isMobile}
              ml={{ lg: idx && "8.3333%" }}
              lg={3}
              item
              sm={12}
              md={4}
            />
            {isMobile && !isLastItem(arr, idx) && (
              <Grid item xs={12}>
                <CustomDivider />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </SectionOne>
      <SectionTwo>
        <Typography gutterBottom>
          L'abus d'alcool est dangereux pour la santé, à consommer avec
          modération.
        </Typography>
        <Typography>La vente d'alcool est interdite aux mineurs.</Typography>
      </SectionTwo>
      <SectionThree
        container
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        mobileMode={isMobile}
      >
        <Grid
          container
          item
          justifyContent={isMobile ? "center" : "flex-start"}
          alignItems="center"
          xs={12}
          md={4}
        >
          <IconList>
            <li>
              <IconWrapper
                target="_blank"
                href="https://www.facebook.com/granvillage/"
              >
                <FacebookIcon />
              </IconWrapper>
            </li>

            <li>
              <IconWrapper
                href="https://twitter.com/granvillage"
                target="_blank"
              >
                <TwitterIcon />
              </IconWrapper>
            </li>

            <li>
              <IconWrapper
                target="_blank"
                href="https://www.instagram.com/granvillage"
              >
                <InstagramIcon />
              </IconWrapper>
            </li>

            <li>
              <IconWrapper
                target="_blank"
                href="https://www.linkedin.com/company/granvillage"
              >
                <LinkedInIcon />
              </IconWrapper>
            </li>
          </IconList>
        </Grid>
        <Grid container item justifyContent="center" xs={12} md={4}>
          <Logo
            src={isMobile ? Granvillage : GranvillageBaseline}
            alt="Granvillage Logo"
            width={isMobile ? 157 : 213}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            textAlign={isMobile ? "center" : "right"}
            color="#5d6166"
            fontSize="14"
          >
            <Typography>Copyright Granvillage.com 2021</Typography>
            <Typography>
              Icons made by Good Ware from www.flaticon.com
            </Typography>
          </Box>
        </Grid>
      </SectionThree>
    </div>
  );
};

export default GranvillageFooter;
