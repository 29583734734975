import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import * as queryString from "query-string";
import { useLocation } from "react-router-dom";
import httpClient from "../../../../httpClient";
import constants from "./constants";
import ApiContext from "../../../../Context/ApiContext";
import PaymentLoader from "./PaymentLoader";

const Container = styled("div")`
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
function MangoPayPaymentComplete() {
  const timerRef = useRef(null);
  const [counter, setCounter] = useState(0);

  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const transactionId = queryParams.transactionId;

  const [loading, setLoading] = useState(true);
  const { apiRoot } = useContext(ApiContext);

  const clearTimer = useCallback(() => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  }, []);

  const notifyTransactionFailed = useCallback(() => {
    console.log(`transaction failed!`);
    clearTimer();
    window.parent.kuuMangoPayHandlePaymentComplete &&
      window.parent.kuuMangoPayHandlePaymentComplete({
        transactionId: transactionId,
        status: constants.PAY_IN_STATUS.FAILED,
        resultCode: "001999",
      });
  }, [transactionId, clearTimer]);

  const notifyTransactionSucceeded = useCallback(async () => {
    console.log(`transaction successful!`);
    clearTimer();
    try {
      const res = await httpClient.get(
        `${apiRoot}/order-transaction/orders/${transactionId}`
      );
      const orders = res.data;
      window.parent.kuuMangoPayHandlePaymentComplete &&
        window.parent.kuuMangoPayHandlePaymentComplete({
          transactionId,
          status: constants.PAY_IN_STATUS.SUCCESS,
          resultCode: "000000",
          orders,
        });
    } catch (error) {
      setLoading(false);
    }
  }, [apiRoot, transactionId, clearTimer]);

  const fetchTransaction = useCallback(
    async (transactionId) => {
      try {
        console.log("polling...");
        const res = await httpClient.get(
          `${apiRoot}/order-transaction/transaction/${transactionId}/status`
        );
        const status = res.data && res.data.status;
        console.log(`polled once with status ${status}`);
        if (status === "SUCCESSFUL") {
          await notifyTransactionSucceeded();
        } else if (status === "FAILED") {
          notifyTransactionFailed();
        }
      } catch (error) {
        console.log(`polled failed with error ${error}`);
        //setLoading(false);
      }
    },
    [apiRoot, notifyTransactionFailed, notifyTransactionSucceeded]
  );

  useEffect(() => {
    timerRef.current = setInterval(async () => {
      setCounter((counter) => counter + 1);
    }, 5000);

    return () => clearTimer();
  }, [clearTimer]);

  useEffect(() => {
    const check = async () => {
      if (transactionId) {
        if (counter === 60) {
          return notifyTransactionFailed();
        }

        await fetchTransaction(transactionId);
      }
    };
    check();
  }, [counter, fetchTransaction, notifyTransactionFailed, transactionId]);

  return (
    <Container>{loading ? <PaymentLoader /> : <div>ERROR!</div>}</Container>
  );
}

export default MangoPayPaymentComplete;
