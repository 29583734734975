import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SectionTitle from "../Common/SectionTitle";
import TextField from "@material-ui/core/TextField";
import { InnerPaper } from "./styles";
import { CartShape } from "../Checkout/proptypes";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import constants from "../VendorStore/utils/constants";
import PaymentForm from "../VendorStore/components/Payment/PaymentForm";
import { getBaseUnit } from "../VendorStore/services/utils";
import { Paper, Typography, useMediaQuery } from "@material-ui/core";
import CartSummary from "../Common/CartSummary";
import ProductCounter from "../Common/ProductCounter";
import PromoCodeInput from "../Common/PromoCodeInput";
import Checkbox from "../Common/Checkbox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useTheme } from "@material-ui/core/styles";
import { NumberFormatCustom } from "@kuupanda/commons";

const Container = styled.div`
  width: 100%;
`;
const StyledInnerPaper = styled(InnerPaper)``;
const ConfirmationLoader = styled(CircularProgress)`
  position: absolute;
`;
const ConfirmationButton = styled(Button)`
  width: 220px;
  height: 48px;
  font-size: 14px;
`;
const ConfirmAreaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  margin-top: 20px;
`;
const TotalGrid = styled(Grid)`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid ${({ theme }) => theme.palette.grey[400]};
  font-weight: 600;
`;

const StyledPaperSummary = styled(Paper)`
  padding: 10px 10px 16px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
`;

const StyledPaperConfirmation = styled(Paper)`
  color: ${({ theme }) => theme.palette.grey["100"]};
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const PriceStyled = styled.span`
  color: ${({ theme }) => theme.palette.grey.A700};
`;

const BorderStyled = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey["200"]};
`;

const ErrorMessagesContainer = styled(Grid)`
  margin-top: 20px;
`;

const CommentGrid = styled(Grid)`
  margin-top: 20px;
`;

const ProductName = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.grey["900"]};
  `}
`;

const ProductSize = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.grey["800"]};
  `}
`;

const SmallGrid = styled(Grid)`
  padding: 3px 8px !important;
`;

const ProductPrice = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.grey["900"]};
  `}
`;

export const ProductBasePrice = styled.div`
  text-decoration: line-through;
  font-size: 13px;
  ${({ theme }) => `
    color: ${theme.palette.grey.A200};
  `}
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 0px;
  margin-top: 10px;
`;

const TosText = styled(Typography)`
  color: black;
`;

const RightsText = styled(Typography)`
  width: 220px;
  margin-top: 10px;
  color: black;
`;

const EmptyCart = styled(Typography)`
  margin-top: 5px;
`;

const columnExtensions = [
  { columnName: "name", align: "left" },
  { columnName: "quantity", width: 150, align: "center" },
  { columnName: "unit", width: 150, align: "right" },
  { columnName: "total", width: 80, align: "right" },
];
const mobileColumnExtensions = [
  { columnName: "name", align: "left", wordWrapEnabled: true },
  { columnName: "quantity", width: 70, align: "center" },
  { columnName: "unit", width: 90, align: "right", wordWrapEnabled: true },
  { columnName: "total", width: 60, align: "right" },
];

const columns = [
  { name: "name", title: "Name" },
  { name: "quantity", title: "Quantity" },
  { name: "unit", title: "Unit" },
  { name: "total", title: "Total" },
];

function Totals({
  subTotalBeforeDiscount,
  deliveryRate,
  deliveryRateDt,
  total,
  subTotal,
  saleChannel,
  taxes,
  couponLinesTotal,
}) {
  return (
    <TotalGrid container spacing={2}>
      <SmallGrid item xs={8}>
        <Typography variant="h6">
          Sous-total{" "}
          {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
        </Typography>
      </SmallGrid>
      <SmallGrid item xs={4} style={{ textAlign: "right" }}>
        <Typography variant="h6">
          {subTotalBeforeDiscount.toFixed(2)}€
        </Typography>
      </SmallGrid>
      {deliveryRate !== undefined && deliveryRate > 0 && (
        <React.Fragment>
          <SmallGrid item xs={8}>
            <Typography variant="h6">
              Livraison{" "}
              {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
            </Typography>
          </SmallGrid>
          <SmallGrid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="h6">
              {(saleChannel === constants.SALE_CHANNEL.PROFESSIONAL &&
                deliveryRateDt.toFixed(2)) ||
                deliveryRate.toFixed(2)}
              {""}€
            </Typography>
          </SmallGrid>
        </React.Fragment>
      )}
      {couponLinesTotal !== undefined && couponLinesTotal > 0 && (
        <React.Fragment>
          <Grid item xs={12}>
            <BorderStyled />
          </Grid>
          <SmallGrid item xs={8}>
            <Typography variant="h6">Remise TTC</Typography>
          </SmallGrid>
          <SmallGrid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="h6">
              -{couponLinesTotal.toFixed(2)}€
            </Typography>
          </SmallGrid>
        </React.Fragment>
      )}
      {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
        <React.Fragment>
          <Grid item xs={12}>
            <BorderStyled />
          </Grid>{" "}
          <SmallGrid item xs={8}>
            <Typography variant="h6">
              Total{" "}
              {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
            </Typography>
          </SmallGrid>
          <SmallGrid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="h6">{subTotal.toFixed(2)}€</Typography>
          </SmallGrid>
        </React.Fragment>
      )}
      {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
        <React.Fragment>
          <SmallGrid item xs={8}>
            <Typography variant="h6">TVA</Typography>
          </SmallGrid>
          <SmallGrid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="h6">{taxes.toFixed(2)}€</Typography>
          </SmallGrid>
        </React.Fragment>
      )}
      <SmallGrid item xs={8}>
        <Typography variant="body">Total {"TTC"}</Typography>
      </SmallGrid>
      <SmallGrid item xs={4} style={{ textAlign: "right" }}>
        {total.toFixed(2)}€
      </SmallGrid>
    </TotalGrid>
  );
}
function ConfirmArea({
  onSubmit,
  isFormValid,
  isFormSubmitting,
  paymentFormError,
  paymentFormReady,
  disableButton,
  showSalesTerms,
  termsAccepted,
  onTermsAcceptChange,
}) {
  return (
    <>
      {showSalesTerms && (
        <StyledCheckbox
          onChange={(event) => onTermsAcceptChange(event.target.checked)}
          value={termsAccepted}
          label={
            <TosText>
              J'ai lu et j'accepte les{" "}
              <a
                href="https://gestion.kuupanda.com/granvillage-cgv"
                rel="noopener noreferrer"
                target="_blank"
              >
                Conditions Générales de Vente
              </a>
            </TosText>
          }
        />
      )}
      <ConfirmAreaContainer>
        <div>
          <ConfirmationButton
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              !isFormValid ||
              isFormSubmitting ||
              Boolean(paymentFormError) ||
              !paymentFormReady ||
              disableButton ||
              (showSalesTerms && !termsAccepted)
            }
          >
            {isFormSubmitting && <ConfirmationLoader size={36} />}
            Je valide ma commande
          </ConfirmationButton>

          {showSalesTerms && (
            <RightsText>
              Pour obtenir plus d'informations sur vos droits et le traitement
              de vos données, cliquez{" "}
              <a
                href="https://gestion.kuupanda.com/politique-donnees-gv"
                rel="noopener noreferrer"
                target="_blank"
              >
                ici
              </a>
            </RightsText>
          )}
        </div>
      </ConfirmAreaContainer>
    </>
  );
}
function SimplifiedOrderSummary({
  classname,
  deliveryRate,
  cart,
  isFormValid,
  isFormSubmitting,
  saleChannel,
  comment,
  onCommentChange,
  orderDTO,
  onStartPaymentProcess,
  onCancelPaymentProcess,
  onSuccessfulPayment,
  onFailedPayment,
  isPaymentBeingProcessed,
  customerInfo,
  paymentMethods,
  onChangeCart,
  initialCodes,
  setCodes,
  couponLines = [],
  codeEvaluationStatus,
  isCustomerInfoValid,
  isRecoveryInfoValid,
  disableButton,
  producerId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [paymentFormError, setPaymentFormError] = useState("empty");
  const [paymentFormReady, setPaymentFormReady] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  //for groupama
  const showSalesTerms = useMemo(() => producerId === "3010", [producerId]);

  const isCartEmpty = useMemo(
    () => !cart || Object.values(cart).length === 0,
    [cart]
  );

  const couponLinesTotal = useMemo(
    () => couponLines.reduce((acc, cl) => acc + cl.discount, 0),
    [couponLines]
  );

  const cartList = useMemo(
    () =>
      Object.values(cart).map(({ quantity, product, size }) => ({
        name: (
          <span>
            <ProductName>{product.name}</ProductName>
            {size && <ProductSize>Taille {size}</ProductSize>}
          </span>
        ),
        quantity: (
          <ProductCounter
            product={product}
            quantity={quantity}
            onChangeCart={onChangeCart}
            size={size}
          />
        ),
        unit: (
          <>
            <ProductPrice>
              {product.price}€/{getBaseUnit(product)}
            </ProductPrice>
            {product.priceReduction > 0 && (
              <ProductBasePrice>
                <NumberFormatCustom
                  value={product.priceBeforeReduction}
                  displayType={"text"}
                />{" "}
              </ProductBasePrice>
            )}
          </>
        ),
        total: (
          <PriceStyled>{(quantity * product.price).toFixed(2)}€</PriceStyled>
        ),
      })),
    [cart, onChangeCart]
  );

  const deliveryFee = deliveryRate !== undefined ? deliveryRate : 0;

  //Free tax delivery fee
  const deliveryRateDt = deliveryFee / 1.2;

  const subTotalBeforeDiscount = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc, { quantity, product }) => acc + quantity * product.price,
        0
      ),
    [cart]
  );

  const productTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc, { quantity, product }) => acc + quantity * product.price,
        0
      ),
    [cart, couponLinesTotal]
  );

  const productTotalVat = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc, { quantity, product }) =>
          acc + quantity * (product.price * (1 + product.vat)),
        0
      ),
    [cart, couponLinesTotal]
  );

  const totalBeforeDiscount =
    productTotal + deliveryFee > 0 ? productTotal + deliveryFee : 0;

  const subTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc, { quantity, product }) =>
          acc +
          (quantity * product.price -
            ((quantity * product.price) / totalBeforeDiscount) *
              couponLinesTotal),
        0
      ) +
      (deliveryFee - (deliveryFee / totalBeforeDiscount) * couponLinesTotal) /
        1.2,
    [cart, totalBeforeDiscount, couponLinesTotal]
  );

  const productTotalToUse =
    saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
      ? productTotalVat
      : productTotal;
  const total =
    productTotalToUse + deliveryFee - couponLinesTotal > 0
      ? productTotalToUse + deliveryFee - couponLinesTotal
      : 0;

  const taxes = total - subTotal;

  const extensions = isMobile ? mobileColumnExtensions : columnExtensions;
  const cellStyles = useMemo(
    () => ({
      paddingLeft: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 0,
      color: theme.palette.grey.A200,
      borderColor: theme.palette.grey["200"],
    }),
    [theme]
  );
  return (
    <Grid xs={12} md={11} container item justify="center">
      <Container classname={classname}>
        <StyledInnerPaper>
          <SectionTitle>Récapitulatif de la commande</SectionTitle>
          <Grid container>
            {!isCartEmpty && (
              <>
                <PromoCodeInput
                  initialCodes={initialCodes}
                  onSubmit={setCodes}
                  codeEvaluationStatus={codeEvaluationStatus}
                />
                <StyledPaperSummary variant="outlined" elevation={0}>
                  <Grid item xs={12}>
                    <CartSummary
                      cartList={cartList}
                      cellStyles={cellStyles}
                      columns={columns}
                      columnExtensions={extensions}
                      showTotal={false}
                    />
                    <Totals
                      deliveryRate={deliveryRate}
                      deliveryRateDt={deliveryRateDt}
                      subTotalBeforeDiscount={subTotalBeforeDiscount}
                      subTotal={subTotal}
                      couponLinesTotal={couponLinesTotal}
                      total={total}
                      taxes={taxes}
                      saleChannel={saleChannel}
                      couponLines={couponLines}
                    />
                  </Grid>
                </StyledPaperSummary>
              </>
            )}
            <StyledPaperConfirmation elevation={0} variant="outlined" xs={12}>
              {!isCartEmpty && (
                <Grid item xs={12}>
                  <PaymentForm
                    orderDTO={orderDTO}
                    isPaymentBeingProcessed={isPaymentBeingProcessed}
                    onSuccessfulPayment={onSuccessfulPayment}
                    onCancelPaymentProcess={onCancelPaymentProcess}
                    onFailedPayment={onFailedPayment}
                    customerInfo={customerInfo}
                    onPaymentFormError={setPaymentFormError}
                    onPaymentFormReady={setPaymentFormReady}
                    paymentMethods={paymentMethods}
                    total={total}
                  />
                </Grid>
              )}
              <CommentGrid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="comment"
                  label="Commentaire pour le producteur"
                  multiline
                  rows={4}
                  value={comment}
                  onChange={onCommentChange}
                />
              </CommentGrid>
              {(!isFormValid || paymentFormError) && !isCartEmpty && (
                <ErrorMessagesContainer item xs={12}>
                  <Alert severity="error">
                    <AlertTitle>Oops</AlertTitle>
                    {!isCustomerInfoValid && (
                      <div>Veuillez vérifier vos informations client</div>
                    )}
                    {!isRecoveryInfoValid && (
                      <div>
                        Veuillez vérifier vos informations de récupération
                      </div>
                    )}
                    {paymentFormError && (
                      <div>Veuillez vérifier vos informations de paiement</div>
                    )}
                  </Alert>
                </ErrorMessagesContainer>
              )}

              {!isCartEmpty && (
                <Grid item xs={12}>
                  <ConfirmArea
                    onSubmit={onStartPaymentProcess}
                    isFormSubmitting={isFormSubmitting}
                    isFormValid={isFormValid}
                    paymentFormError={paymentFormError}
                    paymentFormReady={paymentFormReady}
                    disableButton={disableButton}
                    showSalesTerms={showSalesTerms}
                    termsAccepted={termsAccepted}
                    onTermsAcceptChange={setTermsAccepted}
                  />
                </Grid>
              )}
            </StyledPaperConfirmation>
          </Grid>
          {isCartEmpty && <EmptyCart>Votre panier est vide</EmptyCart>}
        </StyledInnerPaper>
      </Container>
    </Grid>
  );
}
SimplifiedOrderSummary.propTypes = {
  classname: PropTypes.string,
  cart: CartShape.isRequired,
  deliveryRate: PropTypes.number,
  isFormValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.func.isRequired,
  saleChannel: PropTypes.oneOf([
    constants.SALE_CHANNEL.STANDARD,
    constants.SALE_CHANNEL.PROFESSIONAL,
  ]).isRequired,
  comment: PropTypes.string,
  onCommentChange: PropTypes.func.isRequired,
  orderDTO: PropTypes.object,
  onStartPaymentProcess: PropTypes.func.isRequired,
  onSuccessfulPayment: PropTypes.func.isRequired,
  onFailedPayment: PropTypes.func.isRequired,
  isPaymentBeingProcessed: PropTypes.bool.isRequired,
  customerInfo: PropTypes.object,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
      options: PropTypes.object,
    })
  ),
  onChangeCart: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

SimplifiedOrderSummary.defaultProps = {
  classname: undefined,
  deliveryRate: undefined,
  comment: undefined,
  orderDTO: undefined,
  customerInfo: undefined,
};

export default SimplifiedOrderSummary;
