import React from "react";
import styled from "styled-components";
import media from "../Helpers/media";

const Body = styled.div`
  font-family: Lato, sans-serif;
  letter-spacing: -0.2px;
`;

const TitleSection = styled.div`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    319deg,
    ${({ theme }) => theme.palette.secondary.main},
    ${({ theme }) => theme.palette.primary.main} 100%,
    #fff
  );
  width: 100%;
  border-radius: 0 0 50% 50% / 0 0 100px 100px;
  text-align: center;
`;

const Title = styled.h1`
  color: white;
  line-height: 40px;
  font-size: 35px;
  letter-spacing: -1.5px;
  font-family: Lato, sans-serif;
  margin: 0;
  max-width: 1080px;
  padding: 0 24px;
`;

const StorySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.down.md`
    text-align:center;
    padding: 0px 20px;
    justify-content: center;
   
  `}
`;
const SubtitleSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.grey["800"]};
  font-size: 15px;
  line-height: 25px;
  padding: 40px 0px 53px 0px;
  max-width: 650px;
`;
const Subtitle = styled.h2`
  color: ${({ theme }) => theme.palette.others.g};
  font-size: 30px;
  line-height: 35px;
  margin-top: 0px;
  margin-bottom: 24px;
  max-width: 800px;
`;
const PictureSection = styled.div`
  float: right;
  margin-right: 200px;
  margin-left: 100px;

  ${media.down.md`
    margin-right:0px;
    margin-left:0px;
  `}
`;
const Picture = styled.img`
  width: 115%;

  ${media.down.md`
    display: none;
  `}
`;

const NumbersSection = styled.div`
  min-height: 280px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: ${({ theme }) => theme.palette.success.light};
  margin-top: 200px;
  margin: 0 -15px;
  border-radius: 0 0 50% 50% / 0 0 100px 100px;
  padding-bottom: 25px;
  ${media.down.sm`
    flex-direction: column;
  `}
`;

const Number = styled.div`
  padding: 0 15px;
  p {
    font-size: 20px;
    ${media.down.sm`
      font-size:16px;
    `}
  }
  max-width: 410px;
`;

const NumbersSubtitle = styled.h3`
  color: ${({ theme }) => theme.palette.others.g};
  font-size: 55px;
  line-height: 10px;
  letter-spacing: -0.04em;
`;

const TeamSection = styled.div`
  color: ${({ theme }) => theme.palette.others.g};
  font-size: 25px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;

const TeamPictureSection = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: wrap;
`;

const TeamPicture = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: ${({ theme }) => theme.palette.others.r};
  margin: 30px -8px;
`;

const TeamPictureTitle = styled.h2`
  ${media.down.sm`
      text-align: center;
    `}
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 25px;
`;

function AboutUs() {
  return (
    <Body>
      <TitleSection>
        <Title>
          Une équipé dédiée à remettre le local au centre des habitudes de
          consommation
        </Title>
      </TitleSection>
      <StorySection>
        <SubtitleSection>
          <Subtitle>
            Notre mission - Vous faire découvrir les producteurs proches de chez
            vous
          </Subtitle>

          <Paragraph>
            A l'origine de Kuupanda, un constat: La difficulté de
            s'approvisionner en produits locaux. Il y a des producteurs partout
            en France, à quelques kilomètres de chez nous. Mais souvent nous ne
            les connaissons pas, et à moins d'aller régulièrement sur leur
            exploitation, c'est compliqué de leur commander des produits.
          </Paragraph>

          <Paragraph>
            Kuupanda c'est donc donné pour objectif de regrouper le plus grand
            nombre de producteurs sur une plateforme, pour vous permettre de
            trouver facilement ceux qui sont autour de chez vous et commander
            leurs produits directement en ligne. Nous voulons permettre au plus
            grand nombre de commander auprès de producteurs respectueux de leurs
            produits et de l'environnement et ainsi remettre le local dans les
            habitudes de consommation.
          </Paragraph>
        </SubtitleSection>
        <PictureSection>
          <Picture src="../images/Illu-farmer.png" />
        </PictureSection>
      </StorySection>

      <NumbersSection>
        <Number>
          <NumbersSubtitle>2018</NumbersSubtitle>
          <p>
            Début du projet. Des premiers producteurs et des premières commandes
          </p>
        </Number>

        <Number>
          <NumbersSubtitle>14</NumbersSubtitle>
          <p>Présents dans 14 départements en France</p>
        </Number>

        <Number>
          <NumbersSubtitle>340</NumbersSubtitle>
          <p>Le nombre de producteur présent sur la plateforme à ce jour</p>
        </Number>
      </NumbersSection>

      <TeamSection>
        <TeamPictureTitle>Une équipe qui grandit</TeamPictureTitle>
      </TeamSection>
      <TeamPictureSection>
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Simon.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Josh.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Pablo.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Priscille.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Isaac.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Arnaud.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Eleana.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Jorge.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Claire.png" />
        <TeamPicture src="https://s3.eu-west-3.amazonaws.com/kuu.public/img/team/Carlos.png" />
      </TeamPictureSection>
    </Body>
  );
}

export default AboutUs;
