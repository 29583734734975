import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";

// Styleds
const StyledInput = styled(TextField)``;
const StyledInputWithoutBorders = styled(InputBase)`
  border-radius: 8px;
  padding: 4px 14px;
  background-color: white;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;
const Label = styled(InputLabel)`
  margin-bottom: 5px;
`;

const Input = ({
  className,
  disabled,
  id,
  name,
  value,
  required,
  onChange,
  type,
  label,
  placeholder,
  fullWidth,
  internalLabel,
  variant,
  pattern,
  size,
  error,
  rows,
  multiline,
  inputProps,
  withoutBorders,
  helperText,
}) => {
  return (
    <>
      {label && <Label htmlFor={id}>{required ? `${label}*` : label}</Label>}
      {!withoutBorders ? (
        <StyledInput
          className={className}
          size={size}
          variant={variant}
          id={id}
          fullWidth={fullWidth}
          label={internalLabel}
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          error={error}
          rows={rows}
          multiline={multiline}
          inputProps={inputProps}
          helperText={helperText}
        />
      ) : (
        <StyledInputWithoutBorders
          className={className}
          size={size}
          id={id}
          fullWidth={fullWidth}
          label={internalLabel}
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          multiline={multiline}
          inputProps={inputProps}
        />
      )}
    </>
  );
};

Input.protoTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  internalLabel: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "email", "password", "checkbox"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  pattern: PropTypes.string,
  size: PropTypes.string,
  withoutBorders: PropTypes.bool,
};

Input.defaultProps = {
  className: "",
  disabled: false,
  error: false,
  id: undefined,
  label: null,
  internalLabel: null,
  type: "text",
  value: "",
  placeholder: undefined,
  onChange: () => {},
  required: false,
  fullWidth: false,
  variant: "outlined",
  pattern: "",
  size: "small",
  multiline: false,
  withoutBorders: false,
};

export default Input;
