import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useScrollTrigger, Zoom } from "@material-ui/core";
import { scrollTo } from "../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export function ScrollTopContainer({
  children,
  threshold,
  target,
  scrollOptions,
}) {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold || 200,
  });

  const handleClick = () => {
    scrollTo(target, scrollOptions);
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTopContainer.propTypes = {
  children: PropTypes.any,
  scrollOptions: PropTypes.object,
  target: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
  threshold: PropTypes.number,
};

ScrollTopContainer.defaultProps = {
  scrollOptions: {},
  threshold: 0,
};
