import React from "react";
import { Box, CardActions, CardContent, Card } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

type LoadingProductItemProps = {
  mediaHeight?: number;
  contentHeight?: number;
};

export default function ProductItemLoader({
  mediaHeight = 180,
  contentHeight = 60,
}: LoadingProductItemProps) {
  return (
    <Card>
      <Skeleton
        animation="wave"
        variant="rect"
        style={{ height: mediaHeight }}
      />
      <CardContent style={{ paddingBottom: 0 }}>
        <Box style={{ height: contentHeight }}>
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Box
          flexGrow={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Skeleton
            animation="wave"
            height={32}
            width={50}
            style={{ transform: "none" }}
          />
        </Box>
      </CardActions>
    </Card>
  );
}
