import React, { HTMLAttributes, InputHTMLAttributes } from "react";
import { ReactComponent as SearchIcon } from "../TopBar/granvillage-search-icon.svg";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #cfd2d7;
  align-items: center;
`;

const Input = styled.input`
  transition: all 0.4s ease;
  position: relative;
  flex: 1 1 auto;
  align-items: stretch;
  width: 1%;
  min-width: 0;
  padding: 7px 5px;
  font-weight: 400;
  font-size: 15px;
  :focus {
    font-size: 10px;
  }
  border: none;
  margin-left: -1px;
  outline-style: none;
`;

const IconWrapper = styled.div`
  line-height: 1;
  color: #85898c;
  text-align: center;
  white-space: nowrap;
  border-right: 0;
  padding: 9px 5px 7px 10px;
  svg {
    fill: #85898c;
    height: 20px;
    width: 15px;
  }
`;

type Props = {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
} & HTMLAttributes<HTMLDivElement>;

const Searcher = React.forwardRef<HTMLInputElement, Props>(
  ({ inputProps, ...rest }, inputRef) => {
    return (
      <Container {...rest}>
        <IconWrapper>
          <SearchIcon />
        </IconWrapper>
        <Input {...inputProps} ref={inputRef} />
      </Container>
    );
  }
);

export default Searcher;
