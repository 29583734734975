import React from "react";
import styled from "styled-components";
import media from "../Helpers/media";
import { Button } from "@material-ui/core";

const Body = styled.div`
  color: ${({ theme }) => theme.palette.grey["800"]};
  font-size: 15px;
`;

const TitleSection = styled.div`
  height: 280px;
  background-image: linear-gradient(
    319deg,
    ${({ theme }) => theme.palette.secondary.main},
    ${({ theme }) => theme.palette.primary.main} 100%,
    #fff
  );
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  border-radius: 0 0 50% 50% / 0 0 100px 100px;
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  padding: 0px 500px;
  color: white;
  text-align: center;
  font-size: 35px;

  ${media.down.md`
    padding : 0px 20px;
  `}

  ${media.down.sm`
      font-size: 25px;
      padding : 0px 20px;
    `}
`;

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
`;

const IntroductionSection = styled.div`
  line-height: 25px;

  ${media.down.md`
    margin-left: 20px;
    text-align: left;
    padding: 0px 30px;
  `}
  a {
    text-decoration: none;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 25px;
`;

const IntroductionSubtitle = styled.h2`
  color: ${({ theme }) => theme.palette.others.g};
  font-size: 30px;

  ${media.down.sm`
    text-align: left;
  `}
`;

const TermsSection = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
  padding: 0 70px;
  ${media.down.md`
    flex-direction: column;
    margin-bottom: 200px;
    padding: 0px 30px;
  `}
`;

const TermsSubtitle = styled.h3`
  color: ${({ theme }) => theme.palette.others.g};
  font-size: 25px;
`;

const TermsImage = styled.img`
  height: 300px;
  ${media.down.sm`
    height: auto;
    max-width 80%;
    padding-top: 20px;
  `}
`;

const TermsItem = styled.div`
  padding: 0 15px;
`;

function HowTo() {
  return (
    <Body>
      <TitleSection>
        <Title>
          Cherchez les producteurs proches de chez vous, et commandez en direct.
        </Title>
      </TitleSection>
      <Content>
        <IntroductionSection>
          <IntroductionSubtitle>Si vous êtes consommateur</IntroductionSubtitle>

          <Paragraph>
            Vous cherchez des producteurs ou des produits de qualité proches de
            chez vous, voici comment faire :
          </Paragraph>
        </IntroductionSection>
        <TermsSection>
          <TermsItem>
            <TermsImage src="../images/Capture-d’écran-du-2021-01-31-17-40-11.png" />

            <TermsSubtitle>1. Renseignez votre ville</TermsSubtitle>

            <Paragraph>
              Sur la page principale, vous devez renseigner votre ville et/ou le
              nom d'un producteur (si vous recherchez un producteur spécifique).
              Vous verrez tous les producteurs disponibles autour de votre lieu
              de recherche
            </Paragraph>
          </TermsItem>

          <TermsItem>
            <TermsImage src="../images/Capture-d’écran-du-2021-01-31-17-40-29.png" />

            <TermsSubtitle>
              2. Découvrez les produits de vos producteurs
            </TermsSubtitle>

            <Paragraph>
              Vous pouvez ensuite cliquer sur les producteurs. Vous découvrirez
              des informations sur leur exploitation et la liste de leurs
              produits. Vous pouvez ajouter ces produits dans votre panier et
              continuer à découvrir d'autres producteurs par la suite
            </Paragraph>
          </TermsItem>

          <TermsItem>
            <TermsImage src="../images/Capture-d’écran-du-2021-01-31-17-40-44.png" />

            <TermsSubtitle>
              3. Validez votre panier et choisissez vos méthodes de retrait
            </TermsSubtitle>

            <Paragraph>
              Une fois que vous avez fini de choisir vos produits. Vous pouvez
              valider votre commande en cliquant sur le panier en bas à droite
              de la page (visible à partir du moment où au moins un produit est
              ajouté). Suivez ensuite les étapes du checkout
            </Paragraph>
          </TermsItem>
        </TermsSection>

        <IntroductionSection>
          <IntroductionSubtitle>Si vous êtes producteur</IntroductionSubtitle>
          <a
            href="https://gestion.kuupanda.com/"
            className="af-class-button-2 w-button"
          >
            <Button variant="contained" color="primary">
              Rendez-vous ici
            </Button>
          </a>
        </IntroductionSection>
      </Content>
    </Body>
  );
}

export default HowTo;
