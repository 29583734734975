import TextTruncate from "react-truncate";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Link, Typography } from "@material-ui/core";

import { convertToMultiLine, countLines, isHtml } from "../../services/utils";
import styled from "styled-components";

const MAX_LINES = 4;

const Wrapper = styled.div`
  width: fit-content;
`;

// We set a fontfamily in appTheme.js for this to work properly, workaround found:
// https://github.com/pablosichert/react-truncate/issues/16#issuecomment-270994604

export function PresentationContent(props) {
  const [lineMax, setLineMax] = useState(MAX_LINES);

  const { numberLines, description } = useMemo(() => {
    let lines = 0;
    let description = props.presentation;
    const isHtmlString = isHtml(props.presentation);

    if (isHtmlString) {
      lines = countLines(props.presentation);
    } else {
      description = convertToMultiLine(props.presentation);
      lines = Object.keys(description).length;
    }

    return { numberLines: lines, description: description };
  }, [props.presentation]);

  return (
    <Wrapper>
      {numberLines > MAX_LINES ? (
        <Typography>
          <TextTruncate
            lines={lineMax}
            ellipsis={
              <span>
                ...
                <Link
                  onClick={() => setLineMax(null)}
                  style={{ cursor: "pointer" }}
                >
                  Voir Plus
                </Link>
              </span>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </TextTruncate>
          {!lineMax && (
            <Link
              component={"span"}
              onClick={() => setLineMax(MAX_LINES)}
              style={{ marginLeft: 8, cursor: "pointer" }}
            >
              Voir Moins
            </Link>
          )}
        </Typography>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: props.presentation }} />
      )}
    </Wrapper>
  );
}

PresentationContent.propTypes = { presentation: PropTypes.string };
