import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@material-ui/core";

// Styled
import styled from "styled-components";
import { StepContainer } from "../Style";

// Components
import ContactPointMain from "../../Common/ContactPointMain";

// Proptypes
import { CartCheckoutShape } from "../proptypes";

// Helpers
import {
  getMaxAvailableDate,
  getMaxPreparationTime,
} from "../../Helpers/contactPoint";
import { formatCart } from "../../Helpers/cart";
import { isEmpty } from "lodash";

const Container = styled(Grid)``;

const emptyFunc = () => {};
export default function Address({
  cart,
  onChangeRecoveryInfo,
  width,
  cartTotals,
  disableNextButton,
  user,
}) {
  const cartByProducer = useMemo(() => formatCart(cart), [cart]);
  const availableDate = useMemo(() => getMaxAvailableDate(cart), [cart]);
  const preparationTime = useMemo(() => getMaxPreparationTime(cart), [cart]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isEmpty(user)) {
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) {
    return (
      <Container container alignItems="center" justify="center" spacing={3}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container container spacing={0}>
      {Object.keys(cartByProducer).map((producer, index) => {
        const { contactPoints, products, vendorId, name } =
          cartByProducer[producer];
        return (
          <StepContainer key={vendorId} width={width}>
            <ContactPointMain
              title={`${index + 1}. ${name}`}
              availableDate={availableDate}
              contactPoints={contactPoints}
              vendorId={vendorId}
              targetInput="recovery_info_value"
              setDeliveryRate={emptyFunc}
              resetDeliveryRate={emptyFunc}
              onStateCheck={emptyFunc}
              vendorIdx={index + 1}
              vendorName={name}
              showVendorName={false}
              totalAmount={cartTotals.productTotal}
              products={products}
              preparationTime={preparationTime}
              onRecoveryInfoChange={onChangeRecoveryInfo}
              showDescription
              disableNextButton={disableNextButton}
              isSimplifiedStore={false}
              cart={cart}
              clientAddress={
                user.addresses && user.addresses.length && user.addresses[0]
              }
            />
          </StepContainer>
        );
      })}
    </Container>
  );
}

Address.propTypes = {
  cart: CartCheckoutShape.isRequired,
  onChangeRecoveryInfo: PropTypes.func.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

Address.defaultProps = {};
