import React from "react";
import { Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
// "carrot" icon theme
import markerIconPath from "./marker-carrot.svg";
import markerIconHoverPath from "./marker-carrot-hoverlist.svg";
//  classic marker theme
// import markerIcon from './place-24px.svg'
// import markerIconHover from './place-24px-hoverlist.svg'

export const MARKER_ICON_SIZE = 50;

export class MarkerWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.markerIcon = {
      url: markerIconPath,
      // This marker is 50 pixels wide by 50 pixels high.
      size: new window.google.maps.Size(MARKER_ICON_SIZE, MARKER_ICON_SIZE),
      // The origin for this image is (0, 0).
      // origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      // anchor: new google.maps.Point(18, 36)
    };
  }

  _onClick = () => {
    this.props.onClick({
      markerID: this.props.marker.id,
      markerPosition: this.props.marker.coord,
      userID: this.props.marker.userID,
    });
  };

  _onLoad = (marker) => {
    // this is a hack, we're setting arbitrary values in the marker object.
    // Those props we're setting are not "supported" on the doc.
    // We're leveraging the `setValues` method which comes from the MVCObject which the Marker Class inherit
    // https://developers.google.com/maps/documentation/javascript/reference/marker#Marker
    // this will allow us to then to market.get('id')
    // Risk: MVCObject seems to be a base low level object so not likely to be updated often
    marker.setValues({
      id: this.props.marker.id,
      userID: this.props.marker.userID,
    });
  };

  getIcon() {
    const { isHoverFromList } = this.props;

    // new icon in case the popup is open (and hence mask the default icon)
    // if (isOpen) return markerIconClicked
    return isHoverFromList ? markerIconHoverPath : this.markerIcon;
  }
  render() {
    const { onClick, isOpen, isHoverFromList, ...rest } = this.props;
    const icon = this.getIcon();
    return (
      <Marker
        animation={isHoverFromList ? window.google.maps.Animation.BOUNCE : null}
        icon={icon}
        onClick={this._onClick}
        onLoad={this._onLoad}
        {...rest}
      />
    );
  }
}

MarkerWrapper.propTypes = {
  isHoverFromList: PropTypes.bool,
  isOpen: PropTypes.bool,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
