import React from "react";
import styled from "styled-components";
import { breakpoints, compose, spacing, palette } from "@material-ui/system";
import { Grid, Link } from "@material-ui/core";

const Box = styled(Grid)`
  ${breakpoints(compose(spacing, palette))}
`;

const Container = styled(Box)<{ mobileMode?: boolean }>`
  padding: 0 16px;
  color: white;
  text-align: ${(props) => (props.mobileMode ? "center" : "left")};
`;

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
`;

const ListContainer = styled.ul<{
  mobileMode?: boolean;
  maxItemsPerColumn?: number;
}>`
  margin: 0;
  padding-left: 10px;
  list-style: none;
  display: grid;
  grid-template-rows: ${(props) =>
    props.mobileMode || !props.maxItemsPerColumn
      ? "auto"
      : `repeat(${props.maxItemsPerColumn}, 1fr)`};
  grid-auto-flow: ${(props) =>
    props.maxItemsPerColumn && !props.mobileMode ? "column" : "row"};
  column-gap: 16px;
`;

const Item = styled.li`
  display: inline-block;
  margin-bottom: 15px;
  a {
    color: white;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export type ListData = {
  title: string;
  items: { description: string; url: string }[];
  maxItemsPerColumn?: number;
};

type Props = ListData & { mobileMode?: boolean } & React.ComponentProps<
    typeof Box
  >;

const List: React.FC<Props> = ({
  mobileMode,
  title = "",
  items = [],
  maxItemsPerColumn,
  ...boxAndGridProps
}) => {
  return (
    <Container mobileMode={mobileMode} {...boxAndGridProps}>
      <Title>{title}</Title>
      <ListContainer
        mobileMode={mobileMode}
        maxItemsPerColumn={maxItemsPerColumn}
      >
        {items.map(({ description, url }) => (
          <React.Fragment>
            <Item>
              <Link href={url} underline="none">
                {description}
              </Link>
            </Item>
          </React.Fragment>
        ))}
      </ListContainer>
    </Container>
  );
};

export default List;
