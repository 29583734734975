import React, { useCallback } from "react";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PropTypes from "prop-types";
import parse from "autosuggest-highlight/parse";
import PinDropIcon from "@material-ui/icons/PinDrop";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import { withSimpleOnChange } from "../ProducerList/Filters/withSimpleOnChange";
import { castLocation } from "../ProducerList/searchService";

const SimpleTextField = withSimpleOnChange(TextField);

// those services are loaded by LoadScript from @react-google-maps/api in MapWithApi
const geocodingService = { current: null };
const autocompleteService = { current: null };

function checkServices() {
  if (window.google) {
    if (!autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!geocodingService.current) {
      geocodingService.current = new window.google.maps.Geocoder();
    }
  }
}

function getPredictionRequest(search) {
  return {
    input: search,
    types: ["geocode"],
    componentRestrictions: { country: "fr" },
  };
}

/**
 * This is a port of https://material-ui.com/components/autocomplete/#google-maps-place
 */
export function AutoCompleteLocationInput({
  onChange,
  onBlur,
  onSubmit,
  placeholder,
  label,
  margin,
  value,
  styles,
}) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useMemo(
    () =>
      _.throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          getPredictionRequest(request),
          callback
        );
      }, 200),
    []
  );

  const _handleSelect = useCallback(
    (event, option) => {
      // when we select a proposed answer, option is an object.
      // In the case that we want to update that location from the read-only view,
      // the field is created again and initialized with a string like "66400 Céret, France"
      const query = _.isString(option)
        ? { address: option }
        : { placeId: option.place_id };

      geocodingService.current.geocode(query, (result, status) => {
        if (status !== "OK") {
          // TODO handle error, see definitions here: https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingStatusCodes
        }
        if (!result.length) return;
        const value = {
          address: result[0].formatted_address,
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        };
        castLocation(value);
        onChange(value);
        onSubmit();
      });
    },
    [onChange, onSubmit]
  );

  const _handleChange = useCallback(
    async (address) => {
      onChange({ ...value, address });
    },
    [onChange, value]
  );

  React.useEffect(() => {
    checkServices();

    const inputValue = value.address;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      setOptions(results || []);
    });
  }, [value, fetch]);

  const handleGeoSuccess = (pos) => {
    const { latitude, longitude } = pos.coords;

    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };

    geocodingService.current.geocode({ location: latlng }, (result, status) => {
      if (status === "OK") {
        const city = result[0].address_components.filter((address) =>
          address.types.includes("locality")
        )[0].long_name;
        const country = result[0].address_components.filter((address) =>
          address.types.includes("country")
        )[0].long_name;

        setLoading(false);

        onChange({ address: `${city}, ${country}`, ...latlng });
        onSubmit();
      }
    });
  };

  const handleGeoError = (error) => {
    setLoading(false);
    console.log(error); //TODO: maybe a prompt if user declines permission
  };

  const geoLocate = (pos) => {
    const { geolocation } = navigator;

    setLoading(true);

    if (geolocation) {
      geolocation.getCurrentPosition(handleGeoSuccess, handleGeoError);
    }
  };

  return (
    <Autocomplete
      style={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      inputValue={value.address}
      onChange={_handleSelect}
      onBlur={onBlur}
      disableClearable
      renderInput={(params) => (
        <SimpleTextField
          {...params}
          variant={"outlined"}
          label={label}
          autoFocus
          autoComplete="false"
          placeholder={placeholder}
          margin={margin}
          InputProps={{
            style: styles,
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PinDropIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {!loading ? (
                  <IconButton onClick={geoLocate}>
                    <MyLocationIcon />
                  </IconButton>
                ) : (
                  <CircularProgress color="secondary" size={20} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
          onChange={_handleChange}
        />
      )}
      renderOption={(option) => <OptionRow option={option} />}
    />
  );
}

AutoCompleteLocationInput.protoTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.bool,
  styles: PropTypes.object,
};

AutoCompleteLocationInput.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  onBlur: () => {},
  value: null,
  placeholder: "",
  label: "",
  margin: "none",
  styles: {},
};

const useOptionIconStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey["800"],
    marginRight: theme.spacing(2),
  },
}));

const OptionRow = React.memo(function OptionRow({ option, iconClassName }) {
  const classes = useOptionIconStyles();
  const matches = option.structured_formatting.main_text_matched_substrings;
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length])
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon className={classes.icon} />
      </Grid>
      <Grid item xs>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
        <Typography variant="body2" color="textSecondary">
          {option.structured_formatting.secondary_text}
        </Typography>
      </Grid>
    </Grid>
  );
});
