import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, CardContent, Divider, Hidden } from "@material-ui/core";
import styled from "styled-components";

//context
import {
  StoreTabKey,
  usePresentation,
} from "../../../Context/PresentationContext";

//components
import { TheNavBar } from "../../components/TheNavBar";
import { ContactPointRow } from "./location/ContactPointRow";
import { DeliveryRow } from "./location/DeliveryRow";
import Skeleton from "@material-ui/lab/Skeleton";
import LoadScriptOnlyIfNeeded from "../../../Common/LoadScriptOnlyIfNeeded";
import { PresentationContent } from "./PresentationContent";
import StoreContent from "../storeContent/StoreContent";
import { LocationContent } from "./location/LocationContent";
import { InformationContent } from "./InformationContent";
import GlobalLoader from "../../../Common/GlobalLoader";

// hooks
import { useTabsWithRoutes } from "../../../Hooks/useTabsWithRoutes";
import axios, { AxiosResponse } from "axios";
import { useWhichBrand } from "../../../Hooks/useWhichBrand";

const API_KEY = "AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg";
const StyledDivider = styled(Divider)`
  margin: 15px 0;
`;

const StyledCard = styled(Card)`
  background: none;
  overflow: inherit;
`;

const StyledCardContent = styled(CardContent)<{ $noBg: boolean }>`
  margin-top: 32px;
  background: ${(props) => (props.$noBg ? "inherit" : "white")};
`;

const Container = React.memo(LoadScriptOnlyIfNeeded);

type StoreHeaderProps = {
  store: any;
  contactPoints: any[];
  deliveryPoints: any[];
  loading: boolean;
  products: any[];
  categoryNameMap: any[];
  categories: any[];
  cart: any;
  handleCartChange: (cart: any) => void;
  onRemoveFromCart: () => void;
  isStoreClosed: boolean;
  onAddToCart: (product: any) => void;
  handleSearchProducts: (search: string) => void;
  externalId?: string;
};

export const StoreHeader = React.memo(function StoreHeader({
  store,
  deliveryPoints,
  contactPoints,
  loading,
  products,
  categoryNameMap,
  categories,
  cart,
  handleCartChange,
  onRemoveFromCart,
  isStoreClosed,
  onAddToCart,
  handleSearchProducts,
  externalId,
}: StoreHeaderProps) {
  const {
    presentationOptions: { storeTabs, showStoreOnTab, defaultTab },
  } = usePresentation();

  const excludeTabs = React.useMemo(
    () => ({
      delivery: !deliveryPoints?.length,
    }),
    [deliveryPoints]
  );

  const { tabsArr, currentTabKey, handleTabClick, cardContentNoBg } =
    useTabsWithRoutes({
      tabs: storeTabs,
      defaultTab,
      excludeTabs,
    });

  const wrappedTabClick = useCallback(
    (tabKey: StoreTabKey) =>
      externalId
        ? handleTabClick(tabKey, { externalId })
        : handleTabClick(tabKey),
    [externalId, handleTabClick]
  );

  const tabContents: Partial<{ [k in StoreTabKey]: JSX.Element }> = {
    description: <PresentationContent presentation={store.presentation} />,
    store: (
      <StoreContent
        products={products}
        categoryNameMap={categoryNameMap}
        loading={loading}
        categories={categories}
        cart={cart}
        handleCartChange={handleCartChange}
        store={store}
        onRemoveFromCart={onRemoveFromCart}
        isStoreClosed={isStoreClosed}
        onAddToCart={onAddToCart}
        handleSearchProducts={handleSearchProducts}
        fixed={false}
      />
    ),
    contactPoints: (
      <LocationContent
        contactPoints={contactPoints}
        RowComponent={ContactPointRow}
        mapTitle={"Points de retraits"}
        isDelivery={false}
      />
    ),
    delivery: (
      <LocationContent
        contactPoints={deliveryPoints}
        mapTitle={
          <Box display={"flex"} alignItems={"center"}>
            Livraisons
          </Box>
        }
        RowComponent={DeliveryRow}
        isDelivery={true}
      />
    ),
    information: <InformationContent store={store} />,
  };

  if (loading) {
    return (
      <Card>
        <Hidden smDown>
          <TheNavBar
            value={0}
            onChange={() => void 0}
            tabStatic={Array(6)
              .fill(null)
              .map((e, i) => ({
                label: <Skeleton animation="wave" height={20} width="60%" />,
              }))}
          />
          <CardContent>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ height: 150, padding: 16 }}
            >
              {Array(storeTabs.length)
                .fill(null)
                .map((e, i) => (
                  <Skeleton
                    key={i}
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 10 }}
                  />
                ))}
            </Box>
          </CardContent>
        </Hidden>
        <Hidden mdUp>
          {Array(4)
            .fill(null)
            .map((e, i) => (
              <Box
                key={`loadingMobileContainerTab${i}`}
                height={65}
                style={{
                  padding: "0 24px 0 24px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, .03)",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="circle"
                  height={20}
                  width={20}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={20}
                  width="80%"
                />
              </Box>
            ))}
        </Hidden>
      </Card>
    );
  }

  return (
    //@ts-ignore
    <Container googleMapsApiKey={API_KEY} loadingElement={<GlobalLoader />}>
      <StyledCard elevation={0}>
        <TheNavBar
          value={currentTabKey}
          onChange={wrappedTabClick}
          tabStatic={tabsArr}
        />
        <StyledCardContent $noBg={cardContentNoBg}>
          {tabContents[currentTabKey as StoreTabKey] || null}
          {!showStoreOnTab && (
            <>
              {currentTabKey !== "store" && <StyledDivider />}
              <StoreContent
                products={products}
                categoryNameMap={categoryNameMap}
                loading={loading}
                categories={categories}
                cart={cart}
                handleCartChange={handleCartChange}
                store={store}
                onRemoveFromCart={onRemoveFromCart}
                isStoreClosed={isStoreClosed}
                onAddToCart={onAddToCart}
                handleSearchProducts={handleSearchProducts}
                fixed={false}
              />
            </>
          )}
        </StyledCardContent>
      </StyledCard>
    </Container>
  );
});
