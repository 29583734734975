import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Hidden, Card, Box } from "@material-ui/core";

//components
import ProductList from "../products/ProductList";
import { CategoryList } from "../storeNavigation/CategoryList";

const StyledBox = styled(Box)`
  margin-top: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.others.brown} !important;
  background-color: ${({ theme }) => theme.palette.info.light};
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.others.yellow};
  border-radius: 0.25rem;
`;

const ListContainer = styled.div`
  margin-top: 10px;
`;

interface StoreProps {
  products: Array<{ count: string; category: string; products: Array<any> }>;
  categoryNameMap: any;
  loading: boolean;
  categories: any;
  cart: any;
  handleCartChange: (cart: any) => void;
  store: any;
  onRemoveFromCart: any;
  isStoreClosed: boolean;
  onAddToCart: (a: any) => void;
  handleSearchProducts: (search: string) => void;
  fixed: boolean;
}

const StoreContent = (props: StoreProps) => {
  const [currentProductsList, setCurrentProductsList] = useState(
    props.products
  );

  useEffect(() => {
    setCurrentProductsList(props.products);
  }, [props.products]);

  const messageStoreClosed = props.loading
    ? ""
    : props.store.closedStoreMessage;

  const handleAddToCard = (cart: any) => {
    return props.onAddToCart(cart);
  };

  return (
    <>
      {props.isStoreClosed ? (
        <StyledBox>
          {messageStoreClosed !== ""
            ? messageStoreClosed
            : "Cette boutique est momentanément fermée"}
        </StyledBox>
      ) : (
        <>
          <Hidden smUp>
            <ListContainer>
              <CategoryList
                products={props.products}
                categoryNameMap={props.categoryNameMap}
                loading={props.loading}
                handleSearchProducts={props.handleSearchProducts}
                fixed={props.fixed}
              />
            </ListContainer>
          </Hidden>
          <ProductList
            products={currentProductsList}
            categories={props.categories}
            cart={props.cart}
            loading={props.loading}
            onCartChange={props.handleCartChange}
            onAddToCart={handleAddToCard}
            onRemoveFromCart={props.onRemoveFromCart}
          />
        </>
      )}
    </>
  );
};

export default StoreContent;
