import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  getDay,
  startOfDay,
} from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { getDeliveryDateFromLocalStorage } from "../Checkout/Components/LocalStorageRepository";
import {
  calculateAvailableRange,
  calculateDeliveryRate,
  isValidDate,
  resetHour,
  isCpDelivery,
  isCpChronopost,
  calculateDeliveryTotal,
  calculateServiceType,
  splitPackagesToWeight,
  calculateTotalWeight,
} from "../Checkout/utils";
import constants from "../Common/constants";
import { getProductsForProducer } from "./cart";

export function getContactPointInfo(contactPoints: any, contactPoint: any) {
  return (
    contactPoints &&
    contactPoint &&
    contactPoints.find((it: any) => it._id === contactPoint.value)
  );
}

const dayMapping = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

function getValidWeekDays(schedule = {}) {
  return Object.entries(schedule)
    .filter(([, value]: any) => value.values && value.values.length > 0)
    .map(([key]) => (dayMapping as any)[key]);
}

function getMinDate(
  contactPointInfo: any,
  preparationTime: number = 1,
  contactPoints: any,
  contactPoint: any
) {
  const defaultMinDate = addDays(new Date(), preparationTime);
  if (contactPointInfo || contactPoints) {
    const cpInfo =
      contactPointInfo || getContactPointInfo(contactPoints, contactPoint);
    const date =
      cpInfo &&
      cpInfo.activityPeriod &&
      cpInfo.activityPeriod.startDate &&
      new Date(cpInfo.activityPeriod.startDate);
    if (isValidDate(date)) {
      return defaultMinDate > date ? defaultMinDate : date;
    }
  }
  return defaultMinDate;
}

function getMaxDate(
  contactPointInfo: any,
  contactPoint: any,
  contactPoints: any
) {
  if (contactPointInfo || contactPoint) {
    const cpInfo =
      contactPointInfo || getContactPointInfo(contactPoints, contactPoint);
    const date =
      cpInfo &&
      cpInfo.activityPeriod &&
      cpInfo.activityPeriod.endDate &&
      new Date(cpInfo.activityPeriod.endDate);
    if (isValidDate(date)) {
      return endOfDay(date);
    }
  }
  return addMonths(endOfDay(new Date()), 5);
}

export function getAvailableDates(
  contactPointInfo: any,
  availableDate: any,
  actualPreparationTime: any,
  contactPoints: any,
  contactPoint: any
) {
  const minPreparationTime = Math.max(actualPreparationTime, 0);
  let preparationTime = minPreparationTime;
  const daysAvailableDate = differenceInDays(
    availableDate ? new Date(availableDate) : new Date(),
    new Date()
  );
  if (daysAvailableDate > minPreparationTime) {
    preparationTime = daysAvailableDate;
  }
  const startRange = getMinDate(
    contactPointInfo,
    preparationTime,
    contactPoints,
    contactPoint
  );
  const endRange = getMaxDate(contactPointInfo, contactPoint, contactPoints);
  if (endRange < startRange) {
    return [];
  }
  const range = eachDayOfInterval({
    start: startRange,
    end: endRange,
  });
  const validWeekDays = getValidWeekDays(contactPointInfo.schedule);
  return range.filter((it) => validWeekDays.includes(getDay(it)));
}

export function getContactPointAvailableDate({
  contactPoint,
  currentDate,
  contactPoints,
  vendorId,
  availableDate,
  preparationTime,
}: {
  contactPoint: any;
  currentDate: Date;
  contactPoints: any;
  vendorId: string;
  availableDate: Date;
  preparationTime: number;
}) {
  const contactPointInfo = getContactPointInfo(contactPoints, contactPoint);
  const availableDates = getAvailableDates(
    contactPointInfo,
    availableDate,
    preparationTime,
    contactPoints,
    contactPoint
  );
  // retrieve previous date
  const storedDate = getDeliveryDateFromLocalStorage(
    contactPointInfo.type,
    vendorId
  );
  let date = storedDate || currentDate;
  // we use getTime to avoid comparing date objects with includes
  // which compares by object reference and not value
  if (
    !date ||
    !availableDates
      .map((it) => it.getTime())
      .includes(startOfDay(date).getTime())
  ) {
    // TODO: selecting the nearest DATE would be better!
    // eslint-disable-next-line prefer-destructuring
    date = availableDates[0];
  }

  return resetHour({ date, contactPoint: contactPointInfo });
}

function isContactPointsValid(
  contactPoint: any,
  date: Date,
  contactPoints: any,
  clientAddress: any,
  cart: any,
  exceedTotalWeight: boolean
) {
  // contact point is selected
  if (!contactPoint) {
    return false;
  }

  // date is selected
  if (!date) {
    return false;
  }

  // if cp is not delivery, inputs are valid
  const contactPointInfo = getContactPointInfo(contactPoints, contactPoint);

  const isDelivery = isCpDelivery(contactPointInfo.type);

  if (!isDelivery) {
    return true;
  }

  //if exceeds relay weight
  if (
    contactPointInfo.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
    exceedTotalWeight
  ) {
    return false;
  }

  // if cp is delivery client address must be selected
  if (!clientAddress) {
    return false;
  }
  const isChronoPost = isCpChronopost(contactPointInfo.type);

  if (isChronoPost) {
    return !checkHasUndeliverableProducts(cart);
  }

  // calculate if there's a range available, if not, it will be invalid.
  return Boolean(
    calculateAvailableRange({
      contactPointInfo,
      clientAddress,
    })
  );
}

export function setInputContent(
  targetInput: any,
  contactPoints: any,
  contactPoint: any,
  vendorId: string,
  clientAddress: any,
  totalAmount: number,
  date: Date,
  products: any,
  onStateCheck: any,
  onRecoveryInfoChange: any,
  comments: any,
  isSimplifiedStore: boolean,
  cart: any,
  relayPoint: any,
  exceedTotalWeight: boolean
) {
  let currentValue: any = {};
  if (targetInput && targetInput.value) {
    try {
      currentValue = JSON.parse(targetInput.value);
    } catch (e) {
      console.log("bad current value");
    }
  }
  const contactPointInfo = getContactPointInfo(contactPoints, contactPoint);

  currentValue[vendorId] = {
    date: date,
    contactPoint: contactPoint,
    contactPointInfo,
    products: products,
    comments,
    shipmentInfo: {
      relayPoint:
        contactPointInfo.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY
          ? relayPoint
          : null,
    },
  };

  const isDelivery = isCpDelivery(contactPointInfo.type);

  const isChronoPost = isCpChronopost(contactPointInfo.type);

  const cartProducts = getProductsForProducer(
    cart,
    vendorId,
    isSimplifiedStore
  );

  const deliveryType = calculateServiceType({
    products: cartProducts,
    contactPoint: contactPointInfo,
  });

  const totalWeight = calculateTotalWeight({ products: cartProducts });

  const packages = splitPackagesToWeight(totalWeight, contactPointInfo);

  if (isDelivery && clientAddress) {
    let deliveryRate = undefined;
    if (isChronoPost) {
      deliveryRate = calculateDeliveryTotal({
        packages,
        products: cartProducts,
        contactPoint: contactPointInfo,
        deliveryType,
        totalAmount,
      });
    } else {
      deliveryRate = calculateDeliveryRate({
        contactPointInfo,
        clientAddress: clientAddress,
        totalAmount: totalAmount,
      });
    }
    currentValue[vendorId].clientAddress = clientAddress;
    currentValue[vendorId].delivery = {
      rate: deliveryRate,
    };
  }
  const valid = isContactPointsValid(
    contactPoint,
    date,
    contactPoints,
    clientAddress,
    cart,
    exceedTotalWeight
  );

  if (targetInput) {
    targetInput.value = JSON.stringify(currentValue);
  }
  onStateCheck(valid);
  onRecoveryInfoChange({
    recoveryInfo: currentValue,
    valid,
    producerId: vendorId,
  });
}

export function isContactPointValid(
  contactPointInfo: any,
  availableDate: Date,
  preparationTime: number,
  contactPoints: any,
  contactPoint: any
) {
  // available date not needed for chronopost
  if (isContactPointChronopost(contactPointInfo)) {
    return true;
  }

  return (
    getAvailableDates(
      contactPointInfo,
      availableDate,
      preparationTime,
      contactPoints,
      contactPoint
    ).length > 0
  );
}

function isContactPointTakeAway(cp: any) {
  return !cp.type || cp.type === "TAKE_AWAY";
}

function isContactPointChronopost(cp: any) {
  return (
    !cp.type ||
    cp.type === "CHRONOPOST" ||
    cp.type === "CHRONOPOST_RELAY" ||
    cp.type === "CHRONOPOST_VITI"
  );
}

export function checkIfIsSelectable(
  cp: any,
  hasFreshProducts: boolean,
  exceedTotalWeight: boolean,
  totalAmount: number,
  cart: any
) {
  const isChronoPost = isCpChronopost(cp.type);

  const hasUnDeliverableProducts = checkHasUndeliverableProducts(cart);
  if (isChronoPost && hasUnDeliverableProducts) {
    return false;
  }

  if (
    isChronoPost &&
    !cp.modes.includes(constants.CHRONOPOST_MODES.FRESH) &&
    hasFreshProducts
  ) {
    return false;
  }

  if (
    cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
    exceedTotalWeight
  ) {
    return false;
  }

  return !(cp.minimumDeliveryAmount && cp.minimumDeliveryAmount > totalAmount);
}

export function createContactOptions(
  contactPoints: any,
  hasFreshProducts: boolean,
  exceedTotalWeight: boolean,
  totalAmount: number,
  cart: any
) {
  return contactPoints.map((cp: any) => ({
    value: cp._id || cp.id,
    label: `${cp.name} ${
      (isContactPointTakeAway(cp) && cp.address && cp.address.text) || ""
    }`,
    comment: cp.comment,
    isRelay: cp.type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY,
    subventionRates: cp.subventionRates ? cp.subventionRates : [],
    isSelectable: checkIfIsSelectable(
      cp,
      hasFreshProducts,
      exceedTotalWeight,
      totalAmount,
      cart
    ),
  }));
}

export function getMaxAvailableDate(cart: any) {
  return Object.values(cart).reduce(
    (maxAvailableDate: any, { product }: any) => {
      const productDate =
        product.availableDate && new Date(product.availableDate);
      return productDate && productDate > maxAvailableDate
        ? productDate
        : maxAvailableDate;
    },
    new Date()
  );
}

export function getMaxPreparationTime(cart: any) {
  return Object.values(cart).reduce(
    (maxPreparationTime: any, { product }: any) =>
      product.preparationTime && product.preparationTime > maxPreparationTime
        ? product.preparationTime
        : maxPreparationTime,
    0
  );
}

export function checkHasUndeliverableProducts(cart: any) {
  return Object.keys(cart).some((it) =>
    cart[it].product.excludedContactPointTypes.includes(
      constants.CONTACT_POINT_TYPE.CHRONOPOST ||
        constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY ||
        constants.CONTACT_POINT_TYPE.CHRONOPOST_VITI
    )
  );
}

export function checkIfHasColdProducts(cart: any) {
  return Object.keys(cart).some((it) =>
    cart[it].product.storage.includes("COLD")
  );
}
