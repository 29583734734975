import React from "react";
import { forEach, isEmpty, mapValues, pick } from "lodash-es";
import { ALL_CATEGORIES } from "../Common/constants";
import { getFullName } from "../VendorStore/services/utils";
import constants from "../VendorStore/utils/constants";
import useDataApi from "./useDataApi";
import { useMemo } from "react";

const buildProducts = (categories) => {
  const categoryMap = {};
  const allCategories = ALL_CATEGORIES.map((category) => category.value);

  for (const category of categories) {
    const isDefaultCat = allCategories.includes(category.category);

    //We dont "support" custom categories in the marketplace
    const productCategory = isDefaultCat
      ? category.category
      : category.products[0].category;

    if (!categoryMap[productCategory]) {
      categoryMap[productCategory] = [];
    }

    categoryMap[productCategory].push(...category.products);
  }

  if (Object.keys(categoryMap).length > 0) {
    return Object.keys(categoryMap).map((key) => ({
      category: key,
      products: categoryMap[key],
      count: categoryMap[key].length,
    }));
  }

  return [];
};
const formatMarker = (
  id,
  userID,
  storeName,
  name,
  lat,
  lng,
  address,
  comment,
  schedule
) => {
  return {
    id,
    userID,
    name,
    storeName,
    address,
    coord: {
      lat,
      lng,
    },
    comment,
    schedule,
  };
};

const getBannerUrl = (bannerUrl, storePictures) => {
  return (
    (storePictures && storePictures.length > 0 && storePictures[0].url) ||
    bannerUrl
  );
};

const getStore = (store, bannerUrl, isGroup, saleChannel, component) => {
  const { storefront, owner, address } = store;
  const firstLineGroupamaAddress = address.route
    ? [address.street_number, address.route].filter(Boolean).join(", ")
    : "";
  const secondLineGroupamaAddress = [
    address.postal_code,
    address.locality?.toUpperCase(),
  ]
    .filter(Boolean)
    .join(" ");

  return {
    ...pick(owner, ["email", "name", "lastName", "phone"]),
    id: store.id,
    bannerUrl: getBannerUrl(bannerUrl, storefront.pictures),
    profileUrl:
      owner.profilePicture &&
      owner.profilePicture[0] &&
      owner.profilePicture[0].url,
    clientId: store.clientId,
    storeName: storefront.name,
    fullName: getFullName(owner),
    city: address.locality,
    address: address.text,
    presentation: storefront.presentation,
    clientClosedStore: storefront.clientClosedStore,
    closedStoreMessage: storefront.closedStoreMessage,
    productionMethod: owner.productionMethod,
    paymentMethods: store.paymentMethods || [
      { type: constants.PAYMENT_METHOD.CREDIT_CARD },
      { type: constants.PAYMENT_METHOD.CASH },
    ],
    storeSlug: store.clientSlug,
    isGroup,
    saleChannel,
    component,
    ID: store.clientId,
    saleChannelsConfig: store.saleChannelsConfig,
    groupamaAddress:
      firstLineGroupamaAddress || secondLineGroupamaAddress ? (
        <React.Fragment>
          {firstLineGroupamaAddress && (
            <React.Fragment>
              <span>{firstLineGroupamaAddress}</span>
            </React.Fragment>
          )}
          {firstLineGroupamaAddress && secondLineGroupamaAddress && <br />}
          {secondLineGroupamaAddress && (
            <React.Fragment>
              <span>{secondLineGroupamaAddress}</span>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        "None address"
      ),
  };
};

const getContactPoints = (producer) => {
  const cPoints = producer.contactPoints;
  const markers = [];
  cPoints.forEach((cPoint) => {
    if (cPoint.type !== "TAKE_AWAY") return;
    const schedule = getSchedule(cPoint.schedule);
    const marker = formatMarker(
      cPoint._id,
      producer.clientId,
      producer.storeName,
      cPoint.name,
      cPoint.address.lat,
      cPoint.address.lng,
      cPoint.address.text,
      cPoint.comment,
      schedule
    );
    // if at the productor of this contact point has at least one delivery
    marker.doesDelivery = producer.doesDelivery;
    marker.activityPeriod = cPoint.activityPeriod;
    marker.isDelivery = cPoint.type === "DELIVERY";
    markers.push(marker);
  });
  return markers;
};

const getSchedule = (schedule) => {
  const out = mapValues(schedule, "values");
  forEach(out, (v, k, obj) => {
    if (isEmpty(v)) delete obj[k];
  });
  return out;
}; // removes the unused `values` prop

const getDeliveryPoints = (arr) => {
  const deliveryContactPoints = [
    ...arr.filter((cPoint) => cPoint.type === "CHRONOPOST"),
    ...arr.filter((cPoint) => cPoint.type === "CHRONOPOST_VITI"),
    ...arr.filter((cPoint) => cPoint.type === "CHRONOPOST_RELAY"),
    ...arr.filter((cPoint) => cPoint.type === "DELIVERY"),
  ];
  // so it's easier during the search to know if user is in delivery range
  return deliveryContactPoints.map((contactPoint) => {
    let maxDist = 0;
    contactPoint.deliveryRates.forEach((deliveryRate) => {
      maxDist = Math.max(deliveryRate.to || 0, maxDist);
    });
    return {
      id: contactPoint._id,
      name: contactPoint.name,
      schedule: getSchedule(contactPoint.schedule),
      coord: {
        lat: contactPoint.address.lat,
        lng: contactPoint.address.lng,
      },
      address: contactPoint.address.text,
      radius: maxDist,
      comment: contactPoint.comment,
      type: contactPoint.type,
      minimumDeliveryAmount: contactPoint.minimumDeliveryAmount,
      freeFeeOrderAmount: contactPoint.freeFeeOrderAmount,
    };
  });
};

const getCategories = ({ products }) => {
  return products.reduce((categories, product) => {
    const category = ALL_CATEGORIES.find(
      (category) => category.value === product.category
    );
    if (category) {
      categories = [...categories, category.label];
    }
    return categories;
  }, []);
};

const useStoreInfo = (
  initialProducerId,
  apiRoot,
  initialSaleChannel,
  isGroup,
  defaultBannerURL,
  component,
  initialCodes,
  initialCartAmount,
  isSimplifiedStore = false,
  initialStoreFrontSlug
) => {
  const {
    state: {
      data: { producer, products: initialProducts, validCodes, couponLines },
      isLoading,
      codesEvaluated,
    },
    codes,
    setCodes,
    setCartAmount,
  } = useDataApi(
    initialProducerId,
    {
      producer: undefined,
      products: [],
    },
    apiRoot,
    initialSaleChannel,
    isGroup,
    initialCodes,
    initialCartAmount,
    isSimplifiedStore,
    initialStoreFrontSlug
  );
  const deliveryPoints =
    (producer && getDeliveryPoints(producer.contactPoints)) || [];
  const contactPoints = (producer && getContactPoints(producer)) || [];
  const store =
    (producer &&
      getStore(
        producer,
        defaultBannerURL,
        isGroup,
        initialSaleChannel,
        component
      )) ||
    {};

  const products = useMemo(
    () => buildProducts(initialProducts),
    [initialProducts]
  );

  const categories = getCategories({ products });

  return {
    deliveryPoints,
    contactPoints,
    store,
    isLoading,
    products,
    producer,
    categories,
    validCodes,
    couponLines,
    setCodes,
    setCartAmount,
    codesEvaluated,
    codes,
  };
};

export default useStoreInfo;
