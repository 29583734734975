/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=600fee62251ad50c97d44b23"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("../js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class HowItWorksView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;
    Controller = HowItWorksView;

    return Controller;
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "602e63144ee5140761e05486";
    htmlEl.dataset["wfSite"] = "600fee62251ad50c97d44b23";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      HowItWorksView.Controller !== HowItWorksView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/kuupanda-gestion-07a594.webflow.css);


          body{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-title-section af-class-no-bottom-space af-class-bg-primary">
              <div className="af-class-container-testimonials af-class-text-center">
                <h1 className="af-class-text-white">
                  Cherchez les producteurs proches de chez vous, et commandez en
                  direct.
                </h1>
              </div>
              <img
                src="../images/divider-round-bottom.svg"
                alt
                className="af-class-divider af-class-divider-bottom-large"
              />
              <div className="af-class-decoration-container af-class-left-bottom">
                <div className="af-class-decorations-wrapper">
                  <div className="af-class-decoration-circle af-class-top-right af-class-bg-primary-2" />
                </div>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-container-testimonials">
                <div className="af-class-content-pair-2">
                  <div
                    data-w-id="c7bbc2b6-2d55-59d8-347c-5b19f3d0e402"
                    style={{ opacity: 0 }}
                    className="af-class-content-pair-text"
                  >
                    <h2 className="af-class-heading-8">
                      Si vous êtes consommateur
                    </h2>
                    <p className="af-class-paragraph-large af-class-text-large">
                      Vous cherchez des producteurs ou des produits de qualité
                      proches de chez vous, voici comment faire:
                    </p>
                  </div>
                </div>
              </div>
              <div className="af-class-columns w-row">
                <div className="af-class-column w-col w-col-4">
                  <img
                    src="../images/Capture-d’écran-du-2021-01-31-17-40-11.png"
                    loading="lazy"
                    alt
                    className="af-class-image"
                  />
                  <h4 className="af-class-heading-9">
                    1. Renseignez votre ville
                  </h4>
                  <div className="af-class-text-block-13">
                    Sur la page principale, vous devez renseigner votre ville
                    et/ou le nom d'un producteur (si vous recherchez un
                    producteur spécifique).
                    <br />
                    Vous verrez tous les producteurs disponibles autour de votre
                    lieu de recherche
                    <br />‍
                  </div>
                </div>
                <div className="af-class-column-2 w-col w-col-4">
                  <img
                    src="../images/Capture-d’écran-du-2021-01-31-17-40-29.png"
                    loading="lazy"
                    alt
                    className="af-class-image-2"
                  />
                  <h4 className="af-class-heading-9">
                    2. Découvrez les produits de vos producteurs
                  </h4>
                  <div className="af-class-text-block-13">
                    Vous&nbsp;pouvez ensuite cliquer sur les producteurs. Vous
                    découvrirez des informations sur leur exploitation et la
                    liste de leurs produits. Vous pouvez ajouter ces produits
                    dans votre panier et continuer à découvrir d'autres
                    producteurs par la suite
                  </div>
                </div>
                <div className="af-class-column-3 w-col w-col-4">
                  <img
                    src="../images/Capture-d’écran-du-2021-01-31-17-40-44.png"
                    loading="lazy"
                    alt
                    className="af-class-image-3"
                  />
                  <h4 className="af-class-heading-9">
                    3.&nbsp; Validez votre panier et choisissez vos méthodes de
                    retrait
                  </h4>
                  <div className="af-class-text-block-13">
                    Une fois que vous avez fini de choisir vos
                    produits.&nbsp;Vous pouvez valider votre commande en
                    cliquant sur le panier en bas à droite de la page (visible à
                    partir du moment où au moins un produit est ajouté).
                    <br />
                    Suivez ensuite les étapes du checkout
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-no-bottom-space af-class-bg-gray-4">
              <div className="af-class-container-testimonials af-class-text-center" />
              <div
                data-w-id="86b339f6-8425-4a95-f1db-315dd622813c"
                style={{ opacity: 0 }}
                className="af-class-content-pair-text"
              />
              <div className="af-class-container-testimonials">
                <div className="af-class-content-pair-2">
                  <div
                    data-w-id="e37cbbd2-80d2-8f02-23f9-76e953798859"
                    style={{ opacity: 0 }}
                    className="af-class-content-pair-text"
                  >
                    <h2 className="af-class-heading-8">
                      Si vous êtes producteur
                    </h2>
                    <a
                      href="https://gestion.kuupanda.com/"
                      className="af-class-button-2 w-button"
                    >
                      Rendez-vous ici
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default HowItWorksView;

/* eslint-enable */
