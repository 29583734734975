import queryString from "querystring";
import _ from "lodash";
import { DEFAULT_CATEGORIES, DEFAULT_TAGS } from "../Common/constants";
import Fuse from "fuse.js";

export const DELIVERY_RADIUS_PROP_NAME = "distance";

// {internal_naming: dokan}
const MAPPING_FILTER_PROPS = {
  radius: "distance",
  vendor: "local_dokan_seller_search",
  category: "local_product_cat",
  product: "product_name",
  lat: "latitude",
  lng: "longitude",
  label: "local_product_tag",
  address: "address",
  onlyDelivery: "onlyDelivery",
};

// {dokan: internal_naming}
const REVERSE_MAPPING_FILTER_PROPS = _.map(MAPPING_FILTER_PROPS, (v, k) => [
  k,
  v,
]).reduce((acc, keyValuePair) => {
  acc[keyValuePair[1]] = keyValuePair[0];
  return acc;
}, {});

const TRANSPARENT_FILTER_PROPS = [
  "radius",
  "category",
  "label",
  "vendor",
  "product",
];

export const castLocation = (location) => {
  if (location.lat) location.lat = parseFloat(location.lat, 10);
  if (location.lng) location.lng = parseFloat(location.lng, 10);
};

export function urlSearchToFilters() {
  let queryStr = window.location.search;

  // remove leading '?'
  queryStr = queryStr[0] === "?" ? queryStr.slice(1) : queryStr;
  const queryObj = queryString.parse(queryStr);
  const filterObj = Object.entries(queryObj).reduce(
    (acc, [filterKey, filterVal]) => {
      return {
        ...acc,
        [REVERSE_MAPPING_FILTER_PROPS[filterKey]]: filterVal,
      };
    },
    {}
  );

  const filters = {
    ..._.pick(filterObj, TRANSPARENT_FILTER_PROPS),
    location: _.pick(filterObj, ["lat", "lng", "address"]),
  };
  if (filters.radius) filters.radius = parseInt(filters.radius, 10);
  castLocation(filters.location);
  return filters;
}

export function filtersToUrlQuery(filters) {
  const queryObj = {
    ...filters, // object location will be omitted
    ...filters.location,
  };
  delete queryObj.location;
  const filterObj = Object.entries(queryObj).reduce(
    (acc, [filterKey, filterVal]) => {
      return {
        ...acc,
        [MAPPING_FILTER_PROPS[filterKey]]: filterVal,
      };
    },
    {}
  );

  // remove empty values
  Object.keys(filterObj).forEach(
    (it) =>
      !filterObj[it] && filterObj[it] !== undefined && delete filterObj[it]
  );

  return queryString.stringify(filterObj);
}

/**
 * Deep merge of the filter object. Will keep target's values if source doesn't provide a non falsy one.
 * Mutates and return target
 * @param target
 * @param source
 * @returns {*}
 */
export const mergeFilters = (target, source) => {
  _.mergeWith(target, source, (obj, source) => {
    // if "empty" filter has more than the url, we keep it
    // for instance the radius from the url would be NaN, and the "empty" has a default of 30
    if (_.isArray(source) || _.isObject(source))
      return mergeFilters(obj, source);
    return source || obj;
  });
  return target;
};

function filterByVendor(user, vendor) {
  if (!vendor) {
    return user;
  }
  const fuse = new Fuse(user, {
    includeScore: true,
    keys: ["farmInfo.name"],
    includeMatches: true,
    distance: 100,
    threshold: 0.3,
  });
  const search = fuse.search(vendor);
  return search.map((s) => s.item);
}

function filterByCategory(user, val) {
  return val ? user.categories && user.categories.includes(val.label) : true;
}

function filterByProduct(user, val) {
  return val ? user.products && user.products.includes(val) : true;
}

function filterByLabel(user, val) {
  return val ? user.tags && user.tags.includes(val.label) : true;
}

function filterByDelivery(user, filters) {
  const { onlyDelivery, location } = filters;
  // if we want delivery and contact point, no need to further
  if (!onlyDelivery) return true;
  // if that user doesn't offer delivery at all
  if (!user.doesDelivery) return false;
  // check if user is near the targeted location
  return user.deliveryPoints.some(({ coord, radius }) => {
    const distanceInMeters =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(_.pick(location, ["lat", "lng"])),
        new window.google.maps.LatLng(coord)
      );
    return distanceInMeters / 1000 < radius;
  });
}

export const filterUsers = (allUsers, filters) => {
  const category = filters.category
    ? DEFAULT_CATEGORIES.find((cat) => cat.value === filters.category)
    : undefined;
  const label = filters.label
    ? DEFAULT_TAGS.find((it) => it.value === filters.label)
    : undefined;
  const filterByCategoryCurried = (user) => filterByCategory(user, category);
  const filterByLabelCurried = (user) => filterByLabel(user, label);
  const filterByProductCurried = (user) =>
    filterByProduct(user, filters.product);
  const filterByDeliveryCurried = (user) => filterByDelivery(user, filters);

  const filteredData = allUsers
    .filter(filterByCategoryCurried)
    .filter(filterByProductCurried)
    .filter(filterByLabelCurried)
    .filter(filterByDeliveryCurried);

  return filterByVendor(filteredData, filters.vendor);
};

export const filterMakers = (users, allMarkers, filters) => {
  const userIdMap = users.reduce((acc, it) => ({ ...acc, [it.id]: true }), {});
  return allMarkers.filter((marker) => {
    // same as filters.onlyDelivery ? marker.isDelivery : true
    return (
      userIdMap[marker.userID] && (!filters.onlyDelivery || marker.isDelivery)
    );
  });
};
