/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid/index";
import ContactPointMain from "../../Common/ContactPointMain";
import { ContactPointShape } from "../proptypes";
import SectionTitle from "../../Common/SectionTitle";
import { InnerPaper } from "../../SimplifiedStore/styles";

const Container = styled.div``;

const CheckoutInfoContainer = InnerPaper;

const StyledDivDescription = styled.div`
  font-size: 16px;
  margin-bottom: 10px !important;
  line-height: 1.3;
  color: ${({ theme }) => theme.palette.others.brown};
  font-weight: 500;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.info.light};
  padding: 0.9em 0.9em 0.9em 0em;
  display: flex;
  padding-left: 15px;
  border-left: 0.6180469716em solid rgba(0, 0, 0, 0.15);
`;

// eslint-disable-next-line react/no-multi-comp
class RecoveryInfoInput extends Component {
  render() {
    const {
      contactPoints,
      clientAddress,
      availableDate,
      vendorId,
      vendorIdx,
      vendorName,
      products,
      showVendorName,
      targetInput,
      totalAmount,
      preparationTime,
      onRecoveryInfoChange,
      onStateCheck,
      resetDeliveryRate,
      disableNextButton,
      isSimplifiedStore,
      cart,
    } = this.props;
    return (
      <Container className="docked-widget">
        <CheckoutInfoContainer>
          {this.props.showTitle && (
            <span>
              <SectionTitle>
                Comment souhaitez-vous récupérer vos produits ?
              </SectionTitle>
              {this.props.showDescription && (
                <div className="description_contact_point">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledDivDescription>
                        {this.props.showVendorName ? (
                          <span>
                            Votre commande contient des produits de producteurs
                            différents. Ainsi, pour chacun d'entre eux, vous
                            devez choisir une méthode de récupération et une
                            date. En fonction du producteur, il peut s'agir
                            d'une livraison ou d'un point de retrait.
                          </span>
                        ) : (
                          <span>
                            Choississez une méthode pour récupérer votre
                            commande. En fonction du producteur, il peut s'agir
                            d'une livraison ou d'un point de retrait. Attention
                            également à bien sélectionner la date.
                          </span>
                        )}
                      </StyledDivDescription>
                    </Grid>
                  </Grid>
                </div>
              )}
            </span>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ContactPointMain
                availableDate={availableDate}
                contactPoints={contactPoints}
                vendorId={vendorId}
                vendorIdx={vendorIdx}
                vendorName={vendorName}
                showVendorName={showVendorName}
                totalAmount={totalAmount}
                clientAddress={clientAddress}
                targetInput={targetInput}
                products={products}
                preparationTime={preparationTime}
                onStateCheck={onStateCheck}
                onRecoveryInfoChange={onRecoveryInfoChange}
                resetDeliveryRate={resetDeliveryRate}
                showDateFieldUp={false}
                disableNextButton={disableNextButton}
                isSimplifiedStore={isSimplifiedStore}
                cart={cart}
              />
            </Grid>
          </Grid>
        </CheckoutInfoContainer>
      </Container>
    );
  }
}

RecoveryInfoInput.propTypes = {
  contactPoints: PropTypes.arrayOf(ContactPointShape).isRequired,
  targetInput: PropTypes.string.isRequired,
  vendorId: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
  vendorName: PropTypes.string,
  showVendorName: PropTypes.bool,
  clientAddress: PropTypes.object,
  resetDeliveryRate: PropTypes.func.isRequired,
  onStateCheck: PropTypes.func.isRequired,
  vendorIdx: PropTypes.number.isRequired,
  showTitle: PropTypes.bool.isRequired,
  availableDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  totalAmount: PropTypes.number,
  showDescription: PropTypes.bool,
  onRecoveryInfoChange: PropTypes.func.isRequired,
  disableNextButton: PropTypes.func,
  cart: PropTypes.object,
};

RecoveryInfoInput.defaultProps = {
  vendorId: 17,
  products: [1, 2, 3],
  vendorName: "vendorName",
  showVendorName: false,
  clientAddress: undefined,
  preparationTime: 0,
  availableDate: undefined,
  totalAmount: 5,
  contactPoints: [
    {
      id: "5e0e1aae74a42e5d65de0409",
      name: "Exploitation",
      address: {
        text: "Rue de San Pluget, Céret, France",
        route: "Rue de San Pluget",
        locality: "Céret",
        administrative_area_level_1: "Occitanie",
        country: "FR",
        postal_code: "66400",
        lat: 42.49206460000001,
        lng: 2.7547217999999702,
        place_id:
          "EiFSdWUgZGUgU2FuIFBsdWdldCwgQ8OpcmV0LCBGcmFuY2UiLiosChQKEgl_QOYsR6C6EhGHl7alg-EryRIUChIJKcug0lmguhIRHL54GCNqy1E",
      },
      schedule: {
        monday: { values: [] },
        tuesday: { values: [] },
        wednesday: { values: [{ start: "17:00", end: "19:00" }] },
        thursday: { values: [] },
        friday: { values: [] },
        saturday: { values: [] },
        sunday: { values: [] },
      },
      type: "TAKE_AWAY",
      comment:
        "Ceci est une commande test,Ceci est un commentaire test Ceci est un commentaire test Ceci est un commentaire test",
      deliveryRates: [],
      _id: "5e0e1aae74a42e5d65de0409",
      activityPeriod: {
        startDate: "2020-05-16T00:00:00.000Z",
        endDate: "2022-06-30T00:00:00.000Z",
      },
    },
    {
      id: "5e0e1adfa946b826fdad3568",
      name: "Marché de Céret",
      address: {
        text: "4 Avenue Georges Clemenceau, Céret, France",
        street_number: "4",
        route: "Avenue Georges Clemenceau",
        locality: "Céret",
        administrative_area_level_1: "Occitanie",
        country: "FR",
        postal_code: "66400",
        lat: 42.4857542,
        lng: 2.7491059000000178,
        place_id: "ChIJSxkoN06guhIRHoaAtOcGpEw",
      },
      schedule: {
        monday: { values: [] },
        tuesday: { values: [] },
        wednesday: { values: [] },
        thursday: { values: [] },
        friday: { values: [] },
        saturday: { values: [{ start: "08:00", end: "13:00" }] },
        sunday: { values: [] },
      },
      type: "DELIVERY",
      freeFeeOrderAmount: 5,
      comment:
        "Ceci est un commentaire test Ceci est un commentaire test Ceci est un commentaire test Ceci est un commentaire test Ceci est un commentaire test",
      deliveryRates: [
        { _id: "5df944a71dcc5f002845c06e", from: 0, to: 5, price: 1 },
        { _id: "5e85c3adca7c8e002bfb4030", from: 5, to: 8, price: 2 },
        { _id: "5e85c3adca7c8e002bfb402f", from: 8, to: 10, price: 3 },
      ],
      _id: "5e0e1adfa946b826fdad3568",
      activityPeriod: {
        startDate: "2020-05-16T00:00:00.000Z",
        endDate: "2022-05-31T00:00:00.000Z",
      },
    },
  ],
  showDescription: false,
  cart: {},
};

export default RecoveryInfoInput;
