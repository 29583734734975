import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// Material Componets
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";

// Components
import Submenu from "../Common/Submenu";
import Section from "../Common/Section";
import FormUser from "./FormUser";
import ItemOrder from "./ItemOrder";

// Hooks
import { useGetAuth } from "../Context/AuthContext";
import { useBrandConfig } from "../Context/BrandConfigContext";

// Styleds
const SectionContainer = styled(Grid)`
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "40px" : "40px 20px"};
`;
const Loader = styled(CircularProgress)`
  margin: auto;
`;

const ProfilePage = ({ section: sectionReq, history, width }) => {
  const [loadingInformations, setLoadingInformations] = useState(false);
  const [loadingCommandes, setLoadingCommandes] = useState(false);
  const { auth, authHttpClient } = useGetAuth();
  const accessToken = (auth && auth.accessToken) || null;
  const userId = (auth && auth.userId) || null;

  const [user, setUser] = useState({});
  const [orders, setOrder] = useState([]);
  const [links, setLinks] = useState({
    informations: {
      label: "Mes Informations",
      checked: sectionReq === "informations",
    },
    commandes: {
      label: "Mes Commandes",
      checked: sectionReq === "commandes",
    },
  });
  const [section, setSection] = useState(sectionReq || "informations");
  const {
    brandConfig: { featureFlags = { manageCustomerBasicInfo: true } },
  } = useBrandConfig();

  useEffect(() => {
    (async () => {
      if (userId && !Object.keys(user).length) {
        try {
          setLoadingInformations(true);
          const reqUser = await authHttpClient.get(`/user/${userId}`);
          setUser(reqUser);
        } catch (error) {
        } finally {
          setLoadingInformations(false);
        }
      }
    })();
  }, [authHttpClient, user, userId]);

  useEffect(() => {
    (async () => {
      if (userId && !orders.length) {
        try {
          setLoadingCommandes(true);
          const reqOrder = await authHttpClient.get(
            `/orderByCustomer/${userId}`
          );
          setOrder(reqOrder || []);
        } catch (error) {
        } finally {
          setLoadingCommandes(false);
        }
      }
    })();
  }, [authHttpClient, userId, orders.length]);

  const handleSubMenuOnClick = useCallback(
    (menuId) => {
      setSection(menuId);
      setLinks(
        Object.keys(links).reduce(
          (result, key) => ({
            ...result,
            [key]: {
              ...links[key],
              checked: key === menuId,
            },
          }),
          {}
        )
      );
      history.push(menuId);
    },
    [setSection, setLinks, links, history]
  );

  useEffect(() => {
    if (section !== sectionReq) {
      handleSubMenuOnClick(sectionReq);
    }
  }, [section, sectionReq, handleSubMenuOnClick]);
  const configuredLinks = Object.fromEntries(
    Object.entries(links).filter(([key]) => {
      return featureFlags.manageCustomerBasicInfo || key !== "informations";
    })
  );
  return (
    <>
      <Submenu
        links={configuredLinks}
        onClick={handleSubMenuOnClick}
        width={width}
      />
      <Hidden smDown>
        {section !== "commandes" ? (
          <Section>
            {loadingInformations ? (
              <Loader size={80} />
            ) : (
              <SectionContainer
                container
                item
                sm={12}
                md={8}
                justify="center"
                width={width}
              >
                <FormUser
                  user={user}
                  authHttpClient={authHttpClient}
                  manageCustomerBasicInfo={featureFlags.manageCustomerBasicInfo}
                />
              </SectionContainer>
            )}
          </Section>
        ) : (
          <Section>
            {loadingCommandes ? (
              <Loader size={80} />
            ) : (
              orders.map((order) => <ItemOrder {...order} />)
            )}
          </Section>
        )}
      </Hidden>
      <Hidden mdUp>
        <SectionContainer
          container
          item
          sm={12}
          md={8}
          justify="center"
          width={width}
        >
          {section !== "commandes" ? (
            <>
              {loadingInformations ? (
                <Loader size={80} />
              ) : (
                <FormUser
                  user={{ ...user, accessToken }}
                  authHttpClient={authHttpClient}
                  manageCustomerBasicInfo={featureFlags.manageCustomerBasicInfo}
                />
              )}
            </>
          ) : (
            <>
              {loadingCommandes ? (
                <Loader size={80} />
              ) : (
                orders.map((order) => <ItemOrder width={width} {...order} />)
              )}
            </>
          )}
        </SectionContainer>
      </Hidden>
    </>
  );
};

ProfilePage.propTypes = {
  section: PropTypes.string,
  history: PropTypes.func.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

ProfilePage.defaultProps = {
  section: "informations",
};

export default withRouter(withWidth()(ProfilePage));
