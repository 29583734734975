import React from "react";
import isArray from "lodash/isArray";
import mapValues from "lodash/mapValues";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { getHOCDisplayName, pseudoGuid } from "./utils";
import _ from "lodash";
import styled from "styled-components";
import {
  DEFAULT_CATEGORIES,
  DEFAULT_TAGS,
  DEFAULT_PRODUCTS,
} from "../Common/constants";

import GlobalLoader from "../Common/GlobalLoader";
import { getInitFilter } from "./Filters/Filters";
import { Modal } from "@material-ui/core";
import { mergeFilters, urlSearchToFilters } from "./searchService";
import httpClient from "../httpClient";

export function withDataFormatter(WrappedComponent) {
  class WithDataFormatter extends React.Component {
    static displayName = `withDataFormatter(${getHOCDisplayName(
      WrappedComponent
    )})`;
    static propTypes = {
      tags: PropTypes.arrayOf(PropTypes.object).isRequired,
      categories: PropTypes.arrayOf(PropTypes.object).isRequired,
      products: PropTypes.arrayOf(PropTypes.object).isRequired,
      url: PropTypes.string,
      defaultRadius: PropTypes.number,
    };
    static defaultProps = {
      // Those could stay here, not sure if we ever planned on fetching them from BE
      tags: DEFAULT_TAGS,
      categories: DEFAULT_CATEGORIES,
      products: DEFAULT_PRODUCTS,
      url: `${process.env.REACT_APP_API_URL}/userClientList`,
      defaultRadius: 30,
    };

    constructor(props) {
      super(props);
      this.state = {
        users: [],
        markers: [],
        isInitializing: true,
      };
      this.tagsMap = this.props.tags.reduce((acc, v) => {
        acc[v.value] = v.label;
        return acc;
      }, {});
      this.categoriesMap = this.props.categories.reduce((acc, v) => {
        acc[v.value] = v.label;
        return acc;
      }, {});
    }

    componentDidMount = async () => {
      const initialFilters = mergeFilters(
        getInitFilter(this.props.defaultRadius) || {},
        urlSearchToFilters()
      );
      this.setState({ initialFilters });
      await this.getUserList(initialFilters);
      this.setState({ isInitializing: false });
    };

    getUserList = async (filters) => {
      const { lat, lng } = filters.location || {};
      const query = lat && lng ? `?lat=${lat}&lng=${lng}` : "";
      this.setState({
        isPageLoading: true,
      });
      const url = this.props.url;
      let data = [];

      try {
        const response = await httpClient(`${url}${query}`);
        data = response.data;
      } catch (e) {
        console.log(e);
      }

      this.format({ data });
    };

    getDefaultMarker = (prod) => {
      return this.formatMarker(
        pseudoGuid(),
        prod.id,
        prod.storeName,
        prod.displayName,
        prod.coord.lat,
        prod.coord.lng,
        prod.farmInfo.address.text
      );
    };

    // output the format that will be use directly by the Marker functional component
    formatMarker = (
      id,
      userID,
      storeName,
      name,
      lat,
      lng,
      address,
      schedule
    ) => {
      return {
        id,
        userID,
        name,
        storeName,
        address,
        coord: {
          lat,
          lng,
        },
        schedule,
      };
    };

    getMarkers = (user) => {
      const cPoints = user.contactPoints;
      if (!isArray(cPoints) || isEmpty(cPoints)) {
        return [this.getDefaultMarker(user)];
      }

      return cPoints.reduce((markers, cPoint) => {
        if (cPoint.type !== "TAKE_AWAY" && cPoint.type !== "DELIVERY") {
          return markers;
        }
        const marker = this.formatMarker(
          cPoint._id,
          user.id,
          user.farmInfo.name,
          cPoint.name,
          cPoint.address.lat,
          cPoint.address.lng,
          cPoint.address.text,
          mapValues(cPoint.schedule, "values") // removes the unused `values` prop
        );
        // if at the productor of this contact point has at least one delivery
        marker.doesDelivery = user.doesDelivery;
        marker.isDelivery = cPoint.type === "DELIVERY";
        markers = [...markers, marker];

        return markers;
      }, []);
    };

    getImageURL = (image) => {
      return image && image.length && image[0] && image[0].url
        ? image[0].url
        : "";
    };

    formatUser = (user, tagsMap) => {
      const farmInfoBase = { address: {} };
      const farmInfo = user.farmInfo || farmInfoBase;
      const deliveryPoints = this.getDeliveryPoints(user.contactPoints);
      const newUser = {
        address: user.address,
        bannerUrl: this.getImageURL(user.farmPictures),
        categories: user.categories || [],
        contactPoints: this.getContactPointsCoords(user.contactPoints),
        coord: {
          lat: parseFloat(farmInfo.address.lat),
          lng: parseFloat(farmInfo.address.lng),
        },
        deliveryPoints: deliveryPoints,
        displayName: `${
          user.name && user.lastName ? `${user.name} ${user.lastName}` : ""
        }`,
        doesDelivery: !!deliveryPoints.length,
        id: user.id,
        farmInfo: farmInfo,
        isGroup: !!user.isGroup,
        productCount: parseInt(user.productCount, 10),
        products: user.products,
        profilePicture: this.getImageURL(user.profilePicture),
        storeName: user.storeName || "",
        storeURL: user.storeURL,
        storeSlug: user.clientSlug,
      };
      const ABlabel = "AB";
      if (user.tags.includes(ABlabel)) {
        _.remove(user.tags, (e) => e === ABlabel);
        user.tags.unshift(ABlabel);
      }
      newUser.tags =
        (user.tags && user.tags.map((value) => tagsMap[value])) || [];
      newUser.categories = newUser.categories.map((category) => {
        return this.categoriesMap[category];
      });

      return newUser;
    };

    getDeliveryPoints = (contactPoints) => {
      const deliveryContactPoints = contactPoints.filter(
        (cPoint) => cPoint.type === "DELIVERY"
      );
      // so it's easier during the search to know if user is in delivery range
      return deliveryContactPoints.map((contactPoint) => {
        let maxDist = 0;
        contactPoint.deliveryRates.forEach((deliveryRate) => {
          maxDist = Math.max(deliveryRate.to || 0, maxDist);
        });
        return {
          coord: {
            lat: contactPoint.address.lat,
            lng: contactPoint.address.lng,
          },
          radius: maxDist,
        };
      });
    };

    getContactPointsCoords = (contactPoints) => {
      return contactPoints
        .filter((cp) => cp.address)
        .map((contactPoint) => ({
          ...contactPoint,
          coord: {
            lat: contactPoint.address.lat,
            lng: contactPoint.address.lng,
          },
        }));
    };

    format = ({ data }) => {
      const users = data.map((user) => this.formatUser(user, this.tagsMap));
      const markers = users.flatMap(this.getMarkers);

      this.setState({
        markers,
        users,
        isPageLoading: false,
      });
    };

    setIsPageLoading = (val) => {
      this.setState({
        isPageLoading: true,
      });
    };

    updateFilters = (filters) => {
      this.getUserList(filters);
    };

    render() {
      const { ...rest } = this.props;
      const { users, markers, isPageLoading, isInitializing, initialFilters } =
        this.state;
      if (isInitializing) {
        return (
          <Modal
            open
            BackdropProps={{ style: { backgroundColor: "#ffffffa8" } }}
          >
            <GlobalLoader />
          </Modal>
        );
      }
      return (
        <WrappedComponent
          isPageLoading={isPageLoading}
          setIsPageLoading={this.setIsPageLoading}
          updateFilters={this.updateFilters}
          filters={initialFilters}
          users={users}
          markers={markers}
          {...rest}
        />
      );
    }
  }
  return WithDataFormatter;
}
