import React from "react";
import PropTypes from "prop-types";
import { getHOCDisplayName } from "../utils";

export function withSimpleOnChange(WrappedComponent, accessor) {
  class WithSimpleOnChange extends React.Component {
    static displayName = `withSimpleOnChange(${getHOCDisplayName(
      WrappedComponent
    )})`;
    static propTypes = {
      onChange: PropTypes.func,
    };

    static defaultAccessor(event) {
      return event.target.value;
    }

    onChange = (event) => {
      if (this.props.onChange) {
        const val = (accessor || WithSimpleOnChange.defaultAccessor)(event);
        return this.props.onChange(val);
      }
    };

    render() {
      const { onChange, ...rest } = this.props;
      return (
        <WrappedComponent
          // produces `users` and `markers`
          onChange={this.onChange}
          {...rest}
        />
      );
    }
  }
  return WithSimpleOnChange;
}
