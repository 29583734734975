import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

// Assets
import { ReactComponent as Mail } from "../Assets/Icons/mail.svg";
import { ReactComponent as Phone } from "../Assets/Icons/phone.svg";
import { ReactComponent as Facebook } from "../Assets/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../Assets/Icons/instagram.svg";
import { ReactComponent as Youtube } from "../Assets/Icons/youtube.svg";

// styled
const Container = styled.div`
  ${({ theme }) => `
    background: ${theme.palette.secondary.main};
  `};
  padding: 100px;
`;

const Copyright = styled.div`
  color: white;
  ${({ theme }) => `
    background: ${theme.palette.secondary.dark};
  `};
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const FooterTitle = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  align-items: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10px;
`;

const FooterList = styled.ul`
  list-style: none;
  padding-left: 0;
  color: #ffffff;
  font-size: 16px;
  line-height: 32px;
`;

const StyledAnchor = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

const ExternalLink = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

const MailIcon = styled(Mail)`
  vertical-align: middle;
  padding-right: 5px;
  color: ${({ theme }) => theme.palette.grey["100"]};
`;

const PhoneIcon = styled(Phone)`
  vertical-align: middle;
  padding-right: 5px;
  color: ${({ theme }) => theme.palette.grey["100"]};
`;

const Footer = () => {
  return (
    <>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12}>
            <FooterTitle>Contactez nous</FooterTitle>
            <FooterList>
              <li>
                <MailIcon />
                <ExternalLink href="mailto:oui@kuupanda.com">
                  producteur@kuupanda.com
                </ExternalLink>
              </li>
              <li>
                <PhoneIcon />
                <ExternalLink href="tel:+33 6 89 66 26 20">
                  +33 7 86 88 58 21
                </ExternalLink>
              </li>
              <li>De 08h30 à 12h et de 13h30 à 17h30</li>
            </FooterList>
            <FooterTitle>Suivez nous</FooterTitle>
            <div style={{ display: "flex" }}>
              <ExternalLink
                target="_blank"
                href="https://www.facebook.com/kuupandafrance"
              >
                <Facebook style={{ paddingRight: 5 }} />
              </ExternalLink>
              <ExternalLink
                target="_blank"
                href="https://www.instagram.com/kuupanda_"
              >
                <Instagram style={{ paddingRight: 5 }} />
              </ExternalLink>
              <ExternalLink
                target="_blank"
                href="https://www.youtube.com/channel/UCLqNY_p1wDHpy7IXlOz8tCw"
              >
                <Youtube />
              </ExternalLink>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <FooterTitle>Á propos</FooterTitle>
            <FooterList>
              <li>
                <StyledAnchor href="/comment-ca-marche">
                  Comment ça marche ?
                </StyledAnchor>
              </li>
              <li>
                <StyledAnchor href="/qui-sommes-nous">
                  Qui porte ce projet ?
                </StyledAnchor>
              </li>
            </FooterList>
          </Grid>
          <Grid item md={4} xs={12}>
            <FooterTitle>Légal</FooterTitle>
            <FooterList>
              <li>
                <StyledAnchor href="/cgv">
                  Conditions Générales de Vente et d'Utilisation (CG)
                </StyledAnchor>
              </li>
              <li>
                <StyledAnchor href="/legal-mentions">
                  Mentions légales
                </StyledAnchor>
              </li>
              <li>
                <StyledAnchor href="/gdpr">
                  Politique de protection des données
                </StyledAnchor>
              </li>
            </FooterList>
          </Grid>
        </Grid>
      </Container>
      <Copyright>© Kuupanda {new Date().getFullYear()}</Copyright>
    </>
  );
};

export default Footer;
