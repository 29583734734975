import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled(Button)`
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: ${(props) =>
    props.$square &&
    `
    padding-left: 0;
    padding-right: 0;
  `};
  padding: 0;
`;

export const QuantityBtn = React.forwardRef(function QuantityBtn(
  { square, ...props },
  ref
) {
  return (
    <StyledButton
      ref={ref}
      $square={square}
      color={"primary"}
      variant={"contained"}
      disableElevation
      {...props}
    />
  );
});

QuantityBtn.propTypes = {
  square: PropTypes.bool,
};
