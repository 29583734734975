import React, { useState } from "react";
import styled from "styled-components";
import {
  List,
  Chip,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { RiFilter3Line, RiCloseLine } from "react-icons/ri";

//utils
import { scrollTo } from "../../services/utils";
import SearchInput from "../SearchInput";

//components
import { CategoryRow, LoadingCategoryRow } from "./CategoryRow";
import { useIsMobile } from "../../../Hooks/useIsMobile";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "white",
    },
  },
  selected: {},
});

//styled
const Container = styled.div<{ $fixed: boolean }>`
  background: white;

  ${({ $fixed }) =>
    $fixed &&
    `
top: 50px;
right: 0;
left: 0;
position: fixed;
z-index: 1000;
`}
`;

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
`;

const StyledListItemText = styled(ListItemText)<{ $selected: boolean }>`
  & > .MuiListItemText-primary {
    font-weight: bold;
    font-weight: ${(props) => (props.$selected ? "bold" : "normal")};
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 15px;
  align-items: center;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 60px;
  width: 100%;
`;

const Filter = styled(RiFilter3Line)`
  height: 24px;
  width: 24px;
`;

const Close = styled(RiCloseLine)`
  height: 24px;
  width: 24px;
`;

const IconContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

type CategoryListProps = {
  categoryNameMap: any;
  loading: boolean;
  products: any[];
  handleSearchProducts: (search: string) => void;
  fixed: boolean;
};

export function CategoryList({
  categoryNameMap,
  products,
  handleSearchProducts,
  fixed,
  loading,
}: CategoryListProps) {
  const classes = useStyles();

  const isMobile = useIsMobile();

  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [categoryListExpanded, setCategoryListExpanded] = useState<boolean>(
    !isMobile
  );

  const handleClick = (category: string | null) => {
    setCurrentCategory(category);

    const categoryToJump = category ? category : products[0].category;

    //@ts-ignore
    scrollTo(`#${categoryToJump}`, { offset: -25, behavior: "auto" });
  };

  const toggleCategories = () => {
    setCategoryListExpanded((state) => !state);
  };

  return (
    <Container $fixed={fixed} className="SAD">
      <Panel>
        {!loading ? (
          <>
            <StyledSearchInput handleSearchChange={handleSearchProducts} />
            {fixed && isMobile && (
              <IconContainer>
                <IconButton onClick={toggleCategories} size="small">
                  {!categoryListExpanded ? <Filter /> : <Close />}
                </IconButton>
              </IconContainer>
            )}
          </>
        ) : (
          <StyledSkeleton animation="wave" />
        )}
      </Panel>

      {(!fixed || categoryListExpanded) && (
        <List disablePadding>
          {loading ? (
            <div>
              <LoadingCategoryRow />
              <Divider />
              <LoadingCategoryRow />
              <Divider />
              <LoadingCategoryRow />
              <Divider />
              <LoadingCategoryRow />
            </div>
          ) : (
            <>
              <ListItem
                button
                selected={!currentCategory}
                onClick={() => handleClick(null)}
                classes={{ root: classes.root, selected: classes.selected }}
              >
                <StyledListItemText $selected={!currentCategory}>
                  Tous les produits
                </StyledListItemText>
                <ListItemSecondaryAction>
                  <Chip
                    color={"primary"}
                    size={"small"}
                    label={products.reduce(
                      (total, category) => total + category.count,
                      0
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {products.map((category: any) => (
                <CategoryRow
                  key={category.category}
                  category={category}
                  categoryNameMap={categoryNameMap}
                  onClick={handleClick}
                  selected={currentCategory === category.category}
                />
              ))}
            </>
          )}
        </List>
      )}
    </Container>
  );
}
