import { FormattedNumber } from "react-intl";
import React from "react";
import styled from "styled-components";
import media from "../Helpers/media";

const Container = styled.div`
  color: white;
  font-size: 15px;
  border-radius: 0px 26px 26px 0px;
  z-index: 100;
  padding: 5px;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `}
  ${media.down.md`
    padding: 3px;
    font-size: 13px;
    border-radius: 26px 0 0 26px
  `}
`;

function DiscountBadge({ className, percentage }) {
  if (!percentage) {
    return "";
  }
  return (
    <Container className={className}>
      <FormattedNumber
        // eslint-disable-next-line
        style="percent"
        value={percentage}
        minimumFractionDigits={0}
        maximumFractionDigits={2}
      />
    </Container>
  );
}

export default DiscountBadge;
