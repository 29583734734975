import React from "react";
import styled from "styled-components";
import { useWhichBrand } from "../Hooks/useWhichBrand";

const ProducerLink = styled.a`
  line-height: 1.4;
  width: fit-content;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  outline-style: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ProducerText = styled.span`
  font-weight: 700;
  padding: 7.2px 15px;
  box-sizing: border-box;
`;

const ProducerButton = () => {
  const { isGranVillage } = useWhichBrand();
  return (
    <ProducerLink
      href={isGranVillage ? "/producteurs" : "https://gestion.kuupanda.com"}
      target="_blank"
    >
      <ProducerText>Je suis producteur</ProducerText>
    </ProducerLink>
  );
};

export default ProducerButton;
