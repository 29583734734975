import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid as TableGrid,
  Table,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Button,
  Grid,
  Divider,
  Typography,
  withWidth,
} from "@material-ui/core";
import styled from "styled-components";

import {
  RiCheckFill as CheckIcon,
  RiCloseLine as ClearIcon,
} from "react-icons/ri";

// Components
import Input from "./Input";
import constants from "./constants";
import { NumberFormatCustom } from "@kuupanda/commons";

const Container = styled(Grid)`
  min-height: 40px;
`;

const CouponInput = styled(Input)`
  width: ${({ width }) => (width !== "xs" ? "200px" : "75%")};
  text-align: center;
  background-color: white;
`;

const CouponDisabledInput = styled(Input)`
  width: ${({ width }) => (width !== "xs" ? "240px" : "75%")};
  text-align: center;
  background-color: white;
  border-radius: 8px;
  .MuiInputBase-input {
    border-radius: 8px;
    height: 8px;
    font-size: 15px;
    color: ${(props) => props.theme.palette.grey["800"]};
  }
`;

const CouponDisabledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CouponDisabledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["700"]};
  margin-right: 10px;
`;

const CouponButton = styled(Button)`
  padding: 15px;
  margin-left: 10px;
`;

const CheckIconStyled = styled(CheckIcon)`
  width: 25px;
  height: 25px;
`;

const ClearIconStyled = styled(ClearIcon)`
  width: 25px;
  height: 25px;
`;

const DividerStyled = styled(Divider)`
  background-color: ${(props) =>
    props.color || props.theme.palette.grey["400"]};
  margin: 15px 0;
`;
const StyledTable = styled(Table.Table)`
  min-width: 100% !important;
`;
const Total = styled(Typography)`
  font-weight: bold;
`;

const CellComponent = (props, cellStyles, cellExtensions = {}) => {
  return (
    <Table.Cell
      {...props}
      style={{ ...cellStyles, ...cellExtensions[props.column.name] }}
    />
  );
};

const CartSummary = ({
  total: totalReq,
  cartList,
  cellStyles,
  cellExtensions,
  columns,
  columnExtensions,
  coupon,
  dividerColor,
  width,
  totalVariant,
  showCoupon,
  showDisabledCoupon,
  onChangeCoupon,
  applyPromo,
  fees,
  showTotal,
  couponLines,
  promoValid,
  clearPromo,
}) => {
  const total = useMemo(() => {
    if (!totalReq) {
      return 0;
    }
    const decimals = 2;
    if (typeof totalReq === "string") {
      return parseFloat(totalReq).toFixed(decimals);
    }
    return totalReq.toFixed(decimals);
  }, [totalReq]);

  const [promoApplied, setPromoApplied] = useState(coupon && coupon.length > 0);

  const onChange = (val) => {
    setPromoApplied(false);
    onChangeCoupon(val);
  };

  const changePromo = async () => {
    if (coupon.length > 0) {
      if (promoApplied) {
        clearPromo();
      } else {
        await applyPromo(coupon);
      }

      setPromoApplied((applied) => !applied);
    }
  };

  return (
    <>
      <TableGrid xs={12} rows={cartList} columns={columns}>
        <Table
          cellComponent={(props) =>
            CellComponent(props, cellStyles, cellExtensions)
          }
          columnExtensions={columnExtensions}
          tableComponent={StyledTable}
        />
      </TableGrid>
      {showDisabledCoupon && (
        <>
          <DividerStyled color={dividerColor} />
          <CouponDisabledContainer>
            <CouponDisabledTypography variant="caption">
              Code promo
            </CouponDisabledTypography>
            <CouponDisabledInput
              width={width}
              fullWidth
              value={coupon}
              size="small"
              disabled
              className="disabledInput"
            />
          </CouponDisabledContainer>
        </>
      )}
      {showCoupon && (
        <>
          <DividerStyled color={dividerColor} />
          <Container
            container
            item
            xs={12}
            justify="flex-end"
            alignItems="flex-start"
          >
            <CouponInput
              width={width}
              placeholder="Saisir le code promo"
              fullWidth
              id="coupon"
              name="coupon"
              value={coupon}
              type="text"
              onChange={(e) => onChange(e.target.value)}
              size="medium"
              error={coupon.length > 0 && promoApplied && !promoValid}
              helperText={
                coupon.length > 0 &&
                promoApplied &&
                !promoValid &&
                "Code promo invalide"
              }
            />
            <CouponButton
              variant="contained"
              color="primary"
              onClick={changePromo}
            >
              {!promoApplied ? <CheckIconStyled /> : <ClearIconStyled />}
            </CouponButton>
          </Container>
        </>
      )}
      {fees && fees.length > 0 && (
        <>
          <DividerStyled color={dividerColor} />
          <Container
            container
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            {fees.map((fee) => (
              <>
                <Total variant={totalVariant}>
                  {fee.type === constants.FEES_TYPE.DELIVERY && "Livraison"}
                </Total>
                <Total variant={totalVariant}>
                  <NumberFormatCustom value={fee.rate} displayType={"text"} />
                </Total>
              </>
            ))}
          </Container>
        </>
      )}

      {couponLines && couponLines.length > 0 && (
        <>
          <DividerStyled color={dividerColor} />
          {couponLines.map((coupon) => (
            <Container
              container
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Total variant="h6">Remise</Total>
              <Total variant="h6">
                -
                <NumberFormatCustom
                  value={coupon.discount}
                  displayType={"text"}
                />
              </Total>
            </Container>
          ))}
        </>
      )}

      {showTotal && total >= 0 && (
        <>
          <DividerStyled color={dividerColor} />
          <Container
            item
            container
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Total variant={totalVariant}>Total (TTC)</Total>
            <Total variant={totalVariant}>
              <NumberFormatCustom value={total} displayType={"text"} />
            </Total>
          </Container>
        </>
      )}
    </>
  );
};

CartSummary.propTypes = {
  cartList: PropTypes.array.isRequired,
  cellStyles: PropTypes.any,
  cellExtensions: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  columnExtensions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      align: PropTypes.string.isRequired,
    })
  ).isRequired,
  dividerColor: PropTypes.string,
  showCoupon: PropTypes.bool,
  onChangeCoupon: PropTypes.func,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalVariant: PropTypes.string,
  coupon: PropTypes.string,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  showDisabledCoupon: PropTypes.bool,
};

CartSummary.defaultProps = {
  total: null,
  cellStyles: {},
  cellExtensions: {},
  coupon: "",
  dividerColor: null,
  showTotal: true,
  onChangeCoupon: () => {},
  totalVariant: "subtitle2",
};

export default withWidth()(CartSummary);
