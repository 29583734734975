import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

//styled
const Container = styled.div`
  position: relative;
`;
const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Slider = ({ children }) => {
  const [index, setIndex] = useState(0);

  const previous = () => {
    setIndex((index + 1) % children.length);
  };

  const next = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(children.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  return (
    children.length > 0 && (
      <Container>
        {children.length > 1 && (
          <Controls>
            <IconButton onClick={previous}>
              <ChevronLeftIcon style={{ color: "white" }} />
            </IconButton>
            <IconButton onClick={next}>
              <ChevronRightIcon style={{ color: "white" }} />
            </IconButton>
          </Controls>
        )}
        {children[index]}
      </Container>
    )
  );
};

export default Slider;
