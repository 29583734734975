import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import styled from "styled-components";
import ChangeDate from "../Checkout/Components/ChangeDate";
import { ContactPointShape } from "../Checkout/proptypes";
import { getFormattedDate, getSlotsForDate } from "../Checkout/utils";
import { Typography, useMediaQuery } from "@material-ui/core";
import Title from "../Checkout/Components/Title";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { RiTimeLine as ClockIcon } from "react-icons/ri";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

// Styleds
const DateFieldContainer = styled.div`
  @media (max-width: 600px) {
    margin: 20px 0 0;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  .react-datepicker__input-container,
  .datepicker-custom-input {
    width: 100%;
    cursor: pointer;
    display: block;
  }
  .react-datepicker-wrapper {
    display: inline-block !important;
    width: inherit !important;
    cursor: pointer;
  }
  .react-datepicker-wrapper {
    display: block;
    margin-top: 0 !important;
  }
  ${({ theme }) => `
    .react-datepicker__day--selected {
      background-color: ${theme.palette.primary.main} !important;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: ${theme.palette.primary.main} !important;
    }
  `};
`;

const DateContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const SlotsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const Slot = styled.div`
  color: ${({ theme, selected }) =>
    selected ? theme.palette.grey[50] : "#464861"};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.grey[100]};
  border-radius: 8px;
  padding: 7px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  margin: 0 5px 5px 0;
  display: flex;
  align-items: center;
  gap: 2px;
  svg {
    fill: ${({ theme, selected }) =>
      selected ? theme.palette.grey[50] : "#464861"};
  }
`;

const ArrowIcon = styled(ArrowForwardIcon)`
  border: 1px solid;
  border-radius: 50px;
  font-size: 10px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
`;

const DateBox = styled.div`
  border: 1.5px solid #eff0f7;
  border-radius: 4px;
  min-width: 200px;
  padding: 5px;
`;

const DateText = styled(Typography)`
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const AvailableDate = styled(Typography)`
  margin-top: 20px;
  color: #6e7191;
`;

const Or = styled(Typography)`
  color: #6e7191;
  font-weight: 500;
`;

function DateField({
  contactPoint,
  selectedDate,
  availableDates,
  onChangeDate,
  isRelay,
}) {
  const isDelivery = contactPoint.type === "DELIVERY";
  const formattedDate = getFormattedDate(selectedDate);
  const dateFormat = isDelivery ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [currentSlot, setCurrentSlot] = useState(selectedDate);

  const datePicker = (
    <DatePicker
      popperPlacement="bottom-end"
      className="contact-point-datepicker"
      customInput={<ChangeDate />}
      dateFormat={dateFormat}
      minDate={availableDates && availableDates[0]}
      maxDate={
        availableDates &&
        availableDates.length > 0 &&
        availableDates[availableDates.length - 1]
      }
      includeDates={availableDates}
      selected={selectedDate}
      onChange={onChangeDate}
      locale="fr"
    />
  );

  const slots = getSlotsForDate({
    date: selectedDate,
    contactPoint,
  });

  const setTimeSlot = (slot) => {
    const hours = slot.getHours();
    const minutes = slot.getMinutes();

    //clone the date
    let newDate = new Date(selectedDate.valueOf());
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    onChangeDate(newDate);
    setCurrentSlot(slot);
  };

  const isTimeEqual = (time, time2) => {
    if (!time || !time2) {
      return false;
    }
    return (
      time.getHours() === time2.getHours() &&
      time.getMinutes() === time2.getMinutes()
    );
  };

  return (
    <DateFieldContainer>
      {isDelivery ? (
        <>
          <Title color="textPrimary" component="div">
            <ArrowIcon /> Choisissez la date de livraison souhaitée
          </Title>
          <Container $isMobile={isMobile}>
            <div>
              <DateText>{formattedDate}</DateText>
            </div>
            <div>Ou</div>
            <div>{datePicker}</div>
          </Container>
        </>
      ) : (
        <>
          <Title color="textPrimary" component="div">
            <ArrowIcon />{" "}
            {isRelay
              ? "Le colis arrivera entre X et X days"
              : "Choisissez la date et le créneau horaire du retrait souhaité"}
          </Title>
          <AvailableDate>Première date disponible :</AvailableDate>
          <Container $isMobile={isMobile}>
            <DateContainer>
              <DateBox>
                <DateText>{formattedDate}</DateText>
                <SlotsContainer>
                  {slots.map((slot, index) => {
                    return (
                      <Slot
                        key={index}
                        onClick={() => setTimeSlot(slot.value)}
                        selected={isTimeEqual(slot.value, currentSlot)}
                      >
                        {slot.label} <ClockIcon />
                      </Slot>
                    );
                  })}
                </SlotsContainer>
              </DateBox>
            </DateContainer>
            <Or>Ou</Or>
            <div>{datePicker}</div>
          </Container>
        </>
      )}
    </DateFieldContainer>
  );
}
DateField.propTypes = {
  contactPoint: ContactPointShape.isRequired,
  selectedDate: PropTypes.object.isRequired,
  onShowDatePicker: PropTypes.func.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeDate: PropTypes.func.isRequired,
};

export default DateField;
