/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=600fee62251ad50c97d44b23"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class ProtectionDesDonneesView extends React.Component {
  static get Controller() {
    Controller = ProtectionDesDonneesView;

    return Controller;
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "604104f80e24c01f1db7b0be";
    htmlEl.dataset["wfSite"] = "600fee62251ad50c97d44b23";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      ProtectionDesDonneesView.Controller !== ProtectionDesDonneesView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/kuupanda-gestion-07a594.webflow.css);


          body{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-section af-class-no-bottom-space af-class-bg-gray-4">
              <div className="af-class-container-testimonials af-class-container-narrow af-class-align-left">
                <div className="af-class-card af-class-terms-card">
                  <div className="af-class-card-body">
                    <div className="af-class-letterhead">
                      <div>
                        <h1 className="af-class-medium-heading af-class-career-description-heading">
                          <strong>Protection des données</strong>
                        </h1>
                      </div>
                    </div>
                    <h1 className="af-class-heading-11">
                      <strong>Politique de protection des données</strong>
                      <br />
                    </h1>
                    <div className="af-class-text-block-28">
                      Lors de votre navigation sur la Plateforme Kuupanda et de
                      l’utilisation des Services en ligne de Kuupanda qui y sont
                      proposés, des données à caractère personnel vous
                      concernant sont collectées et traitées par nos services.
                      <br />
                      <br />
                      La présente politique de protection des données a pour
                      objet de vous informer sur:
                      <br />- la façon dont nous collectons et traitons vos
                      données à caractère personnel;
                      <br />- les cookies que nous déposons sur votre terminal
                      de navigation.
                      <br />
                    </div>
                    <h1 className="af-class-heading-11">
                      <strong>Vos données personnelles</strong>
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      <strong>1.&nbsp;Responsable du traitement</strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-27">
                      Les données à caractère personnel vous concernant en tant
                      que producteur sont collectées dès l’exécution de votre
                      inscription au sein de la plateforme Kuupanda, sont
                      traitées par la société SAS KUUPANDA, société exploitant
                      la Plateforme Kuupanda.
                      <br />
                      <br />
                      Les données à caractère personnel vous concernant en tant
                      que client sont collectées pour l’exécution de vos
                      commandes passées au sein de la Plateforme Kuupanda. Elles
                      sont traitées conjointement par la société SAS KUUPANDA,
                      société exploitant la Plateforme Kuupanda et par chaque
                      producteur auprès duquel vous passez une commande.
                      <br />
                      <br />
                      Les données à caractère personnel vous concernant sont
                      également traitées sous la responsabilité de la société
                      SAS KUUPANDA à des fins de prospection commerciale,
                      d’analyse de navigation, de mesures d’audience, de
                      publicité comportementale, d’études statistiques, pour
                      mener des enquêtes de satisfaction et afin de lutter
                      contre la fraude bancaire.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        2. Pourquoi traitons-nous vos données et sur quel
                        fondement légal?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-27">
                      <br />
                    </div>
                    <img
                      src="images/Capture-d’écran-du-2021-03-04-17-11-29.png"
                      loading="lazy"
                      alt
                    />
                    <h6 className="af-class-heading-12">
                      <strong>
                        3.&nbsp;Quelles sont les données à caractère personnel
                        que nous traitons vous concernant?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Nous collectons et traitons notamment:
                      <br />- Les données à caractère personnel que vous avez
                      renseignées au sein de notre Plateforme lors de la
                      création de compte (email, nom, prénom, adresse, mot de
                      passe,etc);
                      <br />
                      -Les informations relatives aux commandes que vous passez
                      au sein de la Plateforme (produit acheté, numéro de
                      commande, adresse de livraison,etc);
                      <br />
                      -Si vous avez accepté l’enregistrement de votre carte
                      bancaire: les informations relatives à votre carte
                      bancaire (nom du porteur de la carte bancaire, les 4
                      derniers chiffres du numéro de la carte bancaire et sa
                      date d’expiration) seront enregistrées par l’intermédiaire
                      d’un prestataire de paiement sécurisé, qui a seul accès à
                      ces données;
                      <br />
                      -Les informations que vous nous transmettez lorsque vous
                      contactez le service-client ou le producteur par le biais
                      de la Plateforme Kuupanda (contenu des échanges, nature de
                      la réclamation, retour de produits,etc);
                      <br />
                      -Vos données de connexion et de navigation au sien de la
                      Plateforme Kuupanda (adresse IP, les produits et pages que
                      vous avez consultés ou recherchés);
                      <br />
                      -Les données relatives à la consultation des emails que
                      nous vous adressons à des fins statistiques et de
                      prospection commerciale;
                      <br />
                      -Les informations que vous renseignez lorsque vous laissez
                      un message sur la Plateforme;
                      <br />
                      -Des informations sur nos publicités que vous consultez.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        4. Combien de temps conservons-nous vos données?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Nous n’avons pas de politique de restriction de
                      conservation des données dans le temps.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>5. A qui sont transmises vos données?</strong>
                      <br />
                    </h6>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-4">
                        5.1 A nos prestataires de services
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Vos données sont accessibles aux prestataires de service
                      auxquels nous faisons appel pour sous-traiter tout ou
                      partie des traitements&nbsp; que nous effectuons avec vos
                      données (hébergement de la Plateforme et des données,
                      maintenance, envoi des emails de confirmation de commande,
                      emailing, paiement sécurisé,etc)
                      <br />
                      <br />
                      Les sous-traitants auxquels nous faisons appel présentent
                      les garanties requises par la réglementation applicable en
                      matière de protection de données à caractère personnel.
                      Ils n’ont accès qu’aux seules données nécessaires à
                      l’exécution de leurs prestations et ne sont pas autorisés
                      à traiter vos données pour d’autres usages. Nous avons par
                      ailleurs signé avec chacun d'entre eux des accords pour
                      garantir la sécurité et l’intégrité de vos données et leur
                      traitement dans le respect de la réglementation
                      applicable.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-5">
                        5.2 Aux producteurs de la Plateforme et les
                        transporteurs
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Les données nécessaires à la gestion de vos commandes sont
                      transmises aux producteurs&nbsp; auprès desquels vous avez
                      acheté un produit par le biais de la Plateforme Kuupanda.
                      <br />
                      <br />
                      Les données permettant d’assurer la livraison de votre
                      commande sont également transmises aux transporteurs que
                      vous aurez sélectionné ou qui vous sera proposé lors de la
                      passation de votre commande
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-6">
                        5.3 Aux autorités
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Vos do-nnées personnelles pourront être divulguées aux
                      autorités en application:&nbsp;
                      <br />
                      -d’une loi,
                      <br />
                      -d’un règlement,
                      <br />
                      -ou en vertu d’une décision d’une autorité réglementaire
                      ou judiciaire compétente.
                      <br />
                      <br />
                      De manière générale, nous nous engageons à nous conformer
                      à toutes les règles légales qui pourrait:
                      <br />
                      -empêcher
                      <br />
                      -limiter,
                      <br />
                      -ou réglementer la diffusion d’informations ou de données
                      et notamment à se conformer à la réglementation applicable
                      en matière de protection de données.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-7">
                        5.4 En cas d'acquisition, fusion, transfert d’activité
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Dans l’hypothèse où la société SAS KUUPANDA ou une partie
                      de ses activités ferait l’objet d’une acquisition par une
                      société tierce ou d’une fusion, nous vous informerons au
                      préalable du transfert de vos données et de la possibilité
                      de vous y opposer.
                      <br />
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        6. Quels sont vos droits sur vos données et comment les
                        exercer?
                      </strong>
                      <br />
                    </h6>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-8">
                        6.1 Quels sont vos droits?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Vous disposez du droit d’obtenir de nos services:
                      <br />
                      -La confirmation que des données à caractère personnel
                      vous concernant sont ou ne sont pas traitées,et le cas
                      échéant d’y avoir accès;
                      <br />
                      -La rectification des données à caractère personnel vous
                      concernant qui sont inexactes ou incomplètes;
                      <br />
                      -Dans certaines conditions, l’effacement de vos données à
                      caractère personnel;
                      <br />
                      -Dans certaines conditions, la limitation du traitement de
                      vos données à caractère personnel;
                      <br />
                      -La portabilité de vos données à caractère
                      personnel;&nbsp;
                      <br />
                      -D’organiser le sort de vos données personnelles en cas de
                      décès (conservation, effacement ou communication à une
                      personne désignée).
                      <br />
                      <br />
                      Vous disposez également du droit de vous opposer dans
                      certaines conditions au traitement de vos données à
                      caractère personnel.&nbsp;
                      <br />
                      <br />
                      Pour plus d'informations sur les droits dont vous disposez
                      à l’égard de vos données, nous vous invitons à consulter
                      le site de la CNIL:{" "}
                      <a href="http://www.cnil.fr">www.cnil.fr</a>.<br />
                      <br />
                      Vous pouvez également à tout moment vous opposer au
                      traitement de vos données à des fins de prospection
                      commerciale et retirer votre consentement au traitement de
                      vos données mis en œuvre par nos services à des fins de
                      publicité comportementale, analyse de la navigation et de
                      mesures d'audience.
                      <br />
                      <br />
                      Nous vous rappelons également que vous disposez du droit
                      d’introduire une réclamation auprès de la CNIL si vous
                      estimez que vos droits n’ont pas été respectés.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong className="af-class-bold-text-9">
                        6.2. Comment exercer mes droits?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Pour toute demande d’accès, d’opposition, rectification,
                      portabilité, limitation, sort de vos données en cas de
                      décès: Merci d’adresser un email à l’adresse suivante{" "}
                      <a href="mailto:rgpd@kuupanda.com">rgpd@kuupanda.com</a>
                      &nbsp; en :<br />
                      -précisant l’objet de votre demande,
                      <br />
                      -indiquant votre nom et prénom,
                      <br />
                      -joignant une photocopie recto-verso d’une pièce
                      d’identité et ce afin de nous permettre de vérifier votre
                      identité.
                      <br />
                      <br />
                      Nos services reviendront vers vous dans les meilleurs
                      délais et au plus tard dans un délai d’un mois à compter
                      de la réception de votre demande.
                      <br />
                      <br />
                      Pour vous opposer au traitement de vos données à des fins
                      de prospection commerciale:
                      <br />
                      Vous pouvez nous adresser un email à l’adresse{" "}
                      <a href="mailto:rgpd@kuupanda.com">
                        rgpd@kuupanda.com
                      </a>{" "}
                      ou cliquer sur le lien de désinscription présent dans nos
                      emails promotionnels et dans notre newsletter.
                      <br />
                      <br />
                      Nos services s’engagent à vous désinscrire dans les
                      meilleurs délais de notre liste de diffusion.
                      <br />
                      <br />
                      Pour retirer votre consentement au traitement de vos
                      données mis en œuvre par nos services à des fins de
                      publicité comportementale, analyse de la navigation et
                      mesures d’audience: vous pouvez nous adresser un email à
                      l’adresse{" "}
                      <a href="mailto:rgpd@kuupanda.com">
                        rgpd@kuupanda.com
                      </a>{" "}
                      ou paramétrer vos cookies.
                      <br />
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        7. Vos données sont-elles transférées en dehors de
                        l’Union Européenne?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Certaines opérations de traitement que nous confions à nos
                      sous-traitants sont réalisées en dehors de l’Union
                      Européenne. Des accords ont été signés avec chacun de ces
                      prestataires afin de garantir que les transferts de vos
                      données sont opérés en toute sécurité et dans le respect
                      de la réglementation applicable en vigueur en matière de
                      protection des données.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        8. Contacter le Délégué à la Protection des données
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-29">
                      Pour toute question portant sur le traitement de vos
                      données personnelles ou pour exercer vos droits sur vos
                      données, le Délégué à la Protection des Données
                      personnelles, désigné par SAS KUUPANDA peut être contacté:
                      <br />
                      -par email à l’adresse suivante:{" "}
                      <a href="mailto:jorgel@kuupanda.com">
                        jorgel@kuupanda.com
                      </a>
                      ;<br />
                      -par courrier postal à l’adresse suivante: SAS KUUPANDA 16
                      rue Joseph Parayre Résidence Les Hortes 66400 Céret.
                      <br />
                    </div>
                    <h1 className="af-class-heading-11">
                      <strong>Nos cookies</strong>
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      <strong>1.&nbsp;Qu’est-ce qu’un cookie?</strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-30">
                      Un cookie est un petit fichier alphanumérique qui est
                      déposé dans le terminal de l’ordinateur, smartphone,
                      tablette, mobile, etc, de l'utilisateur, lors de sa
                      connexion à la Plateforme.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>
                        2. Quels cookies utilise-t-on sur la Plateforme?
                      </strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-30">
                      Au sein de nos sites (kuupanda.com et
                      gestion.kuupanda.com), nous utilisons des cookies pour
                      permettre:&nbsp;
                      <br />
                      -D’améliorer votre navigation au sein des sites et ainsi
                      vous permettre d’utiliser ses différentes fonctionnalités,
                      telles que notamment pour vous permettre de vous
                      identifier et de vous connecter à votre compte
                      utilisateur;
                      <br />
                      -D’établir des statistiques et volumes de fréquentation et
                      d'utilisation des divers éléments composant les sites
                      (rubrique et contenus visités, parcours, date et heure de
                      consultation…), permettant d’améliorer l’intérêt et
                      l'ergonomie des sites;
                      <br />
                      -De reconnaître votre navigateur comme ancien visiteur
                      enregistré, pour se souvenir de toutes les préférences qui
                      peuvent être envoyées lors de l’utilisation des sites par
                      votre navigateur et notamment de garder en mémoir votre
                      panier d’achat;
                      <br />
                      -D’afficher de la publicité personnalisée en fonction de
                      votre navigation, de votre profil, des informations que
                      vous avez fournies;&nbsp;
                      <br />
                      -De personnaliser l’affichage des contenus des sites en
                      fonction de votre navigation, de votre profil, des
                      informations que vous avez fournies;
                      <br />
                      -De mesurer et analyser l’audience de nos sites;&nbsp;
                      <br />
                      -Votre accès à des espaces réservés et personnels de nos
                      sites, tels que votre compte grâce à des identifiants ou
                      des données que vous avez éventuellement antérieurement
                      confiés;
                      <br />
                      -De mettre en œuvre des mesures de sécurité et de
                      prévention de fraude.
                      <br />
                      <br />A ce titre, et pour les cookies qui n’ont pas pour
                      finalité exclusive de permettre ou faciliter la navigation
                      ou qui ne sont pas strictement nécessaires à la gestion de
                      vos commandes ou à la fourniture des services, un bandeau
                      d'information s’affiche lors de votre connexion aux sites
                      afin de vous informer préalablement au dépôt de ces
                      cookies, de leur présence et de vous permettre, le cas
                      échéant, de vous opposer à leur dépôt sur votre terminal.
                      <br />
                      <br />
                      Le dépôt de cookies réalisé dans le cadre de l’utilisation
                      des sites ne permet pas de vous identifier personnellement
                      mais il enregistre des informations relatives à la
                      navigation de votre terminal au sein des sites que nous
                      pourrons lire lors de vos visites ultérieures au sein des
                      sites.
                      <br />
                      <br />
                      Les informations collectées sont pour notre usage exclusif
                      et celui de nos partenaires, et ne sont en aucun cas
                      cédées à des tiers.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      <strong>3.&nbsp;Comment désactiver les cookies?</strong>
                      <br />
                    </h6>
                    <div className="af-class-text-block-30">
                      Vous êtes informé que tout paramétrage que vous pourrez
                      entreprendre sera susceptible de modifier votre navigation
                      sur Internet et vos conditions d’accès à certains services
                      nécessitant l’utilisation de cookies. Votre attention est
                      attirée sur le fait qu’un certain nombre de
                      fonctionnalités nécessaires à la navigation sur les sites
                      risquent d’être dégradées du fait des paramétrages que
                      vous aurez opérés (difficultés d’enregistrement ou
                      d’affichage…)
                      <br />
                      <br />
                      Le cas échéant, nous ne saurions en aucun cas être tenu
                      pour responsable des dysfonctionnements constatés.
                      <br />
                      <br />
                      Vous pouvez faire le choix à tout moment d’exprimer et de
                      modifier vos souhaits en matière de cookies, par le moyen
                      décrit ci-dessous&nbsp;
                      <br />
                      Les choix qui vous sont offerts par votre logiciel de
                      navigation pour le paramétrage des cookies:
                      <br />
                      -Vous pouvez configurer votre logiciel de navigation de
                      manière à ce que les cookies soient enregistrés dans votre
                      terminal&nbsp; ou, au contraire qu’ils soient rejetés,
                      soit systématiquement soit selon leur émetteur.
                      <br />
                      -Vous pouvez également configurer votre logiciel de
                      navigation de manière à ce que l’acceptation ou le refus
                      des cookies vous soient proposés ponctuellement, avant
                      qu’un cookie soit susceptible d’être enregistré dans votre
                      terminal.
                      <br />
                      <br />
                      Pour la gestion des cookies et de vos choix, la
                      configuration de chaque navigateur est différente. Elle
                      est décrite dans le menu d’aide de votre navigateur, qui
                      vous permettra de savoir de quelle manière modifier vos
                      souhaits en matière de cookies.
                      <br />- Pour{" "}
                      <a href="https://support.microsoft.com/fr-fr/topic/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                        Internet Explorer™ 7 et 8
                      </a>
                      ,<br />
                      -Pour{" "}
                      <a href="https://support.microsoft.com/fr-fr/windows?ui=fr-FR&rs=fr-FR&ad=FR">
                        Internet Explorer™ 9
                      </a>
                      ,<br />
                      -Pour{" "}
                      <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">
                        Safari™&nbsp;
                      </a>
                      ,<br />
                      -Pour{" "}
                      <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">
                        Chrome™ version 30 et plus
                      </a>
                      ,<br />
                      -Pour{" "}
                      <a href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=activer-desactiver-cookies&redirectlocale=fr">
                        Firefox™ version 24 et plus&nbsp;
                      </a>
                      .<br />
                      <br />
                      Sur le site lors de l'apparition du bandeau, vous pouvez
                      aussi désactiver les cookies. Il vous suffit de vous
                      rendre sur “Paramètres des cookies” puis vous pouvez
                      choisir de rendre tout ou une partie des cookies inactifs.
                      <br />
                      <br />
                      Pour le paramétrage des cookies d’analyse CookiesPro:
                      <br />
                      <br />
                      Les cookies de la solution de suivi CookiesPro implantés
                      sur nos sites permettent de nous indiquer quand et combien
                      d’utilisateurs ont visité nos sites, quel système de
                      navigation ou d’exploitation ils utilisent et par quel
                      site ils sont arrivés sur nos sites:
                      <br />
                      <br />
                      Ces cookies nous permettent de connaître:
                      <br />
                      -La valeur en euros et la composition de votre panier,
                      même si la transaction n’est pas finalisée;
                      <br />
                      -Le contenu de vos recherches sur la liste;
                      <br />
                      -Votre horodatage;
                      <br />
                      -Votre localisation (géolocalisation basée sur votre
                      adresse IP à la ville la plus proche);
                      <br />
                      -L’URL de ma page de destination et l’URL du site Web à
                      partir duquel vous êtes arrivé;
                      <br />
                      -Votre adresse IP pseudonymisée;
                      <br />
                      -Le nom, la version, la langue et la taille de la fenêtre
                      de votre navigateur et de votre système d’exploitation
                      tels qu’ils sont transmis par votre navigateur.
                      <br />
                      <br />
                      Ces informations nous permettent d’améliorer nos services
                      et l’expérience utilisateur.
                      <br />
                      <br />
                      Vous pouvez vous opposer au dépôt des cookies CookiesPro
                      dans le paramétrage de votre navigateur. Pour plus
                      d’informations, consultez{" "}
                      <a href="https://cookiepedia.co.uk/giving-consent-to-cookies">
                        la politique de confidentialité de CookiesPro
                      </a>
                      .<br />
                      <br />
                      Pour le paramétrage des cookies de la fonctionnalité de
                      remarketing ou “Audiences Similaires” de Google Inc.
                      <br />
                      Nous utilisons les fonctionnalités Remarketing et
                      Audiences Similaires de Google Inc. sur nos sites (1600
                      Amphitheatre Parkway, Mountain View, CA 94043, États-Unis,
                      “Google”) pour analyser le comportement et les intérêts
                      des visiteurs de nos sites.
                      <br />
                      <br />
                      Le dépôt des cookies par Google permet d’analyser
                      l’utilisation que vous faites des sites et ainsi de créer
                      des publicités basées sur vos centres d’intérêts,
                      notamment pour :<br />
                      -créer des profils d’utilisateurs similaires à celui des
                      visiteurs de nos sites (Audiences similaires);
                      <br />
                      -d’afficher des annonces personnalisées en fonction de
                      votre parcours sur les sites lorsque vous visitez un autre
                      site Web sur le Réseau Display de Google (Remarketing).
                      <br />
                      <br />
                      Vous pouvez désactiver de manière permanente l’utilisation
                      des cookies par Google en cliquant sur le lien ci-dessous,
                      en téléchargeant et en installant le plug-in fourni:
                      <br />
                      <a href="https://support.google.com/ads/answer/7395996?hl=fr">
                        https://support.google.com/ads/answer/7395996?hl=fr
                        <br />‍
                      </a>
                      <br />
                    </div>
                    <div className="af-class-text-block-30">
                      Vous pouvez également désactiver l’utilisation de cookies
                      tiers en sélectionnant la page de désactivation de Network
                      Advertising Initiative à l’adresse
                      <br />
                      <a href="https://www.networkadvertising.org/choices/">
                        https://www.networkadvertising.org/choices/
                        <br />‍
                      </a>
                      <br />
                    </div>
                    <div className="af-class-text-block-30">
                      Pour plus d’informations sur la politique de
                      confidentialité de Google, rendez-vous sur :<br />
                      <a href="https://www.google.com/privacy/ads/">
                        https://www.google.com/privacy/ads/
                      </a>
                      <br />
                      <br />
                    </div>
                    <div className="af-class-text-block-30">
                      Pour le paramétrage de cookies de suivi des conversions
                      Google Adwords
                      <br />
                      <br />
                      Nous utilisons le programme de publicité en ligne “Google
                      AdWords” sur nos sites Web et, dans ce contexte, le suivi
                      des conversions (évaluation des visites), service
                      d’analyse fourni par Google Inc. (1600 Amphitheatre
                      Parkway, Mountain View, CA 94043, États-Unis, “Google”).
                      <br />
                      <br />
                      Lorsque vous cliquez sur une annonce diffusée par Google,
                      un cookie de suivi des conversions est placé sur votre
                      ordinateur. Ces cookies ont une validité limitée, ne
                      contiennent pas de données personnelles et ne sont donc
                      pas utilisés à des fins d’identification personnelle. Si
                      vous visitez certaines pages de nos sites et que le cookie
                      n'a pas expiré nous pouvons, ainsi que Google, identifier
                      que vous avez cliqué sur l’annonce publicitaire affichée
                      dans le moteur de recherche Google et que vous avez été
                      redirigé vers cette page.
                      <br />
                      <br />
                      Les informations obtenues à l’aide du cookie de conversion
                      sont utilisées pour créer des statistiques de conversion.
                      Cela nous indique le nombre total d’utilisateurs qui ont
                      cliqué sur l’une de nos annonces et qui ont été redirigés
                      vers une page de tag de suivi des conversions. Cependant,
                      nous ne recevons aucune information identifiant
                      personnellement les utilisateurs.
                      <br />
                      <br />
                      Vous pouvez empêcher le stockage des cookies en
                      sélectionnant les paramètres techniques appropriés de
                      votre logiciel de navigation. Nous attirons toutefois
                      votre attention sur le fait que, dans ce cas, vous ne
                      pourrez peut-être pas utiliser toutes les fonctionnalités
                      des sites dans son intégralité et ne serez pas inclus dans
                      les statistiques de suivi des conversions.
                      <br />
                      Vous pouvez également désactiver la publicité
                      personnalisée dans les paramètres des annonces Google
                      Ads.&nbsp;
                      <br />
                      Pour plus d’informations, voir &nbsp;
                      <a href="https://support.google.com/ads/answer/2662922?hl=fr">
                        https://support.google.com/ads/answer/2662922?hl=fr
                      </a>
                      &nbsp;
                      <br />
                      <br />
                      Vous pouvez également paramétrer l’utilisation de cookies
                      par des tiers en cliquant sur le lien{" "}
                      <a href="https://www.networkadvertising.org/choices/">
                        https://www.networkadvertising.org/choices/
                      </a>
                      <br />
                      Pour plus d’informations et la politique de
                      confidentialité de Google, rendez-vous sur:{" "}
                      <a href="https://www.google.com/policies/privacy/">
                        https://www.google.com/policies/privacy/
                      </a>
                      <br />
                      <br />
                    </div>
                    <div className="af-class-text-block-30">
                      Nous collectons également automatiquement les information
                      suivantes:
                      <br />
                      -Adresses IP,
                      <br />
                      -Le navigateur que vous utilisez,
                      <br />
                      -Votre fournisseur d’accès internet (FAI),
                      <br />
                      -Référence ou les pages que vous avez visionnées au sein
                      de nos sites (tels que les pages HTML, images, etc,)
                      <br />
                      -Votre système d’exploitation,
                      <br />
                      -La date et l’heure de votre accès ou des données agrégées
                      sur les clics agrégées dont nous avons besoin pour
                      analyser les tendances et optimiser nos services.&nbsp;
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default ProtectionDesDonneesView;

/* eslint-enable */
