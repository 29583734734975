import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { baseMargin, InnerPaper } from "./styles";
import SectionTitle from "../Common/SectionTitle";
import constants from "../VendorStore/utils/constants";
import Input from "../Common/Input";
import { isValidEmail } from "../Helpers/validation";

const Container = styled.div`
  ${baseMargin};
  width: 100%;
`;

const StyledPaper = InnerPaper;

const InputsContainer = styled.div`
  padding: 20px 0 0;
`;

function SimplifiedCustomerForm({
  className,
  onChange,
  name,
  lastName,
  email,
  phone,
  company,
  saleChannel,
}) {
  const handleOnChange = useCallback(
    (field) => (event) => {
      let invalid = false;
      if (field === "email") {
        invalid = !isValidEmail(event.target.value);
      } else {
        invalid = !Boolean(event.target.value && event.target.value.length > 0);
      }

      setInvalid((prev) => ({ ...prev, [field]: invalid }));
      onChange({ field, value: event.target.value });
    },
    [onChange]
  );

  const [invalid, setInvalid] = useState({
    name: false,
    lastName: false,
    email: false,
    phone: false,
    company: false,
  });

  return (
    <Grid xs={12} md={11} container item justify="center">
      <Container className={className}>
        <StyledPaper>
          <SectionTitle>Vos informations</SectionTitle>
          <InputsContainer>
            <Grid container item spacing={3}>
              {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
                <Grid container item direction="column" xs={12} md={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="label"
                  >
                    <Input
                      fullWidth={true}
                      label="Entreprise"
                      id="company"
                      name="company"
                      value={company}
                      onChange={handleOnChange("company")}
                      required
                      type="text"
                      error={invalid.company}
                    />
                  </Typography>
                </Grid>
              )}
              {saleChannel === constants.SALE_CHANNEL.STANDARD && (
                <>
                  <Grid container item direction="column" xs={12} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="label"
                    >
                      <Input
                        label="Prénom"
                        fullWidth={true}
                        id="name"
                        name="name"
                        value={name}
                        onChange={handleOnChange("name")}
                        required
                        type="text"
                        error={invalid.name}
                      />
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={12} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="label"
                    >
                      <Input
                        fullWidth={true}
                        label="Nom"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={handleOnChange("lastName")}
                        required
                        type="text"
                        error={invalid.lastName}
                      />
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid container item direction="column" xs={12} md={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="label"
                >
                  <Input
                    fullWidth={true}
                    label="Email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleOnChange("email")}
                    required
                    type="email"
                    error={invalid.email}
                  />
                </Typography>
              </Grid>
              <Grid container item direction="column" xs={12} md={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="label"
                >
                  <Input
                    fullWidth={true}
                    label="Téléphone"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleOnChange("phone")}
                    required
                    type="text"
                    error={invalid.phone}
                  />
                </Typography>
              </Grid>
            </Grid>
          </InputsContainer>
        </StyledPaper>
      </Container>
    </Grid>
  );
}
SimplifiedCustomerForm.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  saleChannel: PropTypes.oneOf([
    constants.SALE_CHANNEL.STANDARD,
    constants.SALE_CHANNEL.PROFESSIONAL,
  ]).isRequired,
};
SimplifiedCustomerForm.defaultProps = {
  className: undefined,
  name: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  company: undefined,
};

export default SimplifiedCustomerForm;
