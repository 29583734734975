import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { withWidth, Hidden } from "@material-ui/core";

// Components
import Footer from "./Footer";
import FooterMobile from "./FooterMobile";

// context
import TopBar from "../TopBar";
import GranvillageFooter from "../GranvillageFooter";
import { useBrandConfig } from "../Context/BrandConfigContext";

const Container = styled.div``;
const Content = styled.div``;

const MainLayout = ({
  children,
  width,
  title,
  hideHeader,
  fixedHeader,
  hideOrderButton,
  hideFooter,
}) => {
  const brandConfig = useBrandConfig();

  return (
    <Container>
      {!hideHeader && <TopBar fixed={fixedHeader} />}
      <Content width={width}>{children}</Content>

      {!hideFooter && (
        <div>
          <Hidden only={["xs", "sm"]}>
            {brandConfig.identifier === "kuupanda" ? (
              <Footer />
            ) : (
              <GranvillageFooter />
            )}
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            {brandConfig.identifier === "kuupanda" ? (
              <FooterMobile />
            ) : (
              <GranvillageFooter />
            )}
          </Hidden>
        </div>
      )}
    </Container>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  hideOrderButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

MainLayout.defaultProps = {
  title: undefined,
  hideHeader: false,
  fixedHeader: false,
  hideOrderButton: true,
  hideFooter: false,
};

export default withWidth()(MainLayout);
