import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "lodash-es";

const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const translatedDays = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
};

export default function Schedule({ schedule, ...rest }) {
  // remove this
  if (!schedule)
    return <i className=" MuiTypography-colorTextSecondary">No Schedule yet</i>;
  return (
    <div className="Schedule" {...rest}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {DAYS.map((day) => (
              <ScheduleRow key={day} name={day} values={schedule[day]} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Schedule.propTypes = {
  schedule: PropTypes.object,
};

const useStyles = makeStyles({
  root: {
    "&:last-child": {
      ".MuiTableCell-root": {
        border: "none",
      },
    },
  },
});

const ScheduleRow = React.memo(function ScheduleRow({ values, name, isLast }) {
  const theme = useTheme();
  const thStyle = {
    paddingLeft: theme.spacing(1),
    verticalAlign: "baseline",
  };
  if (isLast) thStyle.border = "none";
  const classes = useStyles();
  // UNCOMMENT to display only available days
  if (!values || !values.length) return null;
  return (
    <TableRow className={classes.root}>
      <TableCell component="th" scope="row" style={thStyle}>
        {capitalize(translatedDays[name])}
      </TableCell>
      <TableCell align="right">
        {values
          ? values.map(({ start, end }, i) => (
              <div key={i}>
                {start}-{end}
              </div>
            ))
          : " - "}
      </TableCell>
    </TableRow>
  );
});

ScheduleRow.propTypes = {
  isLast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
};
