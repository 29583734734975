import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useConfig } from "../../../../Context/ConfigContext";

const Container = styled.div`
  // hide form close button
  #PaylineWidget .pl-icon-close {
    display: none;
  }
`;
function executeCancelAction() {
  var cancelUrl = window.Payline.Api.getCancelAndReturnUrls().cancelUrl;
  //Execution du endToken
  window.Payline.Api.endToken(
    null,
    function () {
      //Redirection
      window.location.href = cancelUrl;
    },
    null,
    false
  );
}

function mangoPayTemplateOnLoad() {
  window
    .$(".pl-pmContainer .pl-pay-btn-container")
    .after(
      '<a class="cancelButton" style="display:block;cursor:pointer" title="Annuler le paiement">Annuler</a>'
    );
  window.$(".cancelButton").click(executeCancelAction);
}
function MangoPayTemplate({ token }) {
  const {
    config: { mangoPayScriptURL, mangoPayStyleURL },
  } = useConfig();
  window.MangoPayTemplateOnLoad = mangoPayTemplateOnLoad;
  return (
    <Container>
      <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src={mangoPayScriptURL} />
        <link href={mangoPayStyleURL} rel="stylesheet" />
      </Helmet>
      <div
        id="PaylineWidget"
        data-token={token}
        data-event-didshowstate="MangoPayTemplateOnLoad"
      />
    </Container>
  );
}

export default MangoPayTemplate;
