import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getDiscountTotal,
  getFeesTotal,
  getProductTotal,
  getTaxesTotal,
} from "../Helpers/cart";
import {
  storeCart,
  getCart,
  storePromo,
  getPromo,
} from "../Helpers/localStorageManager";
import { useHttpClient } from "../Hooks/useHttpClient";
import { useApiConfig } from "./ApiContext";
import { CartProductDto } from "../Cart/cart.dto";

const CartContext = React.createContext();

const getProduct = (productMap, id) => productMap[id];

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({});
  const [hide, setHide] = useState(false);
  const [added, setAdded] = useState(false);
  const [changed, setHasChanged] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [couponLines, setCouponLines] = useState([]);
  const [promoValid, setPromoValid] = useState(false);

  const { apiRoot } = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const getNewCart = useCallback(
    async (cart, promoCode) => {
      // Remove the producer info from the product, to reduce payload size
      const cartDto = Object.fromEntries(
        Object.entries(cart).map(([productId, productInfo]) => {
          return [
            productId,
            {
              ...productInfo,
              product: {
                ...productInfo.product,
                producer: {
                  id: productInfo.product.producer.id,
                  isGroup: productInfo.product.producer.isGroup || false,
                },
              },
            },
          ];
        })
      );

      const newCart = await httpClient.post("/cart/validateCart", {
        cart: cartDto,
        cartAmount: Object.values(cart).reduce(
          (acc, it) => acc + it.product.price * it.quantity,
          0
        ),
        ...(promoCode && { codes: promoCode.split(",") }),
      });

      setPromoValid(newCart.promoCode && newCart.promoCode.length > 0);

      setCouponLines(newCart.couponLines);

      return newCart;
    },
    [httpClient]
  );

  const validateCart = useCallback(
    async (cart, promoCode) => {
      const cartResponse = await getNewCart(cart, promoCode);

      const removed = Object.values(cart).reduce((acc, item) => {
        if (!cartResponse.cart[item.product.id]) {
          return {
            ...acc,
            [item.product.id]: { ...item },
          };
        } else {
          return acc;
        }
      }, {});

      setHasChanged(false);

      const cartToValidate = { ...cartResponse.cart, ...removed };

      const validatedCart = Object.values(cartToValidate).reduce(
        (acc, item) => {
          const errors = cartResponse.productErrors[item.product.id] || [];
          const promos = cartResponse.appliedPromos[item.product.id] || [];

          if (errors.length > 0) {
            setHasChanged(true);
          }

          return {
            ...acc,
            [item.product.id]: { ...item, errors, promos },
          };
        },
        {}
      );

      const storedCart = Object.values(cartResponse.cart).reduce(
        (acc, item) => {
          const errors = cartResponse.productErrors[item.product.id] || [];
          const promos = cartResponse.appliedPromos[item.product.id] || [];

          return {
            ...acc,
            [item.product.id]: { ...item, errors, promos },
          };
        },
        {}
      );

      setCart(validatedCart);
      storeCart(storedCart);
    },
    [getNewCart]
  );

  const checkAndAcknowlege = useCallback(
    async (cart, promoCode) => {
      const cartResponse = await getNewCart(cart, promoCode);

      setCart(cartResponse.cart);
      storeCart(cartResponse.cart);
    },
    [getNewCart]
  );

  const addProduct = useCallback(
    async ({ id, producer, productMap, quantity = 1 }) => {
      const newCart = { ...cart };

      // get product instance from producer's product map
      const product = getProduct(productMap, id);

      const producerName = producer.storefront.name;

      const newProduct = {
        quantity,
        product: new CartProductDto({
          ...product,
          producer: { ...producer, name: producerName },
        }),
        size: null,
      };

      //TODO: consider size, add here as key maybe?
      const key = newProduct.product.id;
      //item is already in the cart
      if (newCart.hasOwnProperty(key)) {
        newCart[key].quantity = quantity;
      } else {
        newCart[key] = newProduct;
      }

      setAdded(true);
      setTimeout(() => setAdded(false), 3000);

      await validateCart(newCart, promoCode);
    },

    [cart, validateCart, setAdded, promoCode]
  );

  const changePromo = (code) => {
    setPromoCode(code);
    storePromo(code);
  };

  const clearPromo = useCallback(async () => {
    setPromoCode("");
    storePromo("");
    await validateCart(cart, "");
  }, [cart, validateCart]);

  const applyPromo = async (code) => {
    await validateCart(cart, code);
  };

  const changeQuantity = useCallback(
    async (id, quantity) => {
      // check that item exists in the cart
      if (!cart.hasOwnProperty(id)) {
        return;
      }
      // dont allow adding more than max sales quantity
      if (
        cart[id].product.availableQuantity !== null &&
        quantity > cart[id].product.availableQuantity
      ) {
        return;
      }
      const newCart = { ...cart };
      newCart[id].quantity = quantity;
      if (quantity === 0) {
        delete newCart[id];
      }

      await validateCart(newCart, promoCode);
    },
    [cart, validateCart, promoCode]
  );

  const removeFromCart = useCallback(
    async (id) => {
      if (!cart.hasOwnProperty(id)) {
        return;
      }
      const newCart = { ...cart };
      delete newCart[id];

      await validateCart(newCart, promoCode);
    },
    [cart, validateCart, promoCode]
  );

  const clearCart = useCallback(() => {
    setPromoCode("");
    storePromo("");
    setCart({});
    storeCart({});
  }, []);

  useEffect(() => {
    const setCode = async () => {
      let { code } = getPromo();

      //we want a string
      if (!code) {
        code = "";
      }

      setPromoCode(code);

      const currentCart = getCart();
      await validateCart(currentCart, code);
    };

    setCode();
  }, [validateCart]);

  const getCartTotals = useCallback(
    (contactPoint, couponLines) => {
      const productTotal = getProductTotal(cart);
      const feesTotal = getFeesTotal(contactPoint);
      const discountTotal = getDiscountTotal(couponLines);
      const taxes = getTaxesTotal(cart);
      const total =
        productTotal + feesTotal + taxes - discountTotal > 0
          ? productTotal + feesTotal + taxes - discountTotal
          : 0;
      return {
        productTotal: productTotal.toFixed(2),
        feesTotal: feesTotal.toFixed(2),
        discountTotal: discountTotal.toFixed(2),
        taxes: taxes.toFixed(2),
        total: total.toFixed(2),
      };
    },
    [cart]
  );

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        changeQuantity,
        removeFromCart,
        clearCart,
        addProduct,
        added,
        hide,
        setHide,
        getCartTotals,
        validateCart,
        changed,
        changePromo,
        applyPromo,
        clearPromo,
        promoCode,
        getNewCart,
        couponLines,
        checkAndAcknowlege,
        promoValid,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);
  if (context == null) {
    throw new Error("You must call useCart only when inside a CartProvider.");
  }
  return context;
}

const CartConsumer = CartContext.Consumer;

export { CartProvider, CartContext, CartConsumer };
