// https://stripe.com/docs/stripe-js/react#element-components
// https://stripe.com/docs/payments/accept-a-payment#web
// This component should receive a list of payment methods (cash / stripe / etc) and render them
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import constants from "../../utils/constants";
import Divider from "@material-ui/core/Divider";
import BasicPaymentMethod from "./BasicPaymentMethod";
import PaymentMethodOptions from "../../../Common/PaymentMethodOptions";
import MangoPay from "./MangoPay/MangoPay";

const StyledDivider = styled(Divider)`
  margin: 15px 0;
`;
const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const PaymentMethodContainer = styled.div`
  min-height: 60px;
`;

function getPaymentMethodDescription(paymentMethod) {
  switch (paymentMethod) {
    case constants.PAYMENT_METHOD.CREDIT_CARD:
      return "";
    case constants.PAYMENT_METHOD.TRANSFER:
      return "Payez en direct par virement";
    case constants.PAYMENT_METHOD.CHEQUE:
      return "Payez en direct par chèque sur place";
    case constants.PAYMENT_METHOD.CASH:
      return "Payez en espèce sur place";
    default:
      return "";
  }
}

function PaymentForm({
  paymentMethods,
  orderDTO,
  onSuccessfulPayment,
  onCancelPaymentProcess,
  onFailedPayment,
  isPaymentBeingProcessed,
  customerInfo,
  onPaymentFormError,
  onPaymentFormReady,
  total,
}) {
  const [paymentMethod, setPaymentMethod] = useState(
    paymentMethods.find((it) => it).type
  );
  const handlePaymentMethodChange = useCallback(
    (newPaymentMethod) => {
      if (!newPaymentMethod) {
        return;
      }
      setPaymentMethod(newPaymentMethod);
    },
    [setPaymentMethod]
  );
  return (
    <Grid item xs={12}>
      <StyledTypography gutterBottom variant="h6" component="h3">
        Mode de paiement
      </StyledTypography>
      <PaymentMethodOptions
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        onChangePaymentMethod={handlePaymentMethodChange}
      />
      <StyledDivider />
      <PaymentMethodContainer>
        {paymentMethod === constants.PAYMENT_METHOD.CREDIT_CARD && (
          // <Stripe
          //   orderDTO={orderDTO}
          //   isPaymentBeingProcessed={isPaymentBeingProcessed}
          //   onSuccessfulPayment={onSuccessfulPayment}
          //   onFailedPayment={onFailedPayment}
          //   customerInfo={customerInfo}
          //   onPaymentFormError={onPaymentFormError}
          //   onPaymentFormReady={onPaymentFormReady}
          // />
          <MangoPay
            active={isPaymentBeingProcessed}
            orders={[orderDTO]}
            buyer={customerInfo}
            amount={total}
            onSuccessfulPayment={onSuccessfulPayment}
            onPaymentEnd={onCancelPaymentProcess}
            onPaymentFormError={onPaymentFormError}
            onPaymentFormReady={onPaymentFormReady}
          />
        )}
        {paymentMethod !== constants.PAYMENT_METHOD.CREDIT_CARD && (
          <BasicPaymentMethod
            description={getPaymentMethodDescription(paymentMethod)}
            paymentMethod={paymentMethod}
            onPaymentFormError={onPaymentFormError}
            onSuccessfulPayment={onSuccessfulPayment}
            isPaymentBeingProcessed={isPaymentBeingProcessed}
            onPaymentFormReady={onPaymentFormReady}
          />
        )}
      </PaymentMethodContainer>
    </Grid>
  );
}

PaymentForm.propTypes = {
  className: PropTypes.string,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
      options: PropTypes.object,
    })
  ),
  paymentMethod: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
  orderDTO: PropTypes.object,
  customerInfo: PropTypes.object,
};
// TODO: calculate payment methods from API config
PaymentForm.defaultProps = {
  paymentMethods: [
    { type: constants.PAYMENT_METHOD.CREDIT_CARD },
    { type: constants.PAYMENT_METHOD.CASH },
  ],
  orderDTO: undefined,
  customerInfo: undefined,
};

export default PaymentForm;
