import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isEmpty } from "lodash";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  RiPriceTag3Line,
  RiErrorWarningLine as WarningIcon,
} from "react-icons/ri";
import {
  getDiscountTotal,
  getProductTotal,
  groupCartItemsByProducer,
  groupCouponLinesProducer,
} from "../Helpers/cart";
import CartItem from "./CartItem";
import PromoCode from "./PromoCode";
import { useCart } from "../Context/CartContext";
import { getPrice } from "../VendorStore/services/utils";
import { NumberFormatCustom } from "@kuupanda/commons";

const CartBadgeContent = styled(Fab)`
  height: 75px;
  width: 75px;
  ${({ theme }) => `
    background: ${theme.palette.primary.contrastText};
  `};
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4) !important;
`;

const CartList = styled.div`
  height: 600px;
  width: 485px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4) !important;
  position: fixed;
  bottom: 10px;
  left: auto;
  right: 25px;
  ${({ theme }) => `
    background: ${theme.palette.primary.contrastText};
  `};
  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  z-index: 1300;
  &.fade-enter {
    opacity: 0.01;
  }
  &.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
  &.fade-exit-done {
    opacity: 0;
  }
`;

const PromoCodeTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  @media (max-width: 768px) {
    right: 5px;
  }
  cursor: pointer;
`;

const Footer = styled.div`
  position: absolute;
  bottom: -1px;
  width: 100%;
  left: 0;
`;

const CartTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  @media (max-width: 768px) {
    left: 5px;
  }
`;

const ClearCart = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1200;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s, visibility 0.4s;
`;

const CartBadge = styled(Badge)`
  position: fixed;
  bottom: 30px;
  left: auto;
  right: 50px;
  @media (max-width: 768px) {
    right: 25px;
    bottom: 10px;
  }
  &.shake {
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -moz-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -ms-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -o-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(0, -1px, 0);
      }
      20%,
      80% {
        transform: translate3d(0, 2px, 0);
      }
      30%,
      50%,
      70% {
        transform: translate3d(0, -4px, 0);
      }
      40%,
      60% {
        transform: translate3d(0, 4px, 0);
      }
    }
  }
  z-index: 9999;
`;

const Header = styled.div`
  padding-bottom: 30px;
`;

const CheckoutButton = styled(Button)`
  height: 75px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

const CloseButton = styled(Button)`
  height: 75px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

const Producer = styled.div`
  padding: 5px 20px;
  margin-top: 20px;
  border: 1px solid #eff0f7;
  border-radius: 4px;
`;

const CheckoutColumn = styled.div`
  float: left;
  width: 80%;
`;

const CloseColumn = styled.div`
  float: left;
  width: 20%;
  background-color: white;
`;

const ProducerName = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
`;

const CartListContent = styled.div`
  overflow-y: auto;
  height: 455px;
  margin: 0 5px;
  padding: 0 2px 45px 2px;
  @media (max-width: 768px) {
    height: 100%;
    padding-bottom: 150px;
  }
`;

const NoItems = styled(Typography)`
  margin-top: 5px;
  -webkit-animation: fadein 250ms;
  -moz-animation: fadein 250ms;
  -ms-animation: fadein 250ms;
  -o-animation: fadein 250ms;
  animation: fadein 250ms;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CartErrorsMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  background: rgba(27, 26, 54, 0.8);
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 4px;
  position: fixed;
  bottom: 80px;
  left: auto;
  right: 110px;
  z-index: 9999;
  padding: 8px 10px;
  @media (max-width: 768px) {
    right: 85px;
    bottom: 65px;
  }
`;

const Subtotal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Coupon = styled.span`
  background-color: ${({ theme }) => theme.palette.warning.light};
  border: 1.5px solid ${({ theme }) => theme.palette.warning.main};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #464861;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: right;
`;

const Savings = styled(Typography)`
  font-size: 14px;
  color: #464861;
`;

const Saving = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.warning.main};
`;

const DiscountAmount = styled(Typography)`
  margin-top: 5px;
  text-align: right;
  color: ${({ theme }) => theme.palette.grey[400]};
  text-decoration: line-through;
`;

const SubTotalAmount = styled(Typography)`
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #464861;
  text-align: right;
`;

const SaleIcon = styled(RiPriceTag3Line)`
  fill: ${({ theme }) => theme.palette.warning.main};
`;

const ProducerCouponLines = ({
  subtotal,
  couponLines,
}: {
  subtotal: number;
  couponLines: any[];
}) => {
  const totalDiscount = couponLines.reduce(
    (acc: number, coupon: any) => acc + coupon.discount,
    0
  );
  return (
    <>
      {couponLines &&
        couponLines.map((line: any) => (
          <Coupon>
            <SaleIcon />
            <span>
              {line.discountApplied.codes && line.discountApplied.codes.length
                ? line.discountApplied.codes.join(",")
                : `-${line.discount} €`}
            </span>
          </Coupon>
        ))}
      <Savings>
        Tu as bénéficié de <Saving>{totalDiscount}€</Saving> de remise!
      </Savings>

      <DiscountAmount>{subtotal.toFixed(2)}€</DiscountAmount>
    </>
  );
};

const Cart = () => {
  const {
    cart,
    changeQuantity,
    removeFromCart,
    clearCart,
    added,
    hide,
    changed,
    changePromo,
    clearPromo,
    promoCode,
    validateCart,
    couponLines,
    promoValid,
  } = useCart();
  const [cartPanelOpen, setCartPanelOpen] = useState(false);
  const [promoOpen, setPromoOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const couponLinesByProducer = useMemo(
    () => groupCouponLinesProducer(couponLines),
    [couponLines]
  );

  const cartContents = useMemo(
    () => groupCartItemsByProducer(cart, couponLinesByProducer),
    [cart, couponLinesByProducer]
  );

  const [cartCount, cartTotal] = useMemo(() => {
    const productTotal = getProductTotal(cart);
    const discountTotal = getDiscountTotal(couponLines);

    const cartTotal: number =
      productTotal - discountTotal > 0 ? productTotal - discountTotal : 0;

    const cartCount: number = Object.values(cart).reduce(
      (acc: number, it: any) => acc + it.quantity,
      0
    );

    return [cartCount, cartTotal.toFixed(2)];
  }, [cart, couponLines]);

  const history = useHistory();

  const toggleCartPanel = useCallback(() => {
    setCartPanelOpen(!cartPanelOpen);

    if (cartPanelOpen) {
      validateCart(cart, promoCode);
    }
  }, [cartPanelOpen, cart, promoCode, validateCart]);

  const togglePromo = () => {
    setPromoOpen((open) => !open);
  };

  const applyPromo = async () => {
    await validateCart(cart, promoCode);
  };

  const goToCheckout = async () => {
    await validateCart(cart, promoCode);

    setCartPanelOpen(false);
    history.push("/checkout");
  };

  if (hide) {
    return <></>;
  }

  return (
    <>
      {!cartPanelOpen && cartCount > 0 && (
        <>
          <CartBadge
            className={added && "shake"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            color="primary"
            badgeContent={
              <NumberFormatCustom
                value={cartCount}
                displayType={"text"}
                suffix={" "}
                fixedDecimalScale={false}
              />
            }
          >
            <CartBadgeContent onClick={toggleCartPanel}>
              <CartIcon fontSize="large" />
            </CartBadgeContent>
          </CartBadge>

          {changed && (
            <CartErrorsMessage>
              <WarningIcon />
              <span>Certains de vos articles ont subi des modifications</span>
            </CartErrorsMessage>
          )}
        </>
      )}
      <TransitionGroup key="list">
        {cartPanelOpen && (
          <CSSTransition timeout={500} classNames="fade">
            <CartList>
              <Header>
                <CartTitle>
                  Panier <ClearCart onClick={clearCart}>(Vider)</ClearCart>
                </CartTitle>
                <PromoCodeTitle onClick={togglePromo}>
                  Vous avez un code promo ?
                </PromoCodeTitle>
              </Header>
              {isEmpty(cartContents) && (
                <NoItems variant="body2">Aucun produit dans le panier.</NoItems>
              )}
              <CartListContent>
                {promoOpen && (
                  <PromoCode
                    clearPromo={clearPromo}
                    promoCode={promoCode}
                    applyPromo={applyPromo}
                    onPromoChange={changePromo}
                    promoValid={promoValid}
                  />
                )}
                {Object.keys(cartContents).map(
                  (producerId: string, index: number) => (
                    <Producer key={index}>
                      <ProducerName>
                        {cartContents[producerId].producerName}
                      </ProducerName>
                      <TransitionGroup>
                        {cartContents[producerId].products.map(
                          (item: any, index: any) => (
                            <CSSTransition
                              key={index}
                              timeout={500}
                              classNames="move"
                            >
                              <CartItem
                                key={item.product.id}
                                quantity={item.quantity}
                                id={item.product.id}
                                name={item.product.name}
                                url={item.product.picture.url}
                                price={item.product.price}
                                onChangeQuantity={changeQuantity}
                                onRemoveFromCart={removeFromCart}
                                errors={item.errors}
                                promos={item.promos}
                                basePrice={item.product.basePrice}
                                loading={loading}
                                availableQuantity={
                                  item.product.availableQuantity
                                }
                                setLoading={setLoading}
                              />
                            </CSSTransition>
                          )
                        )}
                      </TransitionGroup>

                      <Subtotal>
                        <div>
                          {couponLinesByProducer &&
                            couponLinesByProducer[producerId] && (
                              <ProducerCouponLines
                                subtotal={cartContents[producerId].subtotal}
                                couponLines={couponLinesByProducer[producerId]}
                              />
                            )}
                          <SubTotalAmount>
                            {!loading ? (
                              <>
                                Montant TTC:{" "}
                                {getPrice({
                                  price: Math.max(
                                    0,
                                    cartContents[producerId].subtotal -
                                      cartContents[producerId].totalDiscount
                                  ),
                                })}
                              </>
                            ) : (
                              <CircularProgress size={15} />
                            )}
                          </SubTotalAmount>
                        </div>
                      </Subtotal>
                    </Producer>
                  )
                )}
              </CartListContent>
              <Footer>
                <CheckoutColumn>
                  <CheckoutButton
                    onClick={goToCheckout}
                    disabled={cartCount === 0}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {!loading ? (
                      <>
                        Commander (TTC) -{" "}
                        <NumberFormatCustom
                          value={cartTotal}
                          displayType={"text"}
                        />
                      </>
                    ) : (
                      <CircularProgress color="secondary" size={15} />
                    )}
                  </CheckoutButton>
                </CheckoutColumn>
                <CloseColumn>
                  <CloseButton fullWidth onClick={toggleCartPanel}>
                    <CloseIcon fontSize="large" />
                  </CloseButton>
                </CloseColumn>
              </Footer>
            </CartList>
          </CSSTransition>
        )}
      </TransitionGroup>
      {cartPanelOpen && <Backdrop onClick={toggleCartPanel} />}
    </>
  );
};

export default Cart;
