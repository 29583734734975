import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

const useRowStyle = makeStyles((theme) => ({
  secondaryItem: {
    paddingLeft: theme.spacing(3),
    alignItems: "start",
  },
  secondaryIcon: {
    marginTop: theme.spacing(0.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  titleLink: {
    display: "flex",
    alignItems: "center",
  },
  itemDivider: {
    borderTop: (props) =>
      props.isFirstItem ? 0 : "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: 0,
  },
}));

export function RowBase({
  marker,
  isFirstItem,
  selected,
  onClick,
  index,
  children,
  onHoverChange,
}) {
  const handleMouseEnter = () => onHoverChange(marker.id);
  const handleClick = () => onClick(marker.id);
  const handleMouseLeave = () => onHoverChange(null);

  const classes = useRowStyle({ isFirstItem });
  return (
    <React.Fragment>
      <ListItem
        divider
        button
        selected={selected}
        classes={{ divider: classes.itemDivider }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <ListItemAvatar style={{ alignSelf: "flex-start", marginTop: 5 }}>
          <Avatar style={{ width: 30, height: 30, fontSize: "1em" }}>
            {index + 1}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>{children}</ListItemText>
      </ListItem>
    </React.Fragment>
  );
}

RowBase.propTypes = {
  children: PropTypes.any.isRequired,
  isFirstItem: PropTypes.bool.isRequired,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onHoverChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
