import React from "react";
import styled, { css } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@material-ui/core";
import { useGetAuth } from "../Context/AuthContext";
import MenuIcon from "@material-ui/icons/Menu";
import MobileMenuTemplate from "./MobileMenuTemplate";
import LogoLink from "./LogoLink";
import Disconnect from "./Disconnect";
import ProducerButton from "./ProducerButton";
import UserButton from "./UserButton";
import DesktopNavBar, {
  desktopOptionsArr,
  MenuOption,
  SubMenuOption,
} from "./DesktopNavBar";
import { useWhichBrand } from "../Hooks/useWhichBrand";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { MenuWithArrow } from "./MenuWithArrow";
import media from "../Helpers/media";
import { TopBarSearcher } from "./TopBarSearcher";

type Props = {
  fixed: boolean;
};

const Header = styled.div<Props & { isGranVillage: boolean }>`
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  display: flex;
  ${media.down.md`
    height: 57px;
    padding-right: 16px;
    padding-left: 16px;
  `}
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0 3px 12px 0 rgba(63, 63, 68, 0.15);
  height: 67px;
  padding: 2.5px 26px;
  background-color: white;
  color: ${(props) => (props.isGranVillage ? "#343A40" : "#212529")};
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LeftSideContainer = styled.div`
  margin-left: 15px;
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const SimpleWrapper = styled.div`
  margin-top: 0;
  margin-left: 15px;
  position: relative;
`;

const CustomMenuItem = styled(MenuItem)`
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  font-size: 14px;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
`;

const StyledMenuList = styled(MenuList)`
  min-width: 200px;
  padding-top: 0;
  padding-bottom: 0;
`;

const crossStyles = css`
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 25px;
  height: 1px;
  width: 25px;
  background: white;
`;

const MobileDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: calc(100% - 56px);
  }
  .MuiBackdrop-root {
    right: inherit;
    width: 56px;
    :before {
      transform: rotate(45deg);
      ${crossStyles}
    }
    :after {
      transform: rotate(-45deg);
      ${crossStyles}
    }
  }
`;

const MobileMenuHeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Divider = styled.div`
  height: 20px;
`;

const SearcherContainer = styled.div`
  width: 265px;
  margin-left: auto;
`;

const MobileMenuLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const MobileMenuItem = styled.div`
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
`;

const MobileSubMenuItem = styled.a`
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  user-select: none;
`;

// TODO change urls for menu options
const mobileOptions: Record<string, MenuOption[]> = {
  sectionOne: [{ description: "Accueil", url: "/" }, ...desktopOptionsArr],
  sectionTwo: [
    {
      description: "Mon profil",
      url: "/consommateur/profil/informations-personnelles",
    },
    { description: "Mon village", url: "/consommateur/mon-village" },
  ],
  myOrders: [{ description: "Mes commandes", url: "/profile/commandes" }],
  createAccount: [{ description: "S'inscrire", url: "/register/consommateur" }],
  signIn: [
    {
      description: "Se connecter",
      url: process.env.REACT_APP_GRANVILLAGE_CUSTOMER_LOGIN_URL || "",
    },
  ],
};

// TODO change urls for user dropdown menu
const userDropdownOptions = {
  loggedIn: [
    {
      description: "Mon profil",
      url: "/consommateur/profil/informations-personnelles",
    },
    { description: "Mon village", url: "/consommateur/mon-village" },
    { description: "Mes commandes", url: "/profile/commandes" },
  ],
  loggedOut: [
    { description: "S'inscrire", url: "/register/consommateur" },
    { description: "Se connecter", url: "/login" }, // process.env.REACT_APP_GRANVILLAGE_CUSTOMER_LOGIN_URL] || "" },
  ],
};

export default function TopBar({ fixed = true }: Props) {
  const location = useLocation();
  const [currentSubMenuKey, setCurrentSubMenuKey] = React.useState<string>("");
  const history = useHistory();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const { auth: user, logout } = useGetAuth();
  const isMobile = useMediaQuery("(max-width:1200px)");
  const [mobileDrawer, setMobileDrawer] = React.useState(false);
  const { isGranVillage } = useWhichBrand();
  const handleLogoClick: React.MouseEventHandler<HTMLElement> = (event) => {
    if (isGranVillage) {
      window.location.href = "/";
      return;
    }
    event.preventDefault();
    history.push("/");
  };

  const handleOpenDropdown: React.MouseEventHandler<HTMLElement> = (event) => {
    setMenuOpen((current) => !current);
  };

  const handleMenuAndDrawerClose = () => {
    setMenuOpen(false);
    setMobileDrawer(false);
  };

  const handleLogout = () => {
    logout();
    handleMenuAndDrawerClose();
  };

  const handleGoLogin = () => {
    history.push("/login");
  };

  const handleSubMenuItemClick: React.MouseEventHandler<HTMLElement> = (
    event
  ) => {
    event.stopPropagation();
    redirectTo(event.currentTarget.dataset.url);
  };

  const toggleDrawer = () => setMobileDrawer(!mobileDrawer);

  const renderMobileSubMenuItem =
    (urlPrefix: string | undefined = "") =>
    ({ description, url }: SubMenuOption) =>
      (
        <MobileSubMenuItem
          data-url={urlPrefix + url}
          onClick={handleSubMenuItemClick}
        >
          {description}
        </MobileSubMenuItem>
      );

  const makeMenuClickHandler =
    ({ description, url, subMenuOptions }: MenuOption) =>
    () => {
      const hasSubMenu = subMenuOptions?.length;
      if (hasSubMenu) {
        setCurrentSubMenuKey((key) => (key === description ? "" : description));
      } else {
        redirectTo(url);
      }
    };

  function redirectTo(url?: string) {
    if (!url) return;
    window.location.href = url;
  }

  const renderMobileMenuItem = (menuOpt: MenuOption) => {
    const { description, url, subMenuUrlPrefix, subMenuOptions } = menuOpt;
    const isSelected = currentSubMenuKey === description;
    const hasSubmenu = subMenuOptions?.length;
    const isCurrentRoute = subMenuUrlPrefix
      ? location.pathname.startsWith(subMenuUrlPrefix)
      : location.pathname === url;
    return (
      <MobileMenuItem onClick={makeMenuClickHandler(menuOpt)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            component="span"
            fontWeight={(isSelected || isCurrentRoute) && 700}
          >
            {description}
          </Box>
          {hasSubmenu &&
            (isSelected ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
        </Box>
        {isSelected && hasSubmenu && (
          <Box marginTop="6px">
            {subMenuOptions?.map(renderMobileSubMenuItem(subMenuUrlPrefix))}
          </Box>
        )}
      </MobileMenuItem>
    );
  };

  return (
    <Header fixed={fixed} isGranVillage={isGranVillage}>
      <Container>
        <LogoContainer>
          <LogoLink onClick={handleLogoClick} />
        </LogoContainer>
        {isMobile ? (
          <React.Fragment>
            <IconButton aria-label="Menu" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <MobileDrawer
              anchor="right"
              open={mobileDrawer}
              onClose={toggleDrawer}
            >
              <MobileMenuTemplate
                header={
                  <MobileMenuHeaderContainer>
                    <MobileMenuLogoContainer>
                      <LogoLink onClick={handleLogoClick} />
                    </MobileMenuLogoContainer>
                    <TopBarSearcher />
                    <Divider />
                    <ProducerButton />
                  </MobileMenuHeaderContainer>
                }
                options={{
                  primary: mobileOptions.sectionOne.map(renderMobileMenuItem),
                  secondary:
                    user && mobileOptions.sectionTwo.map(renderMobileMenuItem),
                  tertiary: user
                    ? mobileOptions.myOrders.map(renderMobileMenuItem)
                    : mobileOptions.createAccount.map(renderMobileMenuItem),
                  quaternary: user ? (
                    <MobileMenuItem onClick={handleLogout}>
                      <Disconnect />
                    </MobileMenuItem>
                  ) : (
                    mobileOptions.signIn.map(renderMobileMenuItem)
                  ),
                }}
                footer={""}
              />
            </MobileDrawer>
          </React.Fragment>
        ) : (
          <LeftSideContainer>
            <DesktopNavBar />
            <SearcherContainer>
              <TopBarSearcher />
            </SearcherContainer>
            <SimpleWrapper>
              <ProducerButton />
            </SimpleWrapper>
            <SimpleWrapper>
              <UserButton
                userName={user?.userName}
                onClick={isGranVillage ? handleOpenDropdown : handleGoLogin}
              />
              <MenuWithArrow
                transformOrigin="right"
                topOffset={65}
                open={menuOpen}
                fullWidth={!user}
                onClose={() => setMenuOpen(false)}
              >
                <StyledMenuList>
                  {(user
                    ? userDropdownOptions.loggedIn
                    : userDropdownOptions.loggedOut
                  ).map(({ description, url }) => (
                    <CustomMenuItem onClick={() => redirectTo(url)}>
                      {description}
                    </CustomMenuItem>
                  ))}
                  {user && (
                    <CustomMenuItem onClick={handleLogout}>
                      <Disconnect hideIcon />
                    </CustomMenuItem>
                  )}
                </StyledMenuList>
              </MenuWithArrow>
            </SimpleWrapper>
          </LeftSideContainer>
        )}
      </Container>
    </Header>
  );
}
