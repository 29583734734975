import PropTypes from "prop-types";
import React from "react";
import { Box, Typography } from "@material-ui/core";
import Schedule from "./Schedule";
import { RowBase } from "./RowBase";
import InfoIcon from "@material-ui/icons/Info";
import styled from "styled-components";

const InfoDescriptionIcon = styled(InfoIcon)`
  margin-right: 7px;
`;

const StyledBox = styled(Box)`
  display: flex;
  padding-top: 10px;
`;

export function ContactPointRow(props) {
  const { marker } = props;
  return (
    <RowBase {...props}>
      <React.Fragment>
        <Typography
          variant={"body1"}
          style={{ fontWeight: "bold" }}
          // className={classes.title}
        >
          {marker.address}
        </Typography>
        <Typography>{marker.name}</Typography>
        <Box
          display={"flex"}
          justifyItems={"flex-end"}
          justifyContent={"flex-end"}
          pl={4}
          mt={1}
        >
          <Schedule schedule={marker.schedule} style={{ width: "100%" }} />
        </Box>
        {marker.comment && marker.comment !== "" && (
          <StyledBox fontStyle="italic" pt={3}>
            <InfoDescriptionIcon color="primary" />
            {marker.comment}
          </StyledBox>
        )}
      </React.Fragment>
    </RowBase>
  );
}

ContactPointRow.propTypes = {
  isFirstItem: PropTypes.bool.isRequired,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onHoverChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
