import localStorageManager from "../../Helpers/localStorageManager";

const LOCAL_STORAGE_KEYS = {
  CONTACT_POINT: "kuuwidgetRememberCP",
  CLIENT_ADDRESS: "kuuwidgetRemember",
  CHOSEN_DATE: "kuuwidgetRememberDate",
  DESCRIPTION: "kuuwidgetRememberDescription",
};
// initialize all LOCAL_STORAGE_KEYS as empty objects
Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
  if (!localStorageManager.getItem(key)) {
    localStorageManager.setItem(key, JSON.stringify({}));
  }
});

export const storeChosenDateInLocalStorage = (chosenDate, type, vendorId) => {
  const vendorIdDateMap =
    JSON.parse(localStorageManager.getItem(LOCAL_STORAGE_KEYS.CHOSEN_DATE)) ||
    {};
  const newDates = {
    ...vendorIdDateMap,
    [vendorId]: { ...(vendorIdDateMap[vendorId] || {}), [type]: chosenDate },
  };
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CHOSEN_DATE,
    JSON.stringify(newDates)
  );
};

export const storeClientAddressInLocalStorage = (clientAddress, vendorId) => {
  const vendorIdAddressMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CLIENT_ADDRESS)
    ) || {};
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CLIENT_ADDRESS,
    JSON.stringify({ ...vendorIdAddressMap, [vendorId]: clientAddress })
  );
};

export const storeContactPointInLocalStorage = (contactPointId, vendorId) => {
  const vendorIdContactPointIdMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CLIENT_ADDRESS)
    ) || {};
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CONTACT_POINT,
    JSON.stringify({ ...vendorIdContactPointIdMap, [vendorId]: contactPointId })
  );
};

export const getClientAddressFromLocalStorage = (vendorId) => {
  const vendorIdAddressMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CLIENT_ADDRESS)
    ) || {};
  return vendorIdAddressMap[vendorId];
};

export const getDeliveryDateFromLocalStorage = (type, vendorId) => {
  const vendorIdDateMap =
    JSON.parse(localStorageManager.getItem(LOCAL_STORAGE_KEYS.CHOSEN_DATE)) ||
    {};
  return (
    vendorIdDateMap[vendorId] &&
    vendorIdDateMap[vendorId][type] &&
    new Date(vendorIdDateMap[vendorId][type])
  );
};

export const getAllContactPointFromLocalStorage = () =>
  JSON.parse(localStorageManager.getItem(LOCAL_STORAGE_KEYS.CONTACT_POINT)) ||
  {};
