import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

// Material Components
import Grid from "@material-ui/core/Grid";
import { Hidden, withWidth } from "@material-ui/core";

// Material Hooks
import { useTheme } from "@material-ui/core/styles";

// Material Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// Components
import SwitchForm from "../Common/SwitchForm";
import Input from "../Common/Input";
import Checkbox from "../Common/Checkbox";
import Button from "../Common/Button";

// Hooks
import { useHttpClient } from "../Hooks/useHttpClient";
import { withAuth } from "../Context/AuthContext";
import UserRegisterModal from "./UserRegisterModal";
import { useApiConfig } from "../Context/ApiContext";
import Typography from "@material-ui/core/Typography";
import { isValidEmail } from "../Helpers/validation";

// Styleds
const Container = styled(Grid)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.grey["200"]};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 40px 0 0 0;
  ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "40px 100px" : "0"}
`;
const FormContainer = styled(Grid)``;
const ContainerMobileForgetPassword = styled.p`
  margin-top: 3px;
`;
const TextOr = styled.p`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 3px;
`;
const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.color || "none"};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;

const InternalLink = styled(RouterLink)`
  text-decoration: none;
  color: ${(props) => props.color || "none"};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;

const Terms = styled.p`
  line-height: 1.5;
`;
const MobileContainer = styled(Grid)`
  height: 100%;
`;
const MobileForm = styled(Grid)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey["100"]};
  margin: 28px 0;
  padding: 23px;
`;
const MobileButton = styled(Button)`
  left: 5%;
  width: 90%;
`;
const Row = styled(ArrowForwardIcon)`
  border: 1px solid;
  border-radius: 50px;
  font-size: 10px;
`;

//work around mui scrollbar bug (https://material-ui.com/components/grid/#limitations)
const Wrapper = styled.div`
  padding: ${(props) => (props.variant === "small" ? "50px 200px" : "20px")};
`;

const Error = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.error.contrastText};
  padding: 5px 10px;
  margin: 12px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

function getFormFields({ theme, disabled, isGranvillage }) {
  return {
    login: {
      name: "login",
      title: "Bienvenue",
      buttonLabel: "Se connecter",
      linkAfterForm: !isGranvillage && (
        <InternalLink
          to="/reset-password"
          color={theme.palette.primary.main}
          disabled={disabled}
        >
          Mot de passe perdu?
        </InternalLink>
      ),
      inputs: {
        email: {
          name: "email",
          label: "Adresse email",
          required: true,
          type: "email",
          fullWidth: true,
        },
        password: {
          name: "password",
          label: "Mot de passe",
          required: true,
          type: "password",
          fullWidth: true,
        },
      },
    },
    createAccount: {
      name: "createAccount",
      title: "Je suis nouveau ici",
      buttonLabel: "Creér un compte",
      buttonMargin: isGranvillage && "7px 0",
      onClick:
        isGranvillage &&
        (() =>
          (window.location.href =
            process.env.REACT_APP_GRANVILLAGE_CUSTOMER_REGISTRATION_URL)),
      link: (
        <Terms>
          Vos données personnelles seront utilisées pour vous accompagner au
          cours de votre visite du site web et gérer l'accès à votre compte.
        </Terms>
      ),
      inputs: {
        firstName: {
          name: "name",
          label: "Prénom",
          required: true,
          fullWidth: true,
          size: {
            xs: 12,
            md: 5,
          },
        },
        lastName: {
          name: "lastName",
          label: "Nom",
          required: true,
          fullWidth: true,
          size: {
            xs: 12,
            md: 7,
          },
        },
        phone: {
          name: "phone",
          label: "Téléphone",
          required: true,
          fullWidth: true,
        },
        email: {
          name: "email",
          label: "Adresse email",
          required: true,
          type: "email",
          fullWidth: true,
        },
        password: {
          name: "password",
          label: "Mot de passe",
          required: true,
          type: "password",
          fullWidth: true,
        },
        checkbox: {
          name: "checkbox",
          type: "checkbox",
          label: (
            <Terms>
              En cochant cette case, vous acceptez les{" "}
              <Link
                target="_blank"
                href="https://kuupanda.com/cgv"
                color={theme.palette.primary.main}
              >
                Conditions Générales d'Utilisation et de Vente
              </Link>
            </Terms>
          ),
          required: true,
          fullWidth: true,
          size: {
            xs: 12,
            md: 5,
          },
        },
      },
    },
  };
}

const Account = ({
  login,
  width,
  onSuccess,
  onError,
  variant,
  isGranvillage,
}) => {
  const { apiRoot } = useApiConfig();
  const theme = useTheme();
  const httpClient = useHttpClient(apiRoot);
  const [values, setValues] = useState({
    checkbox: false,
  });
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const fields = useMemo(
    () => getFormFields({ theme, disabled: !!loading, isGranvillage }),
    [theme, loading]
  );
  const [showForm, setShowForm] = useState(fields.login.name);
  const [openUserRegister, setOpenUserRegister] = useState(false);
  const withoutBorders = useMemo(
    () => width === "xs" || width === "sm",
    [width]
  );

  const handleChangeInputs = useCallback(
    (e) => {
      const value =
        e.target.type !== "checkbox"
          ? e.target.type === "email"
            ? e.target.value.toLowerCase()
            : e.target.value
          : e.target.checked;

      setValues({
        ...values,
        [e.target.name]: value,
      });
    },
    [values, setValues]
  );

  const handleCreateAccount = useCallback(
    async (values) => {
      await httpClient.post("/user", {
        ...values,
        isProducer: false,
      });
      await login(apiRoot, {
        email: values.email,
        password: values.password,
      });
    },
    [httpClient, apiRoot, login]
  );

  const handleLogin = useCallback(
    async (values) => {
      await login(apiRoot, values);
    },
    [apiRoot, login]
  );

  const handleSubmit = useCallback(
    async (_, formId) => {
      if (isGranvillage) {
        window.location.href =
          process.env.REACT_APP_GRANVILLAGE_CUSTOMER_LOGIN_URL;
        return;
      }
      setLoading(formId);
      setError(null);
      try {
        switch (formId) {
          case "login":
            await handleLogin(values);
            onSuccess();
            break;
          case "createAccount":
            await handleCreateAccount(values);
            onSuccess();
            break;
          default:
            throw Error("Invalid formId");
        }
      } catch (error) {
        setError("Identifiants invalides");
        onError(error);
      } finally {
        setLoading(null);
        setValues({});
      }
    },
    [handleLogin, values, onSuccess, handleCreateAccount, onError]
  );
  function isDisabledSubmit(formKey) {
    const emailValid = isValidEmail(values.email);

    if (hasCheckbox(formKey)) {
      return Boolean(
        loading || !values[fields[formKey].inputs.checkbox.name] || !emailValid
      );
    }
    return !!loading;
  }

  function hasCheckbox(formKey) {
    return Boolean(fields[formKey].inputs.checkbox);
  }

  function isCheckbox(formKey, key) {
    return fields[formKey].inputs[key].type === "checkbox";
  }

  return (
    <>
      <Hidden smDown>
        <Wrapper variant={variant}>
          <Container width={width} container justify="space-evenly" spacing={4}>
            {Object.keys(fields).map((formKey) => (
              <FormContainer item key={formKey} sm={5} xs={11}>
                <SwitchForm
                  id={fields[formKey].name}
                  title={fields[formKey].title}
                  showForm={showForm === fields[formKey].name}
                  buttonLabel={fields[formKey].buttonLabel}
                  buttonMargin={fields[formKey].buttonMargin}
                  onClick={
                    fields[formKey].onClick ||
                    (() => {
                      setError(null);
                      setShowForm(fields[formKey].name);
                    })
                  }
                  onSubmit={handleSubmit}
                  linkAfterForm={fields[formKey].linkAfterForm}
                  loading={fields[formKey].name === loading}
                  disabledSubmit={isDisabledSubmit(formKey)}
                  disabledOpenForm={Boolean(loading)}
                  error={error}
                >
                  <Grid container item spacing={1}>
                    {Object.keys(fields[formKey].inputs).map((key) => {
                      if (!isCheckbox(formKey, key)) {
                        return (
                          !isGranvillage && (
                            <Grid
                              key={key}
                              item
                              xs={
                                (fields[formKey].inputs[key].size &&
                                  fields[formKey].inputs[key].size.xs) ||
                                12
                              }
                              md={
                                (fields[formKey].inputs[key].size &&
                                  fields[formKey].inputs[key].size.md) ||
                                12
                              }
                            >
                              <Input
                                fullWidth={
                                  fields[formKey].inputs[key].fullWidth
                                }
                                key={key}
                                id={`${formKey}-${key}`}
                                name={fields[formKey].inputs[key].name}
                                value={values[fields[formKey].inputs[key].name]}
                                label={fields[formKey].inputs[key].label}
                                required={fields[formKey].inputs[key].required}
                                type={fields[formKey].inputs[key].type}
                                disabled={!!loading}
                                withoutBorders={withoutBorders}
                                onChange={handleChangeInputs}
                              />
                            </Grid>
                          )
                        );
                      }
                      return (
                        <Checkbox
                          key={key}
                          id={`${formKey}-${key}`}
                          name={fields[formKey].inputs[key].name}
                          value={values[fields[formKey].inputs[key].name]}
                          label={fields[formKey].inputs[key].label}
                          required={fields[formKey].inputs[key].required}
                          disabled={!!loading}
                          onChange={handleChangeInputs}
                        />
                      );
                    })}
                  </Grid>
                  {fields[formKey].link && fields[formKey].link}
                </SwitchForm>
              </FormContainer>
            ))}
          </Container>
        </Wrapper>
      </Hidden>
      <Hidden mdUp>
        <MobileContainer container>
          <MobileForm container item xs={12} spacing={3}>
            {!isGranvillage && (
              <>
                {error && (
                  <Error variant="body1" component="h1">
                    {error}
                  </Error>
                )}
                <Grid item xs={12}>
                  <Input
                    fullWidth={fields["login"].inputs["email"].fullWidth}
                    key="email"
                    id="login-email"
                    name={fields["login"].inputs["email"].name}
                    value={values[fields["login"].inputs["email"].name]}
                    label={fields["login"].inputs["email"].label}
                    required={fields["login"].inputs["email"].required}
                    type={fields["login"].inputs["email"].type}
                    disabled={!!loading}
                    withoutBorders={withoutBorders}
                    onChange={handleChangeInputs}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    fullWidth={fields["login"].inputs["password"].fullWidth}
                    key="password"
                    id="login-password"
                    name={fields["login"].inputs["password"].name}
                    value={values[fields["login"].inputs["password"].name]}
                    label={fields["login"].inputs["password"].label}
                    required={fields["login"].inputs["password"].required}
                    type={fields["login"].inputs["password"].type}
                    disabled={!!loading}
                    withoutBorders={withoutBorders}
                    onChange={handleChangeInputs}
                  />
                  <ContainerMobileForgetPassword>
                    <InternalLink
                      to="/reset-password"
                      color={theme.palette.primary.main}
                    >
                      Mot de passe perdu?
                    </InternalLink>
                  </ContainerMobileForgetPassword>
                </Grid>
              </>
            )}

            <MobileButton
              onClick={(e) => handleSubmit(e, "login")}
              loading={!!loading}
              size="large"
            >
              Se connecter
            </MobileButton>
            <CenteredGrid item xs={12}>
              <TextOr>ou</TextOr>
              {isGranvillage && (
                <Link
                  href={
                    process.env.REACT_APP_GRANVILLAGE_CUSTOMER_REGISTRATION_URL
                  }
                  color={theme.palette.primary.main}
                  disabled={!!loading}
                >
                  Se créer un compte <Row />
                </Link>
              )}
              {!isGranvillage && (
                <InternalLink
                  to={"/login"}
                  onClick={() => {
                    setError(null);
                    setOpenUserRegister(true);
                  }}
                  color={theme.palette.primary.main}
                  disabled={!!loading}
                >
                  Se créer un compte <Row />
                </InternalLink>
              )}
            </CenteredGrid>
          </MobileForm>
        </MobileContainer>
        <UserRegisterModal
          open={openUserRegister}
          error={error}
          onClose={() => {
            setError(null);
            setOpenUserRegister(false);
          }}
        >
          {Object.keys(fields["createAccount"].inputs).map((key) => {
            if (!isCheckbox("createAccount", key)) {
              return (
                <Grid item xs={12} key={key}>
                  <Input
                    fullWidth={fields["createAccount"].inputs[key].fullWidth}
                    key={key}
                    id={`createAccount-${key}`}
                    name={fields["createAccount"].inputs[key].name}
                    value={values[fields["createAccount"].inputs[key].name]}
                    label={fields["createAccount"].inputs[key].label}
                    required={fields["createAccount"].inputs[key].required}
                    type={fields["createAccount"].inputs[key].type}
                    disabled={!!loading}
                    withoutBorders={withoutBorders}
                    onChange={handleChangeInputs}
                  />
                </Grid>
              );
            }
            return (
              <Checkbox
                key={key}
                id={`createAccount-${key}`}
                name={fields["createAccount"].inputs[key].name}
                value={values[fields["createAccount"].inputs[key].name]}
                label={fields["createAccount"].inputs[key].label}
                required={fields["createAccount"].inputs[key].required}
                disabled={!!loading}
                onChange={handleChangeInputs}
              />
            );
          })}
          <MobileButton
            onClick={(e) => handleSubmit(e, "createAccount")}
            loading={!!loading}
            disabled={isDisabledSubmit("createAccount")}
            size="large"
          >
            Créer mon compte
          </MobileButton>
        </UserRegisterModal>
      </Hidden>
    </>
  );
};

Account.protoTypes = {
  login: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

Account.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

export default withAuth(withWidth()(Account));
