import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  SvgIcon,
  Typography,
  withWidth,
} from "@material-ui/core";

// Material Icons
import LanguageIcon from "@material-ui/icons/Language";
import StorefrontIcon from "@material-ui/icons/Storefront";

// Assets
import { ReactComponent as Trash } from "../../Assets/Icons/trash.svg";

// Components
import ButtonIconOption from "../../Common/ButtonIconOption";
import CartSummary from "../../Common/CartSummary";

// Helpers

// PropTypes
import { CartCheckoutShape } from "../proptypes";

// Contansts
import constants from "../../VendorStore/utils/constants";
import Title from "../Components/Title";
import BasicPaymentMethod from "../../VendorStore/components/Payment/BasicPaymentMethod";
import MangoPay from "../../VendorStore/components/Payment/MangoPay/MangoPay";
import { PAYMENT_TYPES } from "../../Common/constants";
import { NumberFormatCustom } from "@kuupanda/commons";

// Styleds
const Container = styled(Grid)`
  padding: ${({ width }) => (width !== "xs" ? "20px 0" : "0")};
`;
const Section = styled(Grid)``;
const Cart = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.grey["100"]};
  border-radius: 8px;
  ${({ width }) => {
    if (width !== "xs") {
      return `
        padding: 40px 20px 50px 20px !important;
        margin-left: 20px;
    `;
    }
  }}
`;
const CartDescription = styled.div`
  margin-left: 12px;
`;
const ProductSize = styled(Typography)`
  font-weight: bold;
`;

// Constant
const PaymentTypes = {
  [PAYMENT_TYPES.ONLINE_PAYMENT]: {
    label: "Paiement en ligne",
    icon: <LanguageIcon />,
  },
  [PAYMENT_TYPES.SPOT_PAYMENT]: {
    label: "Paiement sur place",
    icon: <StorefrontIcon />,
  },
};

// Helpers
function cartTableFormat({ quantity, product }, { width, onRemoveCartItem }) {
  const result = {
    item: (
      <>
        <Title
          noWrap={true}
          component="h1"
          color={width !== "xs" ? "primary" : "textPrimary"}
        >
          <NumberFormatCustom
            value={quantity}
            displayType={"text"}
            suffix={" "}
            fixedDecimalScale={false}
          />{" "}
          x {product.name}
        </Title>
        <CartDescription>
          <Typography
            noWrap={true}
            component="h2"
            variant="caption"
            color="textSecondary"
          >
            {product.producer.name}
          </Typography>
        </CartDescription>
      </>
    ),
    price: (
      <Typography component="h1" variant="caption" color="textPrimary">
        <NumberFormatCustom
          value={quantity * product.price}
          displayType={"text"}
        />
      </Typography>
    ),
  };

  if (width === "xs") {
    return {
      ...result,
      price: (
        <>
          <Typography
            component="h1"
            variant="caption"
            color="textPrimary"
            style={{ fontWeight: "bold" }}
          >
            <NumberFormatCustom value={product.price} displayType={"text"} />
          </Typography>
          <Button onClick={() => onRemoveCartItem(product.id)}>
            <SvgIcon titleAccess="delete">
              <Trash />
            </SvgIcon>
          </Button>
        </>
      ),
    };
  }

  return {
    ...result,
    remove: (
      <Button onClick={() => onRemoveCartItem(product.id)}>
        <SvgIcon titleAccess="delete">
          <Trash />
        </SvgIcon>
      </Button>
    ),
  };
}
function getColumns({ width }) {
  if (width === "xs") {
    return [
      {
        name: "item",
        title: "Article",
      },
      {
        name: "price",
        title: "Prix",
      },
    ];
  }
  return [
    {
      name: "item",
      title: "Article",
    },
    {
      name: "price",
      title: "Prix",
    },
    {
      name: "remove",
      title: "Retirer",
    },
  ];
}
function getColumnExtensions({ width }) {
  if (width === "xs") {
    return [
      { columnName: "item", width: "70%", align: "left" },
      { columnName: "price", align: "center" },
    ];
  }
  return [
    {
      columnName: "item",
      align: "left",
      width: "70%",
    },
    {
      columnName: "price",
      align: "center",
    },
    {
      columnName: "remove",
      align: "right",
    },
  ];
}

function getCellExtensions({ width }) {
  if (width === "xs") {
    return {
      item: {
        verticalAlign: "baseline",
      },
      price: {
        verticalAlign: "baseline",
      },
    };
  }
  return {
    item: {
      verticalAlign: "baseline",
    },
    price: {
      verticalAlign: "baseline",
    },
    remove: {
      verticalAlign: "baseline",
    },
  };
}

const Payment = ({
  cart,
  paymentTypes,
  paymentType,
  onRemoveCartItem,
  onChangePaymentType,
  onPaymentFormError,
  onPaymentFormReady,
  onSuccessfulPayment,
  onCancelPaymentProcess,
  width,
  isPaymentBeingProcessed,
  cartTotals,
  fees,
  ordersDTO,
  accessToken,
  couponLines,
  disableNextButton,
}) => {
  const total = cartTotals.total;
  const [coupon, setCoupon] = useState(""); // for now it's here but it should be in the parent

  const cartList = useMemo(() => {
    return Object.values(cart).map(({ quantity, product }) =>
      cartTableFormat({ quantity, product }, { width, onRemoveCartItem })
    );
  }, [cart, onRemoveCartItem, width]);

  const handleChangePaymentType = useCallback(
    (e, type) => {
      onChangePaymentType(type);
    },
    [onChangePaymentType]
  );
  const handleChangeCoupon = useCallback((coupon) => {
    setCoupon(coupon);
  }, []);

  return (
    <Container container spacing={4} width={width} justify="space-between">
      <Section
        container
        item
        xs={12}
        sm={6}
        spacing={2}
        alignContent={"flex-start"}
      >
        <Grid item xs={12}>
          <Title>Moyen de paiement</Title>
        </Grid>
        <Grid item xs={12}>
          {paymentTypes.map((key) => {
            const payment = PaymentTypes[key];
            return (
              <ButtonIconOption
                key={key}
                selected={paymentType}
                icon={payment.icon}
                onClick={handleChangePaymentType}
                id={key}
                label={payment.label}
              />
            );
          })}
        </Grid>
        {paymentType === PAYMENT_TYPES.ONLINE_PAYMENT && (
          <React.Fragment>
            <Grid item xs={12}>
              <Title>Information bancaire</Title>
            </Grid>
            <Grid item xs={12}>
              <MangoPay
                active={isPaymentBeingProcessed}
                orders={ordersDTO}
                buyer={undefined}
                amount={total}
                onSuccessfulPayment={onSuccessfulPayment}
                onPaymentEnd={onCancelPaymentProcess}
                onPaymentFormError={onPaymentFormError}
                onPaymentFormReady={onPaymentFormReady}
                accessToken={accessToken}
              />
            </Grid>
          </React.Fragment>
        )}
        {paymentType === PAYMENT_TYPES.SPOT_PAYMENT && (
          <React.Fragment>
            <Grid item xs={12}>
              <BasicPaymentMethod
                description="Payez en espèce sur place"
                paymentMethod={constants.PAYMENT_METHOD.CASH}
                onPaymentFormError={onPaymentFormError}
                onSuccessfulPayment={onSuccessfulPayment}
                isPaymentBeingProcessed={isPaymentBeingProcessed}
                onPaymentFormReady={onPaymentFormReady}
              />
            </Grid>
          </React.Fragment>
        )}
      </Section>

      <Section
        container
        item
        xs={12}
        sm={6}
        direction="column"
        spacing={width !== "xs" ? 6 : 2}
      >
        <Grid item>
          <Title>Récapitulatif de mon panier</Title>
        </Grid>
        <Cart item width={width}>
          <CartSummary
            total={total}
            fees={fees}
            totalVariant="body1"
            cartList={cartList}
            cellStyles={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingTop: 10,
              verticalAlign: "top",
              borderBottom: "none",
            }}
            columns={getColumns({ width })}
            columnExtensions={getColumnExtensions({ width })}
            cellExtensions={getCellExtensions({ width })}
            showCoupon={false}
            onChangeCoupon={handleChangeCoupon}
            coupon={coupon}
            couponLines={couponLines}
          />
        </Cart>
      </Section>
    </Container>
  );
};

Payment.propTypes = {
  cart: CartCheckoutShape.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  paymentType: PropTypes.oneOf([
    PAYMENT_TYPES.ONLINE_PAYMENT,
    PAYMENT_TYPES.SPOT_PAYMENT,
  ]),
  paymentMethod: PropTypes.oneOf([constants.PAYMENT_METHOD.CREDIT_CARD]),
  onRemoveCartItem: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onFailedPayment: PropTypes.func,
  onPaymentFormError: PropTypes.func,
  onPaymentFormReady: PropTypes.func,
  onSuccessfulPayment: PropTypes.func,
};

Payment.defaultProps = {
  paymentType: PAYMENT_TYPES.ONLINE_PAYMENT,
  paymentMethod: constants.PAYMENT_METHOD.CREDIT_CARD,
  disableNextButton: (value) => {},
  onRemoveCartItem: () => {},
  onChangePaymentType: () => {},
  onFailedPayment: () => {},
  onPaymentFormError: () => {},
  onPaymentFormReady: () => {},
  onSuccessfulPayment: () => {},
};

export default withWidth()(Payment);
