import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

//components
import { AutoCompleteLocationInput } from "../../Common/AutoCompleteLocationInput";
import LoadScriptOnlyIfNeeded from "../../Common/LoadScriptOnlyIfNeeded";
import Button from "../../Common/Button";

// consts
const librariesToLoad = ["places", "geometry"];
const API_KEY = "AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg";

//styled
const Header = styled(DialogTitle)`
  text-align: center;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

const Content = styled(DialogContent)`
  text-align: center;
`;

const Actions = styled.div`
  margin: 0 auto;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  ${({ theme }) => `
    color: ${theme.palette.grey[500]};
  `};
`;

const Modal = ({ open, onSearch, value, onChange, toggleDialog }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={toggleDialog}
      aria-labelledby="location-dialog-title"
    >
      <Header id="location-dialog-title">
        <Title variant="h6">Renseignez votre location</Title>
        <CloseButton aria-label="close" onClick={toggleDialog}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <Content>
        <LoadScriptOnlyIfNeeded
          id="script-loader"
          googleMapsApiKey={API_KEY}
          libraries={librariesToLoad}
          onLoad={() => setLoaded(true)}
          loadingElement={<div></div>}
        >
          {loaded && (
            <AutoCompleteLocationInput
              onChange={onChange}
              value={value}
              placeholder="Ville, rue..."
            />
          )}
        </LoadScriptOnlyIfNeeded>
      </Content>
      <DialogActions>
        <Actions>
          <Button
            component="button"
            size="large"
            onClick={() => onSearch(value)}
          >
            Commencer la recherche
          </Button>
        </Actions>
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

Modal.defaultProps = {
  open: false,
  onChange: () => {},
  onSearch: () => {},
  setModalOpen: () => {},
  value: {},
};

export default Modal;
