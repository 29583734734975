import PropTypes from "prop-types";
import React from "react";

/**
 * Simple wrapper to disable page form submission
 */
export const Form = React.memo(function Form({ onSubmit, children, props }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    return onSubmit && onSubmit(event);
  };
  return (
    <form onSubmit={handleSubmit} {...props}>
      {children}
    </form>
  );
});

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
