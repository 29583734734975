import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Meta = ({
  title,
  description,
  keywords,
  ogTitle,
  ogURL,
  ogDescription,
  ogImage,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogURL} />
      <meta property="og:image" content={ogImage} />
    </Helmet>
  );
};

Meta.protoTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  ogTitle: PropTypes.string.isRequired,
  ogURL: PropTypes.string.isRequired,
  ogDescription: PropTypes.string.isRequired,
  ogImage: PropTypes.string.isRequired,
};

Meta.defaultProps = {
  title: "KuuPanda",
  description: "La qualité de la proximité - Réinventons notre économie locale",
  keywords:
    "Producteurs, produits, locaux, circuit-court, vente directe, producteurs locaux, produits locaux.",
  ogTitle: "KuuPanda",
  ogURL: "https://kuupanda.com",
  ogDescription: "",
  ogImage: "",
};

export default Meta;
