import React from "react";
import styled from "styled-components";

type Props = {
  transformOrigin?: "left" | "right";
  topOffset?: string | number;
  fullWidth?: boolean;
  // only pass this property if you are going to use click event to open and close the menu
  // else if you need to use hover event, you only need to add the following css to parent element:
  // :hover > div { display: block }
  open?: boolean;
  onClose?: () => void;
};

const defaultTransformOrigin: Props["transformOrigin"] = "left";

const getTransformOriginStyles =
  (value: string | number) =>
  ({ transformOrigin: origin = defaultTransformOrigin }: Props) =>
    `${origin}: ${value};`;

const getFullWidthStyles = ({
  transformOrigin: origin = defaultTransformOrigin,
  fullWidth,
}: Props) => {
  if (!fullWidth) return;
  const propertyName = origin === "left" ? "right" : "left";
  return `${propertyName}: 0;`;
};

const getTopOffsetValue = ({ topOffset: to }: Props) => {
  if (!to) return 0;
  return typeof to === "number" ? to + "px" : to;
};

const getDisplayValue = ({ open }: Props) => {
  if (typeof open === "undefined") return "none";
  return open ? "block" : "none";
};

const StyledMenuListContainer = styled.div<Omit<Props, "onClose">>`
  display: ${getDisplayValue};
  position: absolute;
  top: ${getTopOffsetValue};
  ${getTransformOriginStyles(0)}
  ${getFullWidthStyles}
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 15px 0 rgba(63, 63, 68, 0.15),
    0 0 1px 1px rgba(63, 63, 68, 0.05);
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    ${getTransformOriginStyles("20px")}
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 10px;
  }
`;

const MenuWithArrow: React.FC<Props> = ({ onClose, ...restProps }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof restProps.open !== undefined && restProps.open) {
      setTimeout(() => {
        // @ts-ignore
        ref.current?.focus();
      }, 0);
    }
  }, [restProps.open]);

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = (event) => {
    if (!event.relatedTarget && onClose) onClose();
  };

  // don't change or remove tabIndex -1, this allows focus event only from js
  return (
    <StyledMenuListContainer
      ref={ref}
      tabIndex={-1}
      onBlur={handleBlur}
      {...restProps}
    >
      {restProps.children}
    </StyledMenuListContainer>
  );
};

export { MenuWithArrow };
