import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ALL_CATEGORIES } from "../Common/constants";
import { baseMargin, InnerPaper } from "./styles";
import SectionTitle from "../Common/SectionTitle";
import { CartShape } from "../Checkout/proptypes";
import Category from "./Category";
import { Grid, MenuItem, Select } from "@material-ui/core";
import { FixedSizeGrid, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { usePresentation } from "../Context/PresentationContext";
import Input from "../Common/Input";
import { calculateProductAvailableQuantity } from "../Helpers/cart";

const Container = styled.div`
  ${baseMargin}
  width: 100%;
`;

const StyledPaper = InnerPaper;

const StyledGrid = styled(Grid)`
  height: ${(props) =>
    props.isEmbedded
      ? `${props.calculatedHeight}px`
      : `${props.calculatedHeightList}px`};
`;

const StyledSelect = styled(Select)`
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 0.9rem;
`;

const FiltersContainer = styled.div`
  margin-bottom: 20px;
  .MuiSelect-select:focus {
    background-color: unset !important;
  }
`;

const StyledCategory = styled.div`
  margin: 15px 0 10px;
  font-weight: 800;
  font-size: 16px;
`;

const CategoryEmbedded = ({ columnIndex, style, data, rowIndex }) => {
  const { filteredProducts, cart, onChangeCart, columnCount } = data;
  const index =
    columnCount > 1 ? columnIndex + rowIndex * columnCount : rowIndex;
  const product = filteredProducts[index];
  if (product) {
    return (
      <div style={style}>
        <Category
          product={product}
          cart={cart}
          onChange={onChangeCart}
          row={index}
        />
      </div>
    );
  }
  return <></>;
};

const CategoryNormal = ({ index, style, data }) => {
  const { filteredProducts, cart, onChangeCart, saleChannel } = data;
  const product = filteredProducts[index];
  return (
    <div style={style}>
      {product.showCategoryLabel && (
        <StyledCategory>{product.category}</StyledCategory>
      )}
      <Category
        product={product}
        cart={cart}
        onChange={onChangeCart}
        row={index}
        saleChannel={saleChannel}
      />
    </div>
  );
};

function SimplifiedProductList({
  categories,
  productsByCategory,
  cart,
  onChangeCart,
  sizes,
  saleChannel,
}) {
  const allCategoriesText = "Toutes les catégories";
  const allSizesText = "Toutes les tailles";
  const [searchValue, setSearchValue] = useState("");
  const [deviceType, setDeviceType] = useState("DESKTOP");
  const [selectedCategory, setSelectedCategory] = useState(allCategoriesText);
  const [selectedSize, setSelectedSize] = useState(allSizesText);
  const { isEmbedded } = usePresentation();
  const variableListRef = useRef({});

  useEffect(() => {
    function updateSize() {
      const newDeviceType =
        window.innerWidth <= 550
          ? "MOBILE"
          : window.innerWidth > 550 && window.innerWidth <= 900
          ? "TABLET"
          : "DESKTOP";
      if (newDeviceType !== deviceType) {
        setDeviceType(newDeviceType);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [deviceType]);

  const columnCount = useMemo(
    () => (deviceType === "MOBILE" ? 1 : deviceType === "TABLET" ? 2 : 3),
    [deviceType]
  );

  const getColumnWidth = useCallback(
    (width) => {
      return columnCount === 1 ? width : Math.round((width - 30) / columnCount);
    },
    [columnCount]
  );

  const sortedProductsByCategory = useMemo(() => {
    const products = productsByCategory.reduce((result, category) => {
      const categoryData = ALL_CATEGORIES.find(
        (c) => c.value === category.category
      );
      const categoryLabel = (categoryData && categoryData.label) || "";
      const products = (category.products || []).map((product, index) => {
        product = {
          ...product,
          category: categoryLabel,
          showCategoryLabel: index === 0,
          categoryCode: product.category,
        };
        return product;
      });
      result = [...result, ...products];
      return result;
    }, []);
    if (isEmbedded) {
      const productsNotAvailable = products.filter(
        (p) => p.availableQuantity <= 0
      );
      const productsAvailable = products.filter((p) => p.availableQuantity > 0);
      return [...productsAvailable, ...productsNotAvailable];
    } else {
      return products;
    }
  }, [isEmbedded, productsByCategory]);

  const allCategories = useMemo(() => {
    return [allCategoriesText, ...categories];
  }, [categories]);

  const allSizes = useMemo(() => {
    return [allSizesText, ...sizes];
  }, [sizes]);

  const orderByCategories = useCallback((products) => {
    let actualCategory = "";
    let showCategoryLabel = false;
    return products.map((product) => {
      if (actualCategory !== product.category) {
        showCategoryLabel = true;
        actualCategory = product.category;
      } else {
        showCategoryLabel = false;
      }
      return {
        ...product,
        showCategoryLabel,
      };
    });
  }, []);

  const filteredProducts = useMemo(() => {
    let products = [...sortedProductsByCategory];
    if (selectedCategory && selectedCategory !== allCategoriesText) {
      products = products.filter(
        (product) =>
          product.category.toLowerCase() === selectedCategory.toLowerCase()
      );
    }
    if (selectedSize && selectedSize !== allSizesText) {
      products = products.filter(
        (product) =>
          product.size &&
          product.size.toLowerCase().includes(selectedSize.toLowerCase())
      );
    }
    if (searchValue.length) {
      products = products.filter((product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    variableListRef.current &&
      variableListRef.current.resetAfterIndex &&
      variableListRef.current.resetAfterIndex(0);
    return orderByCategories(products);
  }, [
    sortedProductsByCategory,
    selectedCategory,
    selectedSize,
    searchValue,
    orderByCategories,
  ]);

  const rowCount = useMemo(() => {
    return Math.ceil(filteredProducts.length / columnCount);
  }, [columnCount, filteredProducts.length]);
  const getItemSize = useCallback(
    (index) => {
      const product = filteredProducts[index];
      const offset = product.showCategoryLabel ? 165 : 120;

      return offset;
    },
    [filteredProducts]
  );

  const handleSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleCategory = useCallback((e) => {
    setSelectedCategory(e.target.value);
  }, []);

  const handleSize = useCallback((e) => {
    setSelectedSize(e.target.value);
  }, []);

  const calculatedHeight = useMemo(() => {
    if (rowCount <= 6) {
      return 280 * rowCount;
    } else {
      return 1830;
    }
  }, [rowCount]);

  const calculatedHeightList = useMemo(() => {
    const productCount = filteredProducts.length;

    if (productCount === 1) {
      return 150;
    }

    if (productCount === 2) {
      return 300;
    }

    if (productCount === 3) {
      return 450;
    }

    if (productCount === 4) {
      return 600;
    }

    if (productCount === 5) {
      return 700;
    }

    if (productCount > 5) {
      return 800;
    }
  }, [filteredProducts]);

  return (
    <Grid xs={12} md={11} container item justify="center" direction="row">
      <Container>
        <StyledPaper>
          <SectionTitle>Les produits de votre producteur</SectionTitle>
          <FiltersContainer>
            <Grid
              container
              item
              justify="flex-end"
              spacing={2}
              alignItems="center"
            >
              {allCategories.includes("Prêt-à-porter") && (
                <Grid container item xs={12} md={3}>
                  <StyledSelect
                    variant="outlined"
                    value={selectedSize}
                    onChange={handleSize}
                  >
                    {allSizes.map((size, index) => (
                      <MenuItem key={index} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
              )}
              <Grid container item xs={12} md={3}>
                <StyledSelect
                  variant="outlined"
                  value={selectedCategory}
                  onChange={handleCategory}
                >
                  {allCategories.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <Input
                  id="search"
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder="Rechercher"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FiltersContainer>
          <StyledGrid
            calculatedHeight={calculatedHeight}
            calculatedHeightList={calculatedHeightList}
            container
            isEmbedded={isEmbedded}
          >
            <AutoSizer>
              {({ height, width }) => {
                return !isEmbedded ? (
                  <VariableSizeList
                    ref={variableListRef}
                    height={height}
                    itemCount={filteredProducts.length}
                    itemSize={getItemSize}
                    width={width}
                    itemData={{
                      filteredProducts,
                      cart,
                      onChangeCart,
                      saleChannel,
                    }}
                  >
                    {CategoryNormal}
                  </VariableSizeList>
                ) : (
                  <FixedSizeGrid
                    columnCount={columnCount}
                    columnWidth={getColumnWidth(width)}
                    rowCount={rowCount}
                    rowHeight={280}
                    height={height}
                    itemCount={filteredProducts.length}
                    width={width}
                    itemData={{
                      filteredProducts,
                      cart,
                      onChangeCart,
                      columnCount,
                    }}
                  >
                    {CategoryEmbedded}
                  </FixedSizeGrid>
                );
              }}
            </AutoSizer>
          </StyledGrid>
        </StyledPaper>
      </Container>
    </Grid>
  );
}

SimplifiedProductList.propTypes = {
  cart: CartShape.isRequired,
  categories: PropTypes.array,
  onChangeCart: PropTypes.func.isRequired,
  productsByCategory: PropTypes.array.isRequired,
  sizes: PropTypes.array,
};

SimplifiedProductList.defaultPropTypes = {
  categories: [],
  sizes: [],
};

export default SimplifiedProductList;
