import Joi from "joi";
import { tlds } from "@hapi/tlds";

const schema = Joi.object().keys({
  email: Joi.string().email({
    tlds: {
      allow: tlds,
    },
  }),
});

export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }

  const result = schema.validate({ email }).error;

  return !Boolean(result);
};
