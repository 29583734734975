import React from "react";
import styled from "styled-components";
import { MenuItem, MenuList } from "@material-ui/core";
import { MenuWithArrow } from "./MenuWithArrow";

const Container = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  position: relative;
  padding: 30px 20px;
  font-size: 16px;
  cursor: pointer;
  :hover > div {
    display: block;
  }
`;

const StyledMenuList = styled(MenuList)`
  min-width: 200px;
  > li {
    font-size: 14px;
    padding: 10px;
    :hover {
      color: ${({ theme }) => theme.palette.primary.main};
      background-color: #f8f9fa;
    }
  }
`;

type Props = {
  items: MenuOption[];
};

export enum DesktopNavOptions {
  Explorer = "Explorer",
  LocalProducts = "Produits locaux",
  Blog = "Blog",
  Podcast = "Podcast",
}

export type SubMenuOption = {
  description: string;
  url: string;
};

export type MenuOption = SubMenuOption & {
  subMenuUrlPrefix?: string;
  subMenuOptions?: SubMenuOption[];
};

export const desktopOptionsArr: MenuOption[] = [
  { description: DesktopNavOptions.Explorer, url: "/explorer" },
  {
    description: DesktopNavOptions.LocalProducts,
    url: "/produits-locaux",
    subMenuUrlPrefix: "/c",
    subMenuOptions: [
      { description: "Fruits", url: "/fruits" },
      { description: "Légumes", url: "/legumes" },
      { description: "Viande", url: "/viande" },
      { description: "Boucherie", url: "/charcuterie" },
      { description: "Charcuterie", url: "/fromages" },
      { description: "Fromages", url: "/produits-laitiers" },
      { description: "Produits laitiers", url: "/vins" },
      { description: "Vins", url: "/bieres" },
      { description: "Epicerie", url: "/alcools" },
      { description: "Panier fruits et légumes", url: "/boissons" },
    ],
  },
  {
    description: DesktopNavOptions.Blog,
    url: "/blogs",
    subMenuUrlPrefix: "/blogs/category",
    subMenuOptions: [
      { description: "Actualités", url: "/actualites/" },
      { description: "Consommer responsable", url: "/consommer-responsable/" },
      { description: "Une journée avec...", url: "/une-journee-avec/" },
      { description: "Recettes", url: "/recettes/" },
      { description: "Un produit, un terroir", url: "/un-produit-un-terroir/" },
      { description: "Tourisme vert", url: "/tourisme-vert/" },
      { description: "Le coin des pros", url: "/le-coin-des-pro/" },
    ],
  },
  { description: DesktopNavOptions.Podcast, url: "/podcast" },
];

const LocalDesktopNavBar: React.FC<Props> = ({ items }) => {
  const renderSubMenuItem =
    (urlPrefix: string | undefined = "") =>
    ({ description, url }: SubMenuOption) =>
      (
        <MenuItem
          key={description}
          data-url={urlPrefix + url}
          onClick={redirectTo}
        >
          {description}
        </MenuItem>
      );

  function redirectTo(e: React.MouseEvent<HTMLLIElement>) {
    e.stopPropagation();
    const url = e.currentTarget.dataset.url;
    if (!url) return;
    window.location.href = url;
  }

  return (
    <Container>
      {items.map(
        ({ description, url, subMenuUrlPrefix, subMenuOptions = [] }) => (
          <Item data-url={url} onClick={redirectTo}>
            {description}
            {Boolean(subMenuOptions?.length) && (
              <MenuWithArrow topOffset={75}>
                <StyledMenuList>
                  {subMenuOptions.map(renderSubMenuItem(subMenuUrlPrefix))}
                </StyledMenuList>
              </MenuWithArrow>
            )}
          </Item>
        )
      )}
    </Container>
  );
};

export default function DesktopNavBar() {
  return <LocalDesktopNavBar items={desktopOptionsArr} />;
}
