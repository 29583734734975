import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Radio, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled(FormControl)`
  width: 460px;
  max-height: 310px;
  overflow: auto;
  margin-bottom: 40px;
`;

const StyledRadio = styled(Radio)`
  &&.MuiRadio-root {
    padding: 0;
    color: ${({ theme }) => theme.palette.warning.main};
    margin-left: 0;
    font-size: 16px;
    &:hover {
      background-color: transparent;
    }
  }
`;

const WrapperListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 11px;
`;
const ListItem = styled.div`
  width: 375px;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  box-sizing: border-box;
  border-color: ${(props) =>
    props.isSelected
      ? props.theme.palette.warning.main
      : props.theme.palette.grey["600"]};
  background-color: ${(props) =>
    props.isSelected ? "rgba(214, 230, 249, 0.3)" : "white"};
`;

const RelayName = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey["900"]};
  line-height: 21px;
  text-transform: lowercase;
  :first-line {
    text-transform: capitalize;
  }
`;

const RelayAddress = styled(Typography)`
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.warning.main};
  text-transform: lowercase;
  :first-line {
    text-transform: capitalize;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: 16px;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
`;

const StyledHelpText = styled(Typography)`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.grey[400]};
  margin-bottom: 30px;
`;

const SelectRelayPoint = (props) => {
  const theme = useTheme();

  const [relayPoint, setRelayPoint] = useState();

  useEffect(() => {
    if (props.currentRelay) {
      setRelayPoint(props.currentRelay.code);
    }
  }, [props.currentRelay, props.relayPoints]);

  const handleSelectRelay = (event) => {
    setRelayPoint(event.target.value);
    const currentRelay = props.relayPoints.find(
      (relay) => relay.code === event.target.value
    );
    props.handleSelectedRelayPoint(currentRelay);
  };

  if (!props.address) {
    return (
      <div>
        <StyledInputLabel id="chronopost-relay-point-select-label">
          Choisissez le point de retrait souhaité
        </StyledInputLabel>
        <StyledHelpText>
          Vous serez informé par email lorsque le colis sera arrivé au point
          relais
        </StyledHelpText>
        <Alert severity="error">
          <span>
            Renseignez votre adresse pour voir les points relais disponibles
          </span>
        </Alert>
      </div>
    );
  }
  return (
    <StyledContainer>
      {!(props.relayPoints && props.currentRelay && relayPoint) ? (
        <CircularProgress />
      ) : (
        <>
          <StyledInputLabel id="chronopost-relay-point-select-label">
            Choisissez le point de retrait souhaité
          </StyledInputLabel>
          <StyledHelpText>
            Vous serez informé par email lorsque le colis sera arrivé au point
            relais
          </StyledHelpText>

          <>
            <StyledFormControl>
              <RadioGroup
                onChange={handleSelectRelay}
                defaultValue={relayPoint}
              >
                {props.relayPoints.map((relay) => (
                  <WrapperListItem>
                    <FormControlLabel
                      value={relay.code}
                      control={
                        <StyledRadio
                          disableRipple
                          color={theme.palette.primary.main}
                        />
                      }
                      label=""
                    />
                    <ListItem isSelected={relay.code === relayPoint}>
                      <RelayName>{relay.name}</RelayName>
                      <RelayAddress isSelected={relay.code === relayPoint}>
                        {" "}
                        {relay.name} - {relay.house_number} {relay.street},{" "}
                        {relay.city}
                      </RelayAddress>
                    </ListItem>
                  </WrapperListItem>
                ))}
              </RadioGroup>
            </StyledFormControl>
            {!relayPoint ||
              (!props.currentRelay && props.relayPoint.length > 0 && (
                <Alert severity="error">
                  <span>Choisissez l'un des points relais disponibles.</span>
                </Alert>
              ))}

            {Object.values(props.relayPoints).length === 0 && (
              <Alert severity="error">
                <span>
                  Aucun point de retrait disponible à l'adresse indiquée.
                  Veuillez choisir une autre méthode de retrait
                </span>
              </Alert>
            )}
          </>
        </>
      )}
    </StyledContainer>
  );
};
export default SelectRelayPoint;
