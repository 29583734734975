import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Typography from "@material-ui/core/Typography";

import Button from "../Common/Button";

import AlcoholImage from "../Assets/Images/alcohol.png";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 715px;
    height: 435px;
    align-items: center !important;
    padding: 50px 10px 20px 10px !important;
    box-sizing: border-box !important;
    position: relative;

    @media (max-width: 700px) {
      min-width: 350px;
      height: 490px;
    }
  }
`;

const StyledImage = styled.img`
  width: 155px;
  height: 60px;
  padding: 5px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 24px;
  width: 430px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  padding: 0 !important;

  @media (max-width: 700px) {
    width: 300px;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  width: 430px;
  padding: 15px;
  @media (max-width: 700px) {
    width: 300px;
  }
`;

const StyledTextContent = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface AgeConfirmationModalProps {
  handleConfirmation: (a: boolean) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
}

const AgeConfirmationModal = (props: AgeConfirmationModalProps) => {
  return (
    <StyledDialog
      onClose={props.handleCloseModal}
      open={props.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <StyledImage src={AlcoholImage} alt={"vent alcool mineur"} />
      <StyledDialogTitle disableTypography>
        Cette boutique contient des produits dont la vente est réglementée.
      </StyledDialogTitle>

      <StyledDialogContent>
        <StyledTextContent>
          Depuis la loi n°2009-87 du 21 juillet 2009 portant réforme de
          l'hôpital et relative aux patients, à la santé et aux territoires, la
          vente de boissons alcooliques aux mineurs est interdite.
        </StyledTextContent>
      </StyledDialogContent>
      <StyledDialogActions>
        {/* @ts-ignore */}
        <Button
          component="button"
          onClick={() => props.handleConfirmation(true)}
          styled={"primary"}
        >
          Je certifie avoir 18 ans ou plus
        </Button>
        {/* @ts-ignore */}
        <Button
          component="button"
          onClick={() => props.handleConfirmation(false)}
          styled={"secondary"}
        >
          Je n'ai pas 18 ans ou plus
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};
export default AgeConfirmationModal;
