import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { Button, Typography } from "@material-ui/core";

// Styleds
const Component = styled(Button)`
  width: 100%;

  @media (min-width: 600px) {
    width: 210px;
  }

  height: 79px;
  text-transform: none;
  background-color: #ffffff;
  color: ${({ theme }) => theme.palette.grey["400"]};
  margin: 10px 10px 0 0;
  padding: 14px 5px;
  .MuiButton-label {
    height: 100%;
  }
  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
  svg {
    fill: ${({ theme }) => theme.palette.grey["200"]};
  }
`;

const Body = styled.div`
  height: fit-content;
  padding: 0px 10px;
  line-height: 0;
`;

const Label = styled(Typography)`
  line-height: 1.2;
`;

const ButtonIconOption = ({ selected, icon, id, label, onClick }) => (
  <Component
    className={`${selected === id && "selected"}`}
    variant="outlined"
    onClick={(e) => onClick(e, id)}
  >
    <Body>
      {icon}
      <Label variant="caption" component="p">
        {label}
      </Label>
    </Body>
  </Component>
);

ButtonIconOption.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonIconOption.defaultProps = {
  selected: "",
  onClick: () => {},
};

export default ButtonIconOption;
