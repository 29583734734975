import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

// Components
import Input from "../Common/Input";
import Button from "../Common/Button";
import Layout from "./Layout";

// Contexts
import { useApiConfig } from "../Context/ApiContext";

// Hooks
import { useHttpClient } from "../Hooks/useHttpClient";
import constants from "../VendorStore/utils/constants";

// Styleds
const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;
const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

// Helpers
function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const ResetPasswordPage = () => {
  const { apiRoot } = useApiConfig();
  const httpClient = useHttpClient(apiRoot);
  const [email, setEmail] = useState("");
  const [emailValide, setEmailValide] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = useCallback((e) => {
    const text = e.target.value;
    setEmailValide(emailIsValid(text));
    setEmail(text);
  }, []);
  const handleSubmit = useCallback(async () => {
    setSuccess(null);
    setError(null);
    setLoading(true);
    try {
      await httpClient.post("/user/reset-password", {
        origin: constants.ORDER_ORIGIN.CLIENT,
        email,
        accountType: "CUSTOMER",
      });
      setSuccess(
        "Super! Un email vous a été envoyé pour réinitialiser votre mot de passe"
      );
    } catch (error) {
      setError("Une erreur s'est produite");
    } finally {
      setEmail("");
      setLoading(false);
    }
  }, [email, httpClient]);

  return (
    <Layout
      title="Réinitialiser le mot de passe"
      success={success}
      error={error}
    >
      <StyledInput
        type="email"
        label="Email"
        id="email"
        name="email"
        required={true}
        fullWidth={true}
        value={email}
        onChange={handleChangeEmail}
        error={!emailValide}
        disabled={loading}
      />
      <StyledButton
        fullWidth={true}
        disabled={!email || !emailValide}
        onClick={handleSubmit}
        loading={loading}
      >
        Envoyer un lien de réinitialisation
      </StyledButton>
      <StyledButton
        fullWidth={true}
        styled="secondary"
        component={RouterLink}
        to="/login"
      >
        Retour
      </StyledButton>
    </Layout>
  );
};

ResetPasswordPage.propTypes = {};

ResetPasswordPage.defaultProps = {};

export default ResetPasswordPage;
