import { useTheme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";

const BackIcon = () => {
  const theme = useTheme();
  return (
    <ArrowBackIcon
      style={{
        borderRadius: 12,
        marginRight: 5,
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
      }}
    />
  );
};

export default BackIcon;
