export function setModalIframePosition(isEmbedded, isModalFullScreen, modalId) {
  if (!isEmbedded || !window.parentIFrame) {
    return;
  }
  window.parentIFrame.getPageInfo((obj) => {
    try {
      const modalPaper = window.document.getElementById(modalId);
      modalPaper.style.position = "fixed";
      if (!isModalFullScreen) {
        const modalHeight = modalPaper.getBoundingClientRect().height;
        const modalWidth = modalPaper.getBoundingClientRect().width;
        const topValue =
          obj.scrollTop -
          obj.offsetTop +
          obj.windowHeight / 2 -
          modalHeight / 2;
        modalPaper.style.right = `calc(50% - ${modalWidth / 2}px)`;
        modalPaper.style.top = `${topValue}px`;
      } else {
        const topValue = obj.scrollTop - obj.offsetTop - 8;
        modalPaper.style.margin = 0;
        modalPaper.style.height = `${obj.windowHeight}px`;
        modalPaper.style.top = `${topValue}px`;
        window.parentIFrame.sendMessage("DISABLE_OVERFLOW");
      }
    } catch (e) {
      console.debug(e);
    }
  });
}

export function closeModalIframe(isEmbedded, isMobile) {
  if (!isEmbedded || !window.parentIFrame) {
    return;
  }
  window.parentIFrame.getPageInfo(false);
  if (isMobile) {
    window.parentIFrame.sendMessage("ENABLE_OVERFLOW");
  }
}
