import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BaseInput from "./BaseInput";
import Button from "@material-ui/core/Button";
import constants from "../VendorStore/utils/constants";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RedeemIcon from "@material-ui/icons/Redeem";
import CircularProgress from "@material-ui/core/CircularProgress";

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-end;
`;

const StyledBaseInput = styled(BaseInput)`
  flex: 0 1 200px;
  @media (min-width: 480px) {
    flex: 0 0 200px;
  }
  > div {
    border-radius: 6px 0 0 6px;
  }
  input {
    box-shadow: none;
  }
`;
const APPLY_BUTTON_STATUS = {
  LOADING: "LOADING",
  STANDBY: "STANDBY",
  VALID: "VALID",
  INVALID: "INVALID",
};

function getBackgroundColorByStatus({ status, theme }) {
  switch (status) {
    case APPLY_BUTTON_STATUS.LOADING:
      return theme.palette.grey.A200;
    case APPLY_BUTTON_STATUS.VALID:
      return theme.palette.success.main;
    case APPLY_BUTTON_STATUS.INVALID:
      return theme.palette.error.main;
    case APPLY_BUTTON_STATUS.STANDBY:
      return theme.palette.primary.main;
    default:
      return theme.palette.primary.main;
  }
}

const StyledButton = styled(Button)`
  ${({ status, theme }) => `
    border-radius: 0 6px 6px 0;
    box-shadow: none;
    background-color: ${getBackgroundColorByStatus({ status, theme })};
    :hover {
      border-radius: 0 6px 6px 0;
      box-shadow: none;
      background-color: ${getBackgroundColorByStatus({ status, theme })};
    }
    min-width: 100px;
  `}
`;

const ConfirmationLoader = styled(CircularProgress)`
  position: absolute;
`;
function PromoCodeInput({ initialCodes = [], onSubmit, codeEvaluationStatus }) {
  const [codes, setCodes] = useState(initialCodes.join(","));
  const [applyButtonStatus, setApplyButtonStatus] = useState(
    APPLY_BUTTON_STATUS.STANDBY
  );
  const handleCodeChange = useCallback(
    (event) => {
      if (applyButtonStatus !== APPLY_BUTTON_STATUS.STANDBY) {
        setApplyButtonStatus(APPLY_BUTTON_STATUS.STANDBY);
      }
      setCodes(event.target.value);
    },
    [applyButtonStatus]
  );
  // use if one wants to avoid requests if the code does not change
  // const splitCodes = useMemo(() => codes.split(","), [codes]);
  const handleChangeCodes = useCallback(() => {
    setApplyButtonStatus(APPLY_BUTTON_STATUS.LOADING);
    return onSubmit(codes.split(","));
  }, [codes, onSubmit]);

  useEffect(() => {
    if (
      codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.UNDETERMINED
    ) {
      return;
    }
    if (codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.VALID) {
      setApplyButtonStatus(APPLY_BUTTON_STATUS.VALID);
    }
    if (codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.INVALID) {
      setApplyButtonStatus(APPLY_BUTTON_STATUS.INVALID);
    }
  }, [codeEvaluationStatus]);

  return (
    <Container>
      <StyledBaseInput
        placeholder="Code promo"
        onChange={handleCodeChange}
        value={codes}
      />
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleChangeCodes}
        disabled={!codes || applyButtonStatus === APPLY_BUTTON_STATUS.LOADING}
        status={applyButtonStatus}
      >
        {applyButtonStatus === APPLY_BUTTON_STATUS.LOADING && (
          <>
            <ConfirmationLoader size={28} />
            Chargement
          </>
        )}
        {applyButtonStatus === APPLY_BUTTON_STATUS.VALID && (
          <>
            <CheckCircleOutlineIcon fontSize="small" />
            Valide
          </>
        )}
        {applyButtonStatus === APPLY_BUTTON_STATUS.INVALID && (
          <>
            <ErrorOutlineIcon fontSize="small" />
            Invalide
          </>
        )}
        {applyButtonStatus === APPLY_BUTTON_STATUS.STANDBY && (
          <>
            <RedeemIcon fontSize="small" />
            Appliquer
          </>
        )}
      </StyledButton>
    </Container>
  );
}
PromoCodeInput.propTypes = {
  initialCodes: PropTypes.arrayOf(PropTypes.string),
};

export default PromoCodeInput;
