import React from "react";
import styled from "styled-components";
import BasketButton from "./BasketButton";
import BackIcon from "./BackBtnIcon";
import { useHistory } from "react-router-dom";

type PropTypes = {
  nextUrl: string;
  backUrl: string;
};

const Container = styled.div`
  margin-top: 60px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-around;
  row-gap: 10px;
  flex-flow: wrap-reverse;
  @media (max-width: 959px) {
    & > button {
      width: 90%;
    }
  }
`;

const ButtonsContainer: React.FC<PropTypes> = (props) => {
  const history = useHistory();

  function handleGoBack() {
    history.push(props.backUrl);
  }

  function handleGoNext() {
    history.push(props.nextUrl);
  }

  return (
    <Container>
      <BasketButton onClick={handleGoBack}>
        <BackIcon />
        Continuer mes achats
      </BasketButton>
      <BasketButton variant="contained" onClick={handleGoNext}>
        Je finalise ma commande
      </BasketButton>
    </Container>
  );
};

export default ButtonsContainer;
