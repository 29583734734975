import React from "react";
import {
  Chip,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

type CategoryRowType = {
  category: any;
  categoryNameMap: any;
  onClick: (category: string | null) => void;
  selected?: boolean;
};

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "white",
    },
  },
  selected: {},
});

const StyledListItemText = styled(ListItemText)<{ $selected: boolean }>`
  & > .MuiListItemText-primary {
    font-weight: bold;
    font-weight: ${(props) => (props.$selected ? "bold" : "normal")};
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0 10px;
`;
export const CategoryRow = React.memo(function CategoryRow({
  category,
  categoryNameMap,
  onClick,
  selected = false,
}: CategoryRowType) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <StyledDivider />
      <ListItem
        classes={{ root: classes.root, selected: classes.selected }}
        button
        onClick={() => onClick(category.category)}
        selected={selected}
      >
        <StyledListItemText $selected={selected}>
          {categoryNameMap[category.category]}
        </StyledListItemText>
        <ListItemSecondaryAction>
          <Chip color={"primary"} size={"small"} label={category.count} />
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
});

export const LoadingCategoryRow = React.memo(function LoadingCategoryRow() {
  return (
    <ListItem>
      <ListItemText>
        <Skeleton animation="wave" variant="text" width={"90%"} />
      </ListItemText>
      <ListItemSecondaryAction>
        <Skeleton
          animation="wave"
          variant="circle"
          width={"1.6em"}
          height={"1.6em"}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
});
