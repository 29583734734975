import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

/*
Were not using onSubmit because currently any change on the value selected (not the value typed)
triggers automatically a submit in Filters
 */
export function ProductFilter({
  options,
  value,
  onChange,
  onSubmit,
  label,
  dense,
}) {
  // this is the text being typed and display in the text input.
  // `value` is the key of the product selected, changes only when user selects an option
  const inputRef = useRef();
  const [input, setInput] = useState("");
  const [optionsStr] = useState(_.map(options, "label"));
  const [handleChange] = useState(() => (event, label) => {
    const newValue = label ? _.find(options, { label }).value : "";
    onChange(newValue);
    // we took the ref of the textField event if what we needed was the ref of the input
    // because by using the `InpuPops.ref` we would override the prop set by the Autocomplete
    const input = inputRef.current.querySelector('input[type="text"]');
    if (input) input.blur();
  });
  useEffect(() => {
    const newInput = value ? _.find(options, { value }).label : "";
    setInput(newInput);
  }, [value, options]);

  const handleOnKeyDown = (event) => {
    if (event.key !== "Enter") return;
    // User pressed enter, 3 scenario:
    // #1 empty string, he desires to clear the field, we accept
    if (input === "") return onChange("");

    const valueObj = _.find(options, { value: input });
    // #2 value matches the label of an existing option, we accept
    if (valueObj) return onChange(valueObj.value);
    // #3 value doesn't match a valid option's label, we ignore
  };

  return (
    <Autocomplete
      options={optionsStr}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={handleOnKeyDown}
          margin={dense ? "dense" : "normal"}
          ref={inputRef}
          inputProps={{
            ...params.inputProps,
          }}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
      inputValue={input}
      onInputChange={(e, v) => setInput(v)}
      onChange={handleChange}
    />
  );
}

ProductFilter.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
