import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { withRouter } from "react-router-dom";
import commonConstants, { PAYMENT_TYPES } from "../Common/constants";
import constants from "../VendorStore/utils/constants";
import Swal from "sweetalert2";

// Material Components
import { Grid, Link } from "@material-ui/core";

// Hook
import { useGetAuth } from "../Context/AuthContext";
import { useActiveStep, useSteps } from "../Common/Stepper";

// Contexts
import { useCart } from "../Context/CartContext";

// Components
import Basket from "./Steps/Basket";
import RouterStepper from "../Common/RouterStepper";
import Payment from "./Steps/Payment";
import Account from "../Account";
import Address from "./Steps/Address";

// Styleds
import { StepContainer } from "./Style";
import {
  getCartByProducerId,
  getCartProducerMap,
  getDiscountTotal,
  getFeesTotal,
  getProductTotal,
  getTaxesTotal,
  calculateDueTo,
} from "../Helpers/cart";
import { UnauthorizedError } from "../Common/Exception/exception";
import { useBrandConfig } from "../Context/BrandConfigContext";
import { isEmpty } from "lodash";
import { Tracking } from "../Helpers/tracking";

// Styleds
const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${({ width, theme }) =>
      width !== "xs" ? "white" : theme.palette.grey["50"]};
  }
`;
const Container = styled(Grid)`
  background-color: ${({ width, theme }) =>
    width !== "xs" ? "white" : theme.palette.grey["50"]};
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "20px 120px 100px" : "0"};
`;

// Constants
const STEP_KEYS = ["auth", "basket", "address", "payment", "confirmation"];

function formatAddresses(addresses = []) {
  return addresses.map((address) => ({
    ...address,
    address1: address.text,
    address2: address.additional_info,
  }));
}
function Checkout({ baseURL, history, match, width }) {
  const { auth = {}, authHttpClient } = useGetAuth();
  const {
    cart,
    removeFromCart,
    changeQuantity,
    setHide,
    clearCart,
    getCartTotals,
    promoCode,
    changePromo,
    applyPromo,
    couponLines,
    checkAndAcknowlege,
    promoValid,
    clearPromo,
  } = useCart();
  const {
    brandConfig: { identifier },
  } = useBrandConfig();
  const isGranvillage = identifier === "granvillage";

  //get an array of all the producers
  const producers = useMemo(() => {
    return Object.keys(cart).map((item) => cart[item].product.producer);
  }, [cart]);

  const [contactPoint, setContactPoint] = useState({});
  const [isRecoveryInfoValid, setIsRecoveryInfoValid] = useState(
    producers.reduce((acc, pr) => ({ ...acc, [pr.id]: false }), {})
  );
  const [paidOrders, setPaidOrders] = useState(undefined);
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPES.ONLINE_PAYMENT);
  const [paymentMethod, setPaymentMethod] = useState(
    constants.PAYMENT_METHOD.CREDIT_CARD
  );
  const [isPaymentBeingProcessed, setIsPaymentBeingProcessed] = useState(false);
  const [isOrderBeingProcessed, setIsOrderBeingProcessed] = useState(false);
  const [disableNextButtonState, setDisableNextButton] = useState(false);
  const [user, setUser] = useState({});

  // If we don't isolate the disableButton in the payment step, it will produce secondary effects when user reload the browser
  const [disablePaymentButton, setDisablePaymentButton] = useState(true);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);

  useEffect(() => {
    setDisablePaymentButton(!acceptedTermsAndConditions);
  }, [acceptedTermsAndConditions]);

  useEffect(() => {
    (async () => {
      if (auth.userId && !Object.keys(user).length) {
        try {
          const reqUser = await authHttpClient.get(`/user/${auth.userId}`);
          if (!isEmpty(reqUser)) {
            setUser({
              ...reqUser,
              addresses: formatAddresses(reqUser.addresses),
            });
          }
        } catch (error) {}
      }
    })();
  }, [auth.userId, authHttpClient, user]);

  useEffect(() => {
    // when removing all items from a producer in the cart
    // the producers change and so we must keep the recoveryInfo in sync
    // with the producers in the order, and by using this we make sure
    // we have the same number of producers in both places
    setIsRecoveryInfoValid((prevState) =>
      producers.reduce(
        (acc, pr) => ({ ...acc, [pr.id]: prevState[pr.id] || false }),
        {}
      )
    );
  }, [producers]);

  const paymentMethods = useMemo(() => {
    const commonPaymentMethods = producers
      .map((producer) =>
        producer.paymentMethods.map((paymentMethod) => paymentMethod.type)
      )
      .reduce(
        (acc, producerPaymentMethods) =>
          acc.length === 0
            ? producerPaymentMethods
            : acc.filter((paymentMethod) =>
                producerPaymentMethods.includes(paymentMethod)
              ),
        []
      );
    setPaymentMethod(commonPaymentMethods[0]);
    return commonPaymentMethods;
  }, [producers]);

  const paymentTypes = useMemo(() => {
    const types = [];
    if (paymentMethods.includes(constants.PAYMENT_METHOD.CREDIT_CARD)) {
      types.push(PAYMENT_TYPES.ONLINE_PAYMENT);
    }
    if (
      paymentMethods.includes(constants.PAYMENT_METHOD.CASH) ||
      paymentMethods.includes(constants.PAYMENT_METHOD.CHEQUE) ||
      paymentMethods.includes(constants.PAYMENT_METHOD.TRANSFER)
    ) {
      types.push(PAYMENT_TYPES.SPOT_PAYMENT);
    }
    setPaymentType(types[0]);
    return types;
  }, [paymentMethods]);

  const fees = useMemo(() => {
    if (!contactPoint) {
      return [];
    }
    const rate = Object.values(contactPoint).reduce(
      (total, { delivery }) => total + ((delivery && delivery.rate) || 0),
      0
    );
    return rate > 0 ? [{ rate, type: commonConstants.FEES_TYPE.DELIVERY }] : [];
  }, [contactPoint]);

  const cartTotals = useMemo(() => {
    const cartTotals = getCartTotals(contactPoint, couponLines);
    Tracking.trackCartInfo({
      cart,
      cartTotals,
      contactPointByProducer: contactPoint,
    });
    return cartTotals;
  }, [cart, getCartTotals, contactPoint, couponLines]);

  const handleRemoveItemFromCart = useCallback(
    (productID) => removeFromCart(productID),
    [removeFromCart]
  );

  const handleChangeCart = useCallback(
    (id, quantity) => changeQuantity(id, quantity),
    [changeQuantity]
  );

  const handleChangePaymentMethod = useCallback((method) => {
    setPaymentMethod(method);
  }, []);

  const submitForm = useCallback(
    async (orderDTOs) =>
      authHttpClient.post(`/order/store/createOrder`, orderDTOs),
    [authHttpClient]
  );

  const handleCancelPaymentProcess = useCallback(async () => {
    setIsPaymentBeingProcessed(false);
  }, [setIsPaymentBeingProcessed]);

  const handleCompleted = useCallback(() => {
    setIsPaymentBeingProcessed(true);
  }, []);

  const ordersDTO = useMemo(() => {
    const cartByProducerId = getCartByProducerId(cart);
    const cartProducerMap = getCartProducerMap(cart);
    return Object.entries(cartByProducerId).map(
      ([producerId, { products }]) => {
        const recoveryInfo = contactPoint[producerId];
        const producer = cartProducerMap[producerId];
        return {
          products: products.map(({ quantity, id, size }) => ({
            productCustom: id,
            quantity,
            size,
          })),
          orderDueTo: calculateDueTo(recoveryInfo),
          contactPoint:
            recoveryInfo &&
            recoveryInfo.contactPointInfo &&
            recoveryInfo.contactPointInfo.id,
          status: "_new",
          origin: constants.ORDER_ORIGIN.CLIENT,
          saleChannel: constants.SALE_CHANNEL.STANDARD,
          customer: { id: auth.userId },
          clientAddress: recoveryInfo && recoveryInfo.clientAddress,
          fees:
            recoveryInfo && recoveryInfo.delivery && recoveryInfo.delivery.rate
              ? [{ ...recoveryInfo.delivery, type: "DELIVERY" }]
              : [],
          comment: recoveryInfo && recoveryInfo.comments,
          codes: promoCode && promoCode.length > 0 ? promoCode.split(",") : [],
          group: producer.isGroup ? producer.id : undefined,
          producer: producer.isGroup ? undefined : producer.id,
          paymentMethod,
          shipmentInfo: recoveryInfo && recoveryInfo.shipmentInfo,
        };
      }
    );
  }, [auth.userId, cart, contactPoint, paymentMethod, promoCode]);

  const handleChangeTermsAndConditions = useCallback((e) => {
    setAcceptedTermsAndConditions((prev) => !prev);
  }, []);

  const handleOrderSubmit = useCallback(
    async ({
      paymentMethod = constants.PAYMENT_METHOD.CASH,
      paymentConfirmation = false,
      transactionId,
      paymentProvider,
      additionalData,
      orders,
    }) => {
      let paidOrders = undefined;
      setIsOrderBeingProcessed(true);
      try {
        if (paymentProvider === constants.PAYMENT_PROVIDER.MANGOPAY) {
          paidOrders = orders;
        } else {
          const dto = ordersDTO.map((dto) => ({
            ...dto,
            paymentMethod,
            paymentConfirmation,
            transactionId,
            paymentProvider,
            additionalData,
          }));
          paidOrders = await submitForm(dto);
        }
      } catch (e) {
        console.error(e);
        // session expiration errors are already handled
        // by the authHttpClient so we ignore them
        if (e instanceof UnauthorizedError) {
          return;
        }
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite lors du traitement de votre commande",
        });
      }
      setPaidOrders(paidOrders);
      setIsOrderBeingProcessed(false);
      clearCart();
    },
    [ordersDTO, submitForm, clearCart]
  );

  const disableNextButton = useCallback((value) => {
    setDisableNextButton(value);
  }, []);

  const handleSuccessfulPayment = useCallback(
    async ({
      paymentMethod,
      paymentConfirmation,
      transactionId,
      paymentProvider,
      additionalData,
      orders,
    }) => {
      setIsPaymentBeingProcessed(false);
      await handleOrderSubmit({
        paymentMethod,
        paymentConfirmation,
        transactionId,
        paymentProvider,
        additionalData,
        orders,
      });
    },
    [handleOrderSubmit]
  );

  const handleFailedPayment = useCallback(async () => {
    setIsPaymentBeingProcessed(false);
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Une erreur s'est produite lors du paiement de votre commande",
    });
  }, []);

  const handleChangeRecoveryInfo = useCallback(
    ({ recoveryInfo, valid, producerId }) => {
      setContactPoint((prevState) => ({
        ...prevState,
        ...recoveryInfo,
      }));
      // valid is by producer ID, and all must be valid to continue
      setIsRecoveryInfoValid((prev) => ({ ...prev, [producerId]: valid }));
    },
    []
  );

  // Steps
  const [steps, switchCompleted] = useSteps(
    [
      {
        label: "Connexion",
        key: STEP_KEYS[0],
        completed: false,
        disabled: true,
        component: (
          <StepContainer width={width}>
            <Account
              width={width}
              onSuccess={() => handleNextStep(STEP_KEYS[0])}
              isGranvillage={isGranvillage}
            />
          </StepContainer>
        ),
      },
      {
        label: "Panier",
        key: STEP_KEYS[1],
        completed: false,
        component: (
          <StepContainer width={width}>
            <Basket
              name={auth.userName}
              lastName={auth.userLastname}
              email={auth.userEmail}
              phone={auth.userPhone}
              cart={cart}
              onRemoveItemFromCart={handleRemoveItemFromCart}
              onChangeCart={handleChangeCart}
              onSuccess={() => handleNextStep(STEP_KEYS[1])}
              width={width}
              cartTotals={cartTotals}
              couponLines={couponLines}
              changePromo={changePromo}
              promoCode={promoCode}
              applyPromo={applyPromo}
              promoValid={promoValid}
              clearPromo={clearPromo}
            />
          </StepContainer>
        ),
      },
      {
        label: "Adresse",
        key: STEP_KEYS[2],
        completed: false,
        component: (
          <Address
            width={width}
            cart={cart}
            onChangeRecoveryInfo={handleChangeRecoveryInfo}
            cartTotals={cartTotals}
            disableNextButton={disableNextButton}
            user={user}
          />
        ),
      },
      {
        label: "Paiement",
        key: STEP_KEYS[3],
        completed: false,
        checkboxController: isGranvillage && {
          label: (
            <>
              Je déclare avoir pris connaissance des conditions générales de
              vente consultables en{" "}
              <Link href="/consommateurs/cgv/" target="_blank">
                cliquant ici
              </Link>{" "}
              et les accepter sans réserve.
            </>
          ),
          checked: acceptedTermsAndConditions,
          onChange: handleChangeTermsAndConditions,
        },
        component: (
          <StepContainer width={width}>
            <Payment
              cart={cart}
              paymentTypes={paymentTypes}
              paymentType={paymentType}
              paymentMethods={paymentMethods}
              paymentMethod={paymentMethod}
              isPaymentBeingProcessed={isPaymentBeingProcessed}
              onCancelPaymentProcess={handleCancelPaymentProcess}
              onChangePaymentType={(type) => handleChangePaymentType(type)}
              onChangePaymentMethod={handleChangePaymentMethod}
              onPaymentFormReady={(ready) =>
                handlePaymentReady(STEP_KEYS[3], ready)
              }
              onPaymentFormError={(error) =>
                handlePaymentError(STEP_KEYS[3], error)
              }
              onRemoveCartItem={handleRemoveItemFromCart}
              onSuccessfulPayment={handleSuccessfulPayment}
              onFailedPayment={handleFailedPayment}
              cartTotals={cartTotals}
              fees={fees}
              ordersDTO={ordersDTO}
              accessToken={auth.accessToken}
              couponLines={couponLines}
              disableNextButton={disableNextButton}
            />
          </StepContainer>
        ),
      },
    ],
    // this hook function like a standard react hook and this array is the list of dependencies
    [
      ordersDTO,
      cartTotals,
      fees,
      isPaymentBeingProcessed,
      acceptedTermsAndConditions,
      auth.accessToken,
      auth.userName,
      auth.userLastname,
      auth.userEmail,
      cart,
      paymentType,
      paymentMethod,
      width,
      promoCode,
      couponLines,
      promoValid,
    ]
  );

  const [activeStep] = useActiveStep(steps, {
    match,
  });

  const buttonControllerForSteps = useMemo(() => {
    return {
      back: {
        label:
          activeStep.key === STEP_KEYS[0] || activeStep.key === STEP_KEYS[1]
            ? "Continuer mes achats"
            : "Précédent",
        redirect:
          activeStep.key === STEP_KEYS[0] || activeStep.key === STEP_KEYS[1]
            ? isGranvillage
              ? "/explorer"
              : "/search"
            : "/checkout/basket",
        external: isGranvillage,
      },
      next: {
        label: "Je finalise ma commande",
        hide:
          activeStep.key === STEP_KEYS[3] ||
          ((width === "xs" || width === "sm") &&
            activeStep.key === STEP_KEYS[0]),
        disabled:
          (activeStep.key === STEP_KEYS[2] && disableNextButtonState) ||
          !activeStep.completed,
      },
      completed: {
        label: "Payer ma commande",
        onlyLastStep: true,
        hide: false,
        disabled: Boolean(
          !activeStep.completed || disablePaymentButton || isOrderBeingProcessed
        ),
      },
    };
  }, [
    activeStep.key,
    activeStep.completed,
    isGranvillage,
    width,
    disableNextButtonState,
    disablePaymentButton,
    isOrderBeingProcessed,
  ]);

  // Handles
  const handleNextStep = useCallback(
    (currentStepKey) => {
      const index = STEP_KEYS.findIndex((key) => key === currentStepKey);
      switchCompleted(STEP_KEYS[index]);
      history.push(`${baseURL}/${STEP_KEYS[index + 1]}`);
    },
    [switchCompleted, history, baseURL]
  );
  const handlePaymentReady = useCallback(
    (paymentKey, ready) => {
      if (ready) {
        const index = steps.findIndex((s) => s.key === paymentKey);
        if (!steps[index].completed) {
          switchCompleted(paymentKey);
        }
      }
    },
    [steps, switchCompleted]
  );

  const handlePaymentError = useCallback(
    (paymentKey, error) => {
      if (error) {
        const index = steps.findIndex((s) => s.key === paymentKey);
        if (steps[index].completed) {
          switchCompleted(paymentKey, { rollback: true });
        }
      }
    },
    [steps, switchCompleted]
  );

  const handleChangePaymentType = useCallback(
    (type) => {
      setPaymentType(type);
      if (type === PAYMENT_TYPES.SPOT_PAYMENT) {
        handlePaymentReady(STEP_KEYS[3], true);
      }
    },
    [handlePaymentReady]
  );

  useEffect(() => {
    if (paidOrders) {
      const joinedIds = paidOrders
        .map((order) => (order.group ? `__G__${order.id}` : `__P__${order.id}`))
        .join("|");
      Tracking.trackTransactionId(joinedIds);
      const joinedId = btoa(joinedIds);
      history.push(`${baseURL}/summary/${joinedId}`);
      setPaidOrders(undefined);
    }
  }, [baseURL, history, paidOrders]);

  useEffect(() => {
    const stepKey = STEP_KEYS[2];
    const index = steps.findIndex((s) => s.key === stepKey);
    const isAuth = !!Object.keys(auth).length;
    // check the number of producers we have in the cart
    // if all these producers have validRecoveryInfo then
    // we do the switch completed
    const validations = Object.values(isRecoveryInfoValid);
    // NOTE: if the result of Object.values(isRecoveryInfoValid) comes with empty array
    // reduce will take it as a true value.
    const isAllRecoveryInfoValid = validations.length
      ? validations.reduce((acc, val) => acc && val, true)
      : false;
    if (isAuth && isAllRecoveryInfoValid && !steps[index].completed) {
      switchCompleted(stepKey);
    } else if (!isAllRecoveryInfoValid && steps[index].completed) {
      switchCompleted(stepKey, { rollback: true });
    }
  }, [isRecoveryInfoValid, steps, switchCompleted, auth]);

  // Init
  useEffect(() => {
    setHide(true);
  }, [setHide]);

  // Unmount
  useEffect(
    () => () => {
      setHide(false);
    },
    [setHide]
  );

  // Validations
  // Validation for auth
  useEffect(() => {
    const isAuth = !!Object.keys(auth).length;
    if (!isAuth && activeStep && activeStep.key !== STEP_KEYS[0]) {
      switchCompleted(STEP_KEYS[0], { rollback: true });
      history.push(`${baseURL}/${STEP_KEYS[0]}`);
    } else if (
      isAuth &&
      !steps.find((step) => step.key === STEP_KEYS[0]).completed
    ) {
      handleNextStep(STEP_KEYS[0]);
    }
  }, [
    auth,
    handleNextStep,
    steps,
    activeStep,
    baseURL,
    history,
    switchCompleted,
  ]);

  const checkCart = useCallback(
    (key) => {
      checkAndAcknowlege(cart, promoCode);
    },
    [cart, promoCode, checkAndAcknowlege]
  );

  // Validation for basket
  useEffect(() => {
    if (Object.keys(auth).length && activeStep.key === STEP_KEYS[1]) {
      if (!activeStep.completed && cart && Object.keys(cart).length) {
        switchCompleted(STEP_KEYS[1]);
      } else if (activeStep.completed && (!cart || !Object.keys(cart).length)) {
        switchCompleted(STEP_KEYS[1], { rollback: true });
      }
    }
  }, [
    cart,
    auth,
    activeStep.key,
    switchCompleted,
    activeStep.completed,
    promoCode,
  ]);

  return (
    <Container width={width}>
      <GlobalStyle />
      <RouterStepper
        baseURL={baseURL}
        steps={steps}
        buttonController={buttonControllerForSteps}
        onCompleted={handleCompleted}
        beforeGoToStep={(key) => checkCart(key)}
      />
    </Container>
  );
}

Checkout.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  baseURL: PropTypes.string.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withRouter(Checkout);
