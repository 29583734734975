import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

//styleds
const SizeBoxContainer = styled.span`
  font-size: 12px;
  padding: 5px 8px 5px 8px;
  background: ${({ theme }) => theme.palette.grey["900"]};
  opacity: 0.9;
  color: white;
  font-weight: 500;
  border-radius: 24px;
`;

const SizeBoxSeperator = styled.span`
  &::after {
    font-weight: 500;
    opacity: 0.25;
    content: "|";
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const SizeBox = ({ sizes }) => {
  const sizesList = sizes.slice(0, 10); // we limit here to keep the list pretty
  const totalSizes = sizesList.length;

  return (
    <SizeBoxContainer>
      {sizesList.map((size, index) => (
        <>
          {size}
          {parseInt(index + 1) < totalSizes && <SizeBoxSeperator />}
        </>
      ))}
    </SizeBoxContainer>
  );
};

SizeBox.protoTypes = {
  sizes: PropTypes.array,
};

SizeBox.defaultProps = {
  sizes: [],
};

export default SizeBox;
