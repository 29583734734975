import CartSummaryWrapper from "./CardSummaryWrapper";
import React from "react";
import ButtonsContainer from "./ButtonsContainer";

export default function Basket() {
  return (
    <React.Fragment>
      <CartSummaryWrapper />
      <ButtonsContainer backUrl="/search" nextUrl="/checkout/address" />
    </React.Fragment>
  );
}
