import React from "react";
import { capitalize } from "lodash";
import { NumberFormatCustom } from "@kuupanda/commons";

export const getFullName = (user) =>
  `${capitalize(user.name)} ${capitalize(user.lastName)}`;

export const isSmDown = (width) => ["sm", "xs"].includes(width);
export const isXS = (width) => width === "xs";

export function wait(delay, result) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(result), delay);
  });
}

export const makeMapFromArray = (
  array,
  keyName = "value",
  valueName = "label"
) =>
  array.reduce((acc, item) => {
    acc[item[keyName]] = item[valueName];
    return acc;
  }, {});

/**
 * from https://stackoverflow.com/a/384380/2766860
 * @param o
 * @returns {*}
 */
function isDOMElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}

/**
 *
 * @param target {Number | String | Node} can be either a pixel offset from the top of the page, a valid css selector, or an element reference
 * @param opts {object} options
 * @param opts.offset {Number} offset in px
 * @param opts.behavior {String} one of "auto", "instant" or "smooth". Passed down to window.scrollTo
 * @param opts.delay {number} in ms
 * @param opts.anchor {string} One of "bottom" or "top"
 */
export function scrollTo(target, opts) {
  const _opts = {
    offset: 0,
    delay: 0,
    behavior: "auto",
    anchor: "top",
    ...opts,
  };
  let pxToAnchor = 0;
  if (typeof target === "number") pxToAnchor = target + _opts.offset;
  else {
    const elemTarget = getTarget(target);
    if (elemTarget) {
      pxToAnchor =
        elemTarget.getBoundingClientRect()[_opts.anchor] +
        window.pageYOffset +
        _opts.offset;

      if (_opts.anchor === "bottom" && window.visualViewport)
        pxToAnchor -= window.visualViewport.height;
    }
  }
  const doScroll = () =>
    window.scrollTo({ top: pxToAnchor, behavior: _opts.behavior });

  if (_opts.delay) setTimeout(doScroll, _opts.delay);
  else doScroll();
}

const getTarget = (target) => {
  if (typeof target === "string") {
    target = document.querySelector(target);
  }
  return isDOMElement(target) ? target : null;
};

export const isHiddenBelow = (target) => {
  const elemTarget = getTarget(target);
  if (!elemTarget || !window.visualViewport) return null;
  return (
    window.visualViewport.height - elemTarget.getBoundingClientRect().bottom <=
    0
  );
};

export function pseudoGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `_${s4()}${s4()}-${s4()}`;
}

export function getBaseUnit(product) {
  return product.baseUnit === "unit"
    ? "unité"
    : product.baseUnit === "l"
    ? "litre"
    : product.baseUnit;
}

export function convertToMultiLine(text) {
  const result = text
    ? text.split("\n").map((line, i, arr) => {
        if (i === arr.length - 1) {
          return <span key={i}>{line}</span>;
        } else {
          return [<span key={i}>{line}</span>, <br key={i + "br"} />];
        }
      })
    : "";
  return result;
}

export function isHtml(string) {
  return /<[^<]+>/.test(string);
}

export function countLines(string) {
  return (string.match(/<p>/g) || []).length;
}

export function getPrice({ price }) {
  return <NumberFormatCustom value={price} displayType={"text"} />;
}

export const getProductName = (product) => {
  const { name, variableUnit, averageWeight } = product;

  if (averageWeight && variableUnit) {
    return `${name} (${formatNumber(averageWeight)}${variableUnit})`;
  }

  return name;
};
const formatNumber = (number) => {
  return number.toString().replace(".", ",");
};

export const getProductUnit = (product) => {
  const unitMap = {
    g: "g",
    kg: "kg",
    l: "L",
    ml: "mL",
    unit: "unité",
  };

  if (!product.variableWeightPrice) {
    return unitMap[product.baseUnit];
  }

  const unit =
    product.baseUnit === "unit"
      ? product.sellByUnit || "unit"
      : product.baseUnit;

  return unitMap[unit] ? unitMap[unit] : "unité";
};

export const calculateVariableWeight = (product) => {
  const { averageWeight, price, variableUnit } = product;

  if (variableUnit === "g" || variableUnit === "ml") {
    return price / (averageWeight / 1000);
  }

  return price / averageWeight;
};
