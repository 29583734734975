import {
  CardHeader,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import AvatarWrapper from "./AvatarWrapper";

export const Header = React.memo(function Header({
  title,
  subheader,
  avatar,
  avatarAlt,
}) {
  const theme = useTheme();

  return (
    <EllipsisCardHeader
      title={
        <div style={{ width: "100%" }}>
          <Typography
            noWrap={true}
            align="left"
            variant={"body1"}
            style={{ color: theme.palette.primary.dark, fontWeight: 500 }}
            title={title}
          >
            {title}
          </Typography>
        </div>
      }
      subheader={
        <Typography
          align="left"
          variant={"body2"}
          color={"textSecondary"}
          noWrap={true}
        >
          {subheader}
        </Typography>
      }
      avatar={
        <AvatarWrapper
          alt={avatarAlt}
          height={40}
          size="40px"
          profilePicture={avatar}
        />
      }
    />
  );
});

Header.propTypes = {
  avatar: PropTypes.string.isRequired,
  avatarAlt: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
const EllipsisCardHeader = withStyles((theme) => ({
  root: {
    padding: "16px", // 8px 16px
  },
  content: {
    overflow: "hidden",
  },
}))(CardHeader);
