import React from "react";
import PropTypes from "prop-types";
import { PROPTYPES } from "../utils";
import { GoogleMap, InfoWindow, MarkerClusterer } from "@react-google-maps/api";
import Popup from "./Popup";
import { MARKER_ICON_SIZE, MarkerWrapper } from "./MarkerWrapper";
import cluster2 from "./cluster2.svg";
import cluster3 from "./cluster3.svg";
import cluster4 from "./cluster4.svg";
import cluster5 from "./cluster5.svg";
import ClusterConflictModal from "./ClusterConflictModal";
import _ from "lodash";
import { withStyles, withWidth } from "@material-ui/core";

// we use the same icons for level 1 and 2
// those size in px matches the size of the icons in the pngs
const clusterSizes = [
  [cluster2, 40],
  [cluster2, 40],
  [cluster3, 43],
  [cluster4, 46],
  [cluster5, 50],
];
const clusterStyles = clusterSizes.map(([url, size], i) => ({
  url,
  height: size,
  width: size,
  fontFamily: "Arial",
  textColor: "#FFF",
  fontWeight: "bold",
}));

const getMapStyleOptions = (showBusiness) => {
  if (showBusiness) return {};
  return {
    styles: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
    ],
  };
};

class _MapWithApi extends React.Component {
  static propTypes = {
    defaultZoom: PropTypes.number.isRequired,
    defaultCenter: PropTypes.shape(PROPTYPES.coordShape).isRequired,
    markers: PropTypes.array,
    onMarkerClick: PropTypes.func,
    openMarkerID: PropTypes.string,
    usersMap: PropTypes.object,
    title: PropTypes.string,
    showBusinesses: PropTypes.bool,
  };

  map = null;

  constructor(props) {
    super(props);
    this.mapOptions = {
      ...getMapStyleOptions(this.props.showBusinesses),
      maxZoom: 18,
      clickableIcons: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: !["xs", "sm"].includes(props.width),
    };
    this.infoWindowOptions = {
      pixelOffset: new window.google.maps.Size(0, -MARKER_ICON_SIZE),
    };

    this.clusterer = null;

    this.state = {
      duplicateMarkers: [],
    };
  }

  closeInfoWindow = () => {
    this.props.onMarkerClick({
      markerID: null,
      userID: null,
    });
  };

  _onClusterClick = (event) => {
    // we hide the current open marker popup if any
    this.closeInfoWindow();

    // some coord comes with diferent precision and thus the comparison would fail, ex : 2.748826000000008 2.748826
    const COORD_PRECISION = 6;
    const acceptableDelta = 10 ** -COORD_PRECISION;

    // checking if we have a cluster full of duplicated location. If yes then show modal
    function isClusterFullOfDup() {
      const markers = event.getMarkers();
      // we take the first marker as reference
      const lat = markers[0].position.lat();
      const lng = markers[0].position.lng();
      for (let j = 1; j < markers.length; j++) {
        // if any of the other marker has a different position,
        // it means this cluster can potentially be more expanded
        if (Math.abs(lat - markers[j].position.lat()) > acceptableDelta)
          return false;
        if (Math.abs(lng - markers[j].position.lng()) > acceptableDelta)
          return false;
      }
      return true;
    }

    if (!isClusterFullOfDup(event)) return true;

    // from here we now the cluster that just been click is full of duplicated markers.
    // We retrieve those marker full info from the id
    const duplicateMarkers = event.getMarkers().map((marker) => {
      const markerID = marker.get("id");
      return _.find(this.props.markers, { id: markerID });
    });

    this.setState({
      duplicateMarkers,
    });
  };

  _onClusterModalClose = () => {
    this.setState({ duplicateMarkers: [] });
  };

  handleMapLoad = (map) => {
    if (this.map === null) {
      this.map = map;
    }
    if (this.props.markers && this.props.markers.length) {
      const bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < this.props.markers.length; i++) {
        bounds.extend(this.props.markers[i].coord);
      }
      map.fitBounds(bounds);
    }
  };

  handleClustererEnd = (clusterer) => {
    // we store the clusterer to use it later in bounceCluster
    this.clusterer = clusterer;
  };

  static bounceCluster = (clusterer, hoveredUserID) => {
    // a single marker also appears as a cluster (which contains onlyone marker).
    // we don't even bother with those ones
    const clusters = clusterer
      .getClusters()
      .filter((cluster) => cluster.getMarkers().length > 1);

    // if the currently looped over cluster contains the marker with the hoveredUserID, we apply the bounce class.
    // more than one cluster can be targeted
    clusters.forEach((cluster) => {
      const markers = cluster.getMarkers();
      let className = "cluster"; // base class, every cluster should alwasy have it

      if (hoveredUserID) {
        const hasHoveredMarker = markers.find(
          (marker) => marker.get("userID") === hoveredUserID
        );
        if (hasHoveredMarker) className += " cluster--bounce";
      }
      if (cluster && cluster.clusterIcon && cluster.clusterIcon.div) {
        cluster.clusterIcon.div.setAttribute("class", className);
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.map && prevProps.markers !== this.props.markers) {
      this.handleMapLoad(this.map);
    }
    if (prevProps.hoveredUserID !== this.props.hoveredUserID) {
      if (this.clusterer) {
        // so it doesn't crash at first call when the clusterer isn't loaded yet
        _MapWithApi.bounceCluster(this.clusterer, this.props.hoveredUserID);
      }
    }
  }

  render() {
    const {
      defaultCenter,
      defaultZoom,
      markers,
      openMarkerID,
      onMarkerClick,
      hoveredUserID,
      usersMap,
      classes,
      title,
    } = this.props;
    const { duplicateMarkers } = this.state;
    const openMarker = _.find(markers, { id: openMarkerID });
    return (
      <React.Fragment>
        <ClusterConflictModal
          open={Boolean(duplicateMarkers.length)}
          usersMap={usersMap}
          markers={duplicateMarkers}
          onClose={this._onClusterModalClose}
        />
        <GoogleMap
          id="search-map"
          zoom={defaultZoom}
          center={defaultCenter}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          options={this.mapOptions}
          onLoad={this.handleMapLoad}
          onClick={this.closeInfoWindow}
        >
          <span className={classes.title}>{title}</span>
          <MarkerClusterer
            styles={clusterStyles}
            onClick={this._onClusterClick}
            onClusteringEnd={this.handleClustererEnd}
            gridSize={15} // default is 60px. This make the cluster less "greedy" https://react-google-maps-api-docs.netlify.com/#markerclusterer
          >
            {(clusterer) =>
              markers.map((marker) => (
                <MarkerWrapper
                  key={marker.id}
                  position={marker.coord}
                  onClick={onMarkerClick}
                  marker={marker}
                  isOpen={openMarkerID === marker.id}
                  isHoverFromList={hoveredUserID === marker.userID}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
          {openMarker && (
            <InfoWindow
              position={openMarker.coord}
              options={this.infoWindowOptions}
              onCloseClick={this.closeInfoWindow}
            >
              <Popup marker={openMarker} user={usersMap[openMarker.userID]} />
            </InfoWindow>
          )}
        </GoogleMap>
      </React.Fragment>
    );
  }
}

export const MapWithApi = withStyles((theme) => ({
  title: {
    position: "absolute",
    top: 0,
    margin: 10, // like other gmap overlay items
    padding: theme.spacing(0.5, 2),
    left: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    borderRadius: 2,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5, 1.5),
      fontSize: "0.9em",
    },
  },
}))(withWidth()(_MapWithApi));
