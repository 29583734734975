/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=600fee62251ad50c97d44b23"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class MentionsLegalesView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;
    Controller = MentionsLegalesView;

    return Controller;
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "602e7c97a22f486fc3dbccc5";
    htmlEl.dataset["wfSite"] = "600fee62251ad50c97d44b23";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      MentionsLegalesView.Controller !== MentionsLegalesView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/kuupanda-gestion-07a594.webflow.css);


          body{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-title-section-ml af-class-no-bottom-space-ml af-class-bg-primary-ml">
              <div className="af-class-container-testimonials af-class-text-center">
                <h1 className="af-class-text-white">Mentions Légales</h1>
              </div>
              <img
                src="images/divider-round-bottom.svg"
                alt
                className="af-class-divider af-class-divider-bottom-large"
              />
              <div className="af-class-decoration-container af-class-left-bottom">
                <div className="af-class-decorations-wrapper">
                  <div className="af-class-decoration-circle af-class-top-right af-class-bg-primary-2" />
                </div>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-container-testimonials">
                <div className="af-class-content-pair-2">
                  <div
                    data-w-id="c7bbc2b6-2d55-59d8-347c-5b19f3d0e402"
                    style={{ opacity: 0 }}
                    className="af-class-content-pair-text"
                  >
                    <h5 className="af-class-heading-8-ml">
                      INFORMATIONS GÉNÉRALES
                    </h5>
                    <p className="af-class-paragraph-large-ml af-class-text-large-ml">
                      Le présent site web est édité et exploité par la société
                      SAS KUUPANDA, société par actions simplifiée au capital de
                      10900,00 euros, immatriculée au RCS de Perpignan sous le
                      n° 878 883 487, dont le siège social est situé 16 rue
                      Joseph Parayre, Res. les hortes, Bat. B, 66400 Céret
                      (France). Le numéro de TVA est le FR62878883487.
                      <br />
                      <br />
                      Le directeur de la publication est M.Pablo FERNANDEZ.
                    </p>
                    <h5 className="af-class-heading-8-ml">
                      COORDONNÉES DE LA SOCIÉTÉ KUUPANDA
                      <br />
                    </h5>
                    <p className="af-class-paragraph-large-ml af-class-text-large-ml">
                      Adresse postale : SAS KUUPANDA, 16 rue Joseph Parayre,
                      Res. les hortes, Bat. B, 66400 Céret (France)
                      <br />
                      Téléphone : +33 4.11.96.01.39
                      <br />
                      Adresse électronique : oui@kuupanda.com
                      <br />
                    </p>
                    <h5 className="af-class-heading-8-ml">
                      HÉBERGEMENT DU SITE
                      <br />
                    </h5>
                    <p className="af-class-paragraph-large-ml af-class-text-large-ml">
                      L'hébergement du site est assuré par la société AWS dont
                      les serveurs sont situés en région parisienne, France.
                      <br />
                      Adresse du siège : Amazon Web Services, Inc. P.O. Box
                      81226 Seattle, WA 98108-1226 (États-unis)
                      <br />
                      Adresse électronique : https://aws.amazon.com
                      <br />
                    </p>
                    <h5 className="af-class-heading-8-ml">
                      TRAITEMENT DES DONNÉES À CARACTÈRE PERSONNEL
                      <br />
                    </h5>
                    <p className="af-class-paragraph-large-ml af-class-text-large-ml">
                      Chaque internaute peut exercer ces droits en écrivant à :
                      rgpd@kuupanda.com ; ou en contactant le Délégué à la
                      Protection des données de la Société, jorgel@kuupanda.com.
                      <br />
                    </p>
                  </div>
                  <img
                    src="images/Illu-farmer.png"
                    width={486}
                    style={{ opacity: 0 }}
                    data-w-id="ccbfdc57-e877-201e-b343-944af19ff98f"
                    alt
                    className="af-class-content-pair-image-ml"
                  />
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default MentionsLegalesView;

/* eslint-enable */
