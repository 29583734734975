import React from "react";
import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box, Grid, ThemeProvider } from "@material-ui/core";

export function FiltersLayout(props) {
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  if (isXsDown) return <XSLayout {...props} />;
  if (isLgUp) return <LGLayout {...props} />;
  // sm and md
  return <SMLayout {...props} />;
}

function XSLayout(props) {
  const theme = useTheme();
  const { xs: verticalSpacing } = props.verticalSpacing;
  const { MoreFilters } = props;
  const onlyDeliveryAnchored = (
    <div style={{ textAlign: "left" }}>{props.onlyDelivery}</div>
  );

  // didn't manage to make responsiveFontSizes work
  const themeXs = React.useMemo(
    () =>
      createMuiTheme({
        ...theme,
        ...{
          typography: {
            htmlFontSize: 20,
          },
          spacing: 8,
        },
      }),
    [theme]
  );

  return (
    <div>
      <ThemeProvider theme={themeXs}>
        {props.isMap ? (
          <React.Fragment>
            {props.location}
            <MoreFilters active={props.showMore}>
              {onlyDeliveryAnchored}
              <Box style={{ marginTop: verticalSpacing }} flexGrow={1}>
                {props.product}
              </Box>
              <Grid spacing={1} container alignItems={"center"}>
                <Grid item xs={props.radius ? 5 : 6}>
                  {props.category}
                </Grid>
                <Grid item xs={props.radius ? 4 : 6}>
                  {props.label}
                </Grid>
                {props.radius && (
                  <Grid item xs={3}>
                    {props.radius}
                  </Grid>
                )}
              </Grid>
              {props.vendor}
            </MoreFilters>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.location}
            {onlyDeliveryAnchored}
            <MoreFilters active={props.showMore}>
              <Box style={{ marginTop: verticalSpacing }} flexGrow={1}>
                {props.product}
              </Box>
              <Grid
                spacing={1}
                container
                justify={"space-around"}
                alignItems={"center"}
              >
                <Grid item xs={props.radius ? 5 : 6}>
                  {props.category}
                </Grid>
                <Grid item xs={props.radius ? 4 : 6}>
                  {props.label}
                </Grid>
                {props.radius && (
                  <Grid item xs={3}>
                    {props.radius}
                  </Grid>
                )}
              </Grid>
              {props.vendor}
            </MoreFilters>
          </React.Fragment>
        )}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          wrap={"nowrap"}
          style={{ marginTop: verticalSpacing * 2 }}
        >
          {props.resultCounter}
          {props.moreFiltesrBtn}
        </Box>
      </ThemeProvider>
    </div>
  );
}
function SMLayout(props) {
  const { sm: verticalSpacing } = props.verticalSpacing;
  const { MoreFilters } = props;
  return (
    <div>
      <Box display={"flex"} alignItems="center">
        {props.location}
        <Box p={1} />
        {props.onlyDelivery}
      </Box>
      <Box style={{ marginTop: verticalSpacing }} flexGrow={1}>
        {props.product}
      </Box>
      <Grid
        style={{ marginTop: verticalSpacing - 8 }}
        spacing={1}
        container
        alignItems={"center"}
      >
        <Grid item xs={props.radius ? 5 : 6}>
          {props.category}
        </Grid>
        <Grid item xs={props.radius ? 5 : 6}>
          {props.label}
        </Grid>
        {props.radius && (
          <Grid item xs={2}>
            {props.radius}
          </Grid>
        )}
      </Grid>
      <MoreFilters active={true}>{props.vendor}</MoreFilters>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        wrap={"nowrap"}
        style={{ marginTop: verticalSpacing * 2 }}
      >
        {props.resultCounter}
        {props.moreFiltesrBtn}
      </Box>
    </div>
  );
}
function LGLayout(props) {
  const { lg: verticalSpacing } = props.verticalSpacing;
  const { MoreFilters } = props;
  return (
    <div>
      <Box display={"flex"} alignItems="center">
        {props.location}
        <Box p={1} />
        {props.onlyDelivery}
      </Box>
      <Grid
        style={{ marginTop: verticalSpacing - 8 }}
        container
        alignItems={"center"}
        spacing={1}
      >
        <Grid item xs={props.radius ? 12 : 6}>
          {props.product}
        </Grid>
        <Grid item xs={props.radius ? 5 : 3}>
          {props.category}
        </Grid>
        <Grid item xs={props.radius ? 5 : 3}>
          {props.label}
        </Grid>
        {props.radius && (
          <Grid item xs={2}>
            {props.radius}
          </Grid>
        )}
      </Grid>
      <MoreFilters active={true}>{props.vendor}</MoreFilters>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        wrap={"nowrap"}
        style={{ marginTop: verticalSpacing * 2 }}
      >
        {props.resultCounter}
        {props.moreFiltesrBtn}
      </Box>
    </div>
  );
}
