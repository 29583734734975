const constants = {
  PAY_IN_STATUS: {
    SUCCESS: "SUCCEEDED",
    FAILED: "FAILED",
    PENDING: "PENDING",
    REQUESTED: "REQUESTED",
  },
};

export default constants;
