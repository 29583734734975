import React from "react";
import PropTypes from "prop-types";
import { getHOCDisplayName } from "./utils";

export function withResponsiveTarget(WrappedComponent, accessor) {
  function WithResponsiveTarget({ target, forwardedRef, ...rest }) {
    return <WrappedComponent ref={forwardedRef} {...rest} />;
  }
  WithResponsiveTarget.displayName = `withResponsiveTarget(${getHOCDisplayName(
    WrappedComponent
  )})`;
  WithResponsiveTarget.propTypes = {
    onChange: PropTypes.func,
  };
  return React.forwardRef((props, ref) => {
    return <WithResponsiveTarget {...props} forwardedRef={ref} />;
  });
}
