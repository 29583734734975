import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Button,
  Hidden,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import { RiInformationLine } from "react-icons/ri";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

//context
import { usePresentation } from "../../Context/PresentationContext";

//utils
import media from "../../Helpers/media";

//components
import ResponsivePicture from "../../Common/ResponsivePicture";

// icons urls
import IconCallsUrl from "../../Assets/Icons/icon-calls.svg";
import IconPinUrl from "../../Assets/Icons/icon-pin.svg";
import { useGetAuth } from "../../Context/AuthContext";
import axios, { AxiosResponse } from "axios";

//styled
const StyledDivider = styled(Divider)`
  margin-top: 24px;
  margin-left: -25px;
  margin-right: -25px;
`;

const StyledBox = styled(Box)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 15px;
`;

const Row = styled(Box)`
  font-size: 14px !important;
`;

const StyledTypography = styled(Typography)`
  margin-top: 10px;
  font-size: 18px;
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  border-radius: 50%;
  width: 116px;
  height: 116px;
  justify-content: center;
  border: 2px solid white;
`;

const StyledImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  ${media.down.md`
   margin: 30px 0;
    width: 80%;
  `}
`;

const StyledContactButton = styled(Button)`
  text-align: center;
  padding: 5px;
  width: 100%;
  text-transform: inherit;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  font-weight: bold;
  :hover {
    background: black;
    color: white;
  }
`;

const StyledHeartIcon = styled(AiOutlineHeart)`
  width: 22px;
  height: 22px;
  stroke: white;
  stroke-width: 40px;
  margin-right: 13px;
  ${media.down.md`
   stroke: rgba(255, 255, 255, 0.5);
  `}
`;

const StyledHeartIconFilled = styled(AiFillHeart)`
  display: none;
  width: 22px;
  height: 22px;
  margin-right: 13px;
`;

const StyledWhiteHeartIconFilled = styled(AiFillHeart)`
  width: 22px;
  height: 22px;
  margin-right: 13px;
`;

const StyledFavoriteBox = styled.div`
  display: flex;
`;

const StyledFavoriteButtonTemplate = styled.div`
  padding: 7.2px 8px;
  font-weight: 700 !important;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  margin-left: 5px;
  cursor: pointer;
  ${media.down.md`
   position: absolute;
   border: 0px !important;
   display: block;
   height: 38px;
   width: 38px;
   top: 85px;
   right: 15px;
   background-color: rgba(255, 255, 255, 0.5);
  `}
`;

const StyledFavoriteButton = styled(StyledFavoriteButtonTemplate)`
  &:hover ${StyledHeartIconFilled} {
    display: block;
  }
  &:hover ${StyledHeartIcon} {
    display: none;
  }
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledIsFavoriteButton = styled(StyledFavoriteButtonTemplate)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  color: white;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  avatar: {
    width: 116,
    height: 116,
    border: "2px solid white",
  },
  storeName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
    },
  },
}));

const ProducerInfoDivider = styled(Divider)`
  margin: 30px 0;
  margin-left: -25px;
  margin-right: -25px;
`;

type ProductorInfoProps = {
  store: any;
  loading: boolean;
  externalId?: string;
};

async function checkFavoriteProducer(
  userEmail: string,
  producerEmail: string,
  setFavorite: any
): Promise<boolean> {
  try {
    const isFavoriteResponse: AxiosResponse<boolean> = await axios({
      method: "get",
      url: `/api/consommateur/check-producteur-fav/${userEmail}/${producerEmail}`,
    });
    if (setFavorite) {
      setFavorite(isFavoriteResponse.data);
    }
    return isFavoriteResponse.data;
  } catch (e) {
    console.error("call failed", e);
    return false;
  }
}

async function addFavoriteProducer(
  userEmail: string,
  producerEmail: string
): Promise<any> {
  return axios({
    method: "patch",
    url: `/api/consommateur/add-producteur-fav`,
    data: {
      consommateur_email: userEmail,
      producteur_email: producerEmail,
    },
  });
}

async function removeFavoriteProducer(
  userEmail: string,
  producerEmail: string
): Promise<any> {
  return axios({
    method: "delete",
    url: `/api/consommateur/remove-producteur-fav`,
    data: {
      consommateur_email: userEmail,
      producteur_email: producerEmail,
    },
  });
}

export default function ProductorInfo({
  store,
  loading,
  externalId,
}: ProductorInfoProps) {
  const [isFavorite, setFavorite] = useState<boolean>(false);
  const {
    presentationOptions: { showFavourite, showProductionMethod },
  } = usePresentation();
  const { auth: user } = useGetAuth();

  const handleFavoriteClick = useCallback(async () => {
    if (!isFavorite) {
      try {
        await addFavoriteProducer(user.userEmail, store.email);
        setFavorite(true);
      } catch (e) {
        console.error("call failed", e);
      }
      return;
    }
    try {
      await removeFavoriteProducer(user.userEmail, store.email);
      setFavorite(false);
    } catch (e) {
      console.error("call failed", e);
    }
  }, [isFavorite, user, store.email]);

  useEffect(() => {
    if (!user || !store.email) {
      return;
    }
    checkFavoriteProducer(user.userEmail, store.email, setFavorite);
  }, [user, store.email]);

  const classes = useStyles();
  if (loading) {
    return (
      <Box
        component={Box}
        className={classes.root}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={1}>
          <Skeleton
            animation="wave"
            variant="circle"
            className={classes.avatar}
          />
        </Box>
        <Skeleton animation="wave" height={60} />
        <Hidden mdDown>
          <Skeleton animation="wave" height={20} />
          <Skeleton animation="wave" height={20} />
          {showProductionMethod && store.productionMethod !== "" && (
            <Skeleton animation="wave" height={20} />
          )}
          <Skeleton animation="wave" height={60} />
          <StyledDivider />
        </Hidden>
      </Box>
    );
  }
  const avatarComp = store.profileUrl ? (
    <Avatar
      alt={store.fullName}
      component={() => (
        <StyledResponsivePicture
          className={classes.avatar}
          containerStyle={{ width: "auto" }}
          baseImgUrl={store.profileUrl}
          fitType="cover"
        />
      )}
    />
  ) : (
    <Avatar className={classes.avatar} alt={store.fullName} />
  );
  return (
    <React.Fragment>
      <Hidden mdUp>
        <StyledImageBox>{avatarComp}</StyledImageBox>
        <StyledTypography align={"center"} className={classes.storeName}>
          {store.storeName}
        </StyledTypography>
        {showFavourite && user && (
          <StyledFavoriteButton onClick={handleFavoriteClick}>
            {isFavorite ? <StyledWhiteHeartIconFilled /> : <StyledHeartIcon />}
          </StyledFavoriteButton>
        )}
      </Hidden>
      <Hidden smDown>
        <Box
          component={Box}
          className={classes.root}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box display={"flex"} justifyContent={"center"} mb={1}>
            {avatarComp}
          </Box>

          <StyledBox
            py={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <StyledTypography
              variant={"h5"}
              align={"center"}
              className={classes.storeName}
            >
              {store.storeName}
            </StyledTypography>
          </StyledBox>
          {showFavourite && user && (
            <ButtonWrapper>
              {isFavorite ? (
                <StyledIsFavoriteButton onClick={handleFavoriteClick}>
                  <StyledFavoriteBox>
                    <StyledWhiteHeartIconFilled /> Retirer de mon village
                  </StyledFavoriteBox>
                </StyledIsFavoriteButton>
              ) : (
                <StyledFavoriteButton onClick={handleFavoriteClick}>
                  <StyledFavoriteBox>
                    <StyledHeartIconFilled />
                    <StyledHeartIcon /> Ajouter à mon village
                  </StyledFavoriteBox>
                </StyledFavoriteButton>
              )}
            </ButtonWrapper>
          )}
          <ProducerInfoDivider />

          <ProducerInfoRow
            Icon={(props: Record<string, any>) => (
              <img src={IconPinUrl} {...props} />
            )}
          >
            {store.groupamaAddress}
          </ProducerInfoRow>
          {store.phone !== "" && (
            <ProducerInfoRow
              Icon={(props: Record<string, any>) => (
                <img src={IconCallsUrl} {...props} />
              )}
            >
              {store.phone}
            </ProducerInfoRow>
          )}
          {showProductionMethod && store.productionMethod !== "" && (
            <ProducerInfoRow Icon={RiInformationLine}>
              {store.productionMethod}
            </ProducerInfoRow>
          )}

          <ButtonWrapper>
            <StyledContactButton
              href={`/producteur/fiche/${externalId}/contact`}
            >
              Contacter le producteur
            </StyledContactButton>
          </ButtonWrapper>
        </Box>
        <StyledDivider />
      </Hidden>
    </React.Fragment>
  );
}

function ProducerInfoRow({ Icon, children, ...rest }: any) {
  return (
    <Box
      display="flex"
      alignItems={"center"}
      mb={1}
      overflow={"hidden"}
      justifyContent={"flex-start"}
      {...rest}
    >
      {Icon && <Icon style={{ marginRight: 16, height: 20, width: 20 }} />}
      <Row overflow={"hidden"}>{children}</Row>
    </Box>
  );
}
