import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { Hidden, Button, Typography } from "@material-ui/core";
import { calculateProductAvailableQuantity } from "../Helpers/cart";
import { NumberFormatCustom } from "@kuupanda/commons";

// Styleds
const ButtonQuantity = styled(Button)`
  & > span {
    line-height: 0;
  }
  padding: 0px 5px;
  border-radius: 0;
  min-width: 10px;
  min-height: 10px;
  width: 20px;
  height: 20px;
`;
const QuantityStyled = styled(Typography)`
  margin: 0 5px;
  font-weight: bold;
`;
const Select = styled.select`
  background-color: ${({ theme }) => theme.palette.grey["200"]};
  border: none;
  border-radius: 8px;
  padding: 5px 8px;
  min-width: 22px;
  height: 35px;
  text-align-last: center;
`;
const Option = styled.option``;

// Helpers
function getOptions(maxSaleQuantity, { value = 1 } = {}) {
  if (!maxSaleQuantity) {
    return (
      <Option value={1} selected>
        1
      </Option>
    );
  }
  const options = [];
  for (let index = 1; index <= maxSaleQuantity; index++) {
    options.push(
      <Option value={index} selected={value === index} key={index}>
        {index}
      </Option>
    );
  }
  return options;
}

function ProductCounter({ product, quantity, onChangeCart, size }) {
  const availableQuantity = product.availableQuantity;

  const handleChangeCart = useCallback(
    ({ newQuantity }) => {
      if (newQuantity >= 0 && newQuantity <= availableQuantity) {
        onChangeCart({
          product,
          quantity: newQuantity,
          size,
        });
      }
    },
    [product, onChangeCart, availableQuantity]
  );
  const handleQuantity = useCallback(
    ({ increase }) => {
      let newQuantity = increase ? quantity + 1 : quantity - 1;

      handleChangeCart({ newQuantity });
    },
    [quantity, handleChangeCart]
  );
  const handleChangeSelectQuantity = useCallback(
    (e) => {
      handleChangeCart({ newQuantity: parseInt(e.target.value) });
    },
    [handleChangeCart]
  );

  return (
    <>
      <Hidden xsDown>
        <ButtonQuantity
          onClick={(e) => {
            handleQuantity({ increase: false });
          }}
          variant="outlined"
          color="primary"
        >
          -
        </ButtonQuantity>
        <QuantityStyled color="textPrimary" variant="body1" component="span">
          <NumberFormatCustom
            value={quantity}
            displayType={"text"}
            suffix={" "}
            fixedDecimalScale={false}
          />
        </QuantityStyled>
        <ButtonQuantity
          onClick={(e) => {
            handleQuantity({ increase: true });
          }}
          variant="outlined"
          color="primary"
        >
          +
        </ButtonQuantity>
      </Hidden>
      <Hidden smUp>
        <Select onChange={handleChangeSelectQuantity}>
          {getOptions(availableQuantity, {
            value: quantity,
          })}
        </Select>
      </Hidden>
    </>
  );
}

ProductCounter.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  onChangeCart: PropTypes.func.isRequired,
};

export default ProductCounter;
