import React from "react";
import PropTypes from "prop-types";
import { Hidden, Fab } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import ReorderIcon from "@material-ui/icons/Reorder";
import { makeStyles } from "@material-ui/core/styles";

// format_align_justify
const useStyle = makeStyles((theme) => ({
  btn: {
    position: "fixed",
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(3),
    },
    left: "50%",
    transform: "translateX(-50%)",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export function SwitchViewModeBtn({ isMap, onClick }) {
  const classes = useStyle();
  const Icon = isMap ? ReorderIcon : MapIcon;
  return (
    <Hidden mdUp>
      <Fab
        onClick={onClick}
        variant={"extended"}
        className={classes.btn}
        color={"primary"}
      >
        <Icon className={classes.icon} />
        {isMap ? "Liste" : "Carte"}
      </Fab>
    </Hidden>
  );
}

SwitchViewModeBtn.propTypes = {
  isMap: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
