import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

// Assets
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ReactComponent as Facebook } from "../../Assets/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../../Assets/Icons/instagram.svg";
import { ReactComponent as Youtube } from "../../Assets/Icons/youtube.svg";

// Components
import CartSummary from "../../Common/CartSummary";
import Logo from "../../Logo";

// Contexts
import { useGetAuth } from "../../Context/AuthContext";
import { useApiConfig } from "../../Context/ApiContext";

// Helpers
import { formatDateString } from "../../Helpers/date";
import constants from "../../Common/constants";
import constantsVendorStore from "../../VendorStore/utils/constants";

// Proptypes
import { CartCheckoutShape } from "../proptypes";

// Styled
import styled from "styled-components";
import { useBrandConfig } from "../../Context/BrandConfigContext";

const Container = styled(Grid)`
  background-color: white;
`;

const ContainerInner = styled.div`
  ${(props) => {
    if (props.width !== "xs" && props.width !== "sm") {
      return `
      margin: 0 20px;
      padding: 20px;
    `;
    }
    return `
      margin: 0;
      padding: 20px 10px;
  `;
  }}
`;

const HeaderContainer = styled(Grid)`
  text-align: center;
  color: ${(props) => props.theme.palette.grey["800"]};
`;

const TitleStyled = styled.div`
  display: flex;
  flex-wrap: ${({ width }) => (width === "xs" ? "wrap" : "nowrap")};
  align-items: flex-start;
  justify-content: center;
  margin-top: ${({ width }) => (width === "xs" ? "40px" : "60px")};
  margin-bottom: ${({ width }) => (width === "xs" ? "40px" : "20px")};
  svg {
    margin-right: 10px;
    width: 1.5em;
    height: 1.5em;
    path {
      fill: ${(props) => props.theme.palette.success.main};
    }
  }
  h5 {
    font-weight: bold;
    color: ${(props) => props.theme.palette.common.black};
  }
`;

const OrderContainer = styled(Grid)`
  background-color: ${({ theme, width }) =>
    width === "xs" ? theme.palette.grey["50"] : "inherit"};
  padding: ${({ width }) => (width === "xs" ? "20px !important" : "0")};
  margin-top: 40px;
  border-radius: ${({ width }) => (width === "xs" ? "9px" : "0")};
`;

const ProducerContainer = styled(Grid)`
  background-color: ${(props) => props.theme.palette.grey["50"]};
  box-sizing: border-box;
  border-radius: 16px;
  margin-right: ${({ width }) => (width === "xs" ? "0" : "24px")};
  padding: ${({ width }) =>
    width === "xs" ? "0 !important" : "38px 30px !important"};
`;

const FirstContainer = styled.div`
  padding: ${(props) => (props.width !== "xs" ? "0 17px" : "0")};
`;

const OrderDataContainer = styled(Grid)`
  margin-top: 30px;
`;

const ProducerData = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["800"]};
  font-style: italic;
`;

const OrderTitleTypography = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.grey["800"]};
`;

const OrderDataTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["900"]};
  div {
    margin-top: 10px;
  }
`;

const AddressTitleTypography = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.grey["700"]};
`;

const AddressDataTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["900"]};
  div {
    margin-top: 10px;
  }
`;

const AddressContainer = styled(Grid)`
  padding: ${(props) => (props.width !== "xs" ? "22px 38px" : "15px")};
  margin-top: 20px;
  background-color: ${(props) => props.theme.palette.grey["100"]};
  border-radius: 16px;
`;

const ProductsContainer = styled(Grid)`
  background-color: ${(props) => props.theme.palette.grey["100"]};
  box-sizing: border-box;
  border: ${(props) => `1px solid ${props.theme.palette.grey["600"]}`};
  border-radius: 16px;
  padding: ${(props) =>
    props.width !== "xs" ? "12px 21px" : "15px"} !important;
  margin-top: ${({ width }) => (width === "xs" ? "28px" : "0")};
  height: fit-content;
`;

const CartProductNameContainer = styled.div`
  margin-left: 12px;
`;

const CartProductTypography = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.grey["900"]};
`;

const CartProductPriceContainer = styled(Typography)`
  text-align: end;
`;

const CartTitle = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.grey["700"]};
`;

const SizeTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["700"]};
`;

const FooterContainer = styled(Grid)`
  margin-top: 40px;
`;

const FooterTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["900"]};
  text-align: center;
  margin-bottom: 10px;
`;

const FollowTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey["600"]};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  margin-top: 10px;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  svg {
    circle {
      fill: ${(props) => props.theme.palette.primary.main};
    }
    path {
      fill: ${(props) => props.theme.palette.common.white};
    }
  }
`;

function getPaymentText(payment) {
  switch (payment) {
    case constantsVendorStore.PAYMENT_METHOD.CASH:
      return "Espèces";
    case constantsVendorStore.PAYMENT_METHOD.CREDIT_CARD:
      return "Carte bancaire";
    default:
      return "";
  }
}

export default function OrderSummary({ width }) {
  const { joinedId } = useParams();
  const { auth = {}, authHttpClient } = useGetAuth();
  const { apiRoot } = useApiConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(false);
  const {
    brandConfig: { identifier },
  } = useBrandConfig();
  const isGranvillage = identifier === "granvillage";

  useEffect(() => {
    async function fetchOrders(ids) {
      setIsLoading(true);
      try {
        const qs = ["", ...ids].join("&id=").replace("&", "?");
        const response = await authHttpClient.get(`/store/form/order/${qs}`);
        setOrders(response);
      } catch (e) {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite",
        });
        setError(true);
      }
      setIsLoading(false);
    }
    const orderIds = atob(joinedId).split("|");
    fetchOrders(orderIds);
  }, [apiRoot, auth.accessToken, authHttpClient, joinedId]);

  const getCartList = useCallback((products) => {
    return products.map((product) => {
      const {
        quantity,
        size,
        productCustom: { name, price },
      } = product;
      const totalProduct = price * quantity;
      return {
        item: (
          <CartProductNameContainer>
            <CartProductTypography noWrap={true} variant="body1">
              {quantity} x {name}
            </CartProductTypography>
            {size && (
              <SizeTypography component="p" variant="caption">
                Size: {size}
              </SizeTypography>
            )}
          </CartProductNameContainer>
        ),
        price: (
          <CartProductPriceContainer component="span">
            <Typography variant="body1">{totalProduct.toFixed(2)}€</Typography>
          </CartProductPriceContainer>
        ),
      };
    });
  }, []);

  const getProductTotal = useCallback((products) => {
    return products.reduce(
      (result, { price, quantity }) => result + price * quantity,
      0
    );
  }, []);

  const getProducerData = useCallback((order) => {
    const isGroup = order.group;
    const orderData = isGroup ? order.group : order.producer;
    const { farmInfo, email, phone, address } = orderData;
    const addressText = isGroup ? address.text : farmInfo.address.text;
    const producerName = isGroup ? orderData.name : farmInfo.name;
    return { producerName, address: addressText, email, phone };
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (error) {
    return <div />;
  }

  return (
    <Container container justify="center" xs={12} direction="column" item>
      <ContainerInner width={width}>
        <Grid container item xs={12} justify="center">
          <Logo height="90" width="320" />
        </Grid>
        <HeaderContainer
          container
          item
          xs={12}
          justify="center"
          direction="column"
        >
          <TitleStyled width={width}>
            <CheckCircleIcon />
            <Typography variant="h5">
              {orders.length > 1
                ? "Vos commandes ont été passées avec succès !"
                : "Votre commande a été passée avec succès !"}
            </Typography>
          </TitleStyled>
          <Typography variant="body2" component="div">
            <div>
              {orders.length > 1
                ? "Vos commandes sont en route et vous allez recevoir un email de confirmation par le poducteur."
                : "Votre commande est en route. Vous allez recevoir un email de confirmation par le producteur."}
            </div>
            <div>Merci pour votre commande chez nous!</div>
          </Typography>
        </HeaderContainer>
        <Grid container item xs={12} justify="center">
          {orders.map((order) => {
            const { producerName, address, email, phone } =
              getProducerData(order);
            const {
              orderNumber,
              orderDueTo,
              paymentMethod,
              createdAt,
              products,
              price,
              couponLines,
              codes,
            } = order;
            const fees = order.fees.filter(
              (fee) => fee.rate !== null && fee.rate !== undefined
            );
            const formatOrderDueTo = formatDateString(orderDueTo);
            const formatCreatedAt = formatDateString(createdAt);
            const { contactPoint, clientAddress } = order;
            return (
              <OrderContainer
                container
                key={order.id}
                item
                xs={12}
                spacing={2}
                justify="space-between"
                width={width}
              >
                <ProducerContainer item xs={12} md={7} width={width}>
                  <FirstContainer width={width}>
                    <Grid item>
                      <Typography variant="h3">
                        <span>{producerName}</span>
                      </Typography>
                      <ProducerData variant="body2" component="div">
                        <div>{email}</div>
                        <div> {address}</div>
                        <div>{phone}</div>
                      </ProducerData>
                    </Grid>
                    <OrderDataContainer
                      container
                      justify="space-between"
                      direction="row"
                    >
                      <Grid item>
                        <OrderTitleTypography variant="body1">
                          Date de commande
                        </OrderTitleTypography>
                        <OrderDataTypography variant="body2">
                          {formatCreatedAt}
                        </OrderDataTypography>
                      </Grid>
                      <Grid item>
                        <OrderTitleTypography variant="body1">
                          Numéro de commande
                        </OrderTitleTypography>
                        <OrderDataTypography variant="body2">
                          {orderNumber}
                        </OrderDataTypography>
                      </Grid>
                      <Grid item>
                        <OrderTitleTypography variant="body1">
                          Moyen de paiement
                        </OrderTitleTypography>
                        <OrderDataTypography variant="body2">
                          {getPaymentText(paymentMethod)}
                        </OrderDataTypography>
                      </Grid>
                    </OrderDataContainer>
                  </FirstContainer>
                  <AddressContainer
                    container
                    justify="space-between"
                    direction="row"
                    width={width}
                  >
                    {contactPoint.type ===
                    constants.CONTACT_POINT_TYPE.TAKE_AWAY ? (
                      <>
                        <Grid item>
                          <AddressTitleTypography variant="body1">
                            Adresse du point de retrait
                          </AddressTitleTypography>
                          <AddressDataTypography variant="body2">
                            {contactPoint.address.text}
                          </AddressDataTypography>
                        </Grid>
                        <Grid item>
                          <AddressTitleTypography variant="body1">
                            Date de retrait
                          </AddressTitleTypography>
                          <AddressDataTypography variant="body2">
                            {formatOrderDueTo}
                          </AddressDataTypography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <AddressTitleTypography variant="body1">
                            Adresse de livraison
                          </AddressTitleTypography>
                          <AddressDataTypography variant="body2">
                            {clientAddress.text}
                          </AddressDataTypography>
                        </Grid>
                        <Grid item>
                          <AddressTitleTypography variant="body1">
                            Date de livraison
                          </AddressTitleTypography>
                          <AddressDataTypography variant="body2">
                            estimé au {formatOrderDueTo}
                          </AddressDataTypography>
                        </Grid>
                      </>
                    )}
                  </AddressContainer>
                </ProducerContainer>
                <ProductsContainer item xs={12} md={4} width={width}>
                  <CartTitle variant="body1">
                    Récapitulatif de la commande
                  </CartTitle>
                  <CartSummary
                    productTotal={getProductTotal(products)}
                    total={price}
                    cartList={getCartList(products)}
                    fees={fees}
                    cellStyles={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingTop: 5,
                      verticalAlign: "top",
                      borderBottom: "none",
                    }}
                    columns={[
                      {
                        name: "item",
                        title: "Article",
                      },
                      {
                        name: "price",
                        title: "Prix",
                      },
                    ]}
                    columnExtensions={[
                      {
                        columnName: "item",
                        align: "left",
                        width: "70%",
                      },
                      {
                        columnName: "price",
                        align: "center",
                      },
                    ]}
                    showDisabledCoupon={true}
                    showCoupon={false}
                    coupon={codes.join(",")}
                    totalVariant="body1"
                    couponLines={couponLines}
                  />
                </ProductsContainer>
              </OrderContainer>
            );
          })}
        </Grid>
        <FooterContainer
          container
          item
          xs={12}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <FooterTypography variant="h3">
              Encore merci pour votre commande. À très vite !
            </FooterTypography>
            <FollowTypography>suivez nous</FollowTypography>
          </Grid>
          <Grid item>
            <ExternalLink
              target="_blank"
              href={
                isGranvillage
                  ? "https://www.instagram.com/granvillage/"
                  : "https://www.instagram.com/kuupanda"
              }
            >
              <Instagram style={{ paddingRight: 5 }} />
            </ExternalLink>
            {!isGranvillage && (
              <ExternalLink
                target="_blank"
                href="https://www.youtube.com/channel/UCLqNY_p1wDHpy7IXlOz8tCw"
              >
                <Youtube style={{ paddingRight: 5 }} />
              </ExternalLink>
            )}
            <ExternalLink
              target="_blank"
              href={
                isGranvillage
                  ? "https://www.facebook.com/granvillage/"
                  : "https://www.facebook.com/kuupandafrance"
              }
            >
              <Facebook />
            </ExternalLink>
          </Grid>
          {isGranvillage ? (
            <ExternalLink href="/">
              <Button color="primary" variant="contained">
                Retour sur le site
              </Button>
            </ExternalLink>
          ) : (
            <LinkStyled to="/">
              <Button color="primary" variant="contained">
                Retour sur le site
              </Button>
            </LinkStyled>
          )}
        </FooterContainer>
      </ContainerInner>
    </Container>
  );
}

OrderSummary.propTypes = {
  cart: CartCheckoutShape.isRequired,
};

OrderSummary.defaultProps = {
  cart: {},
};
