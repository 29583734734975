export interface CartProducer {
  id: string;
  name: string;
  storeName: string;
  clientId: string;
  email: string;
  phone: string;
  clientSlug: string;
  isGroup: boolean;
  paymentMethods: any;
  address: string;
  contactPoints: any;
}

export class CartProducerDto {
  id: string;
  name: string;
  storeName: string;
  clientId: string;
  email: string;
  phone: string;
  clientSlug: string;
  isGroup: boolean;
  paymentMethods: any;
  address: string;
  contactPoints: any;

  constructor(props: CartProducer) {
    this.id = props.id;
    this.name = props.name;
    this.storeName = props.storeName;
    this.clientId = props.clientId;
    this.email = props.email;
    this.phone = props.phone;
    this.clientSlug = props.clientSlug;
    this.isGroup = props.isGroup;
    this.paymentMethods = props.paymentMethods;
    this.address = props.address;
    this.contactPoints = props.contactPoints;
  }
}

export interface CartProducts {
  id: string;
  baseUnit: string;
  name: string;
  price: number;
  basePrice: string;
  quantity: number;
  weight: number;
  stock: number;
  maxSalesQuantity: number;
  availableQuantity: number;
  vat: number;
  availableDate: Date;
  size: string;
  preparationType: string;
  preparationTime: number;
  picture: any;
  ageVerification: boolean;
  childProducts: any;
  storage: string;
  excludedContactPointTypes: string[];
  producer: CartProducer;
}

export class CartProductDto {
  id: string;
  baseUnit: string;
  name: string;
  price: number;
  basePrice: string;
  quantity: number;
  weight: number;
  stock: number;
  maxSalesQuantity: number;
  availableQuantity: number;
  vat: number;
  availableDate: Date;
  size: string;
  preparationType: string;
  preparationTime: number;
  picture: any;
  ageVerification: boolean;
  childProducts: any;
  storage: string;
  excludedContactPointTypes: string[];
  producer: CartProducer;

  constructor(props: CartProducts) {
    this.id = props.id;
    this.baseUnit = props.baseUnit;
    this.name = props.name;
    this.price = props.price;
    this.basePrice = props.basePrice;
    this.quantity = props.quantity;
    this.weight = props.weight;
    this.stock = props.stock;
    this.maxSalesQuantity = props.maxSalesQuantity;
    this.availableQuantity = props.availableQuantity;
    this.vat = props.vat;
    this.availableDate = props.availableDate;
    this.size = props.size;
    this.preparationType = props.preparationType;
    this.preparationTime = props.preparationTime;
    this.picture = props.picture;
    this.ageVerification = props.ageVerification;
    this.childProducts = props.childProducts;
    this.storage = props.storage;
    this.excludedContactPointTypes = props.excludedContactPointTypes;
    this.producer = new CartProducerDto(props.producer);
  }
}
