import { useMemo } from "react";
import httpClient from "../httpClient";

function getFullUrl(baseUrl, path) {
  return `${baseUrl}${path}`;
}

export function useHttpClient(baseUrl, { accessToken } = {}) {
  return useMemo(() => {
    const headers = {};
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    return {
      get: async (path) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.get(url, {
          headers,
        });
        return res.data;
      },
      post: async (path, body) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.post(url, body, {
          headers,
        });
        return res.data;
      },
      patch: async (path, body) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.patch(url, body, {
          headers,
        });
        return res.data;
      },
    };
  }, [baseUrl, accessToken]);
}
