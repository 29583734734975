import { AdobeLaunch } from "./AdobeLaunch";
import React from "react";

export interface ProductByCategory {
  category: string;
  count: number;
  products: Product[];
}

export interface CartItem {
  quantity: number;
  product: Product;
  size: null;
  errors: any[];
  promos: any[];
}

export interface Product {
  category: string;
  name: string;
  baseProduct: string;
  baseUnit: BaseUnit;
  saleChannels: SaleChannel[];
  id: string;
  preparationTime: number;
  tags: any[];
  maxSaleQuantity: number;
  childProducts: any[];
  producer: Producer;
  isProductGroup: boolean;
  price: string;
  picture: PictureElement;
  pictures: PictureElement[];
  preparationType: string;
  basePrice: number;
  priceReduction: number;
  discounts: any[];
  weight: number;
  storage: Storage;
  excludedContactPointTypes: any[];
  ageVerification: boolean;
  availableQuantity: number;
  priceBeforeReduction: number;
  vat: number;
  active: boolean;
  highlight: Highlight;
  sizes: any[];
  metadata: any;
  description?: string;
  stock?: number;
}

export enum BaseUnit {
  Kg = "kg",
  Unit = "unit",
}

export enum Highlight {
  None = "NONE",
}

export interface PictureElement {
  url: string;
  default?: boolean;
  _id?: string;
  id?: string;
  contentType?: string;
  type?: string;
  size?: string;
}

export interface Producer {
  id: string;
  storeName: string;
}

export enum SaleChannel {
  STANDARD = "STANDARD",
  PROFESSIONAL = "PROFESSIONAL",
}

export interface Store {
  email: string;
  name: string;
  lastName: string;
  phone: string;
  id: string;
  bannerUrl: string;
  profileUrl: string;
  clientId: string;
  storeName: string;
  fullName: string;
  city: string;
  address: string;
  presentation: string;
  clientClosedStore: boolean;
  closedStoreMessage: null;
  paymentMethods: PaymentMethod[];
  isGroup: boolean;
  saleChannel: string;
  component: string;
  ID: string;
}

export enum FunnelStepKey {
  AUTH = "auth",
  CART = "basket",
  ADDRESS = "address",
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

export interface FunnelStep {
  completed: boolean;
  component: React.ReactNode;
  label: string;
  key: FunnelStepKey;
}
export interface PaymentMethod {
  type: string;
}

export class Tracking {
  static init() {
    AdobeLaunch.try.init();
  }

  static trackFunnelSteps(step: FunnelStepKey) {
    AdobeLaunch.try.trackFunnelSteps(step);
  }

  static trackCartInfo({
    cart,
    contactPointByProducer,
    cartTotals,
  }: {
    cart: Record<string, CartItem>;
    contactPointByProducer: Record<string, { type: string }>;
    cartTotals: {
      productTotal: string;
      feesTotal: string;
      discountTotal: string;
      taxes: string;
      total: string;
    };
  }) {
    AdobeLaunch.try.trackCartInfo({
      cart,
      contactPointByProducer,
      cartTotals,
    });
  }

  static trackProducerPageInfo({
    store,
    productByCategory,
  }: {
    store: Store;
    productByCategory: ProductByCategory[];
  }) {
    AdobeLaunch.try.trackProducerPageInfo({
      store,
      productByCategory,
    });
  }

  static trackAnonymousConnection() {
    AdobeLaunch.try.trackAnonymousConnection();
  }

  static trackUserLogin() {
    AdobeLaunch.try.trackUserLogin();
  }

  static trackTransactionId(joinedIds: string) {
    AdobeLaunch.try.trackTransactionId(joinedIds);
  }
}
