import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Grid, Button, Hidden } from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useMediaQuery } from "@material-ui/core";
import InputStepper from "./InputStepper";
import SelectInput from "./SelectInput";
import { useCart } from "../Context/CartContext";

const Controls = styled.div`
  background-color: ${({ theme }) => theme.palette.grey["900"]};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 10px;
  padding: 10px 5px;
`;

const AddBasketIcon = styled(ShoppingBasketIcon)`
  margin-left: 10px;
`;

const AddBasketIconButton = styled(Button)`
  font-size: 16px;
  text-transform: inherit;
  &.Mui-disabled {
    color: white;
    background-color: gray;
  }
`;

const Background = styled.div`
  ${(props) =>
    props.$isMobile &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props.theme.palette.grey["900"]};
    z-index: 9999;
  `}
`;

const NotificationStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  bottom: 10px;
  ${(props) =>
    props.$isDialog &&
    `right: 0; bottom:0;
  `};
  opacity: 0.8;
  backdrop-filter: blur(15px);
  border-radius: 3px;
  color: white;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  z-index: 999;
  ${(props) =>
    props.$isDialog &&
    props.$isMobile &&
    `
    top: 50%;
    left: 50%;
    width: 249px;
    height: 200px;
    transform: translate(-50%, -50%);
  `}
`;
const SuccessContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey["900"]};
  ${NotificationStyles}
`;

const SuccessMessage = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  font-size: 80px;
`;

const ErrorContainer = styled.div`
  background: ${({ theme }) => theme.palette.error.main};
  ${NotificationStyles}
`;

const ErrorMessage = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const ErrorIcon = styled(RemoveCircleIcon)`
  font-size: 30px;
`;

const Spacer = styled(Grid)``;

export const QuantityEmbedded = ({
  availableQuantity,
  id,
  onChange,
  product,
  value,
}) => {
  const { removeFromCart } = useCart();

  const [controlsOpen, setControlsOpen] = useState(false);
  const [quantity, setQuantity] = useState(value);
  const [productAdded, setProductAdded] = useState(false);
  const [size, setSize] = useState(null);
  const [noStock, setNoStock] = useState(false);

  const hasSizes =
    product.productSizesList && product.productSizesList.length > 0;

  useEffect(() => {
    if (!size && hasSizes) {
      setSize(product.productSizesList[0]);
    }
  }, [product, size, hasSizes]);

  return useMemo(() => {
    function addToBasket() {
      if (!quantity) {
        removeFromCart(product.id);
      } else {
        onChange({
          product,
          quantity,
          size,
        });
      }
      setProductAdded(true);
      //hide after 2 seconds
      setTimeout(() => {
        setProductAdded(false);
      }, 2000);

      setControlsOpen(false);
    }

    function showNoStock() {
      setNoStock(true);

      //hide after 2 seconds
      setTimeout(() => {
        setNoStock(false);
      }, 2000);
    }

    return (
      <>
        {controlsOpen ? (
          <Controls>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={hasSizes ? 6 : 12}
                style={{ position: "relative" }}
              >
                <InputStepper
                  id={`${id}-quantity-selector`}
                  onChange={setQuantity}
                  value={quantity}
                  step={product.baseUnit === "unit" ? 1 : 0.001}
                  availableQuantity={availableQuantity}
                  onReachMaxQuantity={showNoStock}
                />
              </Grid>
              {hasSizes && (
                <Grid item xs={12} md={hasSizes ? 6 : 12}>
                  <SelectInput
                    id={`${id}-size-selector`}
                    value={size}
                    onChange={setSize}
                    options={product.productSizesList}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AddBasketIconButton
                  fullWidth
                  style={{ marginTop: 4 }}
                  variant="contained"
                  color="primary"
                  disabled={quantity <= 0}
                  onClick={addToBasket}
                >
                  Ajouter au panier
                  <AddBasketIcon />
                </AddBasketIconButton>
              </Grid>
            </Grid>
          </Controls>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={
              Number.isFinite(product.availableQuantity) &&
              product.availableQuantity <= 0
            }
            onClick={() => setControlsOpen(!controlsOpen)}
          >
            <ShoppingBasketIcon />
          </Button>
        )}
        {productAdded && (
          <SuccessContainer>
            <SuccessIcon />
            <SuccessMessage>
              Produit ajouté au panier avec succès !
            </SuccessMessage>
          </SuccessContainer>
        )}
        {noStock && (
          <ErrorContainer>
            <ErrorIcon />
            <ErrorMessage>Quantité maximum atteinte !</ErrorMessage>
          </ErrorContainer>
        )}
      </>
    );
  }, [
    availableQuantity,
    id,
    product,
    controlsOpen,
    quantity,
    size,
    onChange,
    hasSizes,
    productAdded,
    noStock,
    removeFromCart,
  ]);
};

const Container = styled.div`
  margin-top: 20px;
`;

export const Quantity = ({
  availableQuantity,
  id,
  onChange,
  product,
  value,
}) => {
  return useMemo(() => {
    return (
      <InputStepper
        id={`${id}-quantity-selector`}
        onChange={(value) => onChange({ product, quantity: parseFloat(value) })}
        value={value}
        disabled={availableQuantity <= 0}
        step={product.baseUnit === "unit" ? 1 : 0.001}
        availableQuantity={availableQuantity}
      />
    );
  }, [availableQuantity, id, onChange, product, value]);
};

export const QuantityModal = ({
  id,
  onChange,
  product,
  value,
  hideAddModal,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { removeFromCart } = useCart();

  const [quantity, setQuantity] = useState(value);
  const [size, setSize] = useState(null);
  const [productAdded, setProductAdded] = useState(false);
  const [noStock, setNoStock] = useState(false);

  const hasSizes =
    product.productSizesList && product.productSizesList.length > 0;

  useEffect(() => {
    if (!size && hasSizes) {
      setSize(product.productSizesList[0]);
    }
  }, [product, size, hasSizes]);

  return useMemo(() => {
    async function addToBasket() {
      if (!quantity) {
        removeFromCart(product.id);
      } else {
        onChange({
          product,
          quantity,
          size,
        });
      }

      if (!hideAddModal) {
        setProductAdded(true);

        //hide after 4 seconds
        setTimeout(() => {
          setProductAdded(false);
        }, 2000);
      }
    }

    async function showNoStock() {
      setNoStock(true);

      //hide after 4 seconds
      setTimeout(() => {
        setNoStock(false);
      }, 2000);
    }

    return (
      <Container>
        <Grid container spacing={1}>
          {hasSizes && (
            <Grid item xs={12} md={2}>
              <SelectInput
                id={`${id}-size-selector`}
                value={size}
                onChange={setSize}
                options={product.productSizesList}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={3} style={{ position: "relative" }}>
            <InputStepper
              id={`${id}-quantity-selector`}
              onChange={setQuantity}
              value={quantity}
              step={product.baseUnit === "unit" ? 1 : 0.001}
              availableQuantity={product.availableQuantity}
              onReachMaxQuantity={showNoStock}
            />
          </Grid>
          <Hidden smDown>
            <Spacer item sm={4} md={hasSizes ? 2 : 4} />
          </Hidden>
          {/* MUI doesnt support offsets, so this is here to replicate that*/}
          <Grid item xs={12} sm={12} md={5}>
            <AddBasketIconButton
              fullWidth
              variant="contained"
              color="primary"
              disabled={quantity <= 0}
              onClick={addToBasket}
            >
              Ajouter au panier
              <AddBasketIcon />
            </AddBasketIconButton>
          </Grid>
        </Grid>

        {productAdded && (
          <Background $isMobile={isMobile}>
            <SuccessContainer $isDialog={true} $isMobile={isMobile}>
              <SuccessIcon />
              <SuccessMessage>
                Produit ajouté au panier avec succès !
              </SuccessMessage>
            </SuccessContainer>
          </Background>
        )}

        {noStock && (
          <Background $isMobile={isMobile}>
            <ErrorContainer $isDialog={true} $isMobile={isMobile}>
              <ErrorIcon />
              <ErrorMessage>Quantité maximum atteinte !</ErrorMessage>
            </ErrorContainer>
          </Background>
        )}
      </Container>
    );
  }, [
    id,
    product,
    quantity,
    size,
    onChange,
    hasSizes,
    productAdded,
    noStock,
    isMobile,
    hideAddModal,
    removeFromCart,
  ]);
};
