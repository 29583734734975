import _LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  Collapse,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import Schedule from "./Schedule";
import _ScheduleIcon from "@material-ui/icons/Schedule";
import _ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ExpandLessIcon from "@material-ui/icons/ExpandLess";
import _CategoryIcon from "@material-ui/icons/CategoryOutlined";
import _MenuBookIcon from "@material-ui/icons/MenuBook";
import { getProductCountText, withTextSecondary } from "../utils";
import { withResponsiveTarget } from "../withResponsiveTarget";
import AvatarWrapper from "../List/AvatarWrapper";
import constants from "../../Common/constants";

const ScheduleIcon = withTextSecondary(_ScheduleIcon);
const CategoryIcon = withTextSecondary(_CategoryIcon);
const ExpandLessIcon = withTextSecondary(_ExpandLessIcon);
const ExpandMoreIcon = withTextSecondary(_ExpandMoreIcon);
const LocalShippingIcon = withTextSecondary(_LocalShippingIcon);
const MenuBookIcon = withTextSecondary(_MenuBookIcon);

const ResponsiveLink = withResponsiveTarget(Link);

const useRowStyle = makeStyles((theme) => ({
  secondaryItem: {
    paddingLeft: theme.spacing(3),
    alignItems: "start",
  },
  secondaryIcon: {
    marginTop: theme.spacing(0.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  titleLink: {
    display: "flex",
    alignItems: "center",
  },
  itemDivider: {
    borderTop: (props) =>
      props.isFirstItem ? 0 : "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: 0,
  },
}));
const stopPropagation = (e) => e.stopPropagation();

export function ClusterConflictRow({ marker, user, isFirstItem }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const classes = useRowStyle({ isFirstItem });
  return (
    <React.Fragment>
      <ListItem
        button
        divider
        onClick={handleExpandClick}
        classes={{ divider: classes.itemDivider }}
      >
        <ListItemAvatar>
          <AvatarWrapper
            alt={user.displayName}
            height={40}
            size="40px"
            profilePicture={user.profilePicture}
          />
        </ListItemAvatar>
        <ListItemText color={"primary"} secondary={marker.name}>
          <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.title}
          >
            <ResponsiveLink
              className={classes.titleLink}
              component={RouterLink}
              to={`/boutique${
                user.isGroup
                  ? `/${constants.STORE_TYPE.GROUP}`
                  : `/${constants.STORE_TYPE.PRODUCER}`
              }/${user.storeSlug}`}
              onClick={stopPropagation}
            >
              {marker.storeName}
              <LaunchIcon fontSize="small" style={{ marginLeft: 3 }} />
            </ResponsiveLink>
          </Typography>
        </ListItemText>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <List disablePadding>
        <Collapse in={expanded}>
          <SecondaryRow icon={<ScheduleIcon />}>
            <Schedule schedule={marker.schedule} />
          </SecondaryRow>
          <SecondaryRow icon={<MenuBookIcon />}>
            {getProductCountText(user.productCount)}
          </SecondaryRow>
          {user.categories.length ? (
            <SecondaryRow icon={<CategoryIcon />}>
              <ul style={{ paddingLeft: 20 }}>
                {user.categories.map((category) => (
                  <li key={category} style={{ textAlign: "start" }}>
                    {category}
                  </li>
                ))}
              </ul>
            </SecondaryRow>
          ) : null}
          {marker.doesDelivery && (
            <SecondaryRow icon={<LocalShippingIcon />}>
              Ce producteur propose également la livraison
            </SecondaryRow>
          )}
        </Collapse>
      </List>
    </React.Fragment>
  );
}

const useSecondaryRowStyle = makeStyles((theme) => ({
  secondaryItem: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: "start",
  },
  secondaryIcon: {
    marginTop: theme.spacing(0.5),
  },
}));
const SecondaryRow = React.memo(function SecondaryRow({ icon, children }) {
  const classes = useSecondaryRowStyle();
  return (
    <ListItem className={classes.secondaryItem}>
      <ListItemIcon className={classes.secondaryIcon}>{icon}</ListItemIcon>
      <ListItemText>
        <Typography variant="body2" component="span">
          {children}
        </Typography>
      </ListItemText>
    </ListItem>
  );
});
