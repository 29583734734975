import PropTypes from "prop-types";
import React from "react";

export default function Map({ lat, lng }) {
  const mapType = "roadmap";
  const mapZoom = 15;
  const query = `${lat},${lng}`;
  return (
    <div style={{ height: `100%` }}>
      <iframe
        title="contactPointLocation"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: "0" }}
        src={`https://www.google.com/maps/embed/v1/place?zoom=${mapZoom}&maptype=${mapType}&q=${query}&key=AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg`}
      />
    </div>
  );
}
Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};
