import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { AutoCompleteLocationInput } from "../../Common/AutoCompleteLocationInput";
import {
  SecondaryActionButton,
  withSecondaryIcon,
} from "./SecondaryActionButton";
import EditIcon from "@material-ui/icons/Edit";
const EditIconStyle = withSecondaryIcon("left")(EditIcon);

export function LocationFilter({
  value,
  onChange,
  onSubmit,
  isEditMode,
  onEditRequest,
  onStopEditRequest,
  dense,
}) {
  if (isEditMode)
    return (
      <AutoCompleteLocationInput
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
        onBlur={onStopEditRequest}
        margin={dense ? "dense" : "normal"}
        label={"Indiquer votre ville"}
      />
    );
  return (
    <Box
      mb={1}
      onClick={onEditRequest}
      component={Grid}
      container
      style={{ cursor: "pointer" }}
      alignItems={"center"}
    >
      <Grid item>
        <Typography variant={"h6"}>
          <b>{value.address}</b>
          {/*<span>&nbsp;&nbsp;-</span>*/}
        </Typography>
      </Grid>
      <Grid item>
        <SecondaryActionButton
          size="small"
          style={{
            marginLeft: 8,
          }}
        >
          <EditIconStyle />
          Changer la ville
        </SecondaryActionButton>
      </Grid>
    </Box>
  );
}

LocationFilter.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditRequest: PropTypes.func.isRequired,
  onStopEditRequest: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
