import styled, { css } from "styled-components";
import Paper from "@material-ui/core/Paper";

export const baseMargin = css`
  margin-top: 16px;
  margin-bottom: 16px;
  @media (min-width: 1280px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;
export const InnerPaper = styled(Paper)`
  padding: 15px 25px 30px;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 15px 10px;
  }
`;
