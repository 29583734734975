import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// MAterial Components
import { Grid, Typography } from "@material-ui/core";

// Components
import Input from "../../Common/Input";
import { Link } from "react-router-dom";
import Title from "./Title";
import { useBrandConfig } from "../../Context/BrandConfigContext";

const Container = styled(Grid)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey["100"]};
    .disabled-input {
      background-color: ${theme.palette.primary.contrastText};
    }
  `}
  padding: 26px 40px;
  border-radius: 8px;
`;

const GridTitle = styled(Grid)`
  margin-bottom: 32px;
`;

const GridEditText = styled(Grid)`
  margin-top: 24px;
`;

const EditText = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.grey["900"]};
    a {
      color: ${theme.palette.primary.main};
      text-decoration: underline;
    }
  `}
`;

export default function DisabledForm({ name, lastName, email, phone }) {
  const {
    brandConfig: { identifier },
  } = useBrandConfig();
  const isGranvillage = identifier === "granvillage";
  return (
    <Container container>
      <GridTitle container item xs={12}>
        <Title>Mes informations</Title>
      </GridTitle>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={3}>
          <Input
            className="disabled-input"
            disabled={true}
            label="Prénom"
            fullWidth={true}
            id="name"
            name="name"
            value={name}
            type="text"
            withoutBorders={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            className="disabled-input"
            disabled={true}
            label="Nom"
            fullWidth={true}
            id="lastName"
            name="lastName"
            value={lastName}
            type="text"
            withoutBorders={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            className="disabled-input"
            disabled={true}
            label="Adresse de messagerie"
            fullWidth={true}
            id="email"
            name="email"
            value={email}
            type="text"
            withoutBorders={true}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Input
            className="disabled-input"
            disabled={true}
            fullWidth={true}
            label="Téléphone"
            id="phone"
            name="phone"
            value={phone}
            required
            type="text"
            withoutBorders={true}
          />
        </Grid>
      </Grid>
      <GridEditText container item xs={12} justify="flex-end">
        <EditText variant="body2">
          Ces informations sont modifiables depuis{" "}
          {!isGranvillage ? (
            <Link to="/profile/informations">votre compte</Link>
          ) : (
            <a href="/consommateur/profil/informations-personnelles">
              votre compte
            </a>
          )}
        </EditText>
      </GridEditText>
    </Container>
  );
}

DisabledForm.propTypes = {
  name: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};
DisabledForm.defaultProps = {
  name: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
};
