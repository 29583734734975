import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

// Components
import Button from "../../Common/Button";
import media from "../../Helpers/media";

// styled
const Container = styled.div`
  position: absolute;
  top: 547px;
  left: 20px;
  right: 20px;
  ${media.down.md`
    background: ${({ theme }) => theme.palette.success.light};
    padding: 20px;
    border-radius: 10px;
  `}
  ${media.down.sm`
    background: none;
    padding: inherit;
    border-radius: 10px;
  `}
`;

const StyledActionButton = styled(Button)`
  width: 100%;
`;

const ActionBoxTitle = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const ActionBoxContent = styled(Typography)`
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const ActionBox = () => {
  return (
    <Container>
      <ActionBoxTitle variant="h5" gutterBottom>
        Vous êtes producteurs ?
      </ActionBoxTitle>
      <ActionBoxContent gutterBottom>
        Vendez vos produits en direct et profitez d’un outil de gestion complet
        et intuitif !
      </ActionBoxContent>
      <StyledLink
        href="https://gestion.kuupanda.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <StyledActionButton
          component="button"
          styled="transparent"
          size="large"
        >
          Découvrez l’outil de gestion
        </StyledActionButton>
      </StyledLink>
    </Container>
  );
};

export default ActionBox;
