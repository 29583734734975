import { ScrollTopContainer } from "./ScrollTopContainer";
import { Fab, useMediaQuery, useTheme } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";

export function ScrollTopBtn() {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  return (
    <ScrollTopContainer>
      <Fab
        color="secondary"
        size={isSM ? "small" : "medium"}
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTopContainer>
  );
}
