import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  OutlinedInput,
  InputAdornment,
  Hidden,
  withWidth,
} from "@material-ui/core";
import { EmailOutlined, Send } from "@material-ui/icons";

// helpers
import media from "../../Helpers/media";

// Components
import Button from "../../Common/Button";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

// styled
const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: white;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
`;

const StyledSearchButton = styled(Button)`
  ${({ theme }) => `
    background: ${theme.palette.success.main};
  `};
  border-radius: 0px 8px 8px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  padding: 100px;
  text-align: center;
  ${({ theme }) => `
    background: ${theme.palette.success.light};
  `};
  ${media.down.lg`
    padding: 40px;
  `}
  ${media.only.lg`
    padding: 80px;
  `}
`;

const NewsletterHeading = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
`;
const StyledAlert = styled(Alert)`
  text-align: left;
`;
const StyledAlertTitle = styled(AlertTitle)`
  font-weight: bold;
`;
const NewsletterSubHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  padding-bottom: 100px;
  ${media.down.lg`
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 20px;
  `}
`;

const MailchimpFormContainer = styled(Grid)``;

function MailchimpForm({ status, message, isMobile, onValidated }) {
  const [emailValue, setEmailValue] = useState("");
  const submit = useCallback(
    (event) => {
      event.preventDefault();
      onValidated({
        EMAIL: emailValue,
      });
    },
    [emailValue, onValidated]
  );
  return (
    <form onSubmit={submit}>
      <MailchimpFormContainer container spacing={0} justify="center">
        {status && (
          <Grid item md={8} xs={12}>
            {status === "sending" && (
              <StyledAlert variant="outlined" severity="info">
                Envoi en cours...
              </StyledAlert>
            )}
            {status === "error" && (
              <StyledAlert variant="outlined" severity="error">
                <StyledAlertTitle>Erreur</StyledAlertTitle>
                <span dangerouslySetInnerHTML={{ __html: message }} />
              </StyledAlert>
            )}
            {status === "success" && (
              <StyledAlert variant="outlined" severity="success">
                <StyledAlertTitle>Super!</StyledAlertTitle>
                Nous vous avons envoyé un email pour confirmer votre abonnement.
              </StyledAlert>
            )}
          </Grid>
        )}
        {!status && (
          <>
            <Grid item md={6} xs={10}>
              <StyledOutlinedInput
                onChange={(e) => setEmailValue(e.target.value)}
                value={emailValue}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                }
                required
                type="email"
                placeholder="Adresse email"
              />
            </Grid>
            <Grid item md={2} xs={2}>
              <StyledSearchButton
                component="button"
                variant="contained"
                color="primary"
                type="submit"
              >
                {isMobile ? <Send /> : "S’inscrire"}
              </StyledSearchButton>
            </Grid>
          </>
        )}
      </MailchimpFormContainer>
    </form>
  );
}

const Newsletter = ({ width }) => {
  const isMobile = useMemo(
    () => width === "xs" || width === "sm" || width === "md",
    [width]
  );

  return (
    <Container>
      <Hidden mdDown>
        <NewsletterHeading variant="h5">
          Vous aimez le projet ? Vous souhaitez en savoir plus sur les
          nouveautés à venir ?
        </NewsletterHeading>
      </Hidden>
      <NewsletterSubHeading variant="h4">
        Inscrivez vous à notre newsletter:
      </NewsletterSubHeading>
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            isMobile={isMobile}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </Container>
  );
};

export default withWidth()(Newsletter);
