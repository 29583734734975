/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=600fee62251ad50c97d44b23"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("../js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class AboutUsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;
    Controller = AboutUsView;

    return Controller;
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "602e63144ee51443f4e0541c";
    htmlEl.dataset["wfSite"] = "600fee62251ad50c97d44b23";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      AboutUsView.Controller !== AboutUsView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/kuupanda-gestion-07a594.webflow.css);


          body{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-title-section af-class-no-bottom-space af-class-bg-primary">
              <div className="af-class-container-testimonials af-class-text-center">
                <h1 className="af-class-text-white">
                  Une équipé dédiée à remettre le local au centre des habitudes
                  de consommation
                </h1>
              </div>
              <img
                src="../images/divider-round-bottom.svg"
                alt
                className="af-class-divider af-class-divider-bottom-large"
              />
              <div className="af-class-decoration-container af-class-left-bottom">
                <div className="af-class-decorations-wrapper">
                  <div className="af-class-decoration-circle af-class-top-right af-class-bg-primary-2" />
                </div>
              </div>
            </div>
            <div className="af-class-section-about">
              <div className="af-class-container-testimonials">
                <div className="af-class-content-pair-2-about">
                  <div
                    data-w-id="c7bbc2b6-2d55-59d8-347c-5b19f3d0e402"
                    style={{ opacity: 0 }}
                    className="af-class-content-pair-text-about"
                  >
                    <h2 className="af-class-heading-8">
                      Notre mission - Vous faire découvrir les producteurs
                      proches de chez vous
                    </h2>
                    <p className="af-class-paragraph-large af-class-text-large">
                      A l'origine de Kuupanda, un constat:&nbsp;La difficulté de
                      s'approvisionner en produits locaux.&nbsp;Il y a des
                      producteurs partout en France, à quelques kilomètres de
                      chez nous.&nbsp;Mais souvent nous ne les connaissons pas,
                      et à moins d'aller régulièrement sur leur exploitation,
                      c'est compliqué de leur commander des produits.
                      <br />
                      <br />
                      Kuupanda c'est donc donné pour objectif de regrouper le
                      plus grand nombre de producteurs sur une plateforme, pour
                      vous permettre de trouver facilement ceux qui sont autour
                      de chez vous et commander leurs produits directement en
                      ligne.&nbsp;Nous voulons permettre au plus grand nombre de
                      commander auprès de producteurs respectueux de leurs
                      produits et de l'environnement et ainsi remettre le local
                      dans les habitudes de consommation.
                      <br />
                      <br />‍<br />
                      <br />
                      <br />
                    </p>
                  </div>
                  <img
                    src="../images/Illu-farmer.png"
                    width={486}
                    style={{ opacity: 0 }}
                    data-w-id="ccbfdc57-e877-201e-b343-944af19ff98f"
                    alt
                    className="af-class-content-pair-image-about"
                  />
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-no-bottom-space af-class-bg-primary-3">
              <div className="af-class-container-testimonials">
                <div
                  data-w-id="e7cd660e-7b6d-a7df-ce69-350a0d5a2564"
                  style={{ opacity: 0 }}
                  className="af-class-large-metrics af-class-text-center"
                >
                  <div className="af-class-large-metric-item">
                    <div className="af-class-text-huge-about af-class-text-white">
                      2018
                    </div>
                    <div className="af-class-text-large">
                      Début du projet.&nbsp;Des premiers producteurs et des
                      premières commandes
                    </div>
                  </div>
                  <div className="af-class-large-metric-item">
                    <div className="af-class-text-huge-about af-class-text-white">
                      14
                    </div>
                    <div className="af-class-text-large">
                      Présents dans 14&nbsp;départements en France
                    </div>
                  </div>
                  <div className="af-class-large-metric-item">
                    <div className="af-class-text-huge-about af-class-text-white">
                      340
                    </div>
                    <div className="af-class-text-large">
                      Le nombre de producteur présent sur la plateforme à ce
                      jour
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="../images/divider-round-bottom.svg"
                alt
                className="af-class-divider af-class-divider-bottom"
              />
            </div>
            <div className="af-class-section-avatar af-class-no-bottom-space af-class-bg-gray-4">
              <div className="af-class-container-testimonials af-class-text-center">
                <div className="af-class-section-title">
                  <h2 className="af-class-heading-25">
                    Une équipe qui grandit
                  </h2>
                </div>
                <div className="af-class-avatar-team-wrapper">
                  <div
                    data-w-id="a9adff58-3df4-1ea5-2595-364806431f4c"
                    style={{ opacity: 0 }}
                    className="af-class-avatar-group"
                  >
                    <img
                      src="../images/Simon.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/TNM2T57LH-U016N7Q1JD7-4baffae66b67-512.png"
                      sizes="140px"
                      srcSet="../images/TNM2T57LH-U016N7Q1JD7-4baffae66b67-512-p-500.png 500w, ../images/TNM2T57LH-U016N7Q1JD7-4baffae66b67-512.png 512w"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/OK.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Priscille.png"
                      sizes="140px"
                      srcSet="../images/Priscille-p-500.png 500w, ../images/Priscille.png 512w"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Isaac.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Arnaud.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Eleana.png"
                      sizes="140px"
                      srcSet="../images/Eleana-p-500.png 500w, ../images/Eleana.png 800w"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Jorge.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Capture-d’écran-du-2021-05-06-12-49-17.png"
                      sizes="140px"
                      srcSet="../images/Capture-d’écran-du-2021-05-06-12-49-17.png 500w, ../images/Capture-d’écran-du-2021-05-06-12-49-17.png 739w"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                    <img
                      src="../images/Capture-d’écran-du-2021-05-06-12-50-38.png"
                      alt
                      className="af-class-avatar af-class-avatar-xlarge af-class-avatar-group-item"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default AboutUsView;

/* eslint-enable */
