import axios from "axios";
import mergeWith from "lodash/mergeWith";
import { storageBranConfigItem } from "./Context/BrandConfigContext";

const httpClient = axios.create();
httpClient.interceptors.request.use((resConfig) =>
  mergeWith(resConfig, {
    headers: { "x-brand-id": storageBranConfigItem.jsonParsedValue?.id || "" },
  })
);

export default httpClient;
