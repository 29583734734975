import React from "react";
import { Button, ButtonProps, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const BasketButton: React.FC<ButtonProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      component="button"
      color="primary"
      size={isMobile ? "large" : "medium"}
      {...props}
    />
  );
};

export default BasketButton;
