import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  color: ${(props) => props.color || props.theme.palette.primary.main};
  margin-bottom: 20px;
`;

function SectionTitle({ children, color }) {
  return (
    <StyledTypography color={color} gutterBottom variant="h5" component="h2">
      {children}
    </StyledTypography>
  );
}
SectionTitle.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SectionTitle;
