import React from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Select,
  ThemeProvider,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { withSimpleOnChange } from "./withSimpleOnChange";
import { makeStyles } from "@material-ui/core/styles";
import { pseudoGuid } from "../utils";
import { FILTER_BORDER_RADIUS } from "../../Common/appTheme";
import CloseIcon from "@material-ui/icons/Close";

const SimpleSelect = withSimpleOnChange(Select);

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1, 0),
    // minWidth: 100,
    width: "100%",
  },
  menuPaper: {
    // we gonna change the theme to round the select clickable button
    // this will also make the menu (overlay) too round, so we're setting explicitly the current them value
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selecRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  selectMenu: {
    "&:focus": {
      borderRadius: "inherit",
    },
  },
  iconStyle: {
    color: theme.palette.grey["800"],
    marginRight: theme.spacing(1),
  },
}));

/**
 * Theming here deserve an explanation. We want a round input/select (looks like a Pill/Chip), but we want the dropdown menu to be
 * the normal amount of squareness (themne.shape.borderRadius)
 *
 * The easiest way would be to just override the borderRadius of the input and leave the rest alone, no need to use theme.
 * That's what we did for the ProductFiler
 *
 * BUT, for that I need to access the element which has the following classes: `MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl`
 * and I can't seem to access it's style through jss API. Select's root starts too low down the tree, same issue for
 * the `input` Select's API offers <Select input={}/>
 *
 * As a workaroung, I apply a theme with the desired spacing to the whole select through the ThemeProvider.
 * This will apply both for the border and the dropdown menu. So I need then to manually "revert" the dropdown menu border
 * to normal the border radius through useStyles.menuPaper
 */
const themeOpts = {
  shape: {
    borderRadius: FILTER_BORDER_RADIUS,
  },
};

/*
Were not using onsubmit because currently any change on the value selected (not the value typed)
triggers automatically a submit in Filters
 */
export function SelectFilter({
  value,
  onChange,
  onSubmit,
  options,
  label,
  dense,
}) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [labelId] = React.useState(`label-select-${pseudoGuid()}`);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <ThemeProvider theme={(outerTheme) => ({ ...outerTheme, ...themeOpts })}>
      <FormControl
        variant={"outlined"}
        className={classes.formControl}
        margin={dense ? "dense" : "none"}
      >
        <InputLabel ref={inputLabel} id={labelId}>
          {label}
        </InputLabel>
        <SimpleSelect
          // SelectDisplayProps={{classeName: 'YOUSEEME'}}
          classes={{ selectMenu: classes.selectMenu }}
          labelWidth={labelWidth}
          labelId={labelId}
          // displayEmpty
          value={value}
          onChange={onChange}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            getContentAnchorEl: null,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
          }}
        >
          <MenuItem value={""}>
            <CloseIcon className={classes.iconStyle} />
            Effacer
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SimpleSelect>
      </FormControl>
    </ThemeProvider>
  );
}

SelectFilter.propTypes = {
  dense: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
