import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Axios from "../httpClient";
import localStorageManager from "../Helpers/localStorageManager";
import constants from "../VendorStore/utils/constants";
import { useApiConfig } from "./ApiContext";
import { useHttpClient } from "../Hooks/useHttpClient";
import { useHistory, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { UnauthorizedError } from "../Common/Exception/exception";
import { useBrandConfig } from "./BrandConfigContext";
import { Tracking } from "../Helpers/tracking";

const AuthContext = createContext(null);

export function withAuth(Component) {
  return function WrappedAuthComponent(props) {
    return (
      <AuthContext.Consumer>
        {({ login }) => <Component {...props} login={login} />}
      </AuthContext.Consumer>
    );
  };
}
// use local variable to avoid state race conditions when two api calls occur simultaneously
let isUnauthorized = false;
function handleUnauthorized(setUnauthorized, setAuth) {
  console.debug("handleUnauthorized");
  if (isUnauthorized) {
    return;
  }
  isUnauthorized = true;
  localStorageManager.removeItem("ku.auth");
  localStorageManager.removeItem("feathers-jwt");
  setUnauthorized(true);
  setAuth(undefined);
}
function handleAuthorizationAttempt(setUnauthorized) {
  console.debug("handleAuthorizationAttempt");
  isUnauthorized = false;
  setUnauthorized(false);
}
async function authCallWrapper(
  method,
  args,
  accessToken,
  setUnauthorized,
  setAuth,
  verifyExpiration
) {
  const { exp } = jwtDecode(accessToken);
  const expirationTime = exp * 1000 - 60000;
  if (verifyExpiration && Date.now() >= expirationTime) {
    console.debug("Token expired");
    handleUnauthorized(setUnauthorized, setAuth);
    throw new UnauthorizedError("Expired token");
  }
  try {
    return await method(...args);
  } catch (e) {
    if (e && e.response && e.response.status === 401) {
      console.debug("Unauthorized api call");
      console.debug(e);
      handleUnauthorized(setUnauthorized, setAuth);
      throw new UnauthorizedError("Invalid or expired token on request");
    }
    throw e;
  }
}

function useAuth() {
  const {
    brandConfig: { identifier },
  } = useBrandConfig();
  const { apiRoot } = useApiConfig();
  const [auth, setAuth] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const httpClient = useHttpClient(apiRoot, auth || {});

  // api client to use when making authed calls
  const authHttpClient = useMemo(() => {
    if (!auth) {
      return;
    }
    const { accessToken } = auth;
    const verifyExpiration = identifier !== "granvillage";
    const commonArgs = [
      accessToken,
      setUnauthorized,
      setAuth,
      verifyExpiration,
    ];
    return {
      get: async (...args) =>
        authCallWrapper(httpClient.get, args, ...commonArgs),
      post: async (...args) =>
        authCallWrapper(httpClient.post, args, ...commonArgs),
      patch: async (...args) =>
        authCallWrapper(httpClient.patch, args, ...commonArgs),
    };
  }, [auth, httpClient.get, httpClient.patch, httpClient.post, identifier]);

  // we validate token on app initialization
  // if found invalid we logout the user
  useEffect(() => {
    async function checkAuth() {
      try {
        if (!auth && identifier !== "granvillage") {
          return;
        }
        const accessToken = auth?.accessToken || "INVALID";
        setLoading(true);
        handleAuthorizationAttempt(setUnauthorized);
        const { data } = await Axios.post(`${apiRoot}/authentication`, {
          strategy: "jwt",
          accessToken: identifier === "granvillage" ? "IGNORED" : accessToken,
        });
        // refresh token
        localStorageManager.setItem("ku.auth", JSON.stringify(data));
        setAuth(data);
        setLoading(false);
        Tracking.trackUserLogin();
      } catch (error) {
        console.debug("invalid token");
        localStorageManager.removeItem("ku.auth");
        localStorageManager.removeItem("feathers-jwt");
        setAuth(undefined);
        setLoading(false);
        Tracking.trackAnonymousConnection();
      }
    }
    checkAuth();
    //eslint-disable-next-line
  }, [apiRoot, identifier]);

  const isLoggedIn = useMemo(() => Boolean(auth && auth.accessToken), [auth]);

  const login = useCallback(
    async (apiRoot, values) => {
      handleAuthorizationAttempt(setUnauthorized);
      const { data } = await Axios.post(`${apiRoot}/authentication`, {
        ...values,
        strategy: "local",
        origin: constants.ORDER_ORIGIN.CLIENT,
        accountType: "CUSTOMER",
      });
      localStorageManager.setItem("ku.auth", JSON.stringify(data));
      setAuth(data);
      return data;
    },
    [setAuth]
  );

  const get = useMemo(() => {
    if (auth) {
      return auth;
    }
    const storage = localStorageManager.getItem("ku.auth");
    if (!storage) {
      return undefined;
    }
    const storageJson = JSON.parse(storage);
    setAuth(storageJson);
    return storageJson;
  }, [auth, setAuth]);

  const logout = () => {
    localStorageManager.removeItem("ku.auth");
    setAuth(null);
    if (identifier === "granvillage") {
      window.location.href = "/perform-logout";
    }
  };

  return [
    get,
    login,
    logout,
    isLoggedIn,
    loading,
    unauthorized,
    setUnauthorized,
    authHttpClient,
  ];
}

export function useGetAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    return {};
  }
  return context;
}

export const AuthProvider = ({ children }) => {
  const [
    auth,
    login,
    logout,
    isLoggedIn,
    loading,
    unauthorized,
    setUnauthorized,
    authHttpClient,
  ] = useAuth();
  const location = useLocation();
  const history = useHistory();
  const {
    brandConfig: { identifier },
  } = useBrandConfig();

  // whenever any api request fails with unauthorized
  // we redirect the user to the login page
  useEffect(() => {
    if (!unauthorized) {
      return;
    }
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Votre session a expiré. Veuillez vous reconnecter",
    });
    if (identifier === "granvillage") {
      return;
    }
    history.push("/login", { from: location });
    setUnauthorized(false);
  }, [history, identifier, location, setUnauthorized, unauthorized]);

  // while we check the auth credentials we do not show anything to the user
  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ login, auth, logout, isLoggedIn, authHttpClient }}
    >
      {children}
    </AuthContext.Provider>
  );
};
