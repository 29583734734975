import React from "react";
import { useGetAuth } from "../Context/AuthContext";
import { Redirect, Route } from "react-router-dom";

export default function PrivateRoute({
  component: Component,
  children,
  render,
  mode,
  ...rest
}) {
  const { isLoggedIn } = useGetAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        return Component ? (
          <Component {...props} />
        ) : children ? (
          children
        ) : (
          render({
            ...props,
          })
        );
      }}
    />
  );
}
