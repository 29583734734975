import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { parse, isBefore } from "date-fns";

// Material Componets
import Grid from "@material-ui/core/Grid";

// Material icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import StorefrontIcon from "@material-ui/icons/Storefront";
import CheckIcon from "@material-ui/icons/Check";

// Helpers
import { formatDateString } from "../Helpers/date";
import { Typography } from "@material-ui/core";

// Styleds
const Container = styled.div`
  width: 100%;
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "20px 170px" : "0"};
`;
const DateContainer = styled(Grid)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  ${({ theme }) => `
    color: ${theme.palette.grey[800]};
  `};
  padding: 14px 0;
`;
const Pin = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ warning, theme }) =>
    warning ? theme.palette.info.main : theme.palette.success.main};
  border-radius: 50px;
  margin: 10px;
`;
const Banner = styled(Grid)`
  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.palette.grey["100"]};
  box-sizing: border-box;
  background-color: #ffffff;
  height: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "158px" : "118px"};
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "10px 50px" : "8px"};
`;
const Icon = styled.div`
  padding: 12px;
  margin: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "0 20px" : "0 10px"};
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.warning ? "rgba(248, 233, 94, 0.39)" : "rgba(132, 191, 38, 0.25)"};
  border-radius: 37px;
  color: ${({ warning, theme }) =>
    warning ? theme.palette.info.main : theme.palette.success.main};
`;
const CreatedAt = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.grey["600"]};
`;
const Number = styled.label`
  font-weight: bold;
  ${({ theme }) => `
    color: ${theme.palette.grey[900]};
  `};
`;
const Producers = styled(Grid)`
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  ${({ theme }) => `
    color ${theme.palette.primary.main};
`};
`;
const Total = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.warning.main};
`;
const TotalLabel = styled.label`
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  align-items: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.grey["800"]};
  margin-bottom: 10px;
`;
const StoreIcon = styled(StorefrontIcon)`
  padding-right: 5px;
`;

const ItemOrder = ({
  producers,
  orderDueTo,
  total,
  orderNumber,
  createdAt,
  width,
}) => {
  const warning = useMemo(() => {
    const now = new Date();
    const date = parse(orderDueTo, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
    if (isBefore(date, now)) {
      return false;
    }
    return true;
  }, [orderDueTo]);

  return (
    <Container width={width}>
      {orderDueTo && (
        <DateContainer container alignItems="center">
          <Pin warning={warning} />
          Livraison prévue le {formatDateString(createdAt)}
        </DateContainer>
      )}
      <Banner
        container
        alignItems="center"
        justify="space-between"
        width={width}
      >
        <Grid container item xs={10} sm={10}>
          <Grid item>
            <Icon warning={warning} width={width}>
              {warning ? <AccessTimeIcon /> : <CheckIcon />}
            </Icon>
          </Grid>
          <Grid container item xs={8} sm={8} direction="column">
            <CreatedAt>{formatDateString(orderDueTo)}</CreatedAt>
            <Number variant="body1">Commande n°{orderNumber}</Number>
            <Producers container alignItems="center">
              <StoreIcon />
              {producers}
            </Producers>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sm={2}
          direction="column"
          alignItems="center"
        >
          <TotalLabel>TOTAL</TotalLabel>
          <Total variant="subtitle1">{total} €</Total>
        </Grid>
      </Banner>
    </Container>
  );
};

ItemOrder.propTypes = {
  producers: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderDueTo: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

ItemOrder.defaultProps = {};

export default ItemOrder;
