import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Hidden,
  Menu,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";

export const DEFAULT_RADIUS = 30;

const styles = (theme) => ({
  button: {
    padding: (props) =>
      props.dense ? theme.spacing(1, 2) : theme.spacing(2, 2),
    margin: (props) =>
      props.dense ? theme.spacing(1, 0, 0.5, 0) : theme.spacing(1, 1),
    borderRadius: 30,
    textTransform: "capitalize",
  },
  menuPaper: {
    marginTop: theme.spacing(1),
  },
  iconStyle: {
    color: theme.palette.grey["800"],
    marginRight: theme.spacing(1),
  },
});

function buildPopopTitle(address) {
  if (!address) return "Distance maximal";
  return (
    <React.Fragment>
      <span>Distance maximal de </span>
      <em>{address}</em>
    </React.Fragment>
  );
}

const buildAriaLabel = (value) => `${value} km`;

class _RadiusFilter extends React.Component {
  static defaultProps = {
    defaultValue: 30,
    maxRadius: 30,
    minRadius: 3,
  };

  static propTypes = {
    address: PropTypes.string,
    classes: PropTypes.any,
    defaultValue: PropTypes.number,
    dense: PropTypes.bool,
    maxRadius: PropTypes.number,
    minRadius: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.marks = [props.minRadius, props.maxRadius].map((v) => ({
      value: v,
      label: `${v}km`,
    }));
    this.buttonRef = null;
    this.state = {
      open: false,
    };
  }

  buildLabel() {
    const { value } = this.props;
    return value ? `${value}km` : "Pas de distance limite";
  }

  handleClick = (e) => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    this.handleClose();
    this.props.onSubmit();
  };

  handleClear = () => {
    this.props.onChange(this.props.defaultValue);
    this.handleSubmit();
  };

  handleChange = (e, k) => {
    this.props.onChange(k);
  };

  render() {
    const { value, address, minRadius, maxRadius, classes } = this.props;
    const { open } = this.state;

    const label = this.buildLabel();
    return (
      <React.Fragment>
        <Button
          type="button"
          variant={"outlined"}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.button}
          ref={(n) => (this.buttonRef = n)}
          style={{ minWidth: 60, width: "100%" }}
        >
          <Hidden xsDown>
            <SettingsEthernetIcon style={{ marginRight: 8 }} />
          </Hidden>
          {label}
        </Button>
        <Menu
          open={open}
          anchorEl={this.buttonRef}
          classes={{ paper: classes.menuPaper }}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Box style={{ minWidth: 300 }}>
            <Box pl={2} pr={2}>
              <Typography variant={"body1"}>
                <b>{buildPopopTitle(address)}</b>
              </Typography>
              <Box pt={6} pb={1} pl={2} pr={2}>
                <Slider
                  defaultValue={value}
                  onChangeCommitted={this.handleChange}
                  getAriaValueText={buildAriaLabel}
                  aria-labelledby="radius-slider"
                  valueLabelDisplay={"on"}
                  step={5}
                  min={minRadius}
                  max={maxRadius}
                  marks={this.marks}
                />
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems={"center"}
              pb={1}
              pl={2}
              pr={2}
              pt={2}
            >
              <Box>
                <Button size={"small"} onClick={this.handleClear}>
                  <CloseIcon fontSize="small" />
                  Effacer
                </Button>
              </Box>
              <Box flexGrow={1} />
              <Box>
                <Button
                  disableElevation
                  size="small"
                  color="primary"
                  variant={"contained"}
                  onClick={this.handleSubmit}
                >
                  Sauver
                </Button>
              </Box>
            </Box>
          </Box>
        </Menu>
      </React.Fragment>
    );
  }
}

export const RadiusFilter = withStyles(styles)(_RadiusFilter);
