const LOCAL_STORAGE_KEYS = {
  CURRENT_CART: "kuuStoreCart",
  CURRENT_PROMO: "kuuStorePromo",
  CURRENT_CART_SIMPLIFIED: "kuuStoreCartSimplified",
  CURRENT_PROMO_SIMPLIFIED: "kuuStoreSimplifiedPromo",
};
function isLocalStorageSupportedCheck() {
  const mod = "modernizr";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}
const isLocalStorageSupported = isLocalStorageSupportedCheck();
// initialize all LOCAL_STORAGE_KEYS as empty objects
Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
  if (!isLocalStorageSupported) {
    return;
  }
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, JSON.stringify({}));
  }
});

export const storeCart = (cart) => {
  if (!isLocalStorageSupported) {
    return;
  }
  localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_CART, JSON.stringify(cart));
};

export const getCart = () => {
  if (!isLocalStorageSupported) {
    return {};
  }
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_CART));
};

export const getPromo = () => {
  if (!isLocalStorageSupported) {
    return {};
  }
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_PROMO));
};

export const storePromo = (code) => {
  if (!isLocalStorageSupported) {
    return;
  }
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.CURRENT_PROMO,
    JSON.stringify({ code })
  );
};

export const storeCartSimplifiedStore = (cart) => {
  if (!isLocalStorageSupported) {
    return;
  }
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.CURRENT_CART_SIMPLIFIED,
    JSON.stringify(cart)
  );
};

export const getCartSimplifiedStore = () => {
  if (!isLocalStorageSupported) {
    return {};
  }
  return JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_CART_SIMPLIFIED)
  );
};

function setItem(keyName, keyValue) {
  if (!isLocalStorageSupported) {
    return;
  }
  return localStorage.setItem(keyName, keyValue);
}
function getItem(keyName) {
  if (!isLocalStorageSupported) {
    return null;
  }
  return localStorage.getItem(keyName);
}
function clear() {
  if (!isLocalStorageSupported) {
    return;
  }
  return localStorage.clear();
}
function removeItem(keyName) {
  if (!isLocalStorageSupported) {
    return;
  }
  return localStorage.removeItem(keyName);
}

const utils = {
  setItem,
  getItem,
  clear,
  removeItem,
};

export default utils;
