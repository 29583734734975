import React from "react";
import PropTypes from "prop-types";

// Material Components
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Component = ({
  id,
  name,
  value,
  required,
  onChange,
  label,
  disabled,
  className,
}) => {
  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          id={id}
          name={name}
          checked={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

Component.protoTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

Component.defaultProps = {
  className: "",
  id: undefined,
  label: null,
  value: "",
  onChange: () => {},
  required: false,
  disabled: false,
};

export default Component;
