import React from "react";
import PropTypes from "prop-types";
import { Box, Collapse, Grid, Hidden } from "@material-ui/core";
import { LocationFilter } from "./LocationFilter";
import { VendorFilter } from "./VendorFilter";
import { SelectFilter } from "./SelectFilter";
import { DEFAULT_CATEGORIES, DEFAULT_TAGS } from "../../Common/constants";
import { DEFAULT_RADIUS, RadiusFilter } from "./RadiusFilter";
import { ResultCounter } from "./ResultCounter";
import { ProductFilter } from "./ProductFilter";
import {
  SecondaryActionButton,
  withSecondaryIcon,
} from "./SecondaryActionButton";
import AddIcon from "@material-ui/icons/Add";
import { FiltersLayout } from "./FiltersLayout";
import RemoveIcon from "@material-ui/icons/Remove";
import { getLocalStorage, setLocalStorage } from "../utils";
import _ from "lodash";
import { mergeFilters } from "../searchService";
import { DeliveryFilter } from "./DeliveryFilter";

const AddIconSec = withSecondaryIcon("left")(AddIcon);
const RemoveIconSec = withSecondaryIcon("left")(RemoveIcon);

export const getLocalStorageFilter = () => {
  const lsFilters = getLocalStorage("filters");
  return _.isObject(lsFilters) ? lsFilters : {};
};

export const setLocalStorageFilter = (values) => {
  if (_.isObject(values)) setLocalStorage("filters", values);
};

export const getInitFilter = (defaultRadius, noLocalStorage) => {
  const out = {
    location: {
      address: "",
      lat: "",
      lng: "",
    },
    vendor: "",
    category: "",
    label: "",
    product: "",
    onlyDelivery: false,
    radius: defaultRadius,
  };
  if (!noLocalStorage) mergeFilters(out, getLocalStorageFilter());
  return out;
};

const verticalSpacing = {
  xs: 4,
  sm: 8,
  lg: 8,
};

export class Filters extends React.Component {
  static propTypes = {
    turnOffLocationEditAfterSubmit: PropTypes.bool,
    values: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    dense: PropTypes.bool,
    tags: PropTypes.array,
    categories: PropTypes.array,
    minRadius: PropTypes.number,
    maxRadius: PropTypes.number,
    resultCount: PropTypes.number.isRequired,
    defaultRadius: PropTypes.number,
    showRadius: PropTypes.bool,
  };
  static defaultProps = {
    dense: true,
    categories: DEFAULT_CATEGORIES,
    tags: DEFAULT_TAGS,
    showRadius: true,
  };

  constructor(props) {
    super(props);
    const isLocationEditable = Filters.getIsLocationEditable(
      this.props.defaultValues
    );
    this.state = {
      isLocationEditable,
      showMore: false,
    };
  }

  static getIsLocationEditable = (values) => {
    return (
      !values ||
      !values.location ||
      !values.location.lat ||
      !values.location.lng ||
      !values.location.address
    );
  };

  triggerOnChange(propName, val, thenSubmit) {
    this.props.onChange({ ...this.props.values, [propName]: val });
    if (thenSubmit) this._onSubmit(propName);
  }

  _locationChange = (val) => {
    return this.triggerOnChange("location", { ...val });
  };

  _vendorChange = (val) => {
    return this.triggerOnChange("vendor", val);
  };

  _labelChange = (val) => {
    this.triggerOnChange("label", val, true);
  };

  _categoryChange = (val) => {
    this.triggerOnChange("category", val, true);
  };

  _radiusChange = (val) => {
    this.triggerOnChange("radius", val);
  };

  _productChange = (val) => {
    this.triggerOnChange("product", val, true);
  };

  _onlyDeliveryChange = (val) => {
    this.triggerOnChange("onlyDelivery", val, true);
  };

  _onSubmit = async (field) => {
    const { turnOffLocationEditAfterSubmit, onSubmit } = this.props;
    // this is a hack to let some filter trigger change and submit immediatly
    // - select filters (label and categories) at value select or clear
    // - distance filter when clearing result (sets default value then submits)
    // Without that timeouts, SearchMap does the submit without having commited changes yet so will always
    // have values that are "one update late"
    // Proper solution could be: move filters state here in Filters instead of main comp SearchMap. That would make Filters
    // being controlled, and so ReactSearch would eventually have to force re-init it in case it want to control it
    setTimeout(() => {
      // console.log('waiting')
      onSubmit(field);
      // TODO the point is to set the location as editable after a submit
      // This doesn't work when clearing all filter because when getIsLocationEditable does its tests
      // the filter's values aren't reseted yet. Don't want to another setTimeout in here.. Plus it's not needed for
      // the first iteration, as a submit always triggers a page refresh. When switchiong to ajax submission we should find something.
      //  A lead could be listing in didComponentUpdate to a prop called 'submitKey' that
      //  SearchMap would update (e.g. increment) every time it does a submit
      if (turnOffLocationEditAfterSubmit) {
        // const isLocationEditable = Filters.getIsLocationEditable(values)
        //this.setState({isLocationEditable});
      }
    }, 0);
  };

  handleLocationEditRequest = () => {
    this.setState({ isLocationEditable: true });
  };

  handleStopLocationEditRequest = () => {
    if (this.props.values.location && this.props.values.location.address) {
      this.setState({ isLocationEditable: false });
    }
  };

  _handleClearAll = () => {
    // either comes from main comp or else,
    // the distanceFilter itself has an harcoded default of its own
    const defaultRadius = this.props.defaultRadius || DEFAULT_RADIUS;
    const emptyFilter = getInitFilter(defaultRadius, true);
    this.props.onChange(emptyFilter);
    this.handleLocationEditRequest();
    return this._onSubmit("location");
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  getActiveFiltersCount = () => {
    const v = this.props.values;
    let count = 0;
    if (v.product !== "") count++;
    if (v.category !== "") count++;
    if (v.label !== "") count++;
    if (v.vendor !== "") count++;
    if (v.onlyDelivery === true) count++;
    if (v.radius && isNaN(v.radius)) count++;
    return count;
  };

  render() {
    const {
      values,
      dense,
      tags,
      categories,
      products,
      minRadius,
      maxRadius,
      defaultRadius,
      resultCount,
      showRadius,
      isMap,
    } = this.props;
    console.debug("Filters value", values);
    const { isLocationEditable, showMore } = this.state;
    // const isLocationEditable = Filters.getIsLocationEditable(this.props.defaultValues)
    const product = (
      <ProductFilter
        value={values.product}
        options={products}
        // we don't need onSubmit, we submit at every change
        onSubmit={this._onSubmit}
        onChange={this._productChange}
        label={"Produits"}
        dense={dense}
      />
    );
    const label = (
      <SelectFilter
        value={values.label}
        options={tags}
        // we don't need onSubmit, we submit at every change
        // onSubmit={this._onSubmit}
        onChange={this._labelChange}
        label={"Label"}
        dense={dense}
      />
    );
    const category = (
      <SelectFilter
        value={values.category}
        options={categories}
        // we don't need onSubmit, we submit at every change
        // onSubmit={this._onSubmit}
        onChange={this._categoryChange}
        label={"Catégorie"}
        dense={dense}
      />
    );
    const radius = showRadius && (
      <RadiusFilter
        value={values.radius}
        defaultValue={defaultRadius}
        onSubmit={() => this._onSubmit("radius")}
        onChange={this._radiusChange}
        dense={dense}
        address={values.location && values.location.address}
        minRadius={minRadius}
        maxRadius={maxRadius}
      />
    );

    const moreFiltesrBtn = (
      <FilterBtn
        onClick={this.toggleShowMore}
        showMore={showMore}
        isMap={isMap}
        filtersCount={this.getActiveFiltersCount()}
      />
    );
    const resultCounter = (
      <ResultCounter count={resultCount} onClear={this._handleClearAll} />
    );
    const vendor = (
      <VendorFilter
        value={values.vendor}
        onChange={this._vendorChange}
        onSubmit={() => this._onSubmit("vendor")}
        dense={dense}
      />
    );

    const onlyDelivery = (
      <DeliveryFilter
        value={values.onlyDelivery}
        onChange={this._onlyDeliveryChange}
        isLocationEmpty={Filters.getIsLocationEditable(values)}
      />
    );
    const location = (
      <LocationFilter
        value={values.location}
        onChange={this._locationChange}
        onSubmit={() => this._onSubmit("location")}
        isEditMode={isLocationEditable}
        onEditRequest={this.handleLocationEditRequest}
        onStopEditRequest={this.handleStopLocationEditRequest}
        dense={dense}
      />
    );

    // So we put all the filters in a varaible with they data and handler plugged.
    // we're handing them out to a layout-er whose only job is to place them
    return (
      <Box p={2}>
        {/*<LocationFilter*/}
        {/*  value={values.location}*/}
        {/*  onChange={this._locationChange}*/}
        {/*  onSubmit={() => this._onSubmit("location")}*/}
        {/*  isEditMode={isLocationEditable}*/}
        {/*  onEditRequest={this.handleLocationEditRequest}*/}
        {/*  dense={dense}*/}
        {/*/>*/}
        {/*{onlyDelivery}*/}
        <FiltersLayout
          label={label}
          category={category}
          product={product}
          radius={radius}
          vendor={vendor}
          onlyDelivery={onlyDelivery}
          location={location}
          isMap={isMap}
          moreFiltesrBtn={moreFiltesrBtn}
          MoreFilters={MoreFilter}
          showMore={showMore}
          resultCounter={resultCounter}
          verticalSpacing={verticalSpacing}
        />
        {/*<Box mt={1} style={{textAlign: 'start'}} display='flex' alignItems={'center'}>*/}
        {/*</Box>*/}
      </Box>
    );
  }
}

function FilterBtn({ showMore, onClick, filtersCount }) {
  return (
    <React.Fragment>
      {/*MD*/}
      {/*<Hidden smDown>*/}
      {/*  <SecondaryActionButton onClick={onClick}>*/}
      {/*    {showMore ? <RemoveIconSec/> : <AddIconSec/>}*/}
      {/*    de filtres*/}
      {/*  </SecondaryActionButton>*/}
      {/*</Hidden>*/}
      {/*SM*/}
      <Hidden smUp>
        <SecondaryActionButton
          onClick={onClick}
          variant={(filtersCount && !showMore) > 0 ? "outlined" : "text"}
          color={(filtersCount && !showMore) > 0 ? "primary" : "default"}
        >
          {(!filtersCount || showMore) &&
            (showMore ? <RemoveIconSec /> : <AddIconSec />)}
          filtres
          {(filtersCount && !showMore) > 0 && (
            <span className={"text"}>&nbsp;({filtersCount})</span>
          )}
        </SecondaryActionButton>
      </Hidden>
    </React.Fragment>
  );
}

FilterBtn.propTypes = {
  onClick: PropTypes.func,
  showMore: PropTypes.bool,
  filtersCount: PropTypes.number,
};

const MoreFilter = React.memo(function MoreFilter({ children, active }) {
  return (
    <Collapse in={active}>
      <Box
        component={Grid}
        item
        flexGrow={1}
        style={{ marginTop: verticalSpacing.sm - 4 }}
      >
        {children}
      </Box>
    </Collapse>
  );
});

MoreFilter.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.any,
};
