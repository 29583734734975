import React from "react";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";

export function ContactPointsList({
  contactPoints,
  openMarkerID,
  onHoverChange,
  onItemClick,
  RowComponent,
}) {
  return (
    <List disablePadding>
      {contactPoints.map((contactPoint, i) => (
        <RowComponent
          marker={contactPoint}
          index={i}
          isFirstItem={i === 0}
          key={contactPoint.id}
          selected={contactPoint.id === openMarkerID}
          onHoverChange={onHoverChange}
          onClick={onItemClick}
        />
      ))}
    </List>
  );
}

ContactPointsList.propTypes = {
  contactPoints: PropTypes.array.isRequired,
  onHoverChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  RowComponent: PropTypes.func.isRequired,
  openMarkerID: PropTypes.string,
};
