import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  RiCheckFill as CheckIcon,
  RiCloseLine as ClearIcon,
} from "react-icons/ri";
import { Typography } from "@material-ui/core";

const PromoBox = styled.div`
  display: flex;
  margin-top: 5px;
  grid-gap: 5px;
`;

const PromoCodeInput = styled(TextField)`
  color: #464861;
  border: 1.5px solid #d9dbe7;
  border-radius: 4px;
  padding: 0 5px;
`;

const PromoButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.warning.main};
  width: 100%;
  color: white;
  border-radius: 4px;
  height: 34px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.warning.light};
  }
  width: 34px;
`;

const PromoError = styled(Typography)`
  color: red;
`;

type PromoCodeProps = {
  applyPromo: (code: string | undefined) => void;
  promoCode: string;
  clearPromo: () => void;
  onPromoChange: (code: string) => void;
  promoValid: boolean;
};

const PromoCode = ({
  applyPromo,
  clearPromo,
  promoCode,
  onPromoChange,
  promoValid,
}: PromoCodeProps) => {
  const [promoApplied, setPromoApplied] = useState(
    promoCode && promoCode.length > 0
  );

  const onChange = (val: string) => {
    setPromoApplied(false);
    onPromoChange(val);
  };

  const changePromo = async () => {
    if (promoCode.length > 0) {
      if (promoApplied) {
        clearPromo();
      } else {
        await applyPromo(promoCode);
      }

      setPromoApplied((applied) => !applied);
    }
  };

  return (
    <>
      <PromoBox>
        <PromoCodeInput
          placeholder="Saisir le code promo"
          inputProps={{ disableUnderline: true }}
          onChange={(e: any) => onChange(e.target.value)}
          value={promoCode}
          fullWidth
        />

        <PromoButton onClick={changePromo}>
          {!promoApplied ? <CheckIcon /> : <ClearIcon />}
        </PromoButton>
      </PromoBox>

      {promoCode.length > 0 && promoApplied && !promoValid && (
        <PromoError>Code promo invalide </PromoError>
      )}
    </>
  );
};

export default PromoCode;
