import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  ${({ theme }) => `color: ${theme.palette.grey["900"]}`};
`;

function BasicPaymentMethod({
  paymentMethod,
  description,
  onSuccessfulPayment,
  isPaymentBeingProcessed,
  onPaymentFormReady,
  onPaymentFormError,
}) {
  useEffect(() => {
    if (!isPaymentBeingProcessed) {
      return;
    }
    onSuccessfulPayment({
      paymentMethod,
      paymentConfirmation: false,
    });
  }, [isPaymentBeingProcessed, onSuccessfulPayment, paymentMethod]);

  useEffect(() => {
    onPaymentFormReady(true);
    onPaymentFormError(false);
  }, [onPaymentFormError, onPaymentFormReady]);

  return (
    <div>
      <StyledTypography variant="body1">{description}</StyledTypography>
    </div>
  );
}

export default BasicPaymentMethod;
