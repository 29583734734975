import { useMemo } from "react";
import { addDays } from "date-fns";
import constants from "../Common/constants";

export function getProductTotal(cart) {
  if (!cart) {
    return 0;
  }

  const result = Object.keys(cart).reduce((total, cartkey) => {
    const { product, quantity, errors } = cart[cartkey];

    const notAvailable = errors.some(
      (error) => error.type === constants.PRODUCT_ERRORS.UNAVAILABLE
    );

    if (notAvailable) {
      return total;
    }

    return total + quantity * product.price;
  }, 0);
  return result;
}

export function getTaxesTotal(cart) {
  if (!cart) {
    return 0;
  }
  const result =
    Object.values(cart).reduce((acc, { quantity, product, errors }) => {
      const notAvailable = errors.some(
        (error) => error.type === constants.PRODUCT_ERRORS.UNAVAILABLE
      );

      if (notAvailable) {
        return acc;
      }

      return acc + quantity * product.price;
    }, 0) - getProductTotal(cart);
  return result;
}

export function getDiscountTotal(couponLines) {
  if (!couponLines) {
    return 0;
  }
  return couponLines.reduce((acc, cl) => acc + cl.discount, 0);
}

export function getFeesTotal(recoveryInfo) {
  if (!recoveryInfo) {
    return 0;
  }
  const result = Object.values(recoveryInfo).reduce(
    (total, { delivery }) => total + ((delivery && delivery.rate) || 0),
    0
  );
  return result;
}

export function getCartByProducerId(cart) {
  return Object.keys(cart).reduce((result, cartKey) => {
    const {
      quantity,
      product: { producer, ...product },
    } = cart[cartKey];

    if (result[producer.id]) {
      result[producer.id].products.push({
        ...product,
        quantity,
      });
    } else {
      result[producer.id] = {
        products: [
          {
            ...product,
            quantity,
          },
        ],
      };
    }

    return result;
  }, {});
}

export function getProductsForProducer(cart, producerId, isSimplifiedStore) {
  const result = getCartByProducerId(cart);

  if (isSimplifiedStore && Object.keys(cart).length > 0) {
    const obj = Object.values(result); // TODO -  IMPROVE  IT
    return obj[0].products;
  } else {
    if (
      result.hasOwnProperty(producerId) &&
      result[producerId].hasOwnProperty("products")
    ) {
      return result[producerId].products;
    }

    return [];
  }
}

export function formatCart(cart) {
  return Object.keys(cart).reduce((result, key) => {
    const item = cart[key] || {};
    const { product } = item;
    const { producer } = product;
    const { address, email, name, contactPoints = [], phone, id } = producer;
    const actualProducer = result[name] || {
      products: [],
    };
    const products = [...actualProducer.products, product.name];
    result = {
      ...result,
      [id]: {
        name,
        contactPoints,
        products,
        vendorId: id,
        producer: {
          address,
          contactPoints,
          email,
          phone,
          vendorId: id,
        },
      },
    };
    return result;
  }, {});
}

export function getCartProducerMap(cart) {
  return Object.values(cart).reduce(
    (acc, { product: { producer } }) => ({
      ...acc,
      [producer.id]: producer,
    }),
    {}
  );
}

export function calculateProductAvailableQuantity({ stock, maxSaleQuantity }) {
  let calculatedStock =
    stock !== undefined && stock !== null && stock >= -1000 ? stock : 999;
  if (calculatedStock < 0) calculatedStock = 0;
  const maxQuantity =
    maxSaleQuantity !== undefined &&
    maxSaleQuantity !== null &&
    maxSaleQuantity >= 0
      ? maxSaleQuantity
      : 999;
  return calculatedStock !== 999 || maxQuantity !== 999
    ? Math.min(Math.floor(calculatedStock), maxQuantity)
    : 50;
}

export function calculateDueTo(recoveryInfo) {
  let orderDueTo =
    recoveryInfo && recoveryInfo.date ? recoveryInfo.date : new Date();

  if (recoveryInfo && recoveryInfo.chronopost) {
    if (
      recoveryInfo &&
      recoveryInfo.contactPointInfo &&
      recoveryInfo.contactPointInfo.frequency
    ) {
      let timeNeeded = 1;
      switch (recoveryInfo.contactPointInfo.frequency) {
        case constants.CHRONOPOST_DELIVERY_FREQUENCY.EVERYDAY:
          timeNeeded = 3;
          break;
        case constants.CHRONOPOST_DELIVERY_FREQUENCY.EVERY_TWO_DAYS:
          timeNeeded = 5;
          break;
        case constants.CHRONOPOST_DELIVERY_FREQUENCY.TWICE_A_WEEK:
          timeNeeded = 7;
          break;
        case constants.CHRONOPOST_DELIVERY_FREQUENCY.ONCE_A_WEEK:
          timeNeeded = 10;
          break;
        default:
          timeNeeded = 1;
          break;
      }

      orderDueTo = addDays(new Date(), timeNeeded);
    }
  }

  return orderDueTo.toISOString();
}

export const groupCartItemsByProducer = (
  currentCart,
  couponLinesByProducer
) => {
  return Object.values(currentCart).reduce((acc, item) => {
    const producerCartInfo = acc[item.product.producer.id] || {
      producerName: item.product.producer.name,
      products: [],
      subtotal: 0,
      totalDiscount:
        (couponLinesByProducer &&
          couponLinesByProducer[item.product.producer.id] &&
          couponLinesByProducer[item.product.producer.id].reduce(
            (acc, coupon) => acc + coupon.discount,
            0
          )) ||
        0,
    };

    producerCartInfo.products.push(item);

    const notAvailable = item.errors.some(
      (error) => error.type === constants.PRODUCT_ERRORS.UNAVAILABLE
    );

    if (!notAvailable) {
      producerCartInfo.subtotal += item.quantity * item.product.price;
    }

    return {
      ...acc,
      [item.product.producer.id]: producerCartInfo,
    };
  }, {});
};

export const groupCouponLinesProducer = (couponLines) => {
  return couponLines.reduce((acc, item) => {
    const lines = acc[item.discountApplied.createdBy] || [];
    lines.push(item);
    return {
      ...acc,
      [item.discountApplied.createdBy]: lines,
    };
  }, {});
};

export function checkMaxAvailableDate(cart) {
  const keys = Object.keys(cart);
  if (!keys.length) return null;
  const currentDate = new Date();
  const maxAvailableDate = keys.reduce((prevDate, productId) => {
    const nextDate = new Date(cart[productId].product.availableDate);
    if (nextDate > prevDate) return nextDate;
    return prevDate;
  }, currentDate);

  if (maxAvailableDate === currentDate) return null;
  return maxAvailableDate;
}

// Hooks
export function useGetCartTotal(cart) {
  return useMemo(() => {
    return getProductTotal(cart);
  }, [cart]);
}
