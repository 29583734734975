const constants = {
  PREPARATION_TYPE: {
    SPECIFIC_DATE: "SPECIFIC_DATE",
    DELAY: "DELAY",
  },
  SALE_CHANNEL: {
    STANDARD: "STANDARD",
    PROFESSIONAL: "PROFESSIONAL",
  },
  PAYMENT_METHOD: {
    CHEQUE: "_cheque",
    CASH: "_cash",
    CREDIT_CARD: "_credit-card",
    TRANSFER: "_transfer",
  },
  PAYMENT_PROVIDER: {
    MANGOPAY: "MANGOPAY",
    STRIPE: "STRIPE",
  },
  COMPONENT: {
    STORE: "STORE",
    FORM: "FORM",
  },
  CODE_EVALUATION_STATUS: {
    INVALID: "INVALID",
    VALID: "VALID",
    UNDETERMINED: "UNDETERMINED",
  },
  ORDER_ORIGIN: {
    ADMIN: "ADMIN",
    CLIENT: "CLIENT",
    FORM: "FORM",
  },
};

export default constants;
