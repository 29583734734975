import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import httpClient from "../httpClient";
import constants from "../Common/constants";
import qs from "qs";

const dataFetchReducer = (state, action = {}) => {
  const { payload, ...extraParams } = action;
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        codesEvaluated: false,
        isLoading: true,
        isError: false,
        ...extraParams,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        codesEvaluated: true,
        isLoading: false,
        isError: false,
        data: payload,
        ...extraParams,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        codesEvaluated: false,
        isLoading: false,
        isError: true,
        ...extraParams,
      };
    default:
      throw new Error();
  }
};
let firstLoad = true;

const useDataApi = (
  initialProducerId,
  initialData,
  apiRoot,
  initialSaleChannel,
  isGroup,
  initialCodes,
  initialCartAmount,
  isSimplifiedStore,
  initialStoreFrontSlug
) => {
  const [producerId, setProducer] = useState(initialProducerId);
  const [storeFrontConfigSlug, setStoreFrontConfigSlug] = useState(
    initialStoreFrontSlug
  );

  const [codes, setCodes] = useState(initialCodes);
  const [cartAmount, setCartAmount] = useState(initialCartAmount);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    codesEvaluated: false,
    isLoading: true,
    isError: false,
    data: initialData,
  });

  const buildUrls = ({
    isGroup,
    producerId,
    initialSaleChannel,
    isSimplifiedStore,
    storeFrontConfigSlug,
  }) => {
    let productURL = isGroup
      ? `${apiRoot}/product/productGroupBySlug/${producerId}`
      : `${apiRoot}/product/productBySlug/${producerId}`;

    let storeURL = isGroup
      ? `${apiRoot}/group/groupBySlug/${producerId}`
      : `${apiRoot}/user/userBySlug/${producerId}`;

    if (isSimplifiedStore) {
      productURL = isGroup
        ? `${apiRoot}/product/clientGroupList/${producerId}`
        : `${apiRoot}/product/clientList/${producerId}`;

      storeURL = isGroup
        ? `${apiRoot}/group/clientList/${producerId}`
        : `${apiRoot}/user/clientList/${producerId}`;
    }

    const queryString = qs.stringify({
      saleChannel: initialSaleChannel,
      component: isSimplifiedStore
        ? constants.COMPONENT.FORM
        : constants.COMPONENT.STORE,
      storeFrontConfigSlug,
    });

    productURL = `${productURL}?${queryString}`;
    storeURL = `${storeURL}?${queryString}`;

    return [productURL, storeURL];
  };

  const [productURL, storeURL] = buildUrls({
    isGroup,
    producerId,
    initialSaleChannel,
    isSimplifiedStore,
    storeFrontConfigSlug,
  });

  const fetchData = useMemo(
    () =>
      async ({ didCancel = false }) => {
        dispatch({ type: "FETCH_INIT", isLoading: firstLoad });

        try {
          const [productResult, producerResult] = await Promise.all([
            httpClient.post(productURL, {
              codes,
              cartAmount,
            }),
            httpClient(storeURL),
          ]);

          if (!didCancel) {
            dispatch({
              type: "FETCH_SUCCESS",
              payload: {
                producer: producerResult.data,
                products: productResult.data.products,
                validCodes: productResult.data.codes,
                couponLines: productResult.data.couponLines,
              },
              codesEvaluated: Boolean(codes && codes.length > 0),
            });
            firstLoad = false;
          }
        } catch (error) {
          console.log(error);
          if (!didCancel) {
            dispatch({ type: "FETCH_FAILURE" });
          }
        }
      },
    [
      apiRoot,
      cartAmount,
      codes,
      initialSaleChannel,
      isGroup,
      producerId,
      isSimplifiedStore,
      storeFrontConfigSlug,
    ]
  );

  const reload = useCallback(() => fetchData({}), [fetchData]);

  useEffect(() => {
    let didCancel = false;
    fetchData({ didCancel });
    return () => {
      didCancel = true;
    };
  }, [
    producerId,
    apiRoot,
    initialSaleChannel,
    isGroup,
    codes,
    cartAmount,
    fetchData,
    isSimplifiedStore,
    storeFrontConfigSlug,
  ]);

  return {
    state,
    codes,
    setCodes,
    setCartAmount,
    setProducer,
    reload,
    setStoreFrontConfigSlug,
  };
};

export default useDataApi;
