import React, { useState, useEffect } from "react";
import styled from "styled-components";
import queryString from "querystring";
import { useHistory } from "react-router-dom";
import {
  getLocalStorageFilter,
  setLocalStorageFilter,
} from "../ProducerList/Filters/Filters";

import { Hidden } from "@material-ui/core";

// helpers
import media from "../Helpers/media";

// sections
import Header from "./sections/Header";
import ActionBox from "./sections/ActionBox";
import ActionBoxMobile from "./sections/ActionBoxMobile";
import Categories from "./sections/Categories";
import Newsletter from "./sections/Newsletter";
import WhyKuu from "./sections/WhyKuu";
import Testimonials from "./sections/Testimonials";
import Footer from "../Layouts/Footer";
import GranvillageFooter from "../GranvillageFooter";
import FooterMobile from "../Layouts/FooterMobile";
import Statistics from "./sections/Statistics";
import LocationModal from "./sections/LocationModal";

//meta
import Meta from "../Common/Meta";

// Assets
import { ReactComponent as People } from "../Assets/Images/people.svg";
import { ReactComponent as Person } from "../Assets/Images/person.svg";
import { ReactComponent as LeafLeft } from "../Assets/Images/leafLeft.svg";
import { ReactComponent as LeafRight } from "../Assets/Images/leafRight.svg";
import { ReactComponent as CircleMobile } from "../Assets/Images/circleMobile.svg";
import { useBrandConfig } from "../Context/BrandConfigContext";

//styled
const Container = styled.div`
  background: #ffffff;
`;

// overflow is needed as the shapes go off the page
const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  height: 689px;
  width: 100%;
  ${({ theme }) => `
    background: linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 108.22%);;
  `};
  ${media.down.lg`
    height: 720px;
  `}
  ${media.down.md`
    height: 720px;
    overflow: hidden;
  `}
`;
const PeopleImage = styled(People)`
  position: absolute;
  top: 197px;
  right: 0;
`;

const PersonImage = styled(Person)`
  position: absolute;
  top: 340px;
  right: -7px;
`;

const CircleMobileImage = styled(CircleMobile)`
  position: absolute;
  top: 507px;
  left: -150px;
`;

const LeftLeafIcon = styled(LeafLeft)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const RightLeafIcon = styled(LeafRight)`
  position: absolute;
  right: 0;
  bottom: -150px;
`;

const MainContent = styled.div`
  margin-top: 300px;
  ${media.down.md`
    margin-top: 30px;
  `};
  height: 100%;
`;
const emptyLocation = {
  address: "",
  lat: "",
  lng: "",
};
const HomePage = () => {
  let history = useHistory();
  const brandConfig = useBrandConfig();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleDialog = (state) => {
    setModalOpen(!state);
  };

  const [value, setValue] = useState({
    ...emptyLocation,
    local_dokan_seller_search: "",
    local_product_cat: "",
  });

  useEffect(() => {
    const filter = getLocalStorageFilter();

    if (filter.hasOwnProperty("location")) {
      setValue((oldValue) => ({ ...oldValue, ...filter.location }));
    }
  }, []);

  const onCategoryChange = (val) => {
    const newValue = { ...value, local_product_cat: val };
    setValue(newValue);

    if (!newValue.address || !newValue.lat || !newValue.lng) {
      setModalOpen(true);
    } else {
      onSearch(newValue);
    }
  };

  const onChangeProducer = (val) => {
    setValue({ ...value, local_dokan_seller_search: val });
  };

  const onChange = (newValue) => {
    const locationValue =
      newValue && newValue.address ? newValue : emptyLocation;
    setLocalStorageFilter({ location: locationValue });
    setValue({
      ...value,
      ...locationValue,
    });
  };

  const onSearch = (searchValue) => {
    const searchParams = {
      latitude: searchValue.lat,
      longitude: searchValue.lng,
      address: searchValue.address,
      local_dokan_seller_search: searchValue.local_dokan_seller_search,
      local_product_cat: searchValue.local_product_cat,
    };

    const params = queryString.stringify(searchParams);

    history.push(`/search?${params}`);
  };

  return (
    <Container>
      <Meta
        title="Bienvenue sur Kuupanda, la place de marché pour trouver des produits"
        description="Commandez les produits de vos producteurs locaux sans intermédiaires, au prix juste. Kuupanda c'est la garantie de pouvoir s'approvisionner en circuits courts simplement grâce à une marketplace intuitive et rapide."
        keywords="Producteurs, produits, locaux, circuit-court, vente directe, producteurs locaux, produits locaux."
        ogTitle="Kuupanda, la place de marché de produits et producteurs locaux."
        ogDescription="Commandez les produits de vos producteurs locaux sans intermédiaires, au prix juste. Kuupanda c'est la garantie de pouvoir s'approvisionner en circuits courts simplement grâce à une marketplace intuitive et rapide."
      />
      <HeaderWrapper>
        <Header
          onSearch={onSearch}
          onChange={onChange}
          onChangeProducer={onChangeProducer}
          value={value}
        />

        <Hidden only={["xs", "sm"]}>
          <ActionBox />
          <PeopleImage />
          <LeftLeafIcon />
          <RightLeafIcon />
        </Hidden>

        <Hidden only={["md", "lg", "xl"]}>
          <PersonImage />
          <CircleMobileImage />
          <ActionBoxMobile />
        </Hidden>
      </HeaderWrapper>
      <MainContent>
        <Categories onCategoryChange={onCategoryChange} />
        <Statistics />
        <WhyKuu />
        <Testimonials />
        <Newsletter />

        <Hidden only={["xs", "sm"]}>
          {brandConfig.identifier === "kuupanda" ? (
            <Footer />
          ) : (
            <GranvillageFooter />
          )}
        </Hidden>

        <Hidden only={["md", "lg", "xl"]}>
          {brandConfig.identifier === "kuupanda" ? (
            <FooterMobile />
          ) : (
            <GranvillageFooter />
          )}
        </Hidden>
      </MainContent>
      <LocationModal
        open={modalOpen}
        toggleDialog={toggleDialog}
        onSearch={onSearch}
        onChange={onChange}
        value={value}
      />
    </Container>
  );
};

export default HomePage;
