import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

// helpers
import media from "../../Helpers/media";

// Assets
import { ReactComponent as Farmer } from "../../Assets/Images/farmer.svg";
import { ReactComponent as Cooker } from "../../Assets/Images/cooker.svg";
import { ReactComponent as Computer } from "../../Assets/Images/computer.svg";

// styled
const WhyTitle = styled(Typography)`
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 46px;
  align-items: center;
  text-align: center;
  ${({ theme }) => `
    color: ${theme.palette.grey[900]};
  `};

  ${media.down.md`
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.palette.grey[800]};
  `}
`;

const Container = styled.div`
  overflow: hidden;
  margin-bottom: 120px;
`;

const WhyCardTitle = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.grey[900]};
  ${media.down.md`
    font-size: 16px;
    line-height: 140%;
  `}
`;

const WhyCardContent = styled(Typography)`
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[800]};
  ${media.down.md`
    max-width: 450px;
    margin: auto;
  `}
  ${media.down.sm`
    font-size: 14px;
    line-height: 140%;
  `}
  ${media.only.sm`
    font-size: 14px;
    line-height: 140%;
    max-width: 300px;
    margin: auto;
  `}
`;

const CardImageHolder = styled.div`
  height: 400px;
  margin-bottom: 25px;
  ${media.down.lg`
    height: 200px;
  `}
`;

const FarmerImage = styled(Farmer)`
  height: 400px;
  ${media.down.lg`
    height: 200px;
  `}
`;

const ComputerImage = styled(Computer)`
  height: 400px;
  ${media.down.lg`
    height: 200px;
  `}
`;

const CookerImage = styled(Cooker)`
  height: 400px;
  ${media.down.lg`
    height: 200px;
  `}
`;

const info = [
  {
    title: "Découvrez les producteurs autour de chez vous",
    text:
      "Découvrez un large choix de produits, à travers un réseau de producteurs locaux de vos terroirs. " +
      "Chacun a sa propre identité, ses méthodes de production...",
    icon: <FarmerImage />,
  },
  {
    title: "Choisissez comment vous souhaitez récupérer vos produits",
    text:
      "Faites vous livrer chez vous ou retirez vos commandes dans les points de " +
      "contact ou directement chez le producteur.",
    icon: <ComputerImage />,
  },
  {
    title: "Dégustez enfin les produits de vos régions",
    text:
      "Cuisinez en profitant de la richesse de nos terroirs. Vous favorisez " +
      "ainsi une consommation durable en valorisant le circuit court.",
    icon: <CookerImage />,
  },
];

const WhyKuu = () => {
  return (
    <Container>
      <WhyTitle variant="h3">Pourquoi commander sur Kuupanda ?</WhyTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={2} justify="space-evenly">
          {info.map(({ title, text, icon }) => (
            <Grid item md={3} sm={12} style={{ textAlign: "center" }}>
              <CardImageHolder>{icon}</CardImageHolder>
              <WhyCardTitle variant="h5">{title}</WhyCardTitle>
              <WhyCardContent>{text}</WhyCardContent>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

export default WhyKuu;
