import { getDistance } from "geolib";
import { getDay, getHours, getMinutes, setHours, setMinutes } from "date-fns";
import constants from "../Common/constants";

const vat = 0.2;

const days = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];
const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export function getFormattedDate(date: Date) {
  if (!date) {
    return "";
  }
  return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`;
}

export function getFormattedHour(date: Date) {
  if (!date) {
    return "";
  }
  return `${date.getHours()}h${
    date.getMinutes() === 0 ? "00" : date.getMinutes()
  }`;
}

export function isValidDate(d: any) {
  return d instanceof Date && !Number.isNaN(d);
}

export const calculateAvailableRange = ({
  contactPointInfo,
  clientAddress,
}: {
  contactPointInfo: any;
  clientAddress: any;
}) => {
  const distance =
    getDistance(
      {
        latitude: contactPointInfo.address.lat,
        longitude: contactPointInfo.address.lng,
      },
      {
        latitude: clientAddress.lat,
        longitude: clientAddress.lng,
      }
    ) / 1000;
  return contactPointInfo.deliveryRates.find(
    (dr: any) => distance >= dr.from && distance < dr.to
  );
};

export const calculateDeliveryRate = ({
  contactPointInfo,
  clientAddress,
  totalAmount,
}: {
  contactPointInfo: any;
  clientAddress: any;
  totalAmount: number;
}) => {
  let selectedRate;
  try {
    const selectedRange = calculateAvailableRange({
      contactPointInfo,
      clientAddress,
    });
    if (selectedRange) {
      const hasFreeDelivery =
        contactPointInfo.freeFeeOrderAmount &&
        totalAmount >= contactPointInfo.freeFeeOrderAmount;
      selectedRate = hasFreeDelivery ? 0 : selectedRange.price;
    }
  } catch (e) {
    console.log("error calculating distance", e);
  }
  // if no rate was found we return undefined
  return selectedRate;
};

export const calculateTotalWeight = ({ products }: any) => {
  if (!products) {
    return 0;
  }

  return products.reduce((total: number, product: any) => {
    const weight = (product && product.weight) || 1;
    return total + weight * product.quantity;
  }, 0);
};

export const calculateDeliveryTotal = ({
  packages,
  products,
  contactPoint,
  deliveryType,
  totalAmount,
}: any) => {
  let totalWeight = 0;

  if (
    contactPoint.freeFeeOrderAmount &&
    totalAmount >= contactPoint.freeFeeOrderAmount
  ) {
    return 0;
  }

  const packagesWeight = packages.reduce(
    (acc: number, { weight }: { weight: number }) => acc + weight,
    0
  );

  const splitWeightArray = splitPackagesToWeight(packagesWeight, contactPoint);

  return splitWeightArray.reduce(
    (acc: number, { weight }: { weight: number }) => {
      totalWeight += weight;

      //only the first 30kg are charged as normal
      if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_VITI) {
        if (totalWeight > 30) {
          return acc + 0.5 * weight;
        }
      }

      return (
        acc +
        calculateChronoPostDeliveryRate({
          weight,
          products: products,
          contactPoint: contactPoint,
          rateType: deliveryType,
        })
      );
    },
    0
  );
};

export const splitPackagesToWeight = (
  totalWeight: number,
  contactPoint: any
) => {
  const maxWeight =
    contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_RELAY
      ? constants.CHRONOPOST_MAX_WEIGHTS.CHRONOPOST_RELAY
      : constants.CHRONOPOST_MAX_WEIGHTS.CHRONOPOST;

  let splitWeightArray = [];
  let rest = totalWeight % maxWeight;
  let division = Math.floor(totalWeight / maxWeight);

  for (let i = 0; i < division; i++) {
    splitWeightArray.push({ weight: maxWeight });
  }

  if (rest !== 0) {
    splitWeightArray.push({ weight: rest });
  }

  return splitWeightArray;
};

export const calculateChronoPostDeliveryRate = ({
  weight,
  products,
  contactPoint,
  rateType,
}: any) => {
  if (!products || !contactPoint || !weight) {
    return 0;
  }

  const rate =
    contactPoint.subventionRates.find(
      (sr: any) =>
        weight > sr.weightRange.start &&
        weight <= sr.weightRange.end &&
        sr.type === rateType
    ) ||
    contactPoint.subventionRates.find(
      (sr: any) => weight > sr.weightRange.start && weight <= sr.weightRange.end
    );

  // if no rate was found we return undefined
  if (!rate) return 0;

  return addVAT(rate.baseRate - rate.subventionRate);
};

export const calculateServiceType = ({ products, contactPoint }: any) => {
  if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_RELAY) {
    return constants.CHRONOPOST_TYPES.CHRONOPOST_RELAY;
  }

  if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_VITI) {
    return constants.CHRONOPOST_TYPES.CHRONOPOST_VITI;
  }

  if (!products) {
    return constants.CHRONOPOST_MODES.DRY;
  }

  const hasColdProducts = products.some(
    (product: any) => product.storage === constants.STORAGE_TYPE.COLD
  );

  return hasColdProducts
    ? constants.CHRONOPOST_MODES.FRESH
    : constants.CHRONOPOST_MODES.DRY;
};
const reverseMapping = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

function parseHourString(hourString: string) {
  const parts = hourString.split(":");
  return [parseInt(parts[0], 10), parseInt(parts[1], 10)];
}

export const getSlotsForDate = ({
  date,
  contactPoint,
}: {
  date: Date;
  contactPoint: any;
}) => {
  if (!date) {
    return [];
  }
  const weekDayName = reverseMapping[getDay(date)];
  const hourRanges = contactPoint.schedule[weekDayName].values;
  const validHours = [];
  for (let idx = 0; idx < hourRanges.length; idx += 1) {
    const [startHour, startMinute] = parseHourString(hourRanges[idx].start);
    const [endHour, endMinute] = parseHourString(hourRanges[idx].end);

    const value = setHours(setMinutes(new Date(), startMinute), startHour);
    const label = `${startHour}h${pad(startMinute)} - ${endHour}h${pad(
      endMinute
    )}`;

    validHours.push({
      label,
      value,
    });
  }
  return validHours;
};

export const resetHour = ({
  date,
  contactPoint,
}: {
  date: Date;
  contactPoint: any;
}) => {
  const weekDayName = reverseMapping[getDay(date)];
  const hourRanges = contactPoint.schedule[weekDayName].values;

  const availableSlots = hourRanges.map((it: any) => {
    const [hour, minute] = parseHourString(it.start);
    return setHours(setMinutes(new Date(), minute), hour);
  });

  const availableHours = hourRanges.map((it: any) => {
    return parseHourString(it.start)[0];
  });

  const firstAvailableHour = availableSlots[0];

  return availableHours.includes(getHours(date))
    ? date
    : setHours(
        setMinutes(date, getMinutes(firstAvailableHour)),
        getHours(firstAvailableHour)
      );
};

export const isCpDelivery = (type: string) => {
  return [
    constants.CONTACT_POINT_TYPE.DELIVERY,
    constants.CONTACT_POINT_TYPE.CHRONOPOST,
    constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY,
    constants.CONTACT_POINT_TYPE.CHRONOPOST_VITI,
  ].includes(type);
};

export const isCpChronopost = (type: string) => {
  return [
    constants.CONTACT_POINT_TYPE.CHRONOPOST,
    constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY,
    constants.CONTACT_POINT_TYPE.CHRONOPOST_VITI,
  ].includes(type);
};

export const isCPChronoPostRelay = (type: string) => {
  return type === constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY;
};

export const isCPChronoPostViti = (type: string) => {
  return type === constants.CONTACT_POINT_TYPE.CHRONOPOST_VITI;
};

export function pad(n: number) {
  return n < 10 ? "0" + n : n;
}

const addVAT = (rate: number): number => {
  const subventionRateWithVAT = rate * (1 + vat);

  return currencyRound(subventionRateWithVAT);
};

export const currencyRound = (amount: number) => parseFloat(amount.toFixed(2));
