import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Map from "../Checkout/Components/Map";
import AddressInput from "./AddressInput";
import { ContactPointShape } from "../Checkout/proptypes";
import {
  calculateDeliveryRate,
  isCpDelivery,
  isCpChronopost,
  calculateDeliveryTotal,
  splitPackagesToWeight,
  calculateServiceType,
  calculateTotalWeight,
} from "../Checkout/utils";

import { Grid } from "@material-ui/core";
import { getProductsForProducer } from "../Helpers/cart";
import { NumberFormatCustom } from "@kuupanda/commons";

const Container = styled(Grid)``;

const DeliveryInfo = styled.div``;

const StyledAddressInput = styled(AddressInput)`
  padding: 30px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.palette.grey["100"]};
  border-radius: 5px;
`;

const ContactPointMap = styled.div`
  width: 100%;
`;
function ContactPointExtraInfo({
  className,
  vendorId,
  contactPoint,
  clientAddress,
  onChangeClientAddress,
  totalAmount,
  isSimplifiedStore,
  cart,
}) {
  const isDelivery = isCpDelivery(contactPoint.type);

  const isChronoPost = isCpChronopost(contactPoint.type);

  const mapEnabled = !isDelivery;

  const cartProducts = getProductsForProducer(
    cart,
    vendorId,
    isSimplifiedStore
  );

  const deliveryType = calculateServiceType({
    products: cartProducts,
    contactPoint,
  });

  const totalWeight = calculateTotalWeight({ products: cartProducts });

  const packages = splitPackagesToWeight(totalWeight, contactPoint);

  let selectedRate;
  if (isDelivery && clientAddress) {
    if (isChronoPost) {
      selectedRate = calculateDeliveryTotal({
        packages,
        products: cartProducts,
        contactPoint,
        deliveryType,
        totalAmount,
      });
    } else {
      selectedRate = calculateDeliveryRate({
        contactPointInfo: contactPoint,
        clientAddress,
        totalAmount,
      });
    }
  }

  const invalidRate =
    isDelivery &&
    clientAddress &&
    (selectedRate === undefined || selectedRate === null);

  const validRate =
    isDelivery &&
    clientAddress &&
    selectedRate !== undefined &&
    selectedRate !== null;

  return (
    <Container
      container
      item
      xs={12}
      className={className}
      alignItems="flex-start"
    >
      {contactPoint && (
        <>
          {mapEnabled && (
            <ContactPointMap className="contact-point-map">
              <Map
                lat={contactPoint.address.lat}
                lng={contactPoint.address.lng}
              />
            </ContactPointMap>
          )}
        </>
      )}
      {isDelivery && (
        <StyledAddressInput
          vendorId={vendorId}
          value={clientAddress}
          onChangeField={onChangeClientAddress}
        />
      )}

      {isDelivery &&
        (!clientAddress || validRate || (invalidRate && !isChronoPost)) && (
          <DeliveryInfo
            className={`${
              validRate ? "woocommerce-info" : "woocommerce-error"
            }`}
          >
            {!clientAddress && (
              <span>
                Remplissez votre adresse pour calculer le prix de livraison.
              </span>
            )}
            {validRate && (
              <span>
                Le prix de livraison à cette adresse est de{" "}
                <NumberFormatCustom value={selectedRate} displayType={"text"} />
              </span>
            )}
            {invalidRate && !isChronoPost && (
              <span>
                Votre adresse est en dehors de la zone de livraison. Veuillez
                sélectionner une autre méthode pour récupérer vos produits.
              </span>
            )}
          </DeliveryInfo>
        )}
    </Container>
  );
}
ContactPointExtraInfo.propTypes = {
  className: PropTypes.string,
  contactPoint: ContactPointShape.isRequired,
  clientAddress: PropTypes.object,
  onChangeClientAddress: PropTypes.func.isRequired,
  totalAmount: PropTypes.string.isRequired,
  cart: PropTypes.object,
};

ContactPointExtraInfo.defaultProps = {
  className: undefined,
  clientAddress: undefined,
  cart: {},
};

export default ContactPointExtraInfo;
