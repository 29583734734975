import React from "react";
import styled from "styled-components";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

const Container = styled.button<Pick<Props, "fullWidth" | "userName">>`
  color: #fff;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  line-height: 1.4;
  user-select: none;
  padding: ${(props) => (props.userName ? "revert" : "7.2px 15px")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  width: ${(props) =>
    props.fullWidth ? "100%" : props.userName ? "38px" : ""};
  height: ${(props) => (props.userName ? "38px" : "")};
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: 0;
  }
`;

type Props = {
  userName: string;
  onClick: React.MouseEventHandler;
  fullWidth?: boolean;
};

const UserButton: React.FC<Props> = ({ fullWidth, userName, onClick }) => {
  return (
    <Container
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={onClick}
      fullWidth={fullWidth}
      userName={userName}
    >
      {userName ? (
        <PersonOutlineIcon />
      ) : (
        <span style={{ fontSize: 16 }}>Inscription / Connexion</span>
      )}
    </Container>
  );
};

export default UserButton;
