import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//utils
import media from "../../Helpers/media";
import { useWhichBrand } from "../../Hooks/useWhichBrand";

//styled
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledTab = styled(Tab)`
  height: 56px;
  margin-right: 30px;
  &:last-child {
    margin-right: 0px;
  }
  padding: 17px 15px;
  opacity: 1;
  min-width: inherit !important;
  text-transform: capitalize;
  white-space: nowrap;
  &.Mui-selected > .MuiTab-wrapper {
    font-weight: bold;
  }
  &.MuiTab-wrapper {
    white-space: nowrap;
  }
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: ${(props) => props.theme.palette.secondary.main};
    transition: height 0.3s ease;
    border-radius: 2px 2px 0 0;
  }
  :hover {
    :after {
      height: 4px;
    }
  }
  ${media.down.lg`
   margin-right: 0px;
   &:last-child {
    margin-right: 0px;
   }
  `}
`;

const StyledAppBar = styled(AppBar)`
  ${(props) =>
    props.isGranVillage
      ? css`
          color: currentColor;
        `
      : ""}
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;
  position: sticky;
  box-shadow: 0 0.2rem 0.4rem 0 hsla(0, 2%, 89%, 0.5);
  ${media.down.md`
    position: absolute;
    width: 1000px;
    left: 40%;
  `};
`;

const StyledTabs = withStyles(
  (theme) => ({
    root: {
      backgroundColor: "white",
      borderRadius: "0.4rem",
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: 4,
      "& > span": {
        width: "100%",
        borderRadius: "2px 2px 0 0",
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
  { withTheme: true }
)((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export function TheNavBar({ value, onChange, tabStatic }) {
  const { isGranVillage } = useWhichBrand();
  return (
    <Wrapper>
      <StyledAppBar
        id="store_header_navbar_tabs"
        color="default"
        elevation={1}
        isGranVillage={isGranVillage}
      >
        <StyledTabs
          value={value}
          onChange={(e, v) => onChange(v)}
          textColor="inherit"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="none"
        >
          {tabStatic.map((tab, i) => (
            <TheTab key={i + tab.key} {...tab} value={tab.key} />
          ))}
        </StyledTabs>
      </StyledAppBar>
    </Wrapper>
  );
}

TheNavBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string | PropTypes.number,
  tabStatic: PropTypes.array.isRequired,
};

const TheTab = React.forwardRef(function TheTab(
  { isSM, label, Icon, ...props },
  ref
) {
  return <StyledTab disableRipple label={label} ref={ref} {...props} />;
});

TheTab.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isSM: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
