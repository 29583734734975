import React, { useCallback, useMemo } from "react";
import { useCart } from "../Context/CartContext";
import CartSummary from "../Common/CartSummary";
import Title from "../Checkout/Components/Title";
import { Button, SvgIcon, Typography, withWidth } from "@material-ui/core";
import ProductCounter from "../Common/ProductCounter";
import { ReactComponent as Trash } from "../Assets/Icons/trash.svg";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

function getCellExtensions({ width }) {
  if (width === "xs") {
    return {
      name: {
        verticalAlign: "baseline",
      },
      quantity: {
        verticalAlign: "baseline",
      },
    };
  }
  return {
    name: {
      verticalAlign: "baseline",
    },
    quantity: {
      verticalAlign: "baseline",
    },
    total: {
      verticalAlign: "baseline",
    },
    action: {
      verticalAlign: "baseline",
    },
  };
}

function getColumns({ width }) {
  if (width === "xs") {
    return [
      { name: "name", title: "Name" },
      { name: "quantity", title: "Quantity" },
      { name: "action", title: "Action" },
    ];
  }
  return [
    { name: "name", title: "Name" },
    { name: "quantity", title: "Quantity" },
    { name: "total", title: "Total" },
    { name: "action", title: "Action" },
  ];
}

function getColumnExtensions({ width }) {
  if (width === "xs") {
    return [
      { columnName: "name", width: "60%", align: "left" },
      { columnName: "quantity", align: "center" },
      { columnName: "action", align: "right" },
    ];
  }
  return [
    { columnName: "name", align: "left" },
    { columnName: "quantity", width: 150, align: "center" },
    { columnName: "total", width: 150, align: "right" },
    { columnName: "action", width: 80, align: "right" },
  ];
}

function cartTableFormat(
  { quantity, product },
  { width, handleChangeCart, onRemoveItemFromCart }
) {
  const result = {
    name: (
      <>
        <Title color="primary" noWrap={true}>
          {product.name}
        </Title>
        <Typography variant="body1" color="textSecondary">
          {product.producer.name}
        </Typography>
      </>
    ),
    quantity: (
      <ProductCounter
        product={product}
        quantity={quantity}
        onChangeCart={handleChangeCart}
      />
    ),
    action: (
      <Button onClick={() => onRemoveItemFromCart(product.id)}>
        <SvgIcon titleAccess="delete">
          <Trash />
        </SvgIcon>
      </Button>
    ),
  };

  if (width === "xs") {
    return {
      ...result,
      name: (
        <>
          <Title color="textPrimary" noWrap={true}>
            {product.name}
          </Title>
          <Typography variant="body1" color="textSecondary">
            {product.producer.name}
          </Typography>
        </>
      ),
      quantity: (
        <>
          <Title color="textPrimary">
            {(quantity * product.price).toFixed(2)}€
          </Title>
          <ProductCounter
            product={product}
            quantity={quantity}
            onChangeCart={handleChangeCart}
          />
        </>
      ),
    };
  }

  return {
    ...result,
    total: (
      <Title color="textPrimary">
        {(quantity * product.price).toFixed(2)}€
      </Title>
    ),
  };
}

const CartSummaryContainer = styled.div`
  margin: ${({ width }) =>
    width !== "xs" && width !== "sm" ? "50px auto" : "50px 40px"};
  max-width: 800px;
`;

const CartSummaryWrapper = ({ width }) => {
  const theme = useTheme();
  const { cart, removeFromCart, changeQuantity, getCartTotals } = useCart();

  const cartTotals = useMemo(() => getCartTotals({}), [getCartTotals]);

  const handleRemoveItemFromCart = useCallback(
    (productID) => removeFromCart(productID),
    [removeFromCart]
  );

  const handleChangeCart = useCallback(
    ({ product, quantity }) => {
      changeQuantity(product.id, quantity);
    },
    [changeQuantity]
  );

  const cartList = useMemo(
    () =>
      Object.values(cart).map(({ quantity, product }) =>
        cartTableFormat(
          { quantity, product },
          {
            width,
            handleChangeCart,
            onRemoveItemFromCart: handleRemoveItemFromCart,
          }
        )
      ),
    [cart, width, handleChangeCart, handleRemoveItemFromCart]
  );

  const cellStyles = useMemo(
    () => ({
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.grey.A200,
    }),
    [theme]
  );

  return (
    <CartSummaryContainer width={width}>
      <CartSummary
        total={cartTotals.total}
        cart={cart}
        cartList={cartList}
        cellStyles={cellStyles}
        cellExtensions={getCellExtensions({ width })}
        columns={getColumns({ width })}
        columnExtensions={getColumnExtensions({ width })}
        dividerColor="white"
        showCoupon={false}
        showDisabledCoupon={false}
      />
    </CartSummaryContainer>
  );
};

export default withWidth()(CartSummaryWrapper);
