import React from "react";
import styled from "styled-components";
import { ContactPointShape } from "../Checkout/proptypes";
import constants from "./constants";
import Title from "../Checkout/Components/Title";
import { Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// Styleds
const Container = styled.div`
  @media (max-width: 600px) {
    margin: 20px 0 0;
  }
`;

const DateContainer = styled.div`
  margin: 10px 0 0 20px;
  display: flex;
  align-items: center;
`;

const DateText = styled(Typography)`
  margin: 0 5px 0 0px;
  font-weight: bold;
`;

const ArrowIcon = styled(ArrowForwardIcon)`
  border: 1px solid;
  border-radius: 50px;
  font-size: 10px;
  margin-right: 5px;
`;

const getFormattedFrequency = (frequency, isRelay) => {
  const baseMessage = isRelay
    ? "Le colis arrivera entre"
    : "Vous serez livré entre";
  let formattedFrequency;

  switch (frequency) {
    case constants.CHRONOPOST_DELIVERY_FREQUENCY.EVERYDAY:
      formattedFrequency = `${baseMessage} 1 et 3 jours`;
      break;
    case constants.CHRONOPOST_DELIVERY_FREQUENCY.EVERY_TWO_DAYS:
      formattedFrequency = `${baseMessage} 2 et 4 jours`;
      break;
    case constants.CHRONOPOST_DELIVERY_FREQUENCY.TWICE_A_WEEK:
      formattedFrequency = `${baseMessage} 3 et 5 jours`;
      break;
    case constants.CHRONOPOST_DELIVERY_FREQUENCY.ONCE_A_WEEK:
      formattedFrequency = `${baseMessage} 5 et 7 jours`;
      break;
    default:
      formattedFrequency = `${baseMessage}`;
  }

  return formattedFrequency;
};

function DeliveryDateFrequency({ contactPoint, isRelay }) {
  return (
    <Container>
      <Title color="textPrimary">
        <ArrowIcon /> Date de Livraison
        <DateContainer variant="body1" component="div" color="textPrimary">
          <DateText component="span" color="primary">
            {getFormattedFrequency(contactPoint.frequency, isRelay)}
          </DateText>
        </DateContainer>
      </Title>
    </Container>
  );
}

DeliveryDateFrequency.propTypes = {
  contactPoint: ContactPointShape.isRequired,
};

export default DeliveryDateFrequency;
