import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import Input from "@material-ui/core/Input";
import { NumberFormatCustom } from "@kuupanda/commons";

// Styled
const QuantitySelector = styled(Input)`
  border-radius: 5px;
  height: 38px;
  input {
    text-align: center;
  }
`;

const QuantityUp = styled.span`
  z-index: 99;
  width: 20px;
  height: 20px;
  color: white;
  background-color: ${({ theme }) => theme.palette.grey["700"]};
  border-radius: 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const QuantityDown = styled.span`
  z-index: 99;
  width: 20px;
  height: 20px;
  color: white;
  background-color: ${({ theme }) => theme.palette.grey["700"]};
  border-radius: 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Stepper = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.grey["100"]};
  padding: 0 15px;
`;

const CustomInput = React.forwardRef((props, ref) => {
  const { fullWidth, error, disabled, ...rest } = props;

  return (
    <QuantitySelector
      inputRef={ref}
      disabled={disabled}
      inputProps={{ inputMode: "decimal" }}
      fullWidth={fullWidth}
      error={error}
      disableUnderline
      {...rest}
    />
  );
});

const InputStepper = ({
  className,
  id,
  value,
  onChange,
  step,
  availableQuantity,
  onReachMaxQuantity,
}) => {
  const handleChange = useCallback(
    (value) => {
      // don't allow more than available amount
      if (parseInt(value) > parseInt(availableQuantity)) {
        onReachMaxQuantity && onReachMaxQuantity();
        return;
      }

      onChange(parseFloat(value));
    },
    [availableQuantity, onChange, onReachMaxQuantity]
  );

  return (
    <Container className={className}>
      <QuantityDown>
        <Stepper onClick={() => handleChange(parseFloat(value) - 1)}>-</Stepper>
      </QuantityDown>
      <NumberFormatCustom
        id={id}
        fullWidth
        onChange={(event) => handleChange(parseFloat(event.target.value))}
        customInput={CustomInput}
        suffix={" "}
        fixedDecimalScale={false}
        inputProps={{
          step,
          style: { textAlign: "center", height: 38, padding: 0 },
        }}
        value={value}
        disableUnderline
        decimalScale={step === 1 ? 0 : 2}
      />
      <QuantityUp>
        <Stepper onClick={() => handleChange(parseFloat(value) + 1)}>+</Stepper>
      </QuantityUp>
    </Container>
  );
};

InputStepper.protoTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

InputStepper.defaultProps = {
  id: "",
  className: "",
  value: "",
  onChange: () => {},
};

export default InputStepper;
