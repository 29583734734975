import React from "react";
import PropTypes from "prop-types";
import { Box, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export function InformationContent({ store }) {
  return (
    <div>
      <InformationRow primary={"Producteur"} secondary={store.fullName} />
      <InformationRow
        primary={"Email"}
        secondary={
          <Link
            color="inherit"
            href={`mailto:${store.email}?subject=[Kupanda]%20`}
          >
            {store.email}
          </Link>
        }
      />
      <InformationRow primary={"Adresse"} secondary={store.address} />
      <InformationRow primary={"Téléphone"} secondary={store.phone} />
      <InformationRow
        primary={"Méthode de production"}
        secondary={store.productionMethod}
      />
    </div>
  );
}

InformationContent.propTypes = {
  store: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  primary: {
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
  },
}));

const InformationRow = React.memo(function InformationRow({
  primary,
  secondary,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography
        align={"right"}
        className={classes.primary}
        component={"span"}
        noWrap
      >
        {primary}:
      </Typography>
      <Box ml={1} flexGrow={1} component={"span"}>
        <Typography component={"span"}>{secondary}</Typography>
      </Box>
    </Box>
  );
});

InformationRow.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.any.isRequired,
};
