import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Form } from "./Form";
export function VendorFilter({ value, onChange, dense, onSubmit }) {
  const theme = useTheme();

  const [vendor, setVendor] = useState(value);
  const [timeOutSearch, setTimeOutSearch] = useState(null);

  useEffect(() => {
    if (value === "") {
      setVendor(value);
    }
  }, [value]);

  const handleCallback = useCallback(
    (event) => {
      const data = event.target.value;
      setVendor(data);

      if (timeOutSearch) {
        clearTimeout(timeOutSearch);
        setTimeOutSearch("");
      }
      const timeOutSearh = setTimeout(() => {
        onChange(data);
      }, 800);
      setTimeOutSearch(timeOutSearh);
    },
    [onChange, timeOutSearch]
  );

  return (
    <Form onSubmit={onSubmit}>
      <TextField
        variant={"outlined"}
        value={vendor}
        style={{
          minWidth: 250,
          width: "100%",
          marginBottom: dense ? theme.spacing(1) : 0,
        }}
        onChange={handleCallback}
        label={"Rechercher un producteur"}
        margin={dense ? "dense" : "none"}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <IconButton
                variant="text"
                // color={'primary'}
                type="submit"
                onClick={onSubmit}
                // icon already have some good padding, so we're cancelling the container inner padding with neg margin
                style={{ marginRight: -9 }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Form>
  );
}

VendorFilter.propTypes = {
  dense: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
