import React, { createContext, useContext } from "react";

const ApiContext = createContext({
  apiRoot: "",
});

export function useApiConfig() {
  const context = useContext(ApiContext);
  if (!context) {
    return {};
  }
  return context;
}
export function ApiProvider({ children }) {
  return (
    <ApiContext.Provider
      value={{
        apiRoot:
          process.env.REACT_APP_API_URL /* Change should be in an environment*/,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export default ApiContext;
