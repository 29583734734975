import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Grid, Typography } from "@material-ui/core";

//context
import { usePresentation } from "../../../Context/PresentationContext";

//components
import ProductItem from "./ProductItem";

export const SECTION_HEADER_Y_PAD = 4;

//styled
const ProductsBox = styled(Box)`
  margin-bottom: 50px;
`;

export function ProductSection({
  section,
  cart,
  categoryNameMap,
  onAdd,
  onProductClick,
  onRemove,
  onChange,
}) {
  const {
    presentationOptions: { simpleProductCard },
  } = usePresentation();
  return (
    <ProductsBox>
      <Box my={SECTION_HEADER_Y_PAD} id={section.category}>
        <Typography variant={"h3"} align={"left"}>
          {categoryNameMap[section.category]}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {section.products.map((product) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={product.id}
            className="product-item"
          >
            <ProductItem
              product={product}
              quantity={cart[product.id]}
              onAdd={onAdd}
              onRemove={onRemove}
              onClick={onProductClick}
              onChange={onChange}
            />
          </Grid>
        ))}
      </Grid>
    </ProductsBox>
  );
}

ProductSection.propTypes = {
  cart: PropTypes.object,
  categoryNameMap: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
};

export function SectionHeader({ children }) {
  return (
    <Box my={4}>
      <Typography variant={"h4"} align={"center"}>
        {children}
      </Typography>
    </Box>
  );
}
