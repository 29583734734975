import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import Grid from "@material-ui/core/Grid";

const Container = styled(Grid)`
  margin-top: 40px;
  width: 100%;
  height: 100%;
`;
const Header = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.grey["600"]};
  border-radius: 8px 8px 0px 0px;
  padding: 20px;
  ${({ theme }) => `
    color: ${theme.palette.grey[800]};
  `};
  height: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
`;
const Body = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.palette.grey["600"]};
  background: ${({ theme }) => theme.palette.grey["50"]};
  padding: 20px;
  height: 100%;
  min-height: 500px;
`;

const Section = ({ title, children }) => {
  return (
    <Container container justify="center">
      <Header container item alignItems="center" xs={10}>
        {title}
      </Header>
      <Body container item xs={10} justify="center">
        {children}
      </Body>
    </Container>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

Section.defaultProps = {
  title: "",
};

export default Section;
