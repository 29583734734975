import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import localStorageManager from "../Helpers/localStorageManager";

export const PROPTYPES = {
  coordShape: {
    lat: PropTypes.number,
    lng: PropTypes.number,
  },
};

export const latLng = (lat, lng) => ({ lat, lng });

export function getHOCDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function pseudoGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `_${s4()}${s4()}-${s4()}`;
}

export const getProductCountText = (productCount) =>
  productCount
    ? `${productCount} produits dans la boutique`
    : "Pas de produits disponibles";
export const getProductCountTextShort = (productCount) =>
  productCount ? `${productCount} produits` : "Pas de produits disponibles";

export const getLocalStorage = (key) => {
  let value = localStorageManager.getItem(key);
  if (!value) return value;
  try {
    value = JSON.parse(value);
  } catch (e) {
    value = null;
  }
  return value;
};

export const setLocalStorage = (key, value) => {
  localStorageManager.setItem(key, JSON.stringify(value));
};
export const withTextSecondary = withStyles((theme) => ({
  root: { color: theme.palette.grey["800"] },
}));
