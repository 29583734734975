import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import RestoreIcon from "@material-ui/icons/Restore";
import { isEmpty } from "lodash-es";
import {
  calculateVariableWeight,
  getBaseUnit,
  getProductName,
  getProductUnit,
} from "../../services/utils";
import MediaSlider from "../../../Common/MediaSlider";
import { QuantityModal } from "../../../Common/Quantity";
import SizeBox from "../../../Common/SizeBox";
import { NumberFormatCustom } from "@kuupanda/commons";

const StyledListItem = styled(ListItem)`
  padding: 0 !important;
`;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const StyledDialog = styled(Dialog)`
  > div > div {
    max-width: 800px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  width: 100%;
  padding: 0;
  padding-top: 0 !important;
  ${(props) =>
    !props.$isMobile &&
    `width: 600px;
  `}
`;

const StyledIconButton = styled(IconButton)`
  color: #ffffff;
  padding: 2px;
  right: 20px;
  &:hover {
    ${({ theme }) => `
    opacity: 0.5;
    background-color: ${theme.palette.grey["A400"]} !important;
  `}
  }
  ${({ theme }) => `
    background-color: ${theme.palette.grey["A400"]};
  `}
`;
const mediaHeight = 300;
const MediaSliderContainer = styled.div`
  width: 100%;
  height: ${mediaHeight}px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const AvailableDateContainer = styled.span`
  background: rgba(110, 113, 145, 0.85);
  backdrop-filter: blur(4px);
  position: absolute;
  border-radius: 4px;
  right: 14px;
  top: 265px;
  color: white;
  font-weight: 500;
  padding: 2px;
`;

const StyledRestoreIcon = styled(RestoreIcon)`
  vertical-align: text-bottom;
  font-size: 16px;
`;

const Price = styled.span`
  color: ${({ theme }) => theme.palette.warning.main};
  font-size: 19px;
  font-weight: 500;
`;

const Units = styled.span`
  ${({ theme }) => `
    color: ${theme.palette.grey["700"]};
  `}
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
`;

const PriceContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  line-height: 127.5%;
  color: #000000;
`;

const SizeBoxHolder = styled.div`
  margin-top: 20px;
`;

const Description = styled.div`
  margin-top: 20px;
  color: #464861;
`;

const StyledQuantityModal = styled(QuantityModal)`
  margin-top: 20px !important;
`;

const Content = styled.div`
  padding: 20px;
`;

const NoStock = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  line-height: 1;
  margin-top: 10px;
`;

export function ProductModal({
  open,
  onClose,
  product,
  onAdd,
  quantity,
  availableDate,
  isAvailable,
  hideAddModal,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  if (!product) return null;

  product = product || {};

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      fullScreen={fullScreen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        id: "kuu-product-modal",
      }}
    >
      <StyledDialogContent $isMobile={isMobile}>
        {!isEmpty(product) && (
          <>
            <MediaSliderContainer>
              <MediaSlider pictures={product.pictures} height={mediaHeight} />
            </MediaSliderContainer>

            {availableDate && availableDate !== "" && (
              <AvailableDateContainer isEmbedded={true}>
                <span>Disponible à partir du</span> {availableDate}{" "}
                <StyledRestoreIcon />
              </AvailableDateContainer>
            )}
            {onClose ? (
              <StyledIconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon color="inherit" />
              </StyledIconButton>
            ) : null}

            <Content>
              <Title variant={"h5"}>{getProductName(product)}</Title>

              {product.productSizesList && product.productSizesList.length > 0 && (
                <SizeBoxHolder>
                  <SizeBox sizes={product.productSizesList} />
                </SizeBoxHolder>
              )}

              <PriceContainer>
                {product.price !== "0.00" ? (
                  <>
                    <Price>
                      <NumberFormatCustom
                        value={product.price}
                        displayType={"text"}
                      />
                    </Price>
                    <Units>{`/ ${getBaseUnit(product)}`}</Units>
                  </>
                ) : (
                  <Price>Paiement sur place</Price>
                )}

                <Typography color={"textSecondary"}>
                  {product.baseUnit === "unit" &&
                    product.averageWeight &&
                    product.variableUnit &&
                    product.variableWeightPrice && (
                      <>
                        <NumberFormatCustom
                          value={calculateVariableWeight(product)}
                          displayType="text"
                          suffix={product.isProfessional ? " € HT" : " €"}
                        />
                        {" / "}
                      </>
                    )}

                  {getProductUnit(product)}
                </Typography>
              </PriceContainer>
              {!isAvailable && <NoStock noWrap>Rupture de stock</NoStock>}
              <Description>
                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
                {product.childProducts.length > 0 && (
                  <List>
                    {product.childProducts.length > 0 &&
                      product.childProducts.map((it, index) => {
                        return (
                          <StyledListItem key={index}>
                            • {it.quantity} x {it.name}
                          </StyledListItem>
                        );
                      })}
                  </List>
                )}
              </Description>

              {isAvailable && (
                <StyledQuantityModal
                  id={product.id}
                  product={product}
                  value={quantity}
                  onChange={onAdd}
                  hideAddModal={hideAddModal}
                />
              )}
            </Content>
          </>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
}

ProductModal.propTypes = {
  quantity: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.object,
  availableDate: PropTypes.string,
  hideAddModal: PropTypes.bool,
};

ProductModal.defaultProps = {
  availableDate: "",
  hideAddModal: false,
};
