import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Schedule from "./Schedule";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { RowBase } from "./RowBase";
import InfoIcon from "@material-ui/icons/Info";

const DeliveryAvailableText = styled.span`
  padding: 6px 40px;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.palette.others.brown};
  background-color: ${({ theme }) => theme.palette.info.light};
  border: 1px solid ${({ theme }) => theme.palette.others.yellow};
`;

const DeliveryFreeText = styled.span`
  padding: 6px 40px;
  color: ${({ theme }) => theme.palette.others.a};
  background-color: ${({ theme }) => theme.palette.others.b};
  border: 1px ${({ theme }) => theme.palette.others.c} solid;
  border-radius: 0.25rem;
`;

export function DeliveryRow(props) {
  const { marker } = props;
  console.log("marker", marker);

  return (
    <RowBase {...props}>
      <React.Fragment>
        <Typography
          variant={"body1"}
          style={{ fontWeight: "bold" }}
          // className={classes.title}
        >
          {marker.name}
        </Typography>
        <ListItem dense>
          <ListItemIcon>
            <SettingsEthernetIcon />
          </ListItemIcon>
          {["CHRONOPOST", "CHRONOPOST_RELAY", "CHRONOPOST_VITI"].includes(
            marker.type
          ) ? (
            <ListItemText>Partout en France</ListItemText>
          ) : (
            <ListItemText>
              Jusqu&apos;à {marker.radius}km autour de l'exploitation
            </ListItemText>
          )}
        </ListItem>
        <ListItem dense>
          <ListItemIcon style={{ alignSelf: "start", marginTop: 4 }}>
            <ScheduleIcon />
          </ListItemIcon>
          {["CHRONOPOST", "CHRONOPOST_RELAY", "CHRONOPOST_VITI"].includes(
            marker.type
          ) ? (
            <ListItemText>Livré par Chronopost</ListItemText>
          ) : (
            <Schedule schedule={marker.schedule} style={{ width: "100%" }} />
          )}
        </ListItem>
        {marker.comment && marker.comment !== "" && (
          <ListItem dense>
            <ListItemIcon style={{ alignSelf: "start", marginTop: 4 }}>
              <InfoIcon color="primary" />
            </ListItemIcon>
            {marker.comment}
          </ListItem>
        )}
        {marker.minimumDeliveryAmount && marker.minimumDeliveryAmount !== "" && (
          <ListItem>
            <DeliveryAvailableText>
              Livraison disponible à partir de {marker.minimumDeliveryAmount}€
              d'achat
            </DeliveryAvailableText>
          </ListItem>
        )}
        {marker.freeFeeOrderAmount && marker.freeFeeOrderAmount !== "" && (
          <ListItem>
            <DeliveryFreeText>
              Livraison gratuite à partir de {marker.freeFeeOrderAmount}€
              d'achat
            </DeliveryFreeText>
          </ListItem>
        )}
      </React.Fragment>
    </RowBase>
  );
}

DeliveryRow.propTypes = {
  isFirstItem: PropTypes.bool.isRequired,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onHoverChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
