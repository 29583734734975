import styled from "styled-components";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Message = styled.div`
  margin-bottom: 16px;
`;

export const Loader = styled(CircularProgress)`
  margin: auto;
`;

export default function PaymentLoader() {
  return (
    <Container>
      <Message>
        Le paiement est en cours de validation, cela peut prendre plusieurs
        secondes.
      </Message>
      <Loader size={80} />
    </Container>
  );
}
