import React, { forwardRef, useCallback, useRef } from "react";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { FixedSizeGrid } from "react-window";
import { AutoSizer, WindowScroller } from "react-virtualized";
import { UserCard } from "./UserCard";
import NoResultImg from "./no-results-420x320.png";
import styled from "styled-components";
import "./style.css";

const UserListGridInnerDiv = styled.div`
  > div {
    height: inherit;
    > a {
      height: inherit;
    }
  }
`;
function getColumnInfo({ screenWidth, containerWidth }) {
  if (screenWidth < 600) {
    return { columnWidth: containerWidth, columnCount: 1 };
  }
  if (screenWidth < 960) {
    return { columnWidth: containerWidth / 2, columnCount: 2 };
  }
  if (screenWidth < 1650) {
    return { columnWidth: containerWidth / 2, columnCount: 2 };
  }
  return { columnWidth: containerWidth / 3, columnCount: 3 };
}
const GUTTER_SIZE = 15;

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      paddingLeft: GUTTER_SIZE,
      paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
));

function BaseUserList({ users, markers, onHoverChange, openMarkerUserID }) {
  const gridRef = useRef();
  const handleScroll = useCallback(
    ({ scrollTop }) => {
      if (gridRef) {
        gridRef.current.scrollTo({
          scrollLeft: 0,
          scrollTop: scrollTop,
        });
      }
    },
    [gridRef]
  );
  const theme = useTheme();
  if (!users.length) {
    return (
      <Grid justify={"center"} container>
        <Grid item>
          <img
            src={NoResultImg}
            alt="Pas de resultats"
            style={{ borderRadius: theme.shape.borderRadius }}
          />
        </Grid>
        <Box mt={3}>
          <Typography variant={"h5"}>
            Aucun producteur trouvé avec vos critères de recherche !
          </Typography>
        </Box>
      </Grid>
    );
  }
  return (
    <AutoSizer>
      {({ height, width: containerWidth }) => {
        const { columnWidth, columnCount } = getColumnInfo({
          screenWidth: window.innerWidth,
          containerWidth,
        });
        return (
          <React.Fragment>
            <WindowScroller onScroll={handleScroll}>
              {() => <div />}
            </WindowScroller>
            <FixedSizeGrid
              ref={gridRef}
              className="UserListGrid"
              columnCount={columnCount}
              columnWidth={columnWidth - GUTTER_SIZE / 1.5}
              height={412 + GUTTER_SIZE}
              rowCount={Math.max(1, Math.floor(users.length / columnCount + 1))}
              rowHeight={412 + GUTTER_SIZE}
              width={containerWidth}
              innerElementType={innerElementType}
            >
              {({ columnIndex, rowIndex, style }) => {
                const user = users[rowIndex * columnCount + columnIndex];
                return (
                  <UserListGridInnerDiv
                    className="UserListGridInnerDiv"
                    style={{
                      ...style,
                      left: style.left + GUTTER_SIZE,
                      top: style.top + GUTTER_SIZE,
                      width: style.width - GUTTER_SIZE,
                      height: style.height - GUTTER_SIZE,
                    }}
                  >
                    {user && (
                      <UserCard
                        user={user}
                        onHoverChange={onHoverChange}
                        isSelectedFromMap={openMarkerUserID === user.clientId}
                      />
                    )}
                    {/*CELL [{columnIndex}-{rowIndex}]*/}
                    {/*{rowIndex === 90 && (*/}
                    {/*  <div>*/}
                    {/*    <img src="https://s3.eu-central-1.amazonaws.com/witbooking.prueba/user/farm/bbc7ff586dbf6166b277c8bd05abc8029c4167707668c8b2f762d714f13de8af.jpeg" />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </UserListGridInnerDiv>
                );
              }}
            </FixedSizeGrid>
          </React.Fragment>
        );
      }}
    </AutoSizer>
  );
}

BaseUserList.propTypes = {};

BaseUserList.defaultProps = {};

export const UserList = BaseUserList;
