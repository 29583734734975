import React, { ReactElement, useCallback } from "react";
import styled from "styled-components";
import { Box, Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

//componets
import { ContactPointRow } from "../storeHeader/location/ContactPointRow";
import { DeliveryRow } from "../storeHeader/location/DeliveryRow";
import LoadScriptOnlyIfNeeded from "../../../Common/LoadScriptOnlyIfNeeded";
import {
  StoreTab,
  StoreTabKey,
  usePresentation,
} from "../../../Context/PresentationContext";
import { PresentationContent } from "../storeHeader/PresentationContent";
import { LocationContent } from "../storeHeader/location/LocationContent";
import { InformationContent } from "../storeHeader/InformationContent";
import StoreContent from "../storeContent/StoreContent";
import GlobalLoader from "../../../Common/GlobalLoader";
import { useTabsWithRoutes } from "../../../Hooks/useTabsWithRoutes";

const API_KEY = "AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg";

const Container = React.memo(LoadScriptOnlyIfNeeded);

const StoreWrapper = styled.div``;

const StyledContainer = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.appBar};
`;

const StyledCardContent = styled(CardContent)`
  width: 100%;
  height: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const CurrentSelectedTab = styled.div<{
  $isSelectedTab?: boolean;
}>`
  height: 56px;
  width: 180px;
  display: flex;
  font-size: 16px;
  position: relative;
  font-weight: ${({ $isSelectedTab }) => $isSelectedTab && "bold"};
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  :first-child {
    margin-left: -30px;
  }

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ $isSelectedTab }) => ($isSelectedTab ? 4 : 0)}px;
    background: ${(props) => props.theme.palette.secondary.main};
    border-radius: 2px 2px 0 0;
    transition: height 0.3s ease;
  }

  :hover {
    :after {
      height: 4px;
    }
  }
`;
const StyledDivider = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: ${(props) => props.theme.palette.grey[300]};
  z-index: ${(props) => props.theme.zIndex.appBar};
`;

const TabsContainer = styled.div``;

interface SaleChannelType {
  id: string;
  saleChannel: "STANDARD" | "PROFESSIONAL";
  component: "STORE" | "FORM";
  isStoreClose: boolean;
  closedChannelMessage: string;
  paymentInCash: boolean;
  paymentInCreditCard: boolean;
}

interface StoreTypes {
  email: string;
  name: string;
  phone: string;
  id: string;
  bannerUrl: string;
  profileUrl: string;
  ID: string;
  storeName: string;
  fullName: string;
  city: string;
  address: string;
  presentation: string;
  clientClosedStore: boolean;
  closedStoreMessage: string;
  paymentMethods: Array<{ type: string }>;
  saleChannelsConfig: Array<SaleChannelType>;
  storeSlug: string;
}

export const StoreCarousel = React.memo(function StoreCarousel({
  store,
  deliveryPoints,
  contactPoints,
  products,
  categoryNameMap,
  loading,
  categories,
  cart,
  handleCartChange,
  onRemoveFromCart,
  isStoreClosed,
  onAddToCart,
  handleSearchProducts,
  externalId,
}: {
  store: StoreTypes;
  deliveryPoints: any;
  contactPoints: any;
  productsComponent?: ReactElement;
  products: any;
  categoryNameMap: any;
  loading: boolean;
  categories: any;
  cart: any;
  handleCartChange: (cart: any) => void;
  onRemoveFromCart: any;
  isStoreClosed: boolean;
  onAddToCart: (product: any) => void;
  handleSearchProducts: (search: string) => void;
  externalId?: string;
}) {
  const {
    presentationOptions: { storeTabs, showStoreOnTab, defaultTab },
  } = usePresentation();

  const excludeTabs = React.useMemo(
    () => ({
      delivery: !deliveryPoints?.length,
      contact: !contactPoints?.length,
    }),
    [deliveryPoints, contactPoints]
  );

  const { tabsArr, currentTabKey, handleTabClick } = useTabsWithRoutes({
    tabs: storeTabs,
    defaultTab,
    excludeTabs,
  });

  const _handleTabClick = useCallback(
    (currentTab: StoreTab) => {
      if (externalId) {
        handleTabClick(currentTab.key, { externalId });
      } else {
        handleTabClick(currentTab.key);
      }

      const tabRef = window.document.getElementById(currentTab.key);
      tabRef &&
        tabRef.scrollIntoView({
          inline: "center",
          block: "nearest",
          behavior: "smooth",
        });
    },
    [externalId, handleTabClick]
  );

  if (loading) {
    return (
      <Card>
        {Array(4)
          .fill(null)
          .map((e, i) => (
            <Box
              key={`loadingMobileContainerTab${i}`}
              height={65}
              style={{
                padding: "0 24px 0 24px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, .03)",
                border: "1px solid rgba(0, 0, 0, 0.05)",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                height={20}
                width="80%"
              />
              <Skeleton
                animation="wave"
                variant="circle"
                height={20}
                width={20}
              />
            </Box>
          ))}
      </Card>
    );
  }

  const tabContents: Partial<{ [k in StoreTabKey]: JSX.Element }> = {
    description: <PresentationContent presentation={store.presentation} />,
    store: (
      <StoreContent
        products={products}
        categoryNameMap={categoryNameMap}
        loading={loading}
        categories={categories}
        cart={cart}
        handleCartChange={handleCartChange}
        store={store}
        onRemoveFromCart={onRemoveFromCart}
        isStoreClosed={isStoreClosed}
        onAddToCart={onAddToCart}
        handleSearchProducts={handleSearchProducts}
        fixed={false}
      />
    ),
    contactPoints: (
      <LocationContent
        contactPoints={contactPoints}
        RowComponent={ContactPointRow}
        mapTitle={"Points de retraits"}
        isDelivery={false}
      />
    ),
    delivery: (
      <LocationContent
        contactPoints={deliveryPoints}
        mapTitle={
          <Box display={"flex"} alignItems={"center"}>
            Livraisons
          </Box>
        }
        RowComponent={DeliveryRow}
        isDelivery={true}
      />
    ),
    information: <InformationContent store={store} />,
  };

  return (
    //@ts-ignore
    <Container googleMapsApiKey={API_KEY} loadingElement={<GlobalLoader />}>
      <StyledContainer>
        <StyledDivider />
        <ButtonsWrapper>
          {tabsArr.map((tb: any) => (
            <CurrentSelectedTab
              key={tb.label}
              id={tb.key}
              onClick={() => _handleTabClick(tb)}
              $isSelectedTab={tb.key === currentTabKey}
            >
              {tb.label}
            </CurrentSelectedTab>
          ))}
        </ButtonsWrapper>
      </StyledContainer>

      <TabsContainer>
        <StyledCardContent>
          {tabContents[currentTabKey as StoreTabKey] || null}
        </StyledCardContent>
      </TabsContainer>

      <StoreWrapper>
        {!showStoreOnTab && currentTabKey !== "store" && (
          <StyledCardContent>
            <StoreContent
              products={products}
              categoryNameMap={categoryNameMap}
              loading={loading}
              categories={categories}
              cart={cart}
              handleCartChange={handleCartChange}
              store={store}
              onRemoveFromCart={onRemoveFromCart}
              isStoreClosed={isStoreClosed}
              onAddToCart={onAddToCart}
              handleSearchProducts={handleSearchProducts}
              fixed={false}
            />
          </StyledCardContent>
        )}
      </StoreWrapper>
    </Container>
  );
});
