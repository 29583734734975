import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Hidden, Typography } from "@material-ui/core";

// helpers
import media from "../../Helpers/media";

// assets
import { ReactComponent as Circle } from "../../Assets/Images/circle.svg";
import ResponsivePicture from "../../Common/ResponsivePicture";

// styled
const CategoryTitle = styled(Typography)`
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  line-height: 46px;
  align-items: center;
  text-align: center;
  ${({ theme }) => `
    color: ${theme.palette.grey[800]};
  `};
  ${media.down.md`
    font-size: 24px;
  `}
`;

const CategoryItemTile = styled.div`
  cursor: pointer;
  position: relative;
  border: 2px solid ${({ theme }) => theme.palette.grey["50"]};
  box-sizing: border-box;
  filter: drop-shadow(0px 5px 26px rgba(74, 74, 74, 0.03));
  border-radius: 16px;
  height: 153px;
  ${media.down.lg`
    width: 100%;
  `}
`;

const CategoryItemTitle = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  white-space: nowrap;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
  ${media.down.lg`
    padding-bottom: 50px;
  `}
`;

const CircleIcon = styled(Circle)`
  position: absolute;
  top: 0;
  z-index: -1;
`;

const CategoriesContainer = styled.div`
  margin-top: 25px;
  margin-left: 132px;
  margin-right: 132px;
  ${media.down.lg`
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  `}
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 16px;
`;

const Spacer = styled.div`
  margin-top: 10px;
  ${media.up.md`
    margin-top: 75px;
  `}
`;
const Category = ({ text, image, value, onCategoryChange }) => {
  return (
    <CategoryItemTile onClick={() => onCategoryChange(value)}>
      <StyledResponsivePicture
        alt={text}
        height={153}
        sizeQuery="(max-width: 959px) 80vw, (min-width: 960px) 20vw"
        baseImgUrl={image}
        fitType="cover"
      />
      <CategoryItemTitle>{text}</CategoryItemTitle>
    </CategoryItemTile>
  );
};
const firstCategoryRow = [
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/fruits.png",
    text: "Fruits et légumes",
    value: "_FRUITS-VEGETABLES",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/bakery.png",
    text: "Boulangerie Patisserie",
    value: "_BAKERY",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/bee.png",
    text: "Produit de la ruche",
    value: "_BEE-PRODUCTS",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/plants.png",
    text: "Plantes aromatiques",
    value: "_AROMATIC_PLANTS",
  },
];
const secondCategoryRow = [
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/cosmetics.png",
    text: "Cosmétique et Bien être",
    value: "_COSMETICS",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/meat.png",
    text: "Boucherie Charcuterie",
    value: "_MEAT-AND-DELICATESSEN",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/drinks.png",
    text: "Boissons",
    value: "_BEVERAGES",
  },
  {
    image:
      "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/processed.png",
    text: "Produits transformés",
    value: "_TRANSFORMED-PRODUCTS",
  },
];

const Categories = ({ onCategoryChange }) => {
  return (
    <Container>
      <CategoryTitle variant="h3">Nos tops catégories !</CategoryTitle>
      <CategoriesContainer>
        <Grid container spacing={2} justify="space-evenly">
          {firstCategoryRow.map((it) => (
            <Grid item md={3} xs={12} key={it.value}>
              <Category
                image={it.image}
                text={it.text}
                value={it.value}
                onCategoryChange={onCategoryChange}
              />
            </Grid>
          ))}
        </Grid>
        <Spacer />
        <Grid container spacing={2} justify="space-evenly">
          {secondCategoryRow.map((it) => (
            <Grid item md={3} xs={12} key={it.value}>
              <Category
                image={it.image}
                text={it.text}
                value={it.value}
                onCategoryChange={onCategoryChange}
              />
            </Grid>
          ))}
        </Grid>
      </CategoriesContainer>
      <Hidden mdDown>
        <CircleIcon />
      </Hidden>
    </Container>
  );
};

Categories.protoTypes = {
  onCategoryChange: PropTypes.func.isRequired,
};

Categories.defaultProps = {
  onCategoryChange: () => {},
};

export default Categories;
