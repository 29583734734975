/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=600fee62251ad50c97d44b23"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("../js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class TermsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    Controller = TermsView;
    return Controller;
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "602e63144ee5144455e05455";
    htmlEl.dataset["wfSite"] = "600fee62251ad50c97d44b23";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      TermsView.Controller !== TermsView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/kuupanda-gestion-07a594.webflow.css);


          body{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-section af-class-no-bottom-space af-class-bg-gray-4">
              <div className="af-class-container-testimonials af-class-container-narrow af-class-align-left">
                <div className="af-class-card af-class-terms-card">
                  <div className="af-class-card-body">
                    <div className="af-class-letterhead">
                      <div>
                        <h1 className="af-class-medium-heading af-class-career-description-heading">
                          Conditions générales d'utilisation
                        </h1>
                      </div>
                    </div>
                    <h1 className="af-class-heading-11">PRÉAMBULE</h1>
                    <div>
                      La Société SAS Kuupanda (ci-après “
                      <strong>Kuupanda</strong>”), exploite un site internet
                      (ci-après “<strong>la Plateforme</strong>”) permettant à
                      des particuliers et professionnels (ci-après “
                      <strong>les Acheteurs</strong>”) d’être mis en relation
                      avec des vendeurs professionnels et particuliers (ci-après{" "}
                      <strong>“les Vendeurs”</strong>) pour l’achat en ligne de
                      produits relevant de l’univers du commerce local de biens
                      alimentaires et bien être (ci-après{" "}
                      <strong>“les Produits”</strong>).
                      <br />
                      Cette Plateforme est accessible à tous les Acheteurs sous
                      l’url{" "}
                      <strong>
                        “www.kuupanda.com”.
                        <br />
                      </strong>
                      Il est rappelé que Kuupanda agit comme simple
                      intermédiaire et n’est en aucun cas revendeur des Produits
                      proposés par les Vendeurs au sein de la Plateforme.
                      <br />
                      Les Produits sont vendus et livrés aux Acheteurs par les
                      Vendeurs, sous leur seule responsabilité.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 1 : ACCEPTATION ET MODIFICATION DES CONDITIONS
                      GÉNÉRALES D’UTILISATION
                      <br />
                    </h1>
                    <div>
                      Les Conditions Générales d’Utilisation de la Plateforme
                      (ci-après les “<strong>CGU</strong>”) ont pour objet de
                      définir les conditions d’accès et d’utilisation de la
                      Plateforme et de ses Services.
                      <br />
                      <br />
                      Toute utilisation de la Plateforme et des Services
                      implique l’acceptation préalable, expresse et sans réserve
                      par l’Utilisateur des CGU. L’Utilisateur est informé et
                      reconnaît qu’en cliquant, lors de la création de son
                      Compte Client, sur le bouton “S’enregistrer” précédé de la
                      mention “
                      <em>
                        En cliquant sur S’enregistrer, vous acceptez les
                        Conditions générales d’utilisation
                      </em>
                      ”, il est tenu par l’ensemble des dispositions des CGU.
                      <br />
                      Kuupanda se réserve le droit, à tout moment, de refuser
                      l’accès à la Plateforme, unilatéralement et sans
                      notification préalable, à tout Utilisateur ne respectant
                      pas les présentes CGU.
                      <br />
                      <br />
                      L’Utilisateur est informé que les CGU de la Plateforme
                      pourront être modifiées à tout moment, unilatéralement par
                      Kuupanda afin notamment de se conformer à toutes
                      évolutions de ses Services ou toutes évolutions légales,
                      jurisprudentielles, éditoriales et/ou techniques. Kuupanda
                      s’engage à informer l’Utilisateur des modifications
                      apportées aux Services et/ou aux CGU par email ou par le
                      biais d’une mention spéciale affichée sein de la
                      Plateforme.
                      <br />
                      <br />
                      Les CGU applicables à chaque Commande passée au sein de la
                      Plateforme sont celles accessibles en ligne au moment de
                      l’acceptation de la Commande par l’Utilisateur et jointe à
                      l’email de confirmation de Commande reçu par l’Acheteur.
                      <br />
                      Il est rappelé à l’Utilisateur qu’il lui appartient de
                      consulter avant toute nouvelle Commande la dernière
                      version des CGU accessible au sein de chaque page de la
                      Plateforme.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 2 - DÉFINITIONS
                      <br />
                    </h1>
                    <div>
                      <strong>Commande : </strong>désigne tout achat de Produit
                      par un Acheteur auprès d’un ou plusieurs Vendeurs par le
                      biais de la Plateforme.
                      <br />
                      <strong>Compte Personnel </strong>: désigne l’espace
                      personnel de l’Acheteur au sein duquel celui-ci peut
                      accéder, après s’être identifié, à ses informations
                      personnelles et à son historique d’achat.
                      <br />
                      <strong>Conditions Générales de Vente ou CGV </strong>:
                      désignent les conditions et modalités de vente des
                      Produits pratiquées par le Marchand. Elles sont
                      accessibles dans la Fiche Vendeur et encadrent les
                      Commandes passées par les Acheteurs auprès des Vendeurs.
                      <br />
                      Kuupanda mets à disposition dans l’outil de gestion des
                      Vendeurs admin.kuupanda.com, un espace pour que les CGVs
                      des Vendeurs soient renseignées.
                      <br />
                      Kuupanda propose un modèle de CGVs pour les Vendeurs
                      qu’ils devront adapter à leur exploitation et à leur
                      fonctionnement.
                      <br />
                      Kuupanda ne se tient en aucun cas responsable des
                      boutiques dont les CGVs ne sont pas renseignées. Kuupanda
                      ne sera pas responsable non plus du non respect de ces
                      dernières par les Vendeurs.
                      <br />
                      <strong>Fiche Vendeur </strong>: désigne la fiche
                      récapitulant l’ensemble des informations relatives au
                      Vendeur, au sein de laquelle sont notamment accessibles
                      ses Conditions Générales de Vente, consultables par les
                      Utilisateurs au sein de la Plateforme.
                      <br />
                      <strong>Fiche Produit </strong>: désigne la fiche
                      descriptive de chaque Produit commercialisé au sein de la
                      Plateforme, composée des photographies et textes renseigné
                      par chaque Vendeur pour la description des Produits qu’il
                      commercialise.
                      <br />
                      <strong>Identifiants </strong>: désignent l’adresse
                      électronique et le mot de passe choisis par l’Utilisateur
                      permettant son identification et l’accès aux Services de
                      Kuupanda.
                      <br />
                      <strong>Panier </strong>: désigne l’emplacement permettant
                      à l’Utilisateur de sélectionner des Produits en vue d’une
                      Commande.
                      <br />
                      <strong>Partenaires : </strong>désignent toute personne
                      morale avec laquelle Kuupanda a conclu un partenariat.
                      <br />
                      <strong>Prix </strong>: désigne le prix total du Produit
                      toutes taxes comprises, payable par l’Acheteur, ne
                      comprenant pas les frais de livraison dans le cas où un
                      Vendeur pratiquerait ce service et souhaiterait être
                      rémunéré pour ce dernier.
                      <br />
                      <strong>Services </strong>: désignent l’ensemble des
                      fonctionnalités mises à la disposition des Utilisateurs
                      par Kuupanda et disponibles au sein de la Plateforme.
                      <br />
                      <strong>Utilisateur </strong>: désigne toute personne qui
                      utilise la Plateforme ou ses Services
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 3 – CONDITIONS ET MODALITES D'ACCÈS A LA
                      PLATEFORME
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      3.1. Informations générales
                      <br />
                    </h6>
                    <div>
                      La Plateforme est accessible gratuitement à tout
                      Utilisateur à l’adresse{" "}
                      <strong>«www.kuupanda.com».</strong> L’Utilisateur doit
                      disposer d’un accès à internet.
                      <br />
                      Tous les logiciels et matériels nécessaires à
                      l’utilisation et au fonctionnement des Services de la
                      Plateforme, ou l’accès à internet sont à la charge de
                      l’Utilisateur. Il est seul responsable du bon
                      fonctionnement de son équipement informatique et de son
                      accès à internet.
                      <br />
                      L’achat de Produits à des Vendeurs est payant conformément
                      à l’article « dispositions financières » des présentes CGU
                      de la Plateforme.
                      <br />
                      Tout Utilisateur peut accéder, consulter ou utiliser tout
                      ou partie de la Plateforme.
                      <br />
                      Pour pouvoir acheter des Produits sur la Plateforme,
                      l’Utilisateur doit être :<br />
                      <br />- une personne physique, âgée de 18 ans révolus et,
                      plus généralement dotée de la capacité à contracter.
                      <br />- une personne morale, dont la personne habilitée à
                      la représenter doit mentionner : les informations valides
                      relatives à son identité (nom / prénom), sa raison
                      sociale, son numéro de SIRET ou d’identification,
                      l’adresse de son siège social, son numéro de téléphone et
                      son adresse électronique.
                    </div>
                    <h6 className="af-class-heading-12">
                      3.2. Création d’un Compte Personnel
                      <br />
                    </h6>
                    <div>
                      Pour pouvoir acheter des Produits sur la Plateforme,
                      l’Utilisateur doit se créer un Compte Personnel
                      (Professionnel ou Particulier).
                      <br />
                      La création d’un Compte Personnel permet à l’Utilisateur
                      de :<br />- gérer ses informations personnelles (adresse
                      postale, adresse mail, mot de passe)
                      <br />- passer une Commande de Produits sur la Plateforme{" "}
                      <br />- obtenir le détail de sa Commande (prix des
                      produits / coût de la livraison) <br />- contacter le
                      service client de Kuupanda <br />- demander une facture{" "}
                      <br />‍<br />
                      L’Utilisateur peut créer un Compte Personnel :<br />- en
                      cliquant sur l’onglet ““
                      <em>Compte client ou Compte restaurateurs/pro</em> ou
                      Compte producteur dans la rubrique “<em>Connexion” </em>.
                      <br />
                      <br />
                      Pour créer son Compte Personnel, l’Utilisateur doit
                      renseigner (i) une adresse électronique valide et (ii) un
                      mot de passe qui ne portent pas atteinte aux droits de
                      tiers. Il s’engage à conserver confidentiel son mot de
                      passe.
                      <br />
                      L’Utilisateur peut à tout moment demander la suppression
                      de son Compte Personnel.
                    </div>
                    <h6 className="af-class-heading-12">
                      3.3 Refus d’accès à la Plateforme
                      <br />
                    </h6>
                    <div>
                      De manière générale, lors de la création de son Compte
                      Personnel l’Utilisateur s’engage à saisir les données qui
                      permettent son identification sous ses entières
                      responsabilité, contrôle et direction et s’engage à
                      communiquer des informations complètes, exactes et
                      actualisées, et à ne pas usurper l’identité d’un tiers, ni
                      à masquer ou modifier son âge.
                      <br />
                      <br />
                      L’Utilisateur s’engage également à :<br />- fournir des
                      informations authentiques, exactes et complètes quant à
                      son identité, adresse et autres données nécessaires à
                      l’accès à la Plateforme, de nature à ne pas induire
                      Kuupanda ou les Vendeurs ou tous tiers en erreur <br />-
                      mettre à jour toute modification concernant ces
                      informations <br />- ne pas accéder à un Compte Personnel
                      appartenant à un autre Utilisateur <br />- prendre toutes
                      les dispositions nécessaires permettant de protéger ses
                      Identifiants et mots de passe ainsi que ses propres
                      données contre toutes atteintes.
                      <br />
                      <br />
                      Kuupanda se réserve la possibilité de suspendre ou refuser
                      l’accès, sans préavis ni indemnité, de façon temporaire ou
                      définitive, à tout ou partie de la Plateforme, et/ou de
                      ses Services et contenus en cas de violation(s) grave(s)
                      et/ou répétée(s) par l’Utilisateur des stipulations des
                      présentes CGU, notamment, et sans que cette liste ne soit
                      limitative, en cas de renseignement d’informations
                      fausses, inexactes, incomplètes ou périmées, ou encore en
                      cas de fraude aux moyens de paiement, tentative
                      d’escroquerie ou toute autre infraction pénale.
                    </div>
                    <h6 className="af-class-heading-12">
                      3.4. Disponibilité de la Plateforme
                      <br />
                    </h6>
                    <div>
                      Kuupanda fait ses meilleurs efforts pour assurer la
                      disponibilité de la Plateforme. Kuupanda ne garantit pas
                      que la Plateforme fonctionne dans tout environnement
                      d’exploitation et qu’elle soit utilisable à tout moment,
                      sans interruption ou erreur, ni que toutes les erreurs
                      pourront être corrigées. La disponibilité de la Plateforme
                      est soumise à une simple obligation de moyens.
                      <br />
                      <br />
                      La Plateforme étant en constante évolution, elle est sujet
                      à des changements ponctuels et/ou à des interruptions
                      temporaires ou définitives sans préavis notamment pour des
                      raisons de maintenance. La responsabilité de Kuupanda ne
                      saurait en aucun cas être engagée en cas d’indisponibilité
                      de la Plateforme du fait de ces changements.
                      <br />
                      <br />
                      Dans ce cadre, Kuupanda se réserve la possibilité
                      d’interrompre, de suspendre momentanément ou de modifier
                      sans préavis l’accès à tout ou partie de la Plateforme,
                      afin d’en assurer la maintenance, ou pour toutes autres
                      raisons, sans que l’interruption n’ouvre droit à aucune
                      obligation ni indemnisation.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 4 - COMPORTEMENT DE L’UTILISATEUR
                    </h1>
                    <h6 className="af-class-heading-12">
                      4.1. Comportement général des Utilisateurs
                      <br />
                    </h6>
                    <div>
                      L’Utilisateur déclare et reconnaît que toutes les
                      informations, données, textes, messages ou tous les autres
                      contenus qu’il publie au sein de la Plateforme (ci-après “
                      <strong>Contributions</strong>”) le sont sous son entière
                      et seule responsabilité.
                      <br />
                      <br />
                      L’Utilisateur s’engage à ne pas publier de Contributions
                      contraires aux lois et règlements en vigueur.
                      <br />
                      <br />
                      Notamment, l’Utilisateur s’interdit :<br />- de diffuser
                      des informations contraires à l’ordre public ou aux bonnes
                      mœurs
                      <br />- de détourner la finalité de la Plateforme et/ou
                      des Services pour faire de la propagande ou du
                      prosélytisme, de la prospection ou du racolage
                      <br />- de diffuser des Contributions contrevenant aux
                      droits de la personnalité de tiers ou présentant un
                      caractère diffamatoire, injurieux, obscène,
                      pornographique, offensant, violent ou incitant à la
                      discrimination, à la violence politique, raciste,
                      xénophobe, sexiste ou homophobe
                      <br />- de publier des informations contrevenant à la
                      législation sur la protection des données personnelles
                      permettant l’identification des personnes physiques sans
                      leur consentement, notamment leur nom de famille, adresse
                      postale et/ou électronique, téléphone, photographie,
                      enregistrement sonore ou audiovisuel, ou de collecter et
                      stocker des données personnelles afférentes aux autres
                      Utilisateurs
                      <br />- d’accéder frauduleusement à la Plateforme et aux
                      Services, ainsi qu’il est indiqué dans les Conditions
                      Générales d’Utilisation de la Plateforme
                      <br />- de transmettre tout message contenant des virus
                      informatiques ou tout autre code, dossier ou programme
                      conçus notamment pour interrompre, détruire ou limiter la
                      fonctionnalité notamment de tout logiciel, ordinateur, ou
                      outil de télécommunication
                      <br />- de harceler de quelque manière que ce soit un
                      autre ou plusieurs autres Utilisateurs
                      <br />
                      -de donner des informations renvoyant vers d’autres sites
                      (que ce soit par la création de liens hypertextes ou par
                      la simple fourniture d’informations) dont le contenu
                      serait susceptible de contrevenir à toute loi ou
                      réglementation en vigueur, et notamment serait susceptible
                      de porter atteinte aux droits des personnes et des biens
                      et/ou aux droits de propriété intellectuelle.
                      <br />
                      <br />
                      L’Utilisateur s’interdit également de porter atteinte à
                      des droits de propriété intellectuelle de tiers.
                      L’Utilisateur s’engage à respecter les lois et
                      réglementations en vigueur et à n’utiliser que des
                      contenus pour lesquels il dispose des droits, ou pour
                      lesquels le titulaire des droits a donné son consentement
                      exprès pour leur diffusion, ou qui sont libres de tout
                      droit.
                      <br />
                      <br />
                      L’Utilisateur s’engage le cas échéant à indemniser
                      Kuupanda de tout préjudice directement ou indirectement
                      lié au non-respect de cette garantie.
                    </div>
                    <h6 className="af-class-heading-12">
                      4.2. Comportement frauduleux
                      <br />
                    </h6>
                    <div>
                      Tout Utilisateur qui agit en fraude des présentes
                      Conditions Générales d’Utilisation s’expose aux poursuites
                      civiles et pénales qui répriment notamment les atteintes
                      au droit d’auteur, aux droits voisins, aux droits des
                      Vendeurs de bases de données ainsi qu’aux systèmes de
                      traitement automatisé de données.
                      <br />
                      <br />
                      Des procédures pourront être engagées par Kuupanda contre
                      l’Utilisateur qui ne respecterait pas ces dispositions.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 5 - PROCESSUS DE COMMANDE
                      <br />
                    </h1>
                    <div>
                      Le processus de Commande au sein de la Plateforme se
                      déroule selon les étapes suivantes:
                      <br />
                      <br />
                      1Pour commander un Produit, l’Utilisateur doit
                      sélectionner le Produit souhaité et cliquer sur l’icône «{" "}
                      <strong>Panier </strong>». Il peut ajouter d’autres
                      Produits en continuant la navigation ou finaliser sa
                      Commande en cliquant sur le bouton «{" "}
                      <strong>Commander </strong>». L’Utilisateur dispose d’un
                      récapitulatif de son Panier dès lors qu’il clique sur
                      l’icône « <strong>Panier </strong>». Il peut alors
                      modifier ou corriger son Panier.
                      <br />
                      2Pour poursuivre sa Commande, l’Utilisateur doit cliquer
                      sur le bouton « <strong>Commander </strong>».
                      <br />
                      3S’il possède un Compte Personnel, l’Utilisateur doit
                      alors renseigner son adresse e-mail et son mot de passe
                      pour se connecter à son Compte Personnel. S’il ne possède
                      pas de Compte Personnel, l’Utilisateur doit créer un
                      Compte Personnel tel que décrit à l’article 3.2 des CGU.
                      <br />
                      4L’Utilisateur doit ensuite renseigner la méthode de
                      récupération de ses produits, soit un enlèvement dans
                      le/les points de contacts de son/ses Vendeurs soit se
                      faire livrer si son/ses Vendeurs proposent la livraison. A
                      ce stade, l’Utilisateur peut à nouveau vérifier et
                      modifier sa Commande.
                      <br />
                      5Dans le cas d’une livraison des informations relatives
                      lui seront demandées : nom, prénom, adresse de livraison
                      et numéro de téléphone.
                      <br />
                      6Pour finaliser sa Commande, l’Utilisateur doit ensuite
                      sélectionner son mode de paiement et procéder au paiement
                      de sa Commande en cliquant sur l’onglet «{" "}
                      <strong>Commander </strong>». En cliquant sur l’onglet «{" "}
                      <strong>Commander </strong>» à cette étape, l’Utilisateur
                      accepte expressément et sans réserve les présentes CGU de
                      la Plateforme et les Conditions Générales de Vente du
                      Vendeur qui commercialise le Produit acheté. Les
                      Conditions Générales de Vente des Vendeurs sont
                      disponibles au sein de chaque Fiche Vendeur.
                      <br />
                      ‍7Lorsque le paiement de sa Commande est validé,
                      l’Acheteur recevra un email de confirmation de Commande.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 6 - DISPOSITIONS FINANCIERES
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      6.1. Prix de vente et modalités de paiement
                      <br />
                    </h6>
                    <div>
                      Les Prix de vente des Produits sur la Plateforme sont
                      indiqués en euros, toutes taxes comprises, mais ne
                      comprennent pas les frais de livraison qui seront indiqués
                      à l’Utilisateur au cours du processus d’achat, avant la
                      validation définitive de sa Commande.
                      <br />
                      <br />
                      Les Vendeurs fixent librement les prix des Produits qu’ils
                      commercialisent par le biais de la Plateforme. Plus
                      particulièrement, en cas d’annonces de réduction de prix,
                      le Vendeur détermine librement le prix de référence, la
                      réduction de prix et le prix réduit annoncé. L’Utilisateur
                      est informé que Kuupanda ne contrôle pas la réalité et la
                      loyauté du prix de référence déterminé par le Marchand.
                      <br />
                      <br />
                      L’Acheteur procède au paiement de ses Commandes
                      directement auprès de Kuupanda, selon les modalités de
                      paiement disponibles au sein de la Plateforme.
                      <br />
                      <br />
                      Le paiement des Commandes passées via la Plateforme est
                      fait à Kuupanda.
                      <br />
                      <br />
                      Toute Commande émanant d’un Acheteur qui n’aurait pas
                      réglé totalement ou partiellement une Commande précédente
                      ou avec lequel un litige de paiement serait en cours
                      d’administration pourra être refusée.
                    </div>
                    <h6 className="af-class-heading-12">
                      6.2. Facturation
                      <br />
                    </h6>
                    <div>
                      Pour chaque vente de Produit, l’Acheteur pourra accéder à
                      sa facture qui sera :<br />
                      <br />- soit accessible au sein de son Compte Personnel en
                      cas de demande spécifique au Vendeur via « l’historique de
                      ses commandes »<br />- soit transmise par email à
                      l’Acheteur lors de la confirmation de sa Commande.
                      <br />
                      <br />
                      Dans le cadre d’une livraison en dehors de la France
                      métropolitaine, les dispositions prévues par le Code
                      général des impôts relatives à la TVA sont applicables. La
                      Commande sera alors soumise à des droits de douane et à
                      des taxes éventuelles, dont Kuupanda ne sera ni
                      responsable, ni en charge des modalités.
                    </div>
                    <h6 className="af-class-heading-12">
                      6.3. Modalités de remboursement de l’Acheteur
                      <br />
                      <br />
                    </h6>
                    <div>
                      Kuupanda utilise le même moyen de paiement que celui que
                      l’Acheteur a utilisé lors du passage de sa Commande pour
                      effectuer le remboursement de l’Acheteur, quelque soit le
                      motif de remboursement de la Commande.
                      <br />
                      <br />
                      Dans le cas d'un paiement en chèque, espèce ou virement
                      bancaire directement sur le compte bancaire du vendeur,
                      c'est le vendeur qui doit effectuer le
                      remboursement.&nbsp;Kuupanda s'engage à intervenir pour
                      veiller au bon déroulement du processus du remboursement.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 7 - LIVRAISON DES PRODUITS PAR LES VENDEURS
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      7.1. Frais de livraison
                      <br />
                    </h6>
                    <div>
                      Les frais de livraison minimum applicables à la Commande
                      sont indiqués lors du processus de Commande à l’étape
                      avant la validation de votre commande.
                      <br />
                      <br />
                      Ces frais de livraison sont fixés librement par le Vendeur
                      uniquement. Ils peuvent varier en fonction de votre code
                      postal de livraison. Le prix définitif vous sera
                      communiqué avant la validation de votre commande.
                    </div>
                    <h6 className="af-class-heading-12">
                      7.2. Modalités de livraison/enlèvement
                      <br />
                    </h6>
                    <div>
                      Le vendeur choisit les modes de livraison qu’il souhaite
                      proposer à ces clients. Deux options s’offrent à lui,
                      l’enlèvement et la livraison.
                      <br />
                    </div>
                    <h6 className="af-class-heading-12">
                      7.2.1 La livraison
                      <br />
                    </h6>
                    <div>
                      L’Acheteur est informé que les Produits ne pourront lui
                      être livrés que si le :<br />
                      <br />- le Vendeur propose la livraison dans un périmètre
                      maximum de 50 km autour de son exploitation
                      <br />- l’Acheteur dispose d’une adresse de livraison
                      valide dans le périmètre indiqué par le Vendeur <br />
                      <br />
                      Les Produits sont livrés par le Vendeur à l’adresse
                      indiquée par l’Acheteur lors de sa Commande.
                      <br />
                      <br />
                      L’Acheteur sélectionne et valide le mode de livraison dans
                      le processus de commande, avant de valider le paiement.
                    </div>
                    <h6 className="af-class-heading-12">
                      7.2.2 L’enlèvement
                      <br />
                    </h6>
                    <div>
                      Sur la plateforme Kuupanda, une fois la ville renseignée
                      dans la barre de recherche, l’acheteur visualise
                      l’ensemble des points d’enlèvement que les Vendeurs lui
                      mettent à disposition.
                      <br />
                      <br />
                      S’il clique sur l’un de ces points via le “pin” de
                      géolocalisation, il y trouvera l’ensemble des informations
                      relatives à ce point de contact (jours d’ouverture,
                      horaires d’ouverture, lieu). Le point de contact sera
                      choisi par le client lors du processus de commande, avant
                      de finaliser le paiement. L’acheteur se rend alors sur
                      place pour récupérer sa commande
                      <br />
                      <br />
                      L’Acheteur pourra choisir entre les différents modes
                      d’enlèvements/livraisons proposés par l’Acheteur et leur
                      prix.
                    </div>
                    <h6 className="af-class-heading-12">
                      7.3. Retard/absence de livraison
                      <br />
                    </h6>
                    <div>
                      En cas de retard, de non-réception totale ou partielle du
                      (des) Produit(s) commandé(s), l’Acheteur est invité à
                      prendre contact avec le Vendeur à compter de la date de
                      livraison convenue afin de traiter le problème.
                      <br />
                      <br />
                      L’Acheteur peut prendre contact avec le Vendeur par le
                      biais de son Compte Personnel.
                    </div>
                    <h6 className="af-class-heading-12">
                      7.4. Responsabilité du Vendeur
                      <br />
                    </h6>
                    <div>
                      Le Vendeur est responsable de plein droit à l’égard des
                      Acheteurs de l’exécution des Commandes passées par le
                      biais de la Plateforme et notamment du bon acheminement
                      des Produits par ses propres moyens ou par ses
                      transporteurs.
                      <br />
                      <br />
                      En conséquence, le Vendeur est responsable de plein droit
                      à l’égard des Acheteurs de tout problème lié à
                      l’enlèvement des produits (retard, erreur sur la
                      préparation, etc), ainsi qu’à la livraison (retard de
                      livraison, erreur sur le Produit livré, casse du Produit
                      pendant le transport, Produit jamais livré en raison d’un
                      vol, d’une erreur du Vendeur et/ou du transporteur auquel
                      il a fait appel, etc.), sauf en cas d’erreur imputable à
                      Kuupanda lors de la communication des informations de
                      Commande au Vendeur.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 8 - DROIT DE RÉTRACTATION
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      8.1. Absence de droit de rétractation pour les Produits
                      susceptibles de se détériorer ou de se périmer rapidement.
                      <br />
                    </h6>
                    <div>
                      L'utilisateur est informé que conformément à la
                      réglementation française en vigueur, le droit de
                      rétractation ne peut pas être exercé s’agissant des
                      Produits qui sont susceptibles de se détériorer ou de se
                      périmer rapidement.
                    </div>
                    <h6 className="af-class-heading-12">
                      8.2. Droit de rétractation applicable aux autres Produits
                      <br />
                    </h6>
                    <div>
                      Conformément aux dispositions des articles L221-18 et
                      suivants du Code de la consommation, l’Acheteur dispose
                      d’un délai de quatorze jours à compter du lendemain de la
                      date de livraison ou de retrait des Produits pour
                      retourner un Produit qui ne lui conviendrait pas et (a)
                      demander le remboursement sans pénalité à l’exception des
                      frais de retour du Produit ou (b) demander l’échange du
                      Produit.
                      <br />
                      <br />
                      L’Acheteur doit alors remplir le formulaire type de
                      rétractation disponible sur le Site ou tout autre écrit
                      indiquant sa volonté de se rétracter. La vente est alors
                      résiliée et l’Acheteur est tenu de renvoyer le Produit. Le
                      Producteur devra alors rembourser la totalité des sommes
                      versées, frais de livraison inclus le cas échéant.
                      L’Acheteur devra retourner les Produits au Producteur au
                      plus tard dans les quatorze jours suivant l’envoi de la
                      rétractation. Les frais de retour seront à la charge de
                      l’Acheteur. &nbsp;Le Producteur s’engage à rembourser
                      l’Acheteur dans un délai de quatorze jours à compter de la
                      date à laquelle il a été informé de la décision de
                      l’Acheteur de se rétracter.
                      <br />
                      <br />
                      Les Produits sont à retourner dans leur état d’origine et
                      propres et complets.
                      <br />
                      <br />
                      Conformément à l’article L221-28 du Code de la
                      consommation, l’Acheteur ne bénéficie pas de ce droit de
                      rétractation pour les Produits qui ne peuvent être
                      expédiés ou sont susceptibles de se détériorer ou de se
                      périmer rapidement du fait de leur nature.
                      <br />
                      <br />
                      Sont ainsi notamment visés :<br />- Un bien détériorable
                      ou périssable sauf produits alimentaires avec une date de
                      durabilité minimale
                      <br />– les produits frais ou surgelés <br />– Un bien que
                      l’Acheteur a ouvert et qui ne peut être retourné pour des
                      raisons d’hygiène ou de protection de la santé (ex :
                      bocaux de conservation, cosmétiques operculés…)
                      <br />- Une boisson alcoolisée dont la livraison est
                      différée au-delà de trente jours et dont la valeur dépend
                      des taux du marché financiers (ex : grands crus réservés
                      avant récolte).
                    </div>
                    <h6 className="af-class-heading-12">
                      8.3. Responsabilité lors du retour de la marchandise
                      <br />
                    </h6>
                    <div>
                      Si l’Acheteur retourne la marchandise après réception, il
                      est entièrement responsable des produits retournés jusqu’à
                      ce que ces derniers soient remis au Vendeur. En cas de
                      litige lors du transport, il en assume la pleine
                      responsabilité.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 9 – PRODUITS NON-CONFORMES, INCOMPLETS OU
                      DÉFECTUEUX
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      9.1. Réception d’un Produit non-conforme, incomplet ou
                      défectueux
                      <br />
                    </h6>
                    <div>
                      En cas de réception d’un colis détérioré, défectueux,
                      incomplet ou non conforme, l’Acheteur est invité à prendre
                      contact avec le Vendeur afin de traiter le problème.
                      <br />
                      <br />
                      L’Acheteur peut prendre contact avec le Vendeur via email
                      ou téléphone (fiche Vendeur). L’email et le téléphone du
                      Vendeur sont communiqués sur le profil du Vendeur.
                    </div>
                    <h6 className="af-class-heading-12">
                      9.2. Garanties légales
                      <br />
                    </h6>
                    <div>
                      Le Vendeur agissant à titre professionnel s’oblige à
                      respecter les dispositions légales et règlementaires
                      relatives à l’exercice d’une activité commerciale de
                      vente.
                      <br />
                      <br />A ce titre, et à l’exception de la garantie
                      contractuelle proposée, le Vendeur est tenu de la garantie
                      légale de conformité mentionnée aux articles L. 217-4 et
                      suivants du Code de la Consommation et de la garantie
                      légale des vices cachés telle que prévues aux articles
                      1641 à 1648 et 2232 du code civil.
                      <br />
                      <br />
                      Les conditions d’exercice de ces garanties légales sont
                      détaillées dans les{" "}
                      <strong>
                        CGV du Vendeur.
                        <br />
                        <br />‍
                      </strong>
                      Chaque remboursement, pour quelque motif que ce soit sera
                      effectué par l’intermédiaire de Kuupanda, qui sera en
                      droit de le refuser, en accord avec le Vendeur.
                      <br />
                      <br />
                      Kuupanda ne peut être engagé d’aucune façon que ce soit
                      dans les garanties assumées par les Vendeurs.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 10 – PROPRIETE INTELLECTUELLE
                      <br />
                    </h1>
                    <h6 className="af-class-heading-12">
                      10.1. Contenu de la Plateforme
                      <br />
                    </h6>
                    <div>
                      Toute utilisation, reproduction, copie, diffusion d’un ou
                      de plusieurs éléments de la Plateforme pour un usage autre
                      que privé est interdite.
                      <br />
                      <br />
                      L’ensemble des contenus et Services de la Plateforme,
                      incluant de manière non limitative, le nom de domaine, les
                      textes, la charte graphique, les graphiques, les
                      photographies, les dessins, les sons, les images, audio et
                      vidéo, mais également l’arborescence, le plan de
                      navigation, la conception et l’organisation de ses
                      rubriques, leurs titres, existants ou à venir, est protégé
                      par des droits de propriété intellectuelle et/ou
                      industrielle, détenus ou revendiqués par Kuupanda, avec
                      l’autorisation des titulaires de ces droits, et le cas
                      échéant des Vendeurs et/ou de ses Partenaires.
                      <br />
                      <br />
                      Kuupanda concède à tout Utilisateur, à titre non exclusif,
                      personnel et incessible, le droit d’utiliser la Plateforme
                      et les Services pour ses seuls besoins. Ce droit est
                      concédé pour la durée de l’Utilisation de la Plateforme.
                      La présente concession de droits n’entraîne le transfert
                      d’aucun droit de propriété intellectuelle et/ou
                      industrielle au profit de l’Utilisateur.
                      <br />
                      <br />
                      En conséquence, tout Utilisateur s’interdit de reproduire
                      sous quelque forme que ce soit, de manière directe ou
                      indirecte, les éléments visés aux alinéas précédents,
                      ainsi que d’altérer les marques, brevets, noms, sigles,
                      logos, couleurs, graphismes ou autres signes figurant sur
                      les éléments mis à disposition par Kuupanda et plus
                      généralement d’utiliser ou exploiter ces éléments.
                    </div>
                    <h6 className="af-class-heading-12">
                      10.2. Bases de données
                      <br />
                    </h6>
                    <div>
                      Kuupanda est producteur et propriétaire de tout ou partie
                      des bases de données, leur structure et leurs contenus,
                      composant la Plateforme, sous réserve des droits détenus
                      par les Vendeurs et/ou ses Partenaires, chacun pour ce qui
                      le concerne.
                      <br />
                      <br />
                      En accédant à la Plateforme, l’Utilisateur reconnaît que
                      les données la composant sont légalement protégées, et,
                      qu’il lui est interdit notamment d’extraire, réutiliser,
                      stocker, reproduire, représenter ou conserver, directement
                      ou indirectement, sur un support quelconque, par tout
                      moyen et sous toute forme que ce soit, tout ou partie
                      qualitativement ou quantitativement substantielle, du
                      contenu des bases de données figurant au sein de la
                      Plateforme auquel il accède, ainsi que d’en faire
                      l’extraction ou la réutilisation répétée et systématique
                      de parties qualitativement et quantitativement non
                      substantielles, lorsque ces opérations excèdent
                      manifestement les conditions d’utilisation normale.
                    </div>
                    <h6 className="af-class-heading-12">
                      10.3 Marques
                      <br />
                    </h6>
                    <div>
                      Kuupanda détient notamment des droits de marque sur le
                      terme “Kuupanda” et le(s) logo(s) associé(s) dans l’Union
                      européenne. Sauf autorisation expresse et préalable, tout
                      usage de la marque “Kuupanda” expose l’Utilisateur à des
                      poursuites pénales et civiles.
                    </div>
                    <h6 className="af-class-heading-12">
                      10.4. Contenus des Fiches Produits
                      <br />
                    </h6>
                    <div>
                      Les contenus des Fiches Produits, en ce compris notamment
                      toutes les photographies, textes, illustrations, images,
                      et/ou vidéos décrivant les Produits commercialisés au sein
                      de la Kuupanda décline en conséquence toute responsabilité
                      dans l’hypothèse où les contenus des Fiches Produits
                      fournis par les Vendeurs porteraient atteinte aux droits
                      de tiers.
                      <br />
                      <br />
                      En cas d’atteinte avérée aux droits d’un tiers du fait de
                      la publication de tout ou partie d’une Fiche Produit au
                      sein de la Plateforme, Kuupanda s’engage à faire ses
                      meilleurs efforts pour faire cesser promptement cette
                      atteinte, dès qu’elle aura été portée à sa connaissance,
                      et retirer de la Plateforme le contenu litigieux.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 11 – RÔLE ET RESPONSABILITÉ DE KUUPANDA
                      <br />
                    </h1>
                    <div>
                      Kuupanda, en qualité d’opérateur de Plateforme, intervient
                      comme simple intermédiaire de mise en relation entre les
                      Vendeurs et les Utilisateurs sur la Plateforme.
                      <br />
                      <br />
                      En application de la loi n°2004-575 du 21 juin 2004 pour
                      la confiance dans l’économie numérique (ci-après la « LCEN
                      »), en sa qualité d’hébergeur, Kuupanda ne peut pas voir
                      sa responsabilité civile engagée du fait des activités ou
                      des informations stockées au sein de la Plateforme si elle
                      n’a pas effectivement connaissance de leur caractère
                      illicite ou de faits et circonstances faisant apparaître
                      ce caractère ou si, dès le moment où elle en a eu
                      connaissance, dans les conditions prévues par la LCEN,
                      elle a agi promptement pour retirer ces données ou en
                      rendre l’accès impossible.
                      <br />
                      <br />
                      En conséquence, la responsabilité de Kuupanda ne pourra
                      être engagée en raison :<br />- des Fiches Produits et des
                      Fiches Vendeurs, et plus largement de tous les contenus
                      et/ou informations fournis par les Vendeurs tant sur les
                      caractéristiques des Produits, que sur leur état ou leur
                      prix, les Vendeurs étant seuls responsables de la bonne
                      exécution de leurs obligations légales d’information
                      précontractuelle auprès des Acheteurs ainsi que de la
                      véracité, la complétude et la mise à jour des informations
                      et contenus qu’il fournit, sauf faute prouvée de Kuupanda
                      <br />- des avis postés par les Acheteurs suite à leurs
                      Commandes
                      <br />
                      <br />
                      En outre, Kuupanda n’est pas soumise à une obligation
                      générale de surveiller les informations transmises par les
                      Vendeurs et stockées au sein de la Plateforme pour leur
                      compte.
                      <br />
                      <br />
                      Enfin, il est rappelé que :<br />- les Produits sont
                      commercialisés par les Vendeurs au sein de la Plateforme
                      sous leur seule responsabilité <br />- Kuupanda mets à
                      disposition dans l’outil de gestion des Vendeurs{" "}
                      <strong>“admin.kuupanda.com”,</strong> un espace pour que
                      les CGVs des Vendeurs soient renseignées, dans le cas
                      échéant, Kuupanda ne se tient en aucun cas responsable.
                      <br />- Kuupanda n’est pas partie au contrat de vente
                      conclu entre les Acheteurs et les Vendeurs lors des
                      passations de Commande.
                      <br />
                      <br />
                      En conséquence, Kuupanda ne saurait être tenu responsable
                      de tous litiges en lien avec les Commandes passées par le
                      biais de la Plateforme, sauf faute prouvée.
                      <br />
                      <br />
                      Kuupanda décline toute responsabilité :<br />- en cas de
                      suppression, impossibilité de stockage, transmission
                      incorrecte ou transmission inopportune des informations ou
                      des données figurant au sein de la Plateforme ou résultant
                      des Services <br />- en cas de dommages causés à
                      l’Utilisateur liés à la performance ou non-performance des
                      Services de la Plateforme <br />- en cas de dommages
                      susceptibles de résulter du téléchargement ou de
                      l’utilisation des informations ou des données disponibles
                      sur Internet via les Services de la Plateforme, tels que
                      dommages causés sur les systèmes informatiques, pertes de
                      données.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 12 - DONNÉES PERSONNELLES
                      <br />
                    </h1>
                    <div>
                      A – Identification de vos équipements (IP, MAC, UDID) et
                      navigateur (Chrome, Firefox, Safari…) :<br />
                      <br />
                      Lorsque vous naviguez sur notre site, certaines de vos
                      informations nous sont transmises tels que votre adresse
                      IP, équipement utilisé et vos parcours de navigation.
                      <br />
                      Nous utilisons l’historique de vos activités pour vous
                      assister, former nos équipes et vous fournir le meilleur
                      service possible. Votre expérience sera unique et
                      personnalisée.
                      <br />
                      Pour détecter les fraudes, et vous protéger (mais nous
                      aussi) contre celles-ci.
                      <br />
                      <br />B – Mot de passe :<br />
                      <br />
                      Rassurez-vous, votre mot de passe est crypté, personnel et
                      incessible pour garantir la sécurité de votre compte
                      client.
                      <br />
                      <br />C – Les cookies et les outils d’analyse :<br />
                      <br />
                      Les cookies peuvent être présents sur votre ordinateur et
                      sur votre téléphone portable. Ces cookies permettent de
                      vous reconnaître lorsque vous revenez sur notre site, ils
                      enregistrent des informations relatives à la navigation
                      sur notre site.
                      <br />
                      L’utilisation des cookies nous permet de stocker des
                      informations relatives à votre navigation sur notre site
                      internet.
                      <br />
                      De sauvegarder vos articles dans votre panier pour plus
                      tard ou lorsque vous n’êtes pas connecté à votre compte
                      Kuupanda.
                      <br />
                      Nous utilisons des outils d’analyse qui utilisent des
                      cookies (adresse IP, adresse Mac, données
                      d’authentification &nbsp;et de navigation sur notre site).
                      <br />
                      Ces cookies permettent l’analyse du comportement des
                      utilisateurs de notre site et de procéder à des
                      statistiques toujours dans l’objectif de rendre plus
                      agréable votre expérience et la navigation sur notre site.
                      <br />
                      <br />
                      Les données dégagées par ces outils sont stockées par des
                      partenaires &nbsp;Européens répondant aux exigences de la
                      RGPD et des partenaires internationaux ( Facebook,
                      Instagram, Google par exemple ) sur des serveurs situés
                      aux Etats-Unis et bénéficient de la protection des données
                      &nbsp;« Safe Harbor Privacy Principles »<br />
                      <br />D – Informations sur les réseaux sociaux et
                      participations aux jeux concours :<br />
                      <br />
                      Nous utilisons ces données pour vous proposer une
                      expérience client personnalisée <br />
                      Vos réponses aux jeux concours, sont utilisées pour
                      remplir notre part du contrat, vos informations sont
                      enregistrées pour que vous puissiez avoir la chance de
                      gagner.
                      <br />
                      Nous vous invitons à consulter les politiques de gestion
                      des données personnelles des différents réseaux sociaux
                      pour avoir connaissance des données personnelles pouvant
                      être transmises par ces derniers.
                      <br />
                      <br />
                      Vous n’êtes pas obligé de nous fournir vos données
                      personnelles, à défaut il ne sera pas possible d’acheter
                      sur notre site pour les données obligatoires et surtout
                      incertain que nous puissions vous offrir une expérience
                      parfaite de A à Z. <br />
                      <br />E – Partage de vos données: <br />
                      <br />
                      Nous ne louons ni vendons vos données à des tiers. <br />
                      <br />
                      Mais toujours dans l’objectif de vous fournir une
                      expérience client optimale, nous partageons vos données,
                      afin d’être en mesure de vous fournir une prestation de
                      service parfaite avec :<br />– Les entreprises qui
                      s’occupent d’acheminer vos achats dans le cas où vos
                      Vendeurs expédient votre commande.
                      <br />
                      -Les hébergeurs de site Web (OVH, Amazon web service).
                      <br />
                      -Les partenaires nous permettant de respecter une
                      obligation légale ou règlementaire, (contribuer à la
                      détection d’une fraude, répondre à un contentieux ou pour
                      agir pour la défense de nos droits.)
                      <br />
                      -Les entreprises qui nous permettent de garder le lien de
                      proximité avec vous : Crisp.
                      <br />
                      -Les partenaires de paiement tels que Stripe ou Mangopay.{" "}
                      <br />
                      <br />F – VOS DROITS :<br />
                      <br />
                      Vous avez le droit d’être informé de l’utilisation de vos
                      données et connaître la finalité de l’utilisation.
                      <br />
                      <br />
                      Vous avez le droit d’accéder à vos informations
                      personnelles, vous pouvez apporter des modifications ou
                      corrections sur vos données directement dans votre compte
                      client. Les partenaires nous permettant de respecter une
                      obligation légale ou règlementaire, (contribuer à la
                      détection d’une fraude, répondre à un contentieux ou pour
                      agir pour la défense de nos droits.)
                      <br />
                      <br />
                      Vous avez le droit de demander la modification, la
                      correction de données personnelles qui seraient erronées
                      (vous pouvez le faire également dans votre compte client).
                      <br />
                      <br />
                      Vous avez le droit à l’oubli : demander que nous
                      supprimions vos données ou que nous arrêtions de les
                      traiter, collecter, dans certains cas (veuillez noter que
                      des dispositions légales ou réglementaires ou des raisons
                      légitimes peuvent nous imposer de conserver vos données)
                      <br />
                      <br />
                      Vous avez le droit de demander l’arrêt de nos messages:
                      vous pouvez vous désinscrire en cliquant en bas de nos
                      e-mails sur « désinscription » ou nous envoyer par
                      courrier postal votre demande écrite à : Kuupanda SAS, 16
                      rue Joseph Parayre, Bat B, Les Hortes, 66400 Céret.
                      <br />
                      <br />
                      Vous pouvez retirer votre consentement à l’utilisation de
                      vos données à des fins commerciales à tout moment : en
                      nous adressant par voie postale votre demande écrite à :
                      Kuupanda SAS, 16 rue Joseph Parayre, Bat B, &nbsp;Les
                      Hortes, 66400 Céret. (la prise en compte de votre demande
                      et la mise à jour de nos systèmes peuvent prendre quelques
                      jours pendant lesquels vous êtes susceptibles de recevoir
                      des messages déjà programmés.
                      <br />
                      <br />
                      Vous avez le droit de faire une réclamation auprès de la
                      Commission Nationale de l’Informatique et des Libertés en
                      sa qualité de régulateur de protection des données, par
                      courrier postal ou en cliquant ici
                      https://www.cnil.fr/fr/agir
                      <br />
                      <br />G – TRANSFERT HORS UNION EUROPÉENNE.
                      <br />
                      <br />
                      Sur ce point, pas d’inquiétude vos données personnelles
                      restent à l’intérieur de l’Union Européen.
                      <br />
                      <br />H – DURÉE DE CONSERVATION
                      <br />
                      <br />
                      Les données liées à la gestion d’un compte client sont
                      conservées pendant le temps de la relation commerciale.
                      Elles peuvent être conservées à des fins de prospection
                      commerciale au maximum pendant 3 ans à compter de la fin
                      de cette relation commerciale ou 3 ans à compter de leur
                      collecte par le responsable de traitement ou du dernier
                      contact émanant du prospect Vous avez le droit d’accéder à
                      vos informations personnelles, vous pouvez apporter des
                      modifications ou corrections sur vos données directement
                      dans votre compte client. Les partenaires nous permettant
                      de respecter une obligation légale ou règlementaire,
                      (contribuer à la détection d’une fraude, répondre à un
                      contentieux ou pour agir pour la défense de nos droits.)
                      <br />
                      <br />
                      Les données liées à la gestion des commandes, livraisons
                      et facturations sont supprimées au bout de 10 ans
                      conformément à la réglementation en vigueur. Vous avez le
                      droit à l’oubli : demander que nous supprimions vos
                      données ou que nous arrêtions de les traiter, collecter,
                      dans certains cas (veuillez noter que des dispositions
                      légales ou réglementaires ou des raisons légitimes peuvent
                      nous imposer de conserver vos données)
                      <br />
                      <br />
                      Les données liées aux cookies sur notre site sont
                      supprimées au bout de 12 mois.
                      <br />
                      <br />
                      Les données statistiques d’identification sont supprimées
                      au bout de 26 mois.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 13- SIGNALER UN CONTENU
                      <br />
                    </h1>
                    <div>
                      Kuupanda a mis en place un dispositif permettant à tout
                      Utilisateur de porter à sa connaissance toute publication
                      sur la Plateforme à caractère litigieux dont il aurait
                      connaissance, notamment tout contenu relevant de la
                      répression de l’apologie des crimes contre l’humanité, de
                      la provocation à la commission d’actes de terrorisme et de
                      leur apologie, de l’incitation à la haine raciale, à la
                      haine à l’égard de personnes à raison de leur sexe, de
                      leur orientation ou identité sexuelle ou de leur handicap
                      ainsi que de la pornographie enfantine, de l’incitation à
                      la violence(notamment l’incitation aux violences sexuelles
                      et sexistes), ainsi que des atteintes à la dignité
                      humaine, afin de permettre le retrait du contenu en cause.
                      <br />
                      <br />
                      Tout Utilisateur peut également demander à Kuupanda le
                      retrait d’un contenu protégé par des droits de propriété
                      intellectuelle.
                      <br />
                      <br />
                      Pour demander le retrait d’un contenu, l’Utilisateur doit
                      envoyer un courrier au siège social de SAS Kuupanda à
                      l’adresse 16 rue Joseph Parayre, Les Hortes, Bat B, dans
                      lequel il fournit les informations suivantes :<br />- pour
                      les personnes physiques : leur nom, prénom, domicile et
                      numéro de téléphone <br />- pour les personnes morales:
                      leur nom, raison sociale, adresse, numéro de téléphone et
                      l’identité de leur représentant légal <br />- la
                      description du contenu litigieux ;les motifs pour lesquels
                      le contenu litigieux, comprenant la mention des
                      dispositions légales et des justifications de faits <br />
                      - la date à laquelle a été constaté le contenu litigieux{" "}
                      <br />- l’adresse URL du contenu litigieux.
                      <br />
                      <br />
                      Il est rappelé que le fait, pour toute personne, de
                      présenter à un hébergeur un contenu ou une activité comme
                      étant illicite dans le but d’en obtenir le retrait ou d’en
                      faire cesser la diffusion, alors qu’elle sait cette
                      information inexacte, est puni d’une peine d’un an
                      d’emprisonnement et de 15.000 euros d’amende.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 14 - DISPOSITIONS GÉNÉRALES
                      <br />
                    </h1>
                    <div>
                      Toute notification d’un Utilisateur, pour être recevable,
                      devra être détaillée, indiquer clairement les raisons de
                      l’insatisfaction et être transmise à KUUPANDA par lettre
                      simple, par e-mail ou via son compte Personnel. Un service
                      après-vente est par ailleurs accessible du lundi au
                      vendredi de 9h à 17h, au numéro indiqué sur la Plateforme
                      soit le 06 89 66 26 20, permettant de répondre à toute
                      question ou réclamation des Utilisateurs.
                      <br />
                      <br />
                      La responsabilité des parties ne pourra être engagée pour
                      tout retard ou inexécution lorsqu’ils sont liés à un cas
                      de force majeure, telle que définie par les textes ou,
                      plus généralement, par la jurisprudence constante.
                      <br />
                      <br />
                      Le fait qu’une clause quelconque des Conditions Générales
                      d’Utilisation devienne nulle, inopposable, caduque,
                      illégale ou inapplicable du fait d’une loi, d’un règlement
                      ou à la suite d’une décision de justice, ne pourra
                      remettre en cause la validité, la légalité,
                      l’applicabilité des autres stipulations des CGU et
                      n’exonère pas l’Utilisateur de l’exécution de ses
                      obligations contractuelles non invalidées.
                    </div>
                    <h1 className="af-class-heading-11">
                      ARTICLE 15 - LITIGES ET DROIT APPLICABLE
                      <br />
                    </h1>
                    <div>
                      Les présentes CGU de la Plateforme sont régies par le
                      droit français. Elles sont rédigées en langue française.
                      Dans le cas où elles seraient traduites en une ou
                      plusieurs langues, seul le texte français ferait foi en
                      cas de litige.
                      <br />
                      <br />
                      En application de l’article L 141-5 du Code de la
                      Consommation, le consommateur peut saisir à son choix,
                      outre l’une des juridictions territorialement compétentes
                      en vertu du code de procédure civile, la juridiction du
                      lieu où il demeurait au moment de la conclusion du contrat
                      ou de la survenance du fait dommageable.
                      <br />
                      <br />
                      La Commission européenne met à disposition des
                      consommateurs de l’Union européenne une plateforme de
                      résolution des litiges en ligne. Les consommateurs peuvent
                      faire une réclamation.
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="../images/divider-sharp-bottom.svg"
                alt
                className="af-class-divider af-class-divider-bottom"
              />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default TermsView;

/* eslint-enable */
