import { useEffect, useState } from "react";
import constants from "../Common/constants";

const useProductErrors = ({ errors, price, basePrice, promos }) => {
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [hasStockChange, setHasStockChange] = useState(false);
  const [hasPriceChange, setHasPriceChange] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(basePrice);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [newPrice, setNewPrice] = useState(price);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    setIsUnavailable(false);
    setHasStockChange(false);
    setHasPriceChange(false);
    setCurrentPrice(basePrice);
    setHasDiscount(false);
    setNewPrice(price);
    setDiscountAmount(0);

    //discounts
    promos &&
      promos.forEach((promo) => {
        if (parseFloat(price) !== parseFloat(basePrice)) {
          setHasPriceChange(true);
          setHasDiscount(true);
          setNewPrice(price);
          setDiscountAmount(promo.amount * 100);
          setCurrentPrice(basePrice);
        }
      });

    //errors
    errors &&
      errors.forEach((error) => {
        if (error.type === constants.PRODUCT_ERRORS.UNAVAILABLE) {
          setIsUnavailable(true);
          setHasPriceChange(false);
          setHasDiscount(false);
        } else if (error.type === constants.PRODUCT_ERRORS.PRICE_CHANGE) {
          setHasPriceChange(true);
          setCurrentPrice(error.prevPrice);
          setNewPrice(error.newPrice);
        } else if (error.type === constants.PRODUCT_ERRORS.STOCK_CHANGE) {
          setHasStockChange(true);
        }
      });
  }, [errors, price, promos, basePrice]);
  return {
    hasPriceChange,
    hasStockChange,
    isUnavailable,
    newPrice,
    currentPrice,
    hasDiscount,
    discountAmount,
  };
};

export default useProductErrors;
