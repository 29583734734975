import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import Grid from "@material-ui/core/Grid";

// Material Icons
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Link from "@material-ui/core/Link";

//Styleds
const Bar = styled(Grid)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  width: 100%;
  ${(props) =>
    (props.width === "xs" || props.width === "sm") &&
    `
      background-color: white;
  `}
  ${(props) =>
    (props.width !== "xs" || props.width !== "sm") &&
    `
      margin-top: -33px;
  `}
`;
const Menu = styled.div`
  margin: 0 36px;
  height: 60px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  text-align: center;
  ${(props) => {
    if (props.width === "xs" || props.width === "sm") {
      let style = `color: ${props.theme.palette.grey["700"]}`;
      if (props.checked) {
        style = `
      color: ${props.theme.palette.primary.main};
      `;
      }
      return `
        margin: 0;
        padding: 0 10px;
        border-bottom: 2px solid;
        ${style}
      `;
    }
  }}
`;
const Label = styled(Link)`
  margin-top: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "15px" : "24px"};
  display: block;
  ${(props) => props.theme.typography.body2}
  font-weight: bold;
`;

const Arrow = styled(ArrowDropUpIcon)`
  margin-top: -3px;
`;

const SubMenu = ({ links, width, onClick }) => {
  return (
    <Bar container justify="center" width={width}>
      {Object.keys(links).map((key) => (
        <Menu width={width} checked={links[key].checked}>
          <Label
            color="inherit"
            underline="none"
            component="button"
            width={width}
            onClick={() => onClick(key)}
          >
            {links[key].label}
          </Label>
          {width !== "xs" && width !== "sm" && links[key].checked && (
            <Arrow fontSize="large" />
          )}
        </Menu>
      ))}
    </Bar>
  );
};

SubMenu.propTypes = {
  links: PropTypes.shape({
    [PropTypes.string.isRequired]: PropTypes.shape({
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  onClick: PropTypes.func,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

SubMenu.defaultProps = {
  onClick: () => {},
};

export default SubMenu;
