import React from "react";
import { Card, CardContent, Hidden, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

//components
import ProductorInfo from "../ProductorInfo";
import { CategoryList } from "./CategoryList";

const useStyles = makeStyles({
  root: {
    position: "sticky",
    top: 0,
  },
  image: {
    height: "100%",
    flexGrow: 1,
  },
  card: {
    borderRadius: "0.4rem",
    boxShadow: "0 .2rem .4rem 0 hsla(0,2%,89%,.5)",
  },
});

//this is horrible but we need to refactor
const StyledCardContent = styled(CardContent)`
  background: linear-gradient(0, #ffffff 66%, transparent 50%);
  margin-bottom: -16px;
`;

const StyledCardContentDesktop = styled(CardContent)`
  padding: 20px 25px;
`;

type StoreNavigationProps = {
  store: any;
  loading: boolean;
  products: any[];
  categoryNameMap: any;
  handleSearchProducts: (search: string) => void;
  externalId?: string;
};

export default function StoreNavigation({
  store,
  loading,
  products,
  categoryNameMap,
  handleSearchProducts,
  externalId,
}: StoreNavigationProps) {
  const classes = useStyles();

  const producerInfo = (
    <ProductorInfo store={store} loading={loading} externalId={externalId} />
  );

  return (
    <React.Fragment>
      <Hidden mdUp>
        <StyledCardContent>{producerInfo}</StyledCardContent>
      </Hidden>
      <Hidden smDown>
        <Box className={`${classes.root} kuu-store-navigation`}>
          <Card className={classes.card}>
            <StyledCardContentDesktop>{producerInfo}</StyledCardContentDesktop>

            <CategoryList
              products={products}
              categoryNameMap={categoryNameMap}
              loading={loading}
              handleSearchProducts={handleSearchProducts}
              fixed={false}
            />
          </Card>
        </Box>
      </Hidden>
    </React.Fragment>
  );
}
