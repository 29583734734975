import React from "react";
import { useBrandConfig } from "../Context/BrandConfigContext";
import { useWhichBrand } from "./useWhichBrand";
import cookiePro from "../Helpers/cookiePro";

function useGranvillageIAdvizeAndCookies() {
  const { isGranVillage, isKuuPanda } = useWhichBrand();
  const { requestDone } = useBrandConfig();

  function getIAdvizeScripts() {
    const scriptOne = document.createElement("script");
    scriptOne.appendChild(
      document.createTextNode(`
      (function(iA,d,v,i,z,e,T,a,g){iA.iAdvizeObject=z;iA[z]=iA[z]||function(){
       iA[z].a.push(arguments)};iA[z].a=iA[z].a||[];T=d.createElement(v);T.async=1;
       T.src=i+'?'+Object.keys(e).map(function(x){return x+'='+e[x]}).join('&');
       a=d.getElementsByTagName(v)[0];a.parentNode.insertBefore(T,a);})(window,
       document,'script','//halc.iadvize.com/iadvize.js','iAdvizeInterface',{
        sid: 4171,
        lang: "fr",
        useExplicitCookiesConsent: true
       });
    `)
    );
    const scriptTwo = document.createElement("script");
    scriptTwo.className =
      "optanon-category-1 optanon-category-2 optanon-category-3 optanon-category-4 optanon-category-5 optanon-category-6";
    scriptTwo.appendChild(
      document.createTextNode(`
      const setIadvizeVisitorCookiesConsent = (function setIadvizeVisitorCookiesConsent() {
        const activeGroups = (OptanonActiveGroups && OptanonActiveGroups.split(',').filter(i => i)) || [];

        iAdvizeInterface('setVisitorCookiesConsent', activeGroups.includes('1')); // la catégorie 1 doit être acceptée par l'utilisateur
        return setIadvizeVisitorCookiesConsent;
      })(); // lancement de la fonction une première fois dès son chargement
      setIadvizeVisitorCookiesConsent()
      OneTrust.OnConsentChanged( setIadvizeVisitorCookiesConsent ); // vérification si l'utilisateur modifie ses choix
    `)
    );
    return [scriptOne /*, scriptTwo */];
  }

  React.useEffect(() => {
    if (!requestDone) return;
    cookiePro.init();
    const head = document.head;
    if (isGranVillage) {
      getIAdvizeScripts().forEach((script) => head.appendChild(script));
    } else if (isKuuPanda) {
    }
  }, [requestDone, isKuuPanda, isGranVillage]);
}

export { useGranvillageIAdvizeAndCookies };
