import React, { useCallback } from "react";
import PropTypes from "prop-types";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { ContactPointShape } from "../Checkout/proptypes";
import constants from "./constants";
import ButtonIconOption from "./ButtonIconOption";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { isCpChronopost } from "../Checkout/utils";

const UnavailableDeliveryLabel = styled(Typography)`
  font-size: 12px;
  margin-left: 3px;
  margin-top: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 450px;
  align-items: left;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const DeliveryWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: auto;
`;

function ContactPointButtons({
  handleOnSelect,
  hasDeliveryCPs,
  hasTakeAwayCPs,
  contactPoint,
  contactPoints,
  unavailableDelivery,
}) {
  const onClickTakeaway = useCallback(
    () => handleOnSelect(constants.CONTACT_POINT_TYPE.TAKE_AWAY),
    [handleOnSelect]
  );
  const onClickDelivery = useCallback(
    () => handleOnSelect(constants.CONTACT_POINT_TYPE.DELIVERY),
    [handleOnSelect]
  );

  const getMinimumDeliveyAmount = (contactPoints) => {
    const minimumDeliveryAmounts = Array.from(
      contactPoints,
      (cp) => cp.minimumDeliveryAmount || null
    ).filter((cp) => {
      return cp !== null;
    });
    return Math.min(...minimumDeliveryAmounts);
  };

  const isChronoPost = isCpChronopost(contactPoint.type);
  return (
    <Wrapper item container xs={12}>
      {hasDeliveryCPs && (
        <DeliveryWrapper>
          <ButtonIconOption
            selected={
              !isChronoPost
                ? contactPoint.type
                : constants.CONTACT_POINT_TYPE.DELIVERY
            }
            icon={<LocalShippingIcon />}
            onClick={onClickDelivery}
            id={constants.CONTACT_POINT_TYPE.DELIVERY}
            label="Livraison à domicile ou en point relais*"
          />
          {unavailableDelivery && (
            <UnavailableDeliveryLabel variant="caption" color="primary">
              {`Livraison disponible à partir de 
            ${
              unavailableDelivery
                ? getMinimumDeliveyAmount(contactPoints) // get the lowest minimumDeliveryAmount value
                : ""
            }
            €`}
            </UnavailableDeliveryLabel>
          )}
        </DeliveryWrapper>
      )}
      {hasTakeAwayCPs && (
        <ButtonIconOption
          selected={contactPoint.type}
          icon={<StorefrontIcon />}
          onClick={onClickTakeaway}
          id={constants.CONTACT_POINT_TYPE.TAKE_AWAY}
          label="Points de retrait du producteur"
        />
      )}
    </Wrapper>
  );
}
ContactPointButtons.propTypes = {
  contactPoint: ContactPointShape.isRequired,
  handleOnSelect: PropTypes.func,
  hasDeliveryCPs: PropTypes.bool,
  hasTakeAwayCPs: PropTypes.bool,
  unavailableDelivery: PropTypes.bool,
};

export default ContactPointButtons;
