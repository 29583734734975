import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Componets
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button as ButtonComponent } from "@material-ui/core";

// Styleds
const Loader = styled(CircularProgress)`
  margin-right: 10px;
`;
const Button = styled(ButtonComponent)`
  text-transform: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;
// switch with Material theme
const PrimaryButton = styled(Button)`
  :hover {
    ${({ theme }) => `
      background-color: ${theme.palette.primary.dark};
    `};
  }
  :disabled {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    color: ${({ theme }) => theme.palette.grey["100"]};
    opacity: 0.5;
  }
`;
const SecondaryButton = styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    border: 2px solid ${theme.palette.primary.main};
  `};
  :hover {
    ${({ theme }) => `
      border-color: ${theme.palette.primary.dark};
    `};
  }
  :disabled {
    opacity: 0.5;
    ${({ theme }) => `
      color: ${theme.palette.primary.main};
    `};
  }
`;

const WhiteButton = styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    background: #ffffff;
    border: 2px solid #ffffff;
  `};
  :hover {
    ${({
      theme: {
        palette: { grey },
      },
    }) => `
      background: ${grey["200"]};
      border: 2px solid ${grey["200"]};
    `};
  }
  :disabled {
    opacity: 0.5;
  }
`;

const WineButton = styled(Button)`
  ${({ theme }) => `
    color: #ffffff;
    background: ${theme.palette.secondary.main};
    border: 2px solid ${theme.palette.secondary.main};
    font-weight: bold;
    font-size: 14px;
  `};
  :hover {
    ${({ theme }) => `
      background: ${theme.palette.primary.main};
      border: 2px solid ${theme.palette.primary.main};
    `};
  }
  :disabled {
    opacity: 0.5;
  }
`;

const TransparentButton = styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    background: transparent;
    border: 2px solid ${theme.palette.secondary.main};
    font-weight: bold;
    font-size: 14px;
  `};
  :hover {
    ${({ theme }) => `
      background: transparent;
      border: 2px solid ${theme.palette.primary.main};
    `};
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const GreyButton = styled(Button)`
  text-transform: none;
  height: 36px;
  background-color: ${({ theme }) => theme.palette.others.k};
  box-shadow: 0px 2px 4px rgba(50, 50, 71, 0.03);
  border-radius: 4px;
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: 500;
  color: #1b1a36;
  margin-right: ${({ marginRight }) => marginRight};
  padding: 0 10px;
  svg {
    fill: #1b1a36;
    height: 30px;
  }
  &:hover {
    background-color: #d9dbe7;
  }
`;

function getButton({
  className,
  size,
  styled,
  loading,
  disabled,
  children,
  onClick,
  fullWidth,
  component,
  to,
  type,
}) {
  switch (styled) {
    case "secondary":
      return (
        <SecondaryButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          component={component}
          to={to}
          type={type}
        >
          {children}
        </SecondaryButton>
      );
    case "white":
      return (
        <WhiteButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          variant="contained"
          component={component}
          to={to}
          type={type}
        >
          {children}
        </WhiteButton>
      );
    case "wine":
      return (
        <WineButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          type={type}
        >
          {children}
        </WineButton>
      );
    case "transparent":
      return (
        <TransparentButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          component={component}
          to={to}
          type={type}
        >
          {children}
        </TransparentButton>
      );
    case "grey":
      return (
        <GreyButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          component={component}
          to={to}
          type={type}
        >
          {children}
        </GreyButton>
      );

    default:
      return (
        <PrimaryButton
          className={className}
          size={size}
          onClick={onClick}
          disabled={loading || disabled}
          fullWidth={fullWidth}
          color="primary"
          variant="contained"
          component={component}
          to={to}
          type={type}
        >
          {children}
        </PrimaryButton>
      );
  }
}

function getLabel({ loading, children }) {
  return (
    <>
      {loading && <Loader size={20} color="inherit" />} {children}
    </>
  );
}

const Component = ({
  className,
  size,
  styled,
  loading,
  disabled,
  children,
  onClick,
  fullWidth,
  component,
  to,
  type,
}) => {
  return getButton({
    className,
    size,
    styled,
    loading,
    disabled,
    children: getLabel({ className, loading, children }),
    onClick,
    fullWidth,
    component,
    to,
    type,
  });
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  styled: PropTypes.oneOf([
    "primary",
    "secondary",
    "subtle",
    "text",
    "white",
    "wine",
    "transparent",
  ]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  component: PropTypes.node.isRequired,
  to: PropTypes.string,
};

Component.defaultProps = {
  onClick: () => {},
  size: "medium",
  styled: "primary",
  disabled: false,
  loading: false,
  className: "",
  fullWidth: false,
  component: "button",
  to: undefined,
};

export default Component;
