import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, IconButton, List } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { ClusterConflictRow } from "./ClusterConflictRow";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function ClusterConflictModal({
  open,
  markers,
  onClose,
  usersMap,
}) {
  const classes = useStyles();
  const getAddress = () => {
    if (!markers.length) return "";
    // all markers have the same position but not necessarily the same text address,
    // some address can be more precise than other. We take the longest one, assuming it's the most complete
    return _.map(markers, "address").sort((a, b) => b.length - a.length)[0];
  };
  return (
    <div>
      <Dialog
        // this is the class we use on the top level.
        // We're re-using tht here because the dialog is anchored on the body directly
        // and therefore every css rues under `.SearchMap` don't apply
        className="SearchMap"
        open={open}
        onClose={onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ paddingRight: 50 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ justifyContent: "space-between" }}
          >
            {getAddress()}
            {onClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List disablePadding>
            {markers.map((marker, i) => (
              <ClusterConflictRow
                marker={marker}
                key={marker.id}
                user={usersMap[marker.userID]}
                isFirstItem={i === 0}
              />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
