import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Componets
import withWidth from "@material-ui/core/withWidth";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Components
import Input from "../Common/Input";
import Button from "../Common/Button";

// Hooks
import { formatDateString } from "../Helpers/date";

// Styleds
const AvatarBanner = styled(Grid)`
  padding: 0 25px;
`;
const StyledAvatar = styled(Avatar)`
  border-radius: 40px;
  width: 125px;
  height: 125px;
`;
const UserName = styled(Typography)`
  margin: 0 15px;
  color: ${({ theme }) => theme.palette.grey["800"]};
  font-weight: bold;
`;
const CreatedAt = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey["600"]};
`;
const Form = styled(Grid)``;
const FormRow = styled(Grid)`
  margin: 10px;
  padding: 0 ${(props) => props.padding || 0};
`;
const StyledButton = styled(Button)`
  ${(props) =>
    (props.width === "xs" || props.width === "sm") &&
    `
    width: 100%;
  `};
`;

const FormUser = ({
  user: { name, lastName, phone, verifyExpires, email, id: userId } = {},
  authHttpClient,
  width,
  manageCustomerBasicInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name,
    lastName,
    phone,
  });

  useEffect(() => {
    setValues({ name, lastName, phone });
  }, [name, lastName, phone]);

  const handleChangeInputs = useCallback(
    (e) => {
      const value = e.target.value;
      setValues({
        ...values,
        [e.target.name]: value,
      });
    },
    [setValues, values]
  );

  const handleUpdate = useCallback(async () => {
    setLoading(true);
    try {
      await authHttpClient.patch(`/user/${userId}`, values);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [authHttpClient, values, userId]);
  return (
    <Form container item>
      <AvatarBanner container item xs={12} sm={12} alignItems="center">
        {width !== "xs" && width !== "sm" && <StyledAvatar alt="avatar" />}
        <UserName
          variant="h3"
          align={width !== "xs" && width !== "sm" ? "left" : "center"}
        >
          {name}
          <CreatedAt variant="body1">
            membre depuis le {verifyExpires && formatDateString(verifyExpires)}
          </CreatedAt>
        </UserName>
      </AvatarBanner>
      <FormRow item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            size="normal"
            fullWidth={true}
            id="name"
            name="name"
            value={values.name}
            internalLabel="Prénom"
            required={true}
            type="text"
            disabled={!!loading || !manageCustomerBasicInfo}
            onChange={handleChangeInputs}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            size="normal"
            fullWidth={true}
            id="lastName"
            name="lastName"
            value={values.lastName}
            internalLabel="Nom"
            required={true}
            type="text"
            disabled={!!loading || !manageCustomerBasicInfo}
            onChange={handleChangeInputs}
          />
        </Grid>
      </FormRow>
      <FormRow item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            size="normal"
            fullWidth={true}
            id="phone"
            name="phone"
            value={values.phone}
            internalLabel="Numéro de téléphone"
            required={true}
            type="text"
            disabled={!!loading || !manageCustomerBasicInfo}
            onChange={handleChangeInputs}
          />
        </Grid>
      </FormRow>
      <FormRow item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            size="normal"
            fullWidth={true}
            id="email"
            name="email"
            value={email}
            internalLabel="Adresse mail"
            required={true}
            type="email"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            size="normal"
            fullWidth={true}
            id="password"
            name="password"
            internalLabel="Mot de passe"
            required={true}
            type="password"
            value="1234567890"
            disabled={true}
          />
        </Grid>
      </FormRow>
      <FormRow container direction="row-reverse" padding="8px">
        {manageCustomerBasicInfo ? (
          <StyledButton
            component="button"
            styled={width !== "xs" && width !== "sm" ? "secondary" : "primary"}
            loading={loading}
            onClick={handleUpdate}
            width={width}
          >
            Enregistrer
          </StyledButton>
        ) : (
          <a href={"https://granvillage.com"}>
            <StyledButton
              component="button"
              styled={
                width !== "xs" && width !== "sm" ? "secondary" : "primary"
              }
              width={width}
            >
              Editer
            </StyledButton>
          </a>
        )}
      </FormRow>
    </Form>
  );
};

FormUser.propTypes = {
  user: PropTypes.object.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

FormUser.defaultProps = {};

export default withWidth()(FormUser);
