import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { Grid, Typography, withWidth } from "@material-ui/core";

// Components
import Logo from "../Logo";

// Contexts
import { useCart } from "../Context/CartContext";

// Styleds
const Page = styled(Grid)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.grey["100"]};
`;
const Container = styled(Grid)`
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "100px 80px" : "80px 20px"};
  height: ${(props) => (props.width !== "xs" ? "auto" : "100%")};
`;
const Title = styled(Typography)`
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
`;
const LogoContainer = styled(Grid)`
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    max-height: 250px;
  }
  @media (min-width: 960px) {
    img {
      width: 80%;
    }
  }
`;
const Message = styled(Typography)`
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 12px;
  font-weight: bold;
`;
const Error = styled(Message)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
`;
const Success = styled(Message)`
  background-color: ${({ theme }) => theme.palette.success.dark};
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

const Layout = ({ title, width, success, error, children }) => {
  const { setHide } = useCart();

  // Init
  useEffect(() => {
    setHide(true);
  }, [setHide]);

  // Unmount
  useEffect(
    () => () => {
      setHide(false);
    },
    [setHide]
  );

  return (
    <Page container justify="center" alignItems="center">
      <Container container item xs={12} sm={8} md={8} width={width} spacing={2}>
        <Grid item xs={12}>
          {error && (
            <Error variant="body1" component="h1">
              {error}
            </Error>
          )}
          {success && (
            <Success variant="body1" component="h1">
              {success}
            </Success>
          )}
        </Grid>
        <LogoContainer item xs={12} sm={12} md={6}>
          <Logo logoType="LARGE_VERTICAL" />
        </LogoContainer>
        <Grid item xs={12} sm={12} md={6}>
          <Title
            component="h1"
            variant="h3"
            color="textSecondary"
            align="center"
          >
            {title}
          </Title>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  title: PropTypes.string.isRequired,
  success: PropTypes.any,
  error: PropTypes.any,
};

Layout.defaultProps = {
  title: "",
  success: undefined,
  error: undefined,
};

export default withWidth()(Layout);
