/**
 * Created by mongoose on 17/04/18.
 */
import React from "react";
import {
  BrandLogo,
  LogoType,
  useBrandConfig,
} from "../Context/BrandConfigContext";

type ValidLogoType = Exclude<LogoType, "INVALID_LOGO_TYPE">;

function getLogoUrlByType(logosArr: BrandLogo[], type: ValidLogoType) {
  const foundIdx = logosArr.findIndex(({ logoType }) => logoType === type);
  if (foundIdx < 0) {
    console.error("Invalid logo type!.");
    return "";
  }
  return logosArr[foundIdx].multimedia.url;
}

type ImgProps = React.HTMLAttributes<HTMLImageElement> & { alt?: string };

type LogoProps = {
  logoType: ValidLogoType;
} & ImgProps;

const Logo = ({
  logoType = "LARGE",
  alt = "Logo",
  ...restProps
}: LogoProps) => {
  const {
    brandConfig: {
      theme: { logoConfigurations },
    },
  } = useBrandConfig();
  const src = getLogoUrlByType(logoConfigurations, logoType);
  return <img {...restProps} src={src} alt={alt} />;
};

export default React.memo(Logo);
