import React from "react";
import { Avatar } from "@material-ui/core";
import styled from "styled-components";
import ResponsivePicture from "../../Common/ResponsivePicture";

const StyledResponsivePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  background-color: red;
`;

export default function AvatarWrapper({ height, size, profilePicture, alt }) {
  if (!profilePicture) {
    return <Avatar alt={alt} />;
  }
  return (
    <Avatar
      alt={alt}
      component={() => (
        <StyledResponsivePicture
          height={height}
          sizeQuery={size}
          baseImgUrl={profilePicture}
          fitType="cover"
        />
      )}
    />
  );
}
