import React, { useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ResponsivePicture from "../Common/ResponsivePicture";
import { RiErrorWarningLine as WarningIcon } from "react-icons/ri";
import constants from "../Common/constants";
import { debounce } from "lodash";

// helpers
import media from "../Helpers/media";

//Hooks
import useProductErrors from "../Hooks/useProductErrors";
import { getPrice } from "../VendorStore/services/utils";
import { NumberFormatCustom } from "@kuupanda/commons";

const Quantity = styled.span`
  position: relative;
`;

const QuantitySelector = styled.input`
  width: auto;
  max-width: 100px;
  min-width: 90px;
  height: 30px;
  float: left;
  display: block;
  margin: 0;
  padding: 0 49px 0 0;
  border: 1px solid ${({ theme }) => theme.palette.grey["200"]};
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 1.65;
  font-weight: 400;
  text-align: center;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;

const QuantityChanger = styled.span`
  float: left;
  position: relative;
  height: 30px;
`;

const QuantityUp = styled.span`
  position: absolute;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.palette.grey["200"]};
  width: 25px;
  text-align: center;
  color: ${({ theme }) => theme.palette.others.z};
  line-height: 31px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 100%;
  top: 0;
  right: -25px;
  font-size: 20px;
`;

const QuantityDown = styled.span`
  position: absolute;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.palette.grey["200"]};
  width: 25px;
  text-align: center;
  line-height: 31px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 100%;
  top: 0;
  color: ${({ theme }) => theme.palette.others.a1};
  right: 0;
  font-size: 20px;
`;

const ProductPrice = styled(Typography)<{ $priceChanged: boolean }>`
  ${({ $priceChanged }) =>
    $priceChanged &&
    `
    color: ${(props: any) => props.theme.palette.grey[400]};
    text-decoration: line-through;
  `};
  margin-top: 10px;
  font-weight: 300;
`;

const RemoveItem = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ItemWrapper = styled.div`
  &.move-enter {
    opacity: 0.01;
    transform: translate(0, -40px);
  }
  &.move-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 250ms ease-in;
  }
  &.move-exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  &.move-exit-active {
    opacity: 0.01;
    transform: translate(0, 40px);
    transition: all 250ms ease-in;
  }
  border: 1px solid #d9dbe7;
  box-shadow: 0px 4px 10px rgba(50, 50, 71, 0.05),
    0px 4px 12px rgba(50, 50, 71, 0.03);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
`;

const ProductTitle = styled.span`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 0;
  max-width: 175px;
  ${media.down.md`
    max-width: 125px;
  `}
  display: block;
`;

const ErrorBar = styled.div<{ errorType: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ errorType }) =>
    errorType === constants.PRODUCT_ERRORS.UNAVAILABLE ? "#DE5F5F" : "#464861"};
  font-weight: 500;
  font-size: 14px;
  background-color: ${({ theme, errorType }) =>
    errorType === constants.PRODUCT_ERRORS.UNAVAILABLE
      ? "#FDEBE1"
      : theme.palette.warning.light};
  padding: 5px;
  border-radius: 4px 4px 0px 0px;
  margin: -10px -10px 10px -10px;
`;

const ItemGrid = styled(Grid)<{ $isUnavailable: boolean }>`
  opacity: ${({ $isUnavailable }) => ($isUnavailable ? 0.4 : 1)};
`;

const ActionButton = styled(Button)`
  color: white;
  background: #464861;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(50, 50, 71, 0.03);
  border-radius: 4px;
  text-transform: none;
  &:hover {
    background: #65677c;
  }
`;

const CartActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-items: flex-end;
  width: 100%;
  margin: 5px;
  gap: 10px;
`;

const DiscountAmount = styled.span`
  color: white;
  background: ${({ theme }) => theme.palette.warning.main};
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
`;

type CartItemProps = {
  id: string;
  name: string;
  price: number;
  quantity: number;
  url: string;
  onRemoveFromCart: (id: string) => void;
  onChangeQuantity: (id: string, quantity: number) => void;
  errors: any[];
  basePrice: number;
  promos: any[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  availableQuantity: number | null;
};
export default function CartItem({
  id,
  name,
  price,
  quantity,
  url,
  onRemoveFromCart,
  onChangeQuantity,
  errors,
  basePrice,
  promos,
  loading,
  setLoading,
  availableQuantity,
}: CartItemProps) {
  const {
    isUnavailable,
    hasPriceChange,
    hasDiscount,
    discountAmount,
    currentPrice,
    newPrice,
  } = useProductErrors({
    errors,
    price,
    basePrice,
    promos,
  });

  const [currentQuantity, setCurrentQuantity] = useState(quantity);

  const change = async (id: string, quantity: number) => {
    if (quantity < 0) return;
    if (availableQuantity !== null && quantity > availableQuantity) {
      return;
    }

    setCurrentQuantity(quantity);
    setLoading(true);
    await handleQuantityChange(id, quantity);
  };

  //eslint-disable-next-line
  const handleQuantityChange = useCallback(
    debounce(async (id: any, quantity: number) => {
      await onChangeQuantity(id, quantity);
      setLoading(false);
    }, 1000),
    []
  );

  return (
    <ItemWrapper>
      {errors &&
        errors.map((error) => (
          <ErrorBar errorType={error.type}>
            <WarningIcon />
            <span>{error.message}</span>
          </ErrorBar>
        ))}

      <ItemGrid
        container
        spacing={2}
        alignContent="center"
        $isUnavailable={isUnavailable}
      >
        <Grid item xs={3} md={3}>
          {/* @ts-ignore */}
          <ResponsivePicture height={84} sizeQuery="84px" baseImgUrl={url} />
        </Grid>
        <Grid item xs={9} md={9} container>
          <Grid item md={9} xs={9} container direction="column" spacing={2}>
            <Grid item xs={9} md={9}>
              <ProductTitle>{name}</ProductTitle>
              <Quantity>
                <NumberFormatCustom
                  disabled
                  value={currentQuantity}
                  customInput={QuantitySelector}
                  suffix={" "}
                  fixedDecimalScale={false}
                />
                <QuantityChanger>
                  <QuantityUp onClick={() => change(id, currentQuantity + 1)}>
                    +
                  </QuantityUp>
                  <QuantityDown onClick={() => change(id, currentQuantity - 1)}>
                    -
                  </QuantityDown>
                </QuantityChanger>
              </Quantity>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <ProductPrice variant="body2" $priceChanged={hasPriceChange}>
              {!loading ? (
                getPrice({ price: quantity * currentPrice })
              ) : (
                <CircularProgress size={15} />
              )}
            </ProductPrice>

            {!isUnavailable && (
              <>
                {hasDiscount && (
                  <DiscountAmount>
                    -{" "}
                    <NumberFormatCustom
                      value={discountAmount}
                      displayType={"text"}
                      suffix={" %"}
                    />
                  </DiscountAmount>
                )}

                {hasPriceChange && (
                  <ProductPrice variant="body2" $priceChanged={false}>
                    {!loading ? (
                      getPrice({ price: quantity * newPrice })
                    ) : (
                      <CircularProgress size={15} />
                    )}
                  </ProductPrice>
                )}

                <RemoveItem onClick={() => onRemoveFromCart(id)}>
                  <span>Supprimer</span>
                </RemoveItem>
              </>
            )}
          </Grid>
        </Grid>
      </ItemGrid>
      {isUnavailable && (
        <CartActions>
          <ActionButton onClick={() => onRemoveFromCart(id)}>
            Compris!
          </ActionButton>
        </CartActions>
      )}
    </ItemWrapper>
  );
}
