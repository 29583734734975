import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// Assets
import { ReactComponent as Mail } from "../Assets/Icons/mail.svg";
import { ReactComponent as Phone } from "../Assets/Icons/phone.svg";
import { ReactComponent as Facebook } from "../Assets/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../Assets/Icons/instagram.svg";
import { ReactComponent as Youtube } from "../Assets/Icons/youtube.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`footer-panel-${index}`}
      aria-labelledby={`footer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `footer-tab-${index}`,
    "aria-controls": `footer-panel-${index}`,
  };
}

const FooterAppBar = styled(AppBar)`
  background-color: white;
`;

const FooterTabs = styled(Tabs)`
  & .MuiTab-wrapper {
    ${({ theme }) => `
      background: ${theme.palette.secondary.main};
    `}
    color: white;
    padding: 10px;
    border-radius: 28px;
  }
  & .MuiTabs-indicator {
    background: white;
  }
`;

const FooterTab = styled(Tab)``;

const FooterList = styled.ul`
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  line-height: 32px;
`;

const StyledAnchor = styled.a`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `};
  text-decoration: none;
`;

const ExternalLink = styled.a`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `};
  text-decoration: none;
`;

const Copyright = styled.div`
  color: white;
  ${({ theme }) => `
    background: ${theme.palette.secondary.dark};
  `};
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const FollowUs = styled(Typography)`
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const MailIcon = styled(Mail)`
  vertical-align: middle;
  padding-right: 5px;
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `};
`;

const PhoneIcon = styled(Phone)`
  vertical-align: middle;
  padding-right: 5px;
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `};
`;

const Social = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FooterContainer = styled.div`
  padding-top: 40px;
`;

export default function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FooterContainer>
      <FooterAppBar position="static" elevation={0}>
        <FooterTabs variant="scrollable" value={value} onChange={handleChange}>
          <FooterTab label="Contactez nous" {...a11yProps(0)} />
          <FooterTab label="Á propos" {...a11yProps(1)} />
          <FooterTab label="Legal" {...a11yProps(2)} />
        </FooterTabs>
      </FooterAppBar>
      <TabPanel value={value} index={0}>
        <FooterList>
          <li>
            <MailIcon />
            <ExternalLink href="mailto:oui@kuupanda.com">
              oui@kuupanda.com
            </ExternalLink>
          </li>
          <li>
            <PhoneIcon />
            <ExternalLink href="tel:+33 6 89 66 26 20">
              +33 6 89 66 26 20
            </ExternalLink>
          </li>
        </FooterList>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FooterList>
          <li>
            <StyledAnchor href="/comment-ca-marche">
              Comment ça marche ?
            </StyledAnchor>
          </li>
          <li>
            <StyledAnchor href="/qui-sommes-nous">
              Qui porte ce projet ?
            </StyledAnchor>
          </li>
        </FooterList>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FooterList>
          <li>
            <StyledAnchor href="/cgv">
              Conditions Générales de Vente et d'Utilisation (CG)
            </StyledAnchor>
          </li>
          <li>
            <StyledAnchor href="/legal-mentions">Mentions légales</StyledAnchor>
          </li>
          <li>
            <StyledAnchor href="/gdpr">
              Politique de protection des données
            </StyledAnchor>
          </li>
        </FooterList>
      </TabPanel>
      <Copyright>
        <FollowUs>Suivez nous</FollowUs>
        <Social>
          <ExternalLink
            target="_blank"
            href="https://www.facebook.com/kuupandafrance"
          >
            <Facebook style={{ paddingRight: 5 }} />
          </ExternalLink>
          <ExternalLink
            target="_blank"
            href="https://www.instagram.com/kuupanda_"
          >
            <Instagram style={{ paddingRight: 5 }} />
          </ExternalLink>
          <ExternalLink
            target="_blank"
            href="https://www.youtube.com/channel/UCLqNY_p1wDHpy7IXlOz8tCw"
          >
            <Youtube />
          </ExternalLink>
        </Social>
        © Kuupanda {new Date().getFullYear()}
      </Copyright>
    </FooterContainer>
  );
}
