import React, { useCallback, useContext, useEffect, useState } from "react";

const ConfigContext = React.createContext();

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({
    env: process.env.NODE_ENV,
    mangoPayScriptURL: process.env.REACT_APP_MANGO_PAY_SCRIPT_URL,
    mangoPayStyleURL: process.env.REACT_APP_MANGO_PAY_STYLE_URL,
  });
  const [loading, setLoading] = useState(true);
  const fetchConfig = useCallback(async () => {
    try {
      // setConfig({
      //   theme: createMuiTheme({
      //     palette: {
      //       primary: {
      //         main: "#000000",
      //       },
      //     },
      //   }),
      // });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  if (loading) {
    return null;
  }

  return (
    <ConfigContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const configuration = useContext(ConfigContext);
  if (configuration == null) {
    throw new Error(
      "You must call useConfig only when inside a ConfigProvider."
    );
  }

  return configuration;
}

const ConfigConsumer = ConfigContext.Consumer;

export { ConfigProvider, ConfigContext, ConfigConsumer };
