import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { baseMargin } from "./styles";
import { Grid } from "@material-ui/core";
import RecoveryInfoInput from "../Checkout/Components/RecoveryInfoInput";

const Container = styled.div`
  ${baseMargin}
  width: 100%;
`;
const emptyFunc = () => {};
function SimplifiedCheckout({
  contactPoints,
  onRecoveryInfoChange,
  producerId,
  storeName,
  cartSubTotal,
  preparationTime,
  availableDate,
  disableNextButton,
  isSimplifiedStore,
  cart,
}) {
  return (
    <Grid xs={12} md={11} container item justify="center" direction="row">
      <Container>
        <RecoveryInfoInput
          vendorId={producerId}
          contactPoints={contactPoints}
          products={[]}
          vendorName={storeName}
          showVendorName={false}
          targetInput="recovery_info_value"
          setDeliveryRate={emptyFunc}
          resetDeliveryRate={emptyFunc}
          onStateCheck={emptyFunc}
          vendorIdx={1}
          showTitle
          showDescription={false}
          onRecoveryInfoChange={onRecoveryInfoChange}
          totalAmount={cartSubTotal}
          preparationTime={preparationTime}
          availableDate={availableDate}
          disableNextButton={disableNextButton}
          isSimplifiedStore={isSimplifiedStore}
          cart={cart}
        />
      </Container>
    </Grid>
  );
}

SimplifiedCheckout.propTypes = {
  contactPoints: PropTypes.array,
  producerId: PropTypes.number.isRequired,
  onRecoveryInfoChange: PropTypes.func.isRequired,
  cartSubTotal: PropTypes.number.isRequired,
  storeName: PropTypes.string,
  preparationTime: PropTypes.number,
  availableDate: PropTypes.object,
  disableNextButton: PropTypes.func,
  cart: PropTypes.object,
};

SimplifiedCheckout.defaultProps = {
  storeName: undefined,
  preparationTime: 0,
  availableDate: undefined,
  cart: {},
};

export default SimplifiedCheckout;
