import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import EuroIcon from "@material-ui/icons/Euro";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ToggleButton from "@material-ui/lab/ToggleButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import NoteIcon from "@material-ui/icons/Note";
import { useTheme } from "@material-ui/core";
import styled from "styled-components";
import { usePresentation } from "../Context/PresentationContext";
import constants from "../VendorStore/utils/constants";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  margin: 10px 0;
  max-width: 100%;
  ${(props) =>
    props.isEmbedded &&
    `
    flex-wrap: wrap;
    button {
      border-radius: 0 !important;
      border-left: 1px solid  rgba(0, 0, 0, 0.12) !important;    
    }
  `}
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 140px;
  ${(props) =>
    props.selected &&
    ` 
    background-color: ${props.theme.palette.primary.main} !important;
    color: ${props.theme.palette.primary.contrastText} !important;
    border-color: ${props.theme.palette.primary.main} !important;
  `};
`;
const PaymentMethodOptionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-size: 12px;
  @media (max-width: 600px) {
    font-size: 10px;
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
`;

function getPaymentMethodTitle(paymentMethod) {
  switch (paymentMethod) {
    case constants.PAYMENT_METHOD.CREDIT_CARD:
      return "Carte de crédit";
    case constants.PAYMENT_METHOD.TRANSFER:
      return "Virement";
    case constants.PAYMENT_METHOD.CHEQUE:
      return "Chèque";
    case constants.PAYMENT_METHOD.CASH:
      return "Paiement sur place";
    default:
      return "";
  }
}

function getPaymentMethodIcon(paymentMethod) {
  switch (paymentMethod) {
    case constants.PAYMENT_METHOD.CREDIT_CARD:
      return <CreditCardIcon fontSize="large" />;
    case constants.PAYMENT_METHOD.TRANSFER:
      return <AccountBalanceIcon fontSize="large" />;
    case constants.PAYMENT_METHOD.CHEQUE:
      return <NoteIcon fontSize="large" />;
    case constants.PAYMENT_METHOD.CASH:
      return <EuroIcon fontSize="large" />;
    default:
      return "";
  }
}

function PaymentMethodOptions({
  paymentMethod,
  paymentMethods,
  onChangePaymentMethod,
}) {
  const { isEmbedded } = usePresentation();
  const theme = useTheme();
  const handlePaymentMethodChange = useCallback(
    (event, newPaymentMethod) => {
      event.preventDefault();
      if (!newPaymentMethod || newPaymentMethod.type === paymentMethod) {
        return;
      }
      onChangePaymentMethod(newPaymentMethod.type);
    },
    [onChangePaymentMethod, paymentMethod]
  );
  return (
    <StyledToggleButtonGroup
      value={paymentMethod}
      exclusive
      aria-label="payment-method"
      isEmbedded={isEmbedded}
    >
      {paymentMethods.map((it, index) => (
        <StyledToggleButton
          selected={it.type === paymentMethod}
          value={it.type}
          theme={theme}
          index={index}
          onClick={(event) => handlePaymentMethodChange(event, it)}
        >
          <PaymentMethodOptionButton>
            {getPaymentMethodIcon(it.type)}
            {getPaymentMethodTitle(it.type)}
          </PaymentMethodOptionButton>
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}

PaymentMethodOptions.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
      options: PropTypes.object,
    })
  ),
  paymentMethod: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
};

PaymentMethodOptions.defaultProps = {
  paymentMethods: [
    { type: constants.PAYMENT_METHOD.CREDIT_CARD },
    { type: constants.PAYMENT_METHOD.CASH },
  ],
};

export default PaymentMethodOptions;
