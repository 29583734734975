import { CartItem, FunnelStepKey, ProductByCategory, Store } from "./tracking";
import { isCpDelivery } from "../Checkout/utils";

declare global {
  interface Window {
    wa_gfr: any;
    _satellite?: any;
  }
}

export enum SiteName {
  GRANVILLAGE = "Granvillage",
}

export enum SiteType {
  ECOMMERCE = "E-commerce",
}

export enum SiteCategory {
  PRODUCER_PAGE = "Page Producteur",
  FUNNEL_PAGE = "Parcours Achat",
}

export enum Breadcrumb {
  EMPTY = "",
  CART = "E-Commerce > Etape 1 > Panier",
  ADDRESS = "E-Commerce > Etape 2 > Adresse",
  PAYMENT = "E-Commerce > Etape 3 > Paiement",
  PAYMENT_DATA = "E-Commerce > Etape 3 bis > Coordonnées Bancaires",
  PAYMENT_CONFIRMATION = "E-Commerce > Etape 4 > Confirmation Paiement",
  MY_ORDERS = "E-Commerce > Mes commandes",
}

export enum AccountPage {
  PRODUCER_PAGE = "Page Producteur",
}

export enum ConnectionType {
  CONNECTED = "Connecte",
  NOT_CONNECTED = "Non Connecte",
}

export enum DeliveryMode {
  IN_PLACE = "Sur place",
  ONLINE = "En ligne",
}

interface GVDataLayer {
  contenu: {
    site_nom: SiteName;
    site_type: SiteType;
    "site categorie": SiteCategory;
    produits_categorie: string; //multiple values separated by "|"
    fil_ariane: Breadcrumb;
    page_compte: AccountPage;
  };
  utilisateur: {
    connexion: ConnectionType;
  };
  cart: {
    [key: string]: string | number;
    montant_total: number;
    nb_pdt: number;
    transaction_id: string;
    mode_livraison: string;
    nb_producteur: number;
  };
  cta: {
    page_origine: string;
    libelle: string;
  };
}

enum EventType {
  NAVIGATION = "NAVIGATION",
  CTA = "CTA",
}

export class AdobeLaunch {
  static ACTIVE: boolean = false;

  static get try() {
    // Creating class proxy so we can wrap all methods inside a try-catch
    // this is a good idea because tracking errors SHOULD NOT hinder the
    // application normal function.
    return new Proxy(this, {
      get(target: any, name) {
        if (typeof target[name] === "function") {
          return function () {
            try {
              if (!AdobeLaunch.ACTIVE) {
                return;
              }
              return target[name].apply(target, arguments);
            } catch (e) {
              console.debug("--- WARNING tracking failed ----", e);
            }
          };
        }
        return target[name];
      },
    });
  }

  static getAdobeLaunchScript(cb: () => void): HTMLScriptElement {
    const script = document.createElement("script");
    const scriptFile =
      process.env.REACT_APP_ADOBE_LAUNCH_SCRIPT ||
      "launch-af06043f6521-staging.min.js";
    script.src = `https://assets.adobedtm.com/854382efcf85/ff1fbb002a57/${scriptFile}`;
    script.onload = cb;
    return script;
  }

  static init() {
    if (!window) {
      console.log(
        "AdobeLaunch init function must be called in a browser's context"
      );
      return;
    }
    AdobeLaunch.ACTIVE = true;
    console.debug("---- Initializing Adobe Launch Tracking ----");
    if (window.wa_gfr && window._satellite) {
      return;
    }
    // https://github.com/adobe/adobe-client-data-layer/wiki
    // https://experienceleague.adobe.com/docs/analytics/implementation/prepare/data-layer.html?lang=en
    window.wa_gfr = window.wa_gfr || {};
    const script = AdobeLaunch.getAdobeLaunchScript(() => {
      window._satellite.pageBottom();
      console.debug("---- Initialized Adobe Launch Tracking ----");
    });
    window.document.head.appendChild(script);
  }

  static getDataLayer(): Partial<GVDataLayer> {
    return window.wa_gfr;
  }

  static sendNavigationEvent(props: Partial<GVDataLayer>): void {
    this.sendDatalayerData(props, EventType.NAVIGATION);
  }

  static sendCtaEvent(props: Partial<GVDataLayer>): void {
    this.sendDatalayerData(props, EventType.CTA);
  }

  private static sendDatalayerData(
    data: Partial<GVDataLayer>,
    eventType: EventType
  ) {
    console.debug(`---- Sending ${eventType} event ----`, data);
    window.wa_gfr = { ...window.wa_gfr, ...data };
    if (window._satellite && eventType === EventType.CTA) {
      window._satellite.track("gv.nav");
    }
    if (window._satellite && eventType === EventType.NAVIGATION) {
      window._satellite.track("gv.cta");
    }
  }

  static funnelStepMapping(key: FunnelStepKey): Breadcrumb {
    const mapping = {
      [FunnelStepKey.AUTH]: Breadcrumb.EMPTY,
      [FunnelStepKey.ADDRESS]: Breadcrumb.ADDRESS,
      [FunnelStepKey.CART]: Breadcrumb.CART,
      [FunnelStepKey.PAYMENT]: Breadcrumb.PAYMENT,
      [FunnelStepKey.CONFIRMATION]: Breadcrumb.PAYMENT_CONFIRMATION,
    };
    return mapping[key] || Breadcrumb.EMPTY;
  }

  static trackFunnelSteps(stepKey: FunnelStepKey) {
    const dataLayer = AdobeLaunch.getDataLayer();
    const existingContent = dataLayer.contenu
      ? dataLayer.contenu
      : {
          site_nom: SiteName.GRANVILLAGE,
          fil_ariane: Breadcrumb.EMPTY,
          site_type: SiteType.ECOMMERCE,
          "site categorie": SiteCategory.FUNNEL_PAGE,
          produits_categorie: "",
          page_compte: AccountPage.PRODUCER_PAGE,
        };
    AdobeLaunch.sendNavigationEvent({
      contenu: {
        ...existingContent,
        "site categorie": SiteCategory.FUNNEL_PAGE,
        fil_ariane: AdobeLaunch.funnelStepMapping(stepKey),
      },
    });
  }

  private static getDeliveryMode(
    contactPointByProducer: Record<string, { type: string }>
  ) {
    if (
      !contactPointByProducer ||
      Object.keys(contactPointByProducer).length === 0
    ) {
      return "";
    }
    return isCpDelivery(contactPointByProducer[0].type)
      ? DeliveryMode.ONLINE
      : DeliveryMode.IN_PLACE;
  }

  static trackCartInfo({
    cart,
    contactPointByProducer,
    cartTotals,
  }: {
    cart: Record<string, CartItem>;
    contactPointByProducer: Record<string, { type: string }>;
    cartTotals: {
      productTotal: string;
      feesTotal: string;
      discountTotal: string;
      taxes: string;
      total: string;
    };
  }) {
    const dataLayer = AdobeLaunch.getDataLayer();
    const numberOfProducts = cart ? Object.keys(cart).length : 0;
    const mapOfProducers = cart
      ? Object.values(cart).reduce(
          (acc, it) => ({ ...acc, [it.product.producer.id]: true }),
          {}
        )
      : {};
    const numberOfProducers = Object.keys(mapOfProducers).length;
    const productObject = cart
      ? Object.values(cart).reduce((acc, it, idx) => {
          const num = idx + 1;
          return {
            ...acc,
            [`pdt_qt_${num}`]: it.quantity,
            [`pdt_pu_${num}`]: it.product.price,
            [`pdt_montant_${num}`]: (
              Number(it.product.price) * it.quantity
            ).toFixed(2),
          };
        }, {})
      : {};
    const deliveryMode = AdobeLaunch.getDeliveryMode(contactPointByProducer);
    dataLayer.cart = dataLayer.cart
      ? { ...dataLayer.cart, mode_livraison: deliveryMode }
      : {
          montant_total: 0,
          nb_pdt: 0,
          nb_producteur: 0,
          transaction_id: "",
          mode_livraison: deliveryMode,
        };
    AdobeLaunch.sendNavigationEvent({
      cart: {
        mode_livraison: dataLayer?.cart?.mode_livraison || "",
        montant_total: Number(cartTotals.productTotal ?? 0),
        nb_pdt: numberOfProducts,
        nb_producteur: numberOfProducers,
        transaction_id: "",
        ...productObject,
      },
    });
  }

  static trackProducerPageInfo({
    store,
    productByCategory,
  }: {
    store: Store;
    productByCategory: ProductByCategory[];
  }) {
    const categories = productByCategory.map((it) => it.category).join("|");
    AdobeLaunch.sendNavigationEvent({
      contenu: {
        site_nom: SiteName.GRANVILLAGE,
        fil_ariane: Breadcrumb.EMPTY,
        site_type: SiteType.ECOMMERCE,
        "site categorie": SiteCategory.PRODUCER_PAGE,
        produits_categorie: categories,
        page_compte: AccountPage.PRODUCER_PAGE,
      },
    });
  }

  static trackAnonymousConnection() {
    AdobeLaunch.sendNavigationEvent({
      utilisateur: { connexion: ConnectionType.NOT_CONNECTED },
    });
  }

  static trackUserLogin() {
    AdobeLaunch.sendNavigationEvent({
      utilisateur: { connexion: ConnectionType.CONNECTED },
    });
  }

  static trackTransactionId(joinedIds: string) {
    const { cart } = AdobeLaunch.getDataLayer();
    const existingCart = cart ?? {
      montant_total: 0,
      nb_pdt: 0,
      nb_producteur: 0,
      transaction_id: "",
      mode_livraison: "",
    };
    AdobeLaunch.sendNavigationEvent({
      cart: { ...existingCart, transaction_id: joinedIds },
    });
  }
}
