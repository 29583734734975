import React, { useEffect } from "react";
import { StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./VendorStore/VendorStore.css";
import "./App.css";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@material-ui/core";
import "typeface-noto-sans-tc";

// Components
import SimplifiedStore from "./SimplifiedStore/SimplifiedStore";
import MarketplaceRoutes from "./Marketplace/MarketplaceRoutes";

// Contexts
import { ApiProvider } from "./Context/ApiContext";
import { ConfigProvider } from "./Context/ConfigContext";
import MangoPayTemplate from "./VendorStore/components/Payment/MangoPay/MangoPayTemplate";
import MangoPayPaymentComplete from "./VendorStore/components/Payment/MangoPay/MangoPayPaymentComplete";
import { withBrandConfig } from "./Context/BrandConfigContext";

import GranvillageStyles from "./GranvillageStyles";
import { CartProvider } from "./Context/CartContext";
import { PresentationProvider } from "./Context/PresentationContext";
import MainLayout from "./Layouts/Main";
import NoAuthBasket from "./NoAuthBasket";
import { useGranvillageIAdvizeAndCookies } from "./Hooks/useGranvillageIAdvizeAndCookies";
import { useWhichBrand } from "./Hooks/useWhichBrand";
import { Tracking } from "./Helpers/tracking";

function App({ brandConfig: { muiTheme } }) {
  const { isGranVillage } = useWhichBrand();
  useGranvillageIAdvizeAndCookies();
  useEffect(() => {
    if (!isGranVillage) {
      return;
    }
    Tracking.init();
  }, [isGranVillage]);
  const finalMuiTheme = isGranVillage
    ? {
        ...muiTheme,
        palette: {
          ...muiTheme.palette,
          text: {
            ...muiTheme.palette.text,
            primary: "#343A40",
          },
        },
      }
    : muiTheme;
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={finalMuiTheme}>
        <StyledThemeProvider theme={finalMuiTheme}>
          {isGranVillage ? <GranvillageStyles /> : null}
          <IntlProvider locale="fr" messages={{}}>
            <ApiProvider>
              <Router>
                <Switch>
                  <Route
                    exact
                    path="/payment/mangopay/template/:token"
                    render={({
                      match: {
                        params: { token },
                      },
                    }) => (
                      <ConfigProvider>
                        <MangoPayTemplate token={token} />
                      </ConfigProvider>
                    )}
                  />

                  <Route path="/payment/mangopay/payment-complete" exact>
                    <MangoPayPaymentComplete />
                  </Route>
                  <Route
                    exact
                    path={[
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/success",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug",
                      "/:storeType(producer|producteur|group)/:producerId/:saleChannelRaw/boutique/:storeFrontSlug/success",
                    ]}
                  >
                    <CartProvider>
                      <SimplifiedStore />
                    </CartProvider>
                  </Route>
                  <Route
                    path="/basket"
                    render={({ match }) => (
                      <CartProvider>
                        <MainLayout hideFooter hideOrderButton>
                          <NoAuthBasket />
                        </MainLayout>
                      </CartProvider>
                    )}
                  />
                  <ConfigProvider>
                    <PresentationProvider>
                      <MarketplaceRoutes />
                    </PresentationProvider>
                  </ConfigProvider>
                </Switch>
              </Router>
            </ApiProvider>
          </IntlProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default withBrandConfig(App);
