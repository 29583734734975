import React from "react";
import styled from "styled-components";
import { Grid, Hidden, Typography } from "@material-ui/core";

// helpers
import media from "../../Helpers/media";

// components
import Slider from "../../Common/Slider";

// asSets
import ResponsivePicture from "../../Common/ResponsivePicture";

// styled
const Container = styled.div`
  margin-top: 50px;
  ${({ theme }) => `
    background: ${theme.palette.secondary.main};
  `};
`;

const Quote = styled(Typography)`
  margin-top: 100px;
  font-size: 288px;
  line-height: 46px;
  color: #ffffff;
  ${media.down.md`
    font-size: 200px;
    line-height: 30px;
  `}
`;

const Headline = styled(Typography)`
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  letter-spacing: 0.01em;
  color: #ffffff;
  ${media.down.md`
    font-size: 16px;
    line-height: 140%;
  `}
`;

const SlideContent = styled.div`
  padding-top: 100px;
  padding-right: 10px;
  padding-bottom: 100px;
  ${media.down.md`
    padding-top: 0;
  `}
`;
const StyledResponsivePicture = styled(ResponsivePicture)`
  object-fit: none;
  height: auto;
  width: auto;
`;

const Author = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
  ${media.down.md`
    font-size: 12px;
    line-height: 131%;  
    letter-spacing: 2px;
  `}
`;

const PaddedGrid = styled(Grid)`
  padding: 30px;
  ${media.only.lg`
    padding-left: 85px;
  `}
`;

const Slide = styled.div``;

const Testimonials = () => {
  return (
    <Container>
      <Slider>
        <Slide>
          <SlideContent>
            <Grid container>
              <Hidden mdDown>
                <Grid item lg={4}>
                  <StyledResponsivePicture
                    alt="Radish"
                    src="https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/radish.png"
                    height={500}
                    sizeQuery="453px"
                    baseImgUrl="https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/radish.png"
                  />
                </Grid>
              </Hidden>
              <PaddedGrid item lg={8} md={12} sm={12}>
                <Quote>&ldquo;</Quote>
                <Headline>
                  Depuis que je commande sur Kuupanda je ne me pose plus la
                  question de la saison, je fonce. J’ai rencontré plein de
                  producteurs autour de chez moi. Je pense que c’est vraiment
                  l’avenir !
                </Headline>
                <Author>
                  Heloise, habitante à Perpignan et néo consommatrice
                </Author>
              </PaddedGrid>
            </Grid>
          </SlideContent>
        </Slide>
        <Slide>
          <SlideContent>
            <Grid container>
              <Hidden mdDown>
                <Grid item md={4}>
                  <StyledResponsivePicture
                    alt="Radish"
                    src="https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/radish.png"
                    height={500}
                    sizeQuery="453px"
                    baseImgUrl="https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/assets/radish.png"
                  />
                </Grid>
              </Hidden>
              <PaddedGrid item md={8}>
                <Quote>&ldquo;</Quote>
                <Headline>
                  J'ai découvert des éleveurs et des maraîchers à moins de 10km
                  de chez moi. Depuis je passe commande et je paie en ligne
                  directement depuis mon smartphone et les producteurs me
                  livrent en moins de 3 jours.
                </Headline>
                <Author>Simon, locavore à Céret</Author>
              </PaddedGrid>
            </Grid>
          </SlideContent>
        </Slide>
      </Slider>
    </Container>
  );
};

export default Testimonials;
