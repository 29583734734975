import React, { useContext, useState } from "react";

import { useWhichBrand } from "../Hooks/useWhichBrand";

export type StoreTabKey =
  | "description"
  | "contactPoints"
  | "delivery"
  | "information"
  | "store"
  | "offersAndEvents"
  | "contact";

export type StoreTab = {
  key: StoreTabKey;
  label: string;
  isExternal?: boolean;
  link?: string | ((props: any) => string);
  noBg?: boolean;
};

type PresentationOptions = {
  storeTabs: StoreTab[];
  showStoreOnTab: boolean;
  showFavourite: boolean;
  defaultTab: StoreTab;
  simpleProductCard: boolean;
  showProductionMethod: boolean;
};

type PresentationContextType = {
  isEmbedded: boolean;
  setIsEmbedded: (b: boolean) => void;
  setPresentationOptions: (po: PresentationOptions) => void;
  presentationOptions: PresentationOptions;
};

const defaultValue = {} as PresentationContextType;

const PresentationContext =
  React.createContext<PresentationContextType>(defaultValue);

const defaultTabs: StoreTab[] = [
  { key: "description", label: "À propos", isExternal: false },
  {
    key: "contactPoints",
    label: "Points de retraits",
    isExternal: false,
  },
  { key: "delivery", label: "Livraison", isExternal: false },
  { key: "information", label: "Information", isExternal: false },
];

const extendedTabs: StoreTab[] = [
  {
    key: "description",
    label: "À propos",
    isExternal: true,
    link: ({ externalId }) => `/producteur/fiche/${externalId}`,
    noBg: false,
  },
  {
    key: "store",
    label: "Boutique",
    isExternal: false,
    noBg: true,
  },
  {
    key: "contactPoints",
    label: "Points de retraits",
    isExternal: false,
    noBg: false,
  },
  { key: "delivery", label: "Livraison", isExternal: false, noBg: false },
  {
    key: "offersAndEvents",
    label: "Offres & événements",
    isExternal: true,
    link: ({ externalId }) =>
      `/producteur/fiche/${externalId}#offres-evenements`,
  },
  {
    key: "contact",
    label: "Contact",
    isExternal: true,
    link: ({ externalId }) => `/producteur/fiche/${externalId}#contact`,
  },
];

const PresentationProvider = ({ children }: any) => {
  const { isKuuPanda } = useWhichBrand();

  const [isEmbedded, setIsEmbedded] = useState(false);
  const [presentationOptions, setPresentationOptions] = useState({
    storeTabs: !isKuuPanda ? extendedTabs : defaultTabs,
    showStoreOnTab: !isKuuPanda,
    showFavourite: !isKuuPanda,
    defaultTab: !isKuuPanda ? extendedTabs[1] : defaultTabs[0],
    simpleProductCard: false,
    showProductionMethod: isKuuPanda,
  });

  return (
    <PresentationContext.Provider
      value={{
        isEmbedded,
        setIsEmbedded,
        presentationOptions,
        setPresentationOptions,
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
};

export function usePresentation() {
  const context = useContext(PresentationContext);
  if (context == null) {
    throw new Error(
      "You must call usePresentation only when inside a PresentationProvider."
    );
  }
  return context;
}

const PresentationConsumer = PresentationContext.Consumer;

export { PresentationProvider, PresentationContext, PresentationConsumer };
