import { BrandConfig, useBrandConfig } from "../Context/BrandConfigContext";

function useWhichBrand() {
  const {
    brandConfig: { identifier },
  } = useBrandConfig();
  const [isKuuPanda, isGranVillage] = (
    ["kuupanda", "granvillage"] as BrandConfig["identifier"][]
  ).map((str) => str === identifier);
  return { isKuuPanda, isGranVillage };
}

export { useWhichBrand };
