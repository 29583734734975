import React from "react";
import styled from "styled-components";
import { Divider } from "@material-ui/core";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  padding: 5px 25px 15px 25px;
  height: 100%;
`;

const DividerOne = styled(Divider)`
  margin-top: 15px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 5px;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

const DividerTwo = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: -12px;
  margin-right: -12px;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

const DividerThree = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -12px;
  margin-right: -12px;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

const DividerFour = styled(Divider)`
  margin-top: 15px;
  margin-bottom: 15px;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

type Props = {
  header: React.ReactNode;
  options: {
    primary: React.ReactNode;
    secondary?: React.ReactNode;
    tertiary: React.ReactNode;
    quaternary: React.ReactNode;
  };
  footer: React.ReactNode;
};

const MobileMenuTemplate: React.FC<Props> = ({ header, options, footer }) => {
  return (
    <Container>
      <div>
        {header}
        <DividerOne />
      </div>
      <div>
        {options.primary}
        <DividerTwo />
      </div>
      <div>
        {options.secondary && (
          <React.Fragment>
            {options.secondary}
            <DividerThree />
          </React.Fragment>
        )}
      </div>
      <div>
        {options.tertiary}
        <DividerThree />
        {options.quaternary}
      </div>
      <div>{footer}</div>
    </Container>
  );
};

export default MobileMenuTemplate;
