import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Grid } from "@material-ui/core";

// Components
import Account from ".";

// Styleds
const Container = styled(Grid)`
  height: 100%;
  margin: 40px 25px;
`;

const AccountPage = ({ onSuccess }) => {
  return (
    <Container Container alignItems="center">
      <Account onSuccess={onSuccess} variant="small" />
    </Container>
  );
};

AccountPage.propTypes = {
  onSuccess: PropTypes.func,
};

AccountPage.defaultProps = {
  onSuccess: () => {},
};

export default AccountPage;
