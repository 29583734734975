import React from "react";
import styled from "styled-components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const ExitIcon = styled(ExitToAppIcon)`
  margin-right: 10px;
`;

type Props = {
  hideIcon?: boolean;
};

const Disconnect: React.FC<Props> = ({ hideIcon }) => {
  return (
    <Container>
      {!hideIcon && <ExitIcon />}
      <BoldSpan>Se déconnecter</BoldSpan>
    </Container>
  );
};

export default Disconnect;
