import { css, ThemedCssFunction, DefaultTheme } from "styled-components";

export const sizes: { [key: string]: number } = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// Iterate through the sizes and create a media template
const media = {
  up: Object.keys(sizes).reduce<{
    [key: string]: ThemedCssFunction<DefaultTheme>;
  }>((acc, label) => {
    acc[label] = (...args: any[]) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(
          //@ts-ignore
          ...args
        )};
      }
    `;
    return acc;
  }, {}),
  down: Object.keys(sizes).reduce<{
    [key: string]: ThemedCssFunction<DefaultTheme>;
  }>((acc, label) => {
    acc[label] = (...args: any[]) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(
          //@ts-ignore
          ...args
        )};
      }
    `;
    return acc;
  }, {}),

  only: Object.keys(sizes).reduce<{
    [key: string]: ThemedCssFunction<DefaultTheme>;
  }>((acc, label) => {
    acc[label] = (...args: any[]) => css`
      @media (width: ${sizes[label]}px) {
        ${css(
          //@ts-ignore
          ...args
        )};
      }
    `;
    return acc;
  }, {}),
};

export default media;
