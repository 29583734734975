import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { Hidden } from "@material-ui/core";

// Assets
import { ReactComponent as LeafLight } from "../../Assets/Images/leafLight.svg";
import { ReactComponent as LeafDark } from "../../Assets/Images/leafDark.svg";

// styled
const Container = styled.div`
  ${({ theme }) => `
    background: ${theme.palette.primary.main};
  `};
  border-radius: 16px;
  margin-top: 120px;
  margin-bottom: 200px;
  z-index: 1000;
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 200px;
  height: 400px;
  position: relative;
`;

const InformationTitle = styled(Typography)`
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #ffffff;
`;

const InformationContent = styled(Typography)`
  font-size: 20px;
  line-height: 28px;
  width: 457px;
  color: ${({ theme }) => theme.palette.grey["50"]};
`;

const NumProducers = styled.div`
  position: absolute;
  top: 10px;
  right: 254px;
`;

const NumProducersContent = styled.span`
  position: absolute;
  top: 53px;
  font-weight: 900;
  font-size: 60px;
  line-height: 46px;
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `};
  left: 69px;
`;

const LeafIconLight = styled(LeafLight)``;

const NumProducts = styled.div`
  position: absolute;
  bottom: -76px;
  right: 10px;
`;

const NumProductsContent = styled.span`
  position: absolute;
  top: 79px;
  font-weight: 900;
  font-size: 57px;
  line-height: 46px;
  color: white;
  left: 14px;
  width: 100%;
`;

const LeafIconDark = styled(LeafDark)``;

const Statistics = () => {
  return (
    <Hidden mdDown>
      <Container>
        <InformationTitle variant="h5">Kuupanda, c’est quoi ?</InformationTitle>
        <InformationContent paragraph>
          Kuupanda est la marketplace qui crée le lien entre producteurs et
          consommateurs. Permettant ainsi de remettre le local au centre des
          habitudes de consommation.
        </InformationContent>
        <InformationContent paragraph>
          Tous les producteurs répertoriés ont une boutique personnalisée sur
          laquelle vous pouvez visualiser et acheter leurs produits.
        </InformationContent>

        <NumProducers>
          <LeafIconLight />
          <NumProducersContent>
            325{" "}
            <span
              style={{
                paddingTop: 5,
                fontSize: 22,
                lineHeight: "32px",
                display: "block",
              }}
            >
              producteurs engagés
            </span>
          </NumProducersContent>
        </NumProducers>

        <NumProducts>
          <LeafIconDark />
          <NumProductsContent>
            + de 3000{" "}
            <span
              style={{
                paddingTop: 5,
                paddingLeft: 15,
                fontSize: 22,
                lineHeight: "32px",
                display: "block",
              }}
            >
              références de produits
            </span>
          </NumProductsContent>
        </NumProducts>
      </Container>
    </Hidden>
  );
};

export default Statistics;
