import React, { useState } from "react";
import Searcher from "../../Common/Searcher";

type SearchInputProps = {
  className?: string;
  handleSearchChange: (value: string) => void;
};

const SearchInput = ({ className, handleSearchChange }: SearchInputProps) => {
  const [currentSearch, setCurrentSearch] = useState<string>("");

  const onChange = (event: any) => {
    const currentSearch = event.target.value;
    setCurrentSearch(currentSearch);
    handleSearchChange(currentSearch);
  };

  return (
    <Searcher
      className={className}
      inputProps={{
        value: currentSearch,
        placeholder: "Rechercher un produit",
        onChange,
      }}
    />
  );
};

export default SearchInput;
