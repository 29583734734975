import React from "react";
import Searcher from "../Common/Searcher";

const TopBarSearcher = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === "Enter" && inputRef?.current) {
      searchOnGranVillage(inputRef.current.value.trim());
    }
  };

  function searchOnGranVillage(text: string) {
    if (text) {
      const searchText = text.trim().replaceAll(/\s+/g, "+");
      // @ts-ignore
      window
        .open(`/explorer?search_form[keyword]=${searchText}`, "_blank")
        .focus();
    }
  }

  return (
    <Searcher
      ref={inputRef}
      inputProps={{
        onKeyPress: handleKeyPress,
        placeholder: "Que recherchez-vous ?",
      }}
    />
  );
};

export { TopBarSearcher };
