import React from "react";
import styled from "styled-components";
import chronopost from "../Assets/Images/chronopost.png";
import {
  isCpChronopost,
  isCPChronoPostRelay,
  isCPChronoPostViti,
} from "../Checkout/utils";
import constants from "./constants";

// Material Components
import {
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

// Styleds
const Description = styled(Typography)`
  margin-left: 30px;
  display: block;
`;
const ContactPointOptionContainer = styled.div`
  margin-bottom: 8px;
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const ChronopostIcon = styled.div`
  height: 28px;
  width: 110px;
  background-color: ${({ theme }) => theme.palette.warning.light};
  border-radius: 3px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChonopostLogo = styled.img`
  width: 80%;
`;

const Title = styled(Typography)<{ $unavailable: boolean }>`
  font-weight: bold;
  margin-left: 30px;
  margin-top: -15px;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: ${(props) => (props.$unavailable ? "50%" : "100%")};
`;

const DeliveryAlert = styled(Alert)`
  margin-left: 30px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Label = styled(FormControlLabel)`
  font-weight: bold;
  ${({ theme }) => `
     color: ${theme.palette.grey["900"]};
     ${theme.typography.body1}
  `}
`;

type ContactPointOptionsProps = {
  className?: string;
  contactPointOptions: any;
  selectedContactPoint: any;
  onSelect: any;
  vendorId: string;
  hasFreshProducts: boolean;
  totalAmount: string;
};

export default function ContactPointOptions({
  contactPointOptions,
  selectedContactPoint,
  onSelect,
  totalAmount,
  vendorId,
  className,
  hasFreshProducts,
}: ContactPointOptionsProps) {
  return (
    <Grid item xs={12}>
      <RadioGroup
        name={`contact_point_${vendorId}`}
        onChange={onSelect}
        className={className}
        defaultValue={
          selectedContactPoint.isSelectable ? selectedContactPoint.value : null
        }
      >
        {contactPointOptions.map((cp: any) => {
          const isChronoPost = isCpChronopost(cp.type);
          const isChronoRelay = isCPChronoPostRelay(cp.type);
          const isChronoViti = isCPChronoPostViti(cp.type);

          return (
            <ContactPointOptionContainer key={cp.value}>
              <LabelWrapper>
                <Label
                  id={`contact_point_${vendorId}_${cp.value}`}
                  value={cp.value}
                  control={<Radio color="primary" />}
                  disabled={!cp.isSelectable}
                  label={cp.label}
                />
                {isChronoPost && (
                  <ChronopostIcon>
                    <ChonopostLogo src={chronopost} />
                  </ChronopostIcon>
                )}
              </LabelWrapper>
              {cp.freeFeeOrderAmount && (
                <Title
                  variant="caption"
                  color="primary"
                  $unavailable={
                    cp.minimumDeliveryAmount > totalAmount ||
                    (cp.type ===
                      constants.CONTACT_POINT_TYPE.CHRONOPOST_RELAY &&
                      hasFreshProducts)
                  }
                >
                  * Livraison gratuite à partir de {cp.freeFeeOrderAmount}€
                </Title>
              )}
              {cp.comment && (
                <Description color="textSecondary" variant="caption">
                  {cp.comment}
                </Description>
              )}
              {cp.minimumDeliveryAmount > totalAmount && (
                <DeliveryAlert severity="error">
                  * Livraison disponible à partir de {cp.minimumDeliveryAmount}€
                </DeliveryAlert>
              )}
              {(isChronoRelay || isChronoViti) && hasFreshProducts && (
                <DeliveryAlert severity="error">
                  * Les produits frais ne peuvent pas être livrés en point
                  relais
                </DeliveryAlert>
              )}
              {cp.chronopostDisabled && isChronoPost && (
                <DeliveryAlert severity="error">
                  * Certains produits ne peuvent être livrés avec Chronopost
                </DeliveryAlert>
              )}
            </ContactPointOptionContainer>
          );
        })}
      </RadioGroup>
    </Grid>
  );
}
