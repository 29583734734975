import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

let _appTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      sizeLarge: {
        height: "56px",
      },
    },
  },
  typography: {
    h3: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "24px",
    },
    subtitle2: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
    },
    body2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    caption: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
    },
    fontFamily: [
      // Roboto and helvetica are loaded externally. Courier New or Arial aren't.
      // textTruncate compute the text length _before_ those font are loaded, which messes up the computation.
      // this line is only to set Courier New in thrird position, right behind Helvetica, so computation are done with courier new which is
      // a rather large font instead of arial
      // https://github.com/pablosichert/react-truncate/issues/16#issuecomment-270994604
      "Roboto",
      "Helvetica",
      "Courier New",
      "Arial",
      "sans-serif",
    ],
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    type: "light",
    primary: {
      main: "#EE406C",
      light: "#FDE4EA",
      contrastText: "#fff",
    },
    secondary: {
      main: "#8C0D3E",
      light: "#EACDD9",
      dark: "#760c35",
      contrastText: "#fff",
    },
    error: {
      light: "#FED8D8",
      main: "#FA8080",
      dark: "#EB2D2D",
      contrastText: "#fff",
    },
    warning: {
      light: "#D6E6F9",
      main: "#7AB0ED",
      contrastText: "#fff",
    },
    info: {
      main: "#F8E95E",
      contrastText: "#fff",
    },
    success: {
      light: "#F3FAE8",
      main: "#84BF26",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    grey: {
      50: "#FCFCFC",
      100: "#EFF0F6",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#D6D8E7",
      700: "#A0A3BD",
      800: "#6E7191",
      900: "#14142B",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: "rgba(0, 0, 0, 0.12)",
  },
  shape: {
    borderRadius: 6,
  },
});

export function createAppTheme({ palette, typography }) {
  return createMuiTheme({
    overrides: {
      MuiButton: {
        sizeLarge: {
          height: "56px",
        },
      },
    },
    typography,
    palette: {
      ...palette,
      common: {
        black: "#000",
        white: "#fff",
      },
      type: "light",
      warning: {
        light: "#D6E6F9",
        main: "#7AB0ED",
        dark: "#3f89df",
        contrastText: "#fff",
      },
      info: {
        light: "#fff3cd",
        main: "#F8E95E",
        contrastText: "#fff",
      },
      grey: {
        50: "#FCFCFC",
        100: "#EFF0F6",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#D6D8E7",
        700: "#A0A3BD",
        800: "#6E7191",
        900: "#14142B",
        A100: "#d5d5d5",
        A200: "#aaaaaa",
        A400: "#303030",
        A700: "#616161",
      },
      others: {
        brown: "#856404",
        yellow: "#ffeeba",
        a: "#0c5460",
        b: "#d1ecf1",
        c: "#bee5eb",
        d: "#fde8ed",
        blue: "#3898ec",
        h: "#0087ee",
        s: "#1da1f2",
        u: "#4172b8",
        e: "#fafafa",
        r: "#f7fafc",
        j: "#e9eef1",
        i: "#e2e2e2",
        y: "#e6e6e6",
        e1: "#ccc",
        c1: "#aab7c4",
        z: "#808b97",
        a1: "#808b94",
        b1: "#4d5055",
        f: "#4f5c65",
        g: "#353e44",
        n: "#041e39",
        v: "#050505",
        f1: "#333",
        k: "#aecc7d",
        w: "#618c1c",
        m: "#0fbf95",
        o: "#3cba58",
        p: "#ffb400",
        q: "#da2424",
        t: "#e4405f",
        l: "#870d3c",
        d1: "#9e2146",
        g1: "#fbf8f6",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      divider: "rgba(0, 0, 0, 0.12)",
    },
    shape: {
      borderRadius: 6,
    },
  });
}

_appTheme = _appTheme = responsiveFontSizes(_appTheme);
export const appTheme = _appTheme;
export const FILTER_BORDER_RADIUS = "30px";
