import PropTypes from "prop-types";

const dayShape = PropTypes.shape({
  values: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
});

export const ContactPointShape = PropTypes.shape({
  _id: PropTypes.string,
  address: PropTypes.shape({
    administrative_area_level_1: PropTypes.string,
    country: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    locality: PropTypes.string,
    place_id: PropTypes.string,
    postal_code: PropTypes.string,
    route: PropTypes.string,
    street_number: PropTypes.string,
    text: PropTypes.string,
  }),
  name: PropTypes.string,
  schedule: PropTypes.shape({
    friday: dayShape,
    monday: dayShape,
    saturday: dayShape,
    sunday: dayShape,
    thursday: dayShape,
    tuesday: dayShape,
    wednesday: dayShape,
  }),
  type: PropTypes.oneOf([
    "DELIVERY",
    "TAKE_AWAY",
    "CHRONOPOST",
    "CHRONOPOST_RELAY",
    "CHRONOPOST_VITI",
  ]),
});

export const CartShape = PropTypes.objectOf(
  PropTypes.shape({
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
  })
);
export const RecoveryInfoShape = PropTypes.objectOf(
  PropTypes.shape({
    date: PropTypes.object.isRequired,
    contactPointInfo: ContactPointShape.isRequired,
    clientAddress: PropTypes.object,
    delivery: PropTypes.shape({
      rate: PropTypes.number.isRequired,
    }),
  })
);

export const CartCheckoutShape = PropTypes.shape({
  [PropTypes.string.isRequired]: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      producer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
});
