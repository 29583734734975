import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Switch,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { withTextSecondary } from "../utils";
import DialogContent from "@material-ui/core/DialogContent";
const LocalShippingIconSecondary = withTextSecondary(LocalShippingIcon);

export function DeliveryFilter({ value, onChange, isLocationEmpty }) {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  if (isLocationEmpty)
    return (
      <React.Fragment>
        <Tooltip title={"Veuillez renseigner une ville d'abord"}>
          <FormControlLabel
            onClick={() => setOpen(true)}
            // disabled={isLocationEmpty}
            control={<Switch checked={value} />}
            label={
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ whiteSpace: "nowrap" }}
              >
                Livraison uniquement
                <LocalShippingIcon
                  style={{ marginLeft: 5 }}
                  color={isLocationEmpty ? "action" : "action"}
                />
              </Box>
            }
          />
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Où souhaitez-vous être livré ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Nos producteur ont des rayons de livraisons limités, une fois
              votre localité renseignée nous pourrons vous montrer les
              livraisons disponibles
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              J'ai compris
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  return (
    <FormControlLabel
      disabled={isLocationEmpty}
      control={
        <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
      }
      label={
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{ whiteSpace: "nowrap" }}
        >
          Livraison uniquement
          <LocalShippingIconSecondary style={{ marginLeft: 5 }} />
        </Box>
      }
    />
  );
}

DeliveryFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  value: PropTypes.bool.isRequired,
  isLocationEmpty: PropTypes.bool.isRequired,
};
