import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// Components
import Button from "../../Common/Button";

// styled
const Container = styled.div`
  position: absolute;
  top: 550px;
  left: 10%;
  right: 10%;
  background-color: ${({ theme }) => theme.palette.others.d};
  border-radius: 16px;
  z-index: 100;
`;

const StyledActionButton = styled(Button)`
  float: right;
`;

const ActionBoxTitle = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const ActionBoxContent = styled(Typography)`
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

const BoxContent = styled(Grid)`
  padding: 25px 50px !important;
`;

const ActionBox = () => {
  return (
    <Container>
      <Grid container spacing={2} style={{ padding: 43 }}>
        <BoxContent item md={6} xs={12}>
          <ActionBoxTitle variant="h5">
            Redevenez acteur de votre consommation
          </ActionBoxTitle>
          <ActionBoxContent gutterBottom>
            En commandant sur Kuupanda en direct des producteurs, vous
            choississez des produits de qualité en promouvant l'agriculture
            locale. Vous faites un vrai geste pour l'environnement en mettant
            les circuits courts au coeur de votre consommation.
          </ActionBoxContent>

          <StyledRouterLink to="/search">
            <StyledActionButton component="button" styled="wine">
              Commander
            </StyledActionButton>
          </StyledRouterLink>
        </BoxContent>
        <BoxContent item md={6} xs={12} xl>
          <ActionBoxTitle variant="h5">
            Producteurs, vendez vos produits en direct
          </ActionBoxTitle>
          <ActionBoxContent gutterBottom>
            Kuupanda a developpé l'outil de gestion le plus simple et le plus
            complet pour vous accompagner tous les jours sur votre exploitation.
            Il vous permet de vendre vos produits en ligne mais aussi de gérer
            votre stock, vos clients, vos commandes, générer des factures etc.
          </ActionBoxContent>
          <a
            href="https://gestion.kuupanda.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <StyledActionButton component="button" styled="wine">
              Devenir vendeur
            </StyledActionButton>
          </a>
        </BoxContent>
      </Grid>
    </Container>
  );
};

export default ActionBox;
