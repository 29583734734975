import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import React, { useState } from "react";
import { StoreTab, StoreTabKey } from "../Context/PresentationContext";

type UseStoreTabsWithRoutesProps = {
  defaultTab: StoreTab;
  tabs: StoreTab[];
  excludeTabs: Partial<Record<StoreTabKey, boolean>>;
};

export function useTabsWithRoutes({
  defaultTab,
  tabs,
  excludeTabs,
}: UseStoreTabsWithRoutesProps) {
  const params = useParams<{ tabKey: StoreTabKey }>();
  const { path } = useRouteMatch();
  const history = useHistory();

  const tabsMap: Map<StoreTabKey, StoreTab> = React.useMemo(
    () => new Map(tabs.map((tab) => [tab.key, tab])),
    [tabs]
  );

  const [currentTabKey, setCurrentTabKey] = useState<StoreTabKey | "">("");
  React.useEffect(() => {
    if (tabsMap.has(params.tabKey)) {
      setCurrentTabKey(params.tabKey);
    } else {
      navigateToTab(defaultTab.key);
      setCurrentTabKey(defaultTab.key);
    }
  }, []);

  const tabsArr = React.useMemo(() => {
    return Array.from(tabsMap.values()).filter((it) => !excludeTabs[it.key]);
  }, [tabsMap, excludeTabs]);

  const handleTabClick = (tabKey: StoreTabKey, props: any = {}) => {
    const currentTab = tabsMap.get(tabKey);
    if (currentTab?.isExternal && currentTab?.link) {
      window.location.href =
        typeof currentTab.link === "function"
          ? currentTab.link(props)
          : currentTab.link;
      return;
    }
    setCurrentTabKey(tabKey);
    navigateToTab(tabKey);
  };

  function navigateToTab(tabKey: string) {
    history.push(generatePath(path, { ...params, tabKey }));
  }

  const cardContentNoBg = Boolean(
    currentTabKey && tabsMap.get(currentTabKey)?.noBg
  );

  return {
    tabsArr,
    currentTabKey,
    handleTabClick,
    cardContentNoBg,
  };
}
