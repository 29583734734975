import { parse, format } from "date-fns";

export function formatDateString(
  date,
  {
    sourceFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSX",
    targetFormat = "dd/MM/yyy",
  } = {}
) {
  return format(parse(date, sourceFormat, new Date()), targetFormat);
}
