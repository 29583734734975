import React from "react";
import PropTypes from "prop-types";
import { GoogleMap } from "@react-google-maps/api";
import { Grid, withStyles, withWidth } from "@material-ui/core";
import { MarkerWrapper } from "./MarkerWrapper";
import { ContactPointsList } from "./ContactPointsList";
import LoadScriptOnlyIfNeeded from "../../../../Common/LoadScriptOnlyIfNeeded";
import { find } from "lodash-es";
import {
  isHiddenBelow,
  isSmDown,
  pseudoGuid,
  scrollTo,
} from "../../../services/utils";

const librariesToLoad = ["places", "geometry"];
const API_KEY = "AIzaSyB1zdGV3s0_FdgVuiho0F3ozMY-N4YXmOg";

const getMapStyleOptions = (showBusiness) => {
  if (showBusiness) return {};
  return {
    styles: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
    ],
  };
};

export class _LocationContent extends React.Component {
  static defaultProps = {
    showBusinesses: false,
  };

  static propTypes = {
    classes: PropTypes.any,
    contactPoints: PropTypes.array.isRequired,
    showBusinesses: PropTypes.bool,
    width: PropTypes.any,
    mapTitle: PropTypes.any,
    RowComponent: PropTypes.any,
    isDelivery: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.mapOptions = {
      ...getMapStyleOptions(this.props.showBusinesses),
      maxZoom: 18,
      clickableIcons: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: !["xs", "sm"].includes(props.width),
    };

    this.map = null;
    this.mapID = `gmap_${pseudoGuid()}`;

    this.state = {
      openMarkerID: null,
      hoverMarkerID: null,
    };
  }

  handleMarkerClick = ({ markerID }) => {
    this.setState({
      openMarkerID: markerID,
    });
  };

  handleListClick = (markerID) => {
    if (this.props.isDelivery) {
      return;
    }
    this.setState({
      openMarkerID: markerID,
    });
    const openMarker = find(this.props.contactPoints, {
      id: markerID,
    });
    this.map.setCenter(openMarker.coord);
    const isSM = isSmDown(this.props.width);
    const mapSelector = `#${this.mapID}`;
    if (isSM && isHiddenBelow(mapSelector))
      scrollTo(mapSelector, {
        offset: 20,
        behavior: "smooth",
        anchor: "bottom",
      });
  };

  handleMapLoad = (map) => {
    if (this.map === null) {
      this.map = map;
    }
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < this.props.contactPoints.length; i++) {
      bounds.extend(this.props.contactPoints[i].coord);
    }
    map.fitBounds(bounds);
  };

  handleListOver = (markerID) => {
    this.setState({ hoverMarkerID: markerID });
  };

  render() {
    const { contactPoints, classes, RowComponent, mapTitle, isDelivery } =
      this.props;
    const { openMarkerID, hoverMarkerID } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={isDelivery ? 12 : 6}>
          <ContactPointsList
            RowComponent={RowComponent}
            contactPoints={contactPoints}
            openMarkerID={openMarkerID}
            onHoverChange={this.handleListOver}
            onItemClick={this.handleListClick}
          />
        </Grid>
        {!isDelivery && (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ minHeight: 300, maxHeight: 600 }}
          >
            <LoadScriptOnlyIfNeeded
              id="script-loader"
              googleMapsApiKey={API_KEY}
              libraries={librariesToLoad}
              loadingElement={<div></div>}
            >
              <GoogleMap
                id={this.mapID}
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                options={this.mapOptions}
                onLoad={this.handleMapLoad}
                onClick={this.closeInfoWindow}
              >
                <span className={classes.title}>{mapTitle}</span>
                {contactPoints.map((marker, i) => (
                  <MarkerWrapper
                    title={`${i + 1} - ${marker.address}`}
                    key={marker.id}
                    position={marker.coord}
                    onClick={this.handleMarkerClick}
                    marker={marker}
                    isHoverFromList={hoverMarkerID === marker.id}
                  />
                ))}
              </GoogleMap>
            </LoadScriptOnlyIfNeeded>
          </Grid>
        )}
      </Grid>
    );
  }
}

export const LocationContent = withStyles((theme) => ({
  title: {
    position: "absolute",
    top: 0,
    margin: 10, // like other gmap overlay items
    padding: theme.spacing(0.5, 2),
    left: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    borderRadius: 2,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5, 1.5),
      fontSize: "0.9em",
    },
  },
}))(withWidth()(_LocationContent));
