import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Grid, Hidden, SvgIcon, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import constants from "../../Common/constants";

//hooks
import useProductErrors from "../../Hooks/useProductErrors";

//icons
import { ReactComponent as Trash } from "../../Assets/Icons/trash.svg";
import { RiErrorWarningLine as WarningIcon } from "react-icons/ri";

// Components
import CartSummary from "../../Common/CartSummary";
import DisabledForm from "../Components/DisabledForm";
import ProductCounter from "../../Common/ProductCounter";
import Title from "../Components/Title";

// Proptypes
import { CartCheckoutShape } from "../proptypes";
import { NumberFormatCustom } from "@kuupanda/commons";

// Styled
const Container = styled(Grid)``;

const GridCartSyled = styled(Grid)`
  margin: ${({ width }) =>
    width !== "xs" && width !== "sm" ? "8px 38px" : "0"};
`;

const CartSummaryContainer = styled.div`
  margin: ${({ width }) =>
    width !== "xs" && width !== "sm" ? "0 50px;" : "0"};
`;

const Price = styled.span`
  ${({ priceChanged }) =>
    priceChanged &&
    `
    color: ${({ theme }) => theme.palette.grey[400]};
    text-decoration: line-through;
  `};
`;

const DiscountAmount = styled.span`
  color: white;
  background: ${({ theme }) => theme.palette.warning.main};
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
  margin-left: 5px;
`;

const ErrorBar = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ errorType }) =>
    errorType === constants.PRODUCT_ERRORS.UNAVAILABLE ? "#DE5F5F" : "#464861"};
  font-weight: 500;
  font-size: 14px;
  background-color: ${({ theme, errorType }) =>
    errorType === constants.PRODUCT_ERRORS.UNAVAILABLE
      ? "#FDEBE1"
      : theme.palette.warning.light};
  padding: 5px;
  border-radius: 4px;
`;

function getCellExtensions({ width }) {
  if (width === "xs") {
    return {
      name: {
        verticalAlign: "baseline",
      },
      quantity: {
        verticalAlign: "baseline",
      },
    };
  }
  return {
    name: {
      verticalAlign: "baseline",
    },
    quantity: {
      verticalAlign: "baseline",
    },
    total: {
      verticalAlign: "baseline",
    },
    action: {
      verticalAlign: "baseline",
    },
  };
}

function getColumnExtensions({ width }) {
  if (width === "xs") {
    return [
      { columnName: "name", width: "60%", align: "left" },
      { columnName: "quantity", align: "center" },
      { columnName: "action", align: "right" },
    ];
  }
  return [
    { columnName: "name", align: "left" },
    { columnName: "quantity", width: 150, align: "center" },
    { columnName: "total", width: 150, align: "right" },
    { columnName: "action", width: 80, align: "right" },
  ];
}

function getColumns({ width }) {
  if (width === "xs") {
    return [
      { name: "name", title: "Name" },
      { name: "quantity", title: "Quantity" },
      { name: "action", title: "Action" },
    ];
  }
  return [
    { name: "name", title: "Name" },
    { name: "quantity", title: "Quantity" },
    { name: "total", title: "Total" },
    { name: "action", title: "Action" },
  ];
}

function TotalRow({ quantity, errors, promos, price, basePrice }) {
  const {
    newPrice,
    hasPriceChange,
    hasDiscount,
    discountAmount,
    currentPrice,
  } = useProductErrors({
    errors,
    price,
    basePrice,
    promos,
  });

  return (
    <Title color="textPrimary">
      <Price variant="body2" priceChanged={hasPriceChange}>
        <NumberFormatCustom
          value={quantity * currentPrice}
          displayType={"text"}
        />
      </Price>

      {hasDiscount && (
        <DiscountAmount>
          -{" "}
          <NumberFormatCustom
            value={discountAmount}
            displayType={"text"}
            suffix={" %"}
          />
        </DiscountAmount>
      )}

      {hasPriceChange && (
        <>
          <br />
          <Price variant="body2">
            <NumberFormatCustom
              value={quantity * newPrice}
              displayType={"text"}
            />
          </Price>
        </>
      )}
    </Title>
  );
}

function cartTableFormat(
  { quantity, product, errors, promos },
  { width, handleChangeCart, onRemoveItemFromCart }
) {
  const isUnavailable = errors.some(
    (error) => error.type === constants.PRODUCT_ERRORS.UNAVAILABLE
  );

  const result = {
    name: (
      <>
        <Title color="primary" noWrap={true}>
          {errors &&
            errors.map((error) => (
              <ErrorBar errorType={error.type}>
                <WarningIcon />
                <span>{error.message}</span>
              </ErrorBar>
            ))}

          {product.name}
        </Title>
        <Typography variant="body1" color="textSecondary">
          {product.producer.name}
        </Typography>
      </>
    ),
    quantity: !isUnavailable && (
      <ProductCounter
        product={product}
        quantity={quantity}
        onChangeCart={handleChangeCart}
      />
    ),
    action: (
      <Button onClick={() => onRemoveItemFromCart(product.id)}>
        <SvgIcon titleAccess="delete">
          <Trash />
        </SvgIcon>
      </Button>
    ),
  };

  if (width === "xs") {
    return {
      ...result,
      name: (
        <>
          <Title color="textPrimary" noWrap={true}>
            {product.name}
          </Title>
          <Typography variant="body1" color="textSecondary">
            {product.producer.name}
          </Typography>
        </>
      ),
      quantity: (
        <>
          <Title color="textPrimary">
            {(quantity * product.price).toFixed(2)}€
          </Title>
          <ProductCounter
            product={product}
            quantity={quantity}
            onChangeCart={handleChangeCart}
          />
        </>
      ),
    };
  }

  return {
    ...result,
    total: !isUnavailable && (
      <TotalRow
        quantity={quantity}
        price={product.price}
        basePrice={product.basePrice}
        errors={errors}
        promos={promos}
      />
    ),
  };
}
function Basket({
  cart,
  name,
  lastName,
  email,
  phone,
  width,
  onRemoveItemFromCart,
  onChangeCart,
  cartTotals,
  couponLines,
  changePromo,
  applyPromo,
  promoCode,
  promoValid,
  clearPromo,
}) {
  const theme = useTheme();
  const total = cartTotals.total;
  const handleChangeCart = useCallback(
    ({ product, quantity }) => {
      onChangeCart(product.id, quantity);
    },
    [onChangeCart]
  );
  const cartList = useMemo(
    () =>
      Object.values(cart).map(({ quantity, product, errors, promos }) =>
        cartTableFormat(
          { quantity, product, errors, promos },
          { width, handleChangeCart, onRemoveItemFromCart }
        )
      ),
    [cart, width, handleChangeCart, onRemoveItemFromCart]
  );

  const cellStyles = useMemo(
    () => ({
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.grey.A200,
    }),
    [theme]
  );

  return (
    <Container container spacing={3}>
      <Hidden smDown>
        <Grid xs={12} item>
          <DisabledForm
            name={name}
            lastName={lastName}
            email={email}
            phone={phone}
          />
        </Grid>
      </Hidden>
      <GridCartSyled item xs={12} width={width}>
        <Hidden smDown>
          <Title>Récapitulatif du panier</Title>
        </Hidden>
        <CartSummaryContainer width={width}>
          <CartSummary
            total={total}
            cart={cart}
            cartList={cartList}
            cellStyles={cellStyles}
            cellExtensions={getCellExtensions({ width })}
            columns={getColumns({ width })}
            columnExtensions={getColumnExtensions({ width })}
            dividerColor="white"
            showCoupon
            showDisabledCoupon={false}
            couponLines={couponLines}
            coupon={promoCode}
            onChangeCoupon={changePromo}
            applyPromo={applyPromo}
            promoValid={promoValid}
            clearPromo={clearPromo}
          />
        </CartSummaryContainer>
      </GridCartSyled>
    </Container>
  );
}

Basket.propTypes = {
  name: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  cart: CartCheckoutShape.isRequired,
  onRemoveItemFromCart: PropTypes.func,
  onChangeCart: PropTypes.func,
  onSuccess: PropTypes.func,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

Basket.defaultProps = {
  name: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  onRemoveItemFromCart: () => {},
  onChangeCart: () => {},
  onSuccess: () => {},
};

export default Basket;
