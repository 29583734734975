import React, { forwardRef } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { RiCalendar2Line as CalendarIcon } from "react-icons/ri";

const ChangeDateButton = styled(Button)`
  text-transform: none;
  height: 36px;
  background-color: #eff0f6;
  box-shadow: 0px 2px 4px rgba(50, 50, 71, 0.03);
  border-radius: 4px;
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: 500;
  color: #1b1a36;
  margin-right: ${({ marginRight }) => marginRight};
  padding: 0 10px;
  svg {
    fill: #1b1a36;
    height: 30px;
  }
  &:hover {
    background-color: #d9dbe7;
  }
`;

function ChangeDate(props, ref) {
  return (
    <ChangeDateButton
      ref={ref}
      {...props}
      color="secondary"
      endIcon={<CalendarIcon />}
    >
      choisir une autre date
    </ChangeDateButton>
  );
}

export default forwardRef(ChangeDate);
