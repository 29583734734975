import styled from "styled-components";

// Material components
import { Grid } from "@material-ui/core";

export const StepContainer = styled(Grid)`
  background-color: white;
  border: ${(props) =>
    props.width !== "xs" && props.width !== "sm"
      ? `1px solid ${props.theme.palette.grey["200"]}`
      : 0};
  box-sizing: border-box;
  border-radius: 8px;
  margin: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "40px 0 0 0" : "40px 25px"};
  padding: ${(props) =>
    props.width !== "xs" && props.width !== "sm" ? "40px 100px" : "0"};
  min-height: ${(props) => {
    if (props.width === "xs") {
      return "calc(100vh - 280px)";
    } else if (props.width === "sm") {
      return "calc(100vh - 400px)";
    }
  }};
`;
