import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Hidden } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import { Redirect, Switch } from "react-router-dom";

//constants
import { DEFAULT_CATEGORIES } from "../../Common/constants";

//utils
import media from "../../Helpers/media";
import Meta from "../../Common/Meta";
import { makeMapFromArray } from "../services/utils";
import { ScrollTopBtn } from "../components/ScrollTopBtn";

//components
import ResponsivePicture from "../../Common/ResponsivePicture";
import { StoreCarousel } from "./storeNavigation/StoreCarousel";
import StoreNavigation from "./storeNavigation/StoreNavigation";
import { StoreHeader } from "./storeHeader/StoreHeader";
import { useIsMobile } from "../../Hooks/useIsMobile";
import { Route, useRouteMatch } from "react-router-dom";
import { useWhichBrand } from "../../Hooks/useWhichBrand";
import axios, { AxiosResponse } from "axios";
import { Tracking } from "../../Helpers/tracking";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  width: 100%;
  aspect-ratio: 720/91;
  max-height: 220px;
  ${media.down.md`
    height: 150px;
    width: 100%;
    border-radius: 0;
    margin: 0;
  `}
`;

const StyledImageSkeleton = styled(Skeleton)`
  height: 182px;
  width: 100%;
  ${media.down.md`
    height: 150px;
  `}
`;

const StyledContainer = styled(Container)`
  ${media.down.lg`
    max-width: 1100px;
  `}
`;

const StyledGrid = styled(Grid)`
  background-color: ${(props) => props.theme.palette.others.g1};
  margin-top: -40px;
  ${media.down.md`
    margin-top: -65px;
  `}
`;

type HomeProps = {
  categories: any[];
  contactPoints: any[];
  deliveryPoints: any[];
  products: any[];
  store: any;
  loading: boolean;
  onAddToCart: ({ data }: any) => void;
  onRemoveFromCart: () => void;
  cart: any;
};

export function Home({
  categories = DEFAULT_CATEGORIES,
  contactPoints,
  deliveryPoints,
  products,
  store,
  loading,
  onAddToCart,
  onRemoveFromCart,
  cart,
}: HomeProps) {
  const { isGranVillage } = useWhichBrand();
  const { path, url } = useRouteMatch();

  const isMobile = useIsMobile();

  const [currentCart, setCurrentCart] = useState(cart || {});

  const categoryNameMap = makeMapFromArray(categories);

  const handleCartChange = (cart: any) => {
    setCurrentCart(cart);
  };

  const handleAddCart = (cart: any) => {
    return onAddToCart({ ...cart, store: store });
  };

  const [currentProductsList, setCurrentProductsList] = useState(products);

  useEffect(() => {
    setCurrentProductsList(products);
  }, [products]);

  useEffect(() => {
    setCurrentCart(cart);
  }, [cart]);

  const [externalId, setExternalId] = useState<string | undefined>(undefined);
  const fetchExternalInfo = useCallback(async () => {
    if (!isGranVillage || !store?.email) {
      return;
    }
    try {
      const axiosResponse: AxiosResponse<string> = await axios.get(
        `/api/public/user/profil/id/${store.email}`
      );
      setExternalId(axiosResponse.data);
    } catch (error) {
      console.error(error);
    }
  }, [isGranVillage, store?.email]);

  useEffect(() => {
    fetchExternalInfo();
  }, [fetchExternalInfo]);

  const handleSearchProducts = (searchingFor: string) => {
    if (searchingFor === "") {
      return setCurrentProductsList(products);
    }
    let productsList: Array<any> = [];
    let currentProductsList = products;
    currentProductsList.forEach((p: any) => {
      productsList = [...productsList, ...p.products];
    });

    const filteredProducts = productsList.filter((product: any) => {
      return (
        product.name.toLowerCase().includes(searchingFor.toLowerCase()) ||
        product.baseProduct.toLowerCase().includes(searchingFor.toLowerCase())
      );
    });

    let filteredProductsByCategories: Array<any> = [];

    for (let category of currentProductsList) {
      for (let p of category.products) {
        if (filteredProducts.find((it) => it === p)) {
          filteredProductsByCategories = [category];
        }
      }
    }
    return setCurrentProductsList(filteredProductsByCategories);
  };

  const isStoreClosed = loading ? false : store.clientClosedStore;

  const { storeName, city, storeSlug, bannerUrl } = store;

  useEffect(() => {
    if (
      !store ||
      !products ||
      Object.keys(store).length === 0 ||
      Object.keys(products).length === 0
    ) {
      return;
    }
    Tracking.trackProducerPageInfo({
      store,
      productByCategory: products,
    });
  }, [store, products]);
  return (
    <>
      <ImageWrapper>
        {!loading && store.bannerUrl ? (
          <StyledResponsivePicture
            sizeQuery="(min-width: 1200px) 700px, (max-width:1200px) 550px"
            baseImgUrl={store.bannerUrl}
            fitType="cover"
          />
        ) : (
          <StyledImageSkeleton animation="wave" variant="rect" />
        )}
      </ImageWrapper>
      <StyledContainer maxWidth={"lg"} disableGutters>
        {!loading && (
          <Meta
            title={
              isGranVillage
                ? `${storeName} - Commandez vos produits locaux sur Granvillage!`
                : `${storeName} - Commandez vos produits locaux sur Kuupanda!`
            }
            description={`${storeName}, producteur à ${city}. Découvrez des produits locaux, autour de chez vous.`}
            keywords={`${storeName},local,produits,locaux,${city},circuit,court`}
            ogTitle={
              isGranVillage
                ? `${storeName} - Découvrez mes produits sur Granvillage.`
                : `${storeName} - Découvrez mes produits sur Kuupanda.`
            }
            ogDescription="Trouvez des producteurs locaux proches de chez vous et commandez sans intermédiaire pour des produits de qualité à des prix accessibles."
            ogURL={
              isGranVillage
                ? `https://granvillage.com/boutique/${storeSlug}`
                : `https://kuupanda.com/boutique/${storeSlug}`
            }
            ogImage={bannerUrl}
          />
        )}
        <StyledGrid container spacing={isMobile ? 0 : 2}>
          <Grid item xs={12} md={3}>
            <StoreNavigation
              store={store}
              products={currentProductsList}
              categoryNameMap={categoryNameMap}
              loading={loading}
              handleSearchProducts={handleSearchProducts}
              externalId={externalId}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Switch>
              <Route path={`${path}/tab/:tabKey`}>
                <Hidden smDown>
                  <StoreHeader
                    store={store}
                    contactPoints={contactPoints}
                    deliveryPoints={deliveryPoints}
                    loading={loading}
                    products={currentProductsList}
                    categoryNameMap={categoryNameMap}
                    categories={categories}
                    cart={currentCart}
                    handleCartChange={handleCartChange}
                    onRemoveFromCart={onRemoveFromCart}
                    isStoreClosed={isStoreClosed}
                    onAddToCart={handleAddCart}
                    handleSearchProducts={handleSearchProducts}
                    externalId={externalId}
                  />
                </Hidden>
                <Hidden mdUp>
                  <StoreCarousel
                    store={store}
                    contactPoints={contactPoints}
                    deliveryPoints={deliveryPoints}
                    loading={loading}
                    products={currentProductsList}
                    categoryNameMap={categoryNameMap}
                    categories={categories}
                    cart={cart}
                    handleCartChange={handleCartChange}
                    onRemoveFromCart={onRemoveFromCart}
                    isStoreClosed={isStoreClosed}
                    onAddToCart={handleAddCart}
                    handleSearchProducts={handleSearchProducts}
                    externalId={externalId}
                  />
                </Hidden>
              </Route>

              <Redirect from="/" to={`${url}/tab/store`} />
            </Switch>

            <ScrollTopBtn />
          </Grid>
        </StyledGrid>
      </StyledContainer>
    </>
  );
}
