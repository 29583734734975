import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Material Components
import { Button, Dialog, Grid, Typography } from "@material-ui/core";

// Material Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Components

// Styleds
const Container = styled(Dialog)`
  .MuiDialog-paperFullScreen {
    background-color: ${({ theme }) => theme.palette.grey["400"]};
  }
`;
const Header = styled(Grid)`
  padding: 30px 25px;
`;
const Title = styled(Typography)``;
const Body = styled(Grid)`
  padding: 30px 25px;
  input {
    background-color: white;
  }
`;
const Error = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px;
  margin: 0 24px;
  font-weight: bold;
  text-align: center;
`;
// Constant

const UserRegisterModal = ({ open, onClose, children, error }) => {
  return (
    <Container fullScreen open={open}>
      <Header container>
        <Button onClick={onClose}>
          <ArrowBackIcon />
        </Button>
      </Header>
      <Title component="h1" variant="h6" align="center">
        INSCRIPTION
      </Title>
      {error && (
        <Error variant="body1" component="h1">
          {error}
        </Error>
      )}
      <Body container spacing={4}>
        {children}
      </Body>
    </Container>
  );
};

UserRegisterModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
};

UserRegisterModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default UserRegisterModal;
