import { storageBranConfigItem } from "@kuupanda/commons";

const cookieProIdDomain =
  process.env.REACT_APP_COOKIE_PRO_ID_DOMAIN ||
  "cd192fc5-9668-4c16-8a78-a980cc4b6476-test";

const [kuupanda, granvillage] = ["kuupanda", "granvillage"];

function init() {
  // we must not show cookies policy in embedded mode
  // we must not show cookies policy for mangopay modal template inside iframe
  if (
    window.location.search.includes("embedded=true") ||
    window.location.pathname.includes("/payment/mangopay/template/")
  ) {
    return;
  }
  const cookieProScript = document.getElementById("cookie-pro-script");
  const identifier =
    storageBranConfigItem.jsonParsedValue?.identifier || "kuupanda";

  let addCookie = true;

  if (cookieProScript) {
    // for testing with ?brandId=
    if (identifier && identifier !== cookieProScript.getAttribute("key"))
      cookieProScript.remove();
    else {
      addCookie = false;
    }
  }

  if (addCookie) {
    switch (identifier) {
      case kuupanda: {
        kuupandaInit();
        break;
      }
      case granvillage: {
        groupamaInit();
        break;
      }
      default:
        console.error("Not possible to add cookie-pro.");
    }
  }
}

function kuupandaInit() {
  addScriptToHead(
    getNewCookieScript({
      key: kuupanda,
      src: "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js",
      domainScript: cookieProIdDomain,
    })
  );
}

function groupamaInit() {
  const script = getNewCookieScript({
    key: granvillage,
    src: process.env.REACT_APP_GROUPAMA_COOKIE_URL,
  });
  script.appendChild(document.createTextNode("function OptanonWrapper() { }"));
  addScriptToHead(script);
}

function getNewCookieScript({ key = "", src = "", domainScript = "" }) {
  const script = document.createElement("script");
  script.src = src;
  script.setAttribute("id", "cookie-pro-script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("charset", "UTF-8");
  script.setAttribute("data-domain-script", domainScript);
  script.setAttribute("data-key", key);
  return script;
}

function addScriptToHead(scriptEl) {
  document.head.appendChild(scriptEl);
}

const cookie = {
  init,
};

export default cookie;
