import { createGlobalStyle } from "styled-components";

const GranvillageGlobalStyle = createGlobalStyle`
  * {
    font-family: "Noto Sans", sans-serif;
  }
  body {
    color: #343A40;
  }
`;

export default GranvillageGlobalStyle;
