import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import constants from "./constants";
import cbLogo from "./cb.jpg";
import visaLogo from "./visa.png";
import masterCardLogo from "./mastercard.png";
import globalConstants from "../../../../VendorStore/utils/constants";
import Button from "@material-ui/core/Button";
import httpClient from "../../../../httpClient";
import ApiContext from "../../../../Context/ApiContext";
import { usePresentation } from "../../../../Context/PresentationContext";
import {
  closeModalIframe,
  setModalIframePosition,
} from "../../../../Helpers/modal";
import PaymentLoader, { Loader } from "./PaymentLoader";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useBeforeunload } from "react-beforeunload";

const Container = styled.div`
  color: ${({ theme }) => theme.palette.grey["800"]};
`;
const DialogContainer = styled.div`
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MangoPayIframe = styled.iframe`
  width: 100%;
  min-height: 650px;
  border: none;
`;

const StyledIconButton = styled(Button)``;

const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DialogTitle = (props) => {
  useBeforeunload((event) => {
    event.preventDefault();
  });
  const { children, onClose, ...other } = props;
  return (
    <StyledMuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <StyledIconButton
          variant="outlined"
          color="secondary"
          aria-label="close"
          onClick={onClose}
        >
          Annuler
        </StyledIconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

function MangoPay({
  active,
  orders,
  buyer,
  amount,
  onPaymentEnd,
  onSuccessfulPayment,
  onPaymentFormReady,
  onPaymentFormError,
  accessToken,
}) {
  //428px is the breakpoint mangopay form uses for going full mobile
  const isMobile = useMediaQuery("(max-width:428px)", {
    noSsr: true,
  });
  const { isEmbedded } = usePresentation();
  const [payIn, setPayIn] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(
    constants.PAY_IN_STATUS.REQUESTED
  );
  const { apiRoot } = useContext(ApiContext);
  const payInCreationDTO = useMemo(
    () => ({
      returnURL: `${window.location.origin}/payment/mangopay/payment-complete`,
      locale: "fr",
      amount,
      buyer,
      orders,
    }),
    [amount, buyer, orders]
  );
  const fetchWebPayIn = useCallback(async () => {
    try {
      setPaymentStatus(constants.PAY_IN_STATUS.REQUESTED);
      const url = accessToken
        ? `${apiRoot}/payment/mangopay/store/payin`
        : `${apiRoot}/payment/mangopay/payin`;
      //FIXME: build axios service that has the embedded token
      const config = accessToken
        ? {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        : {};
      const res = await httpClient.post(url, payInCreationDTO, config);
      if (!res.data || !res.data.token) {
        setPaymentStatus(constants.PAY_IN_STATUS.FAILED);
      } else {
        setPayIn(res.data);
        setPaymentStatus(constants.PAY_IN_STATUS.PENDING);
      }
    } catch (error) {
      setPaymentStatus(constants.PAY_IN_STATUS.FAILED);
    }
  }, [accessToken, apiRoot, payInCreationDTO]);

  useEffect(() => {
    if (active) {
      fetchWebPayIn();
      setModalIframePosition(isEmbedded, isMobile, "kuu-mangopay-modal");
    } else {
      closeModalIframe(isEmbedded, isMobile);
    }
  }, [active, fetchWebPayIn, isEmbedded, isMobile]);

  useEffect(() => {
    onPaymentFormReady(true);
    onPaymentFormError(false);
  }, [onPaymentFormError, onPaymentFormReady]);

  const handleClose = useCallback(() => onPaymentEnd(), [onPaymentEnd]);
  const retryPayment = useCallback(() => fetchWebPayIn(), [fetchWebPayIn]);
  window.kuuMangoPayHandlePaymentCancelled = handleClose;
  window.kuuMangoPayHandlePaymentComplete = useCallback(
    ({ transactionId, status, resultCode, orders }) => {
      console.debug("handlePaymentComplete", transactionId, status, resultCode);
      if (status === constants.PAY_IN_STATUS.SUCCESS) {
        // closeDialog
        onPaymentEnd();
        // call success
        onSuccessfulPayment({
          paymentMethod: globalConstants.PAYMENT_METHOD.CREDIT_CARD,
          paymentConfirmation: true,
          transactionId,
          paymentProvider: globalConstants.PAYMENT_PROVIDER.MANGOPAY,
          additionalData: {
            status,
            resultCode,
          },
          orders,
        });
      } else {
        // we do not call parent's onFail because
        // we handle all failures scenarios within
        // the modal for improved usability

        // Transaction cancelled by user
        if (resultCode === "101002") {
          handleClose();
          setPaymentStatus(constants.PAY_IN_STATUS.PENDING);
          return;
        }
      }
      // if failed show error screen to prompt retry
      setPaymentStatus(status);
    },
    [handleClose, onPaymentEnd, onSuccessfulPayment]
  );
  return (
    <Container>
      <div>
        Paiement sécurisé avec les méthodes suivantes:
        <div style={{ marginTop: "10px" }}>
          <img
            src={cbLogo}
            alt="cbLogo"
            style={{ width: "45px", marginRight: "5px" }}
          />
          <img
            src={visaLogo}
            alt="visaLogo"
            style={{ width: "60px", marginRight: "5px" }}
          />
          <img
            src={masterCardLogo}
            alt="masterCardLogo"
            style={{ width: "55px" }}
          />
        </div>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={active}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
        PaperProps={{
          id: "kuu-mangopay-modal",
          style: { margin: 0 },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="kuu-mangopay-modal-dialog-title" onClose={handleClose}>
          Paiement
        </DialogTitle>
        <DialogContainer>
          {paymentStatus !== constants.PAY_IN_STATUS.PENDING &&
            paymentStatus !== constants.PAY_IN_STATUS.FAILED &&
            paymentStatus !== constants.PAY_IN_STATUS.REQUESTED && (
              <PaymentLoader />
            )}
          {paymentStatus === constants.PAY_IN_STATUS.REQUESTED && (
            <Loader size={80} />
          )}
          {paymentStatus === constants.PAY_IN_STATUS.PENDING && (
            <MangoPayIframe
              src={`${window.location.origin}/payment/mangopay/template/${payIn.token}`}
            />
          )}
          {paymentStatus === constants.PAY_IN_STATUS.FAILED && (
            <div>
              <div>Échec du paiement, veuillez réessayer</div>
              <Button variant="contained" onClick={retryPayment}>
                Réessayer
              </Button>
            </div>
          )}
        </DialogContainer>
      </Dialog>
    </Container>
  );
}

export default MangoPay;
