import { makeStyles } from "@material-ui/core/styles";
import React, { useRef } from "react";
import classnames from "classnames";
import {
  Box,
  CardContent,
  Chip,
  Grid,
  List,
  ListItem,
  Popper,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CategoryIcon from "@material-ui/icons/CategoryOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { getProductCountTextShort } from "../utils";

const withTextSecondary = withStyles((theme) => ({
  root: { color: theme.palette.grey["800"], marginRight: theme.spacing(1) },
}));
const MenuBookIconSecondary = withTextSecondary(MenuBookIcon);
const CategoryIconSecondary = withTextSecondary(CategoryIcon);
const LocalShippingIconSecondary = withTextSecondary(LocalShippingIcon);

const useInfoStyle = makeStyles((theme) => ({
  content: {
    height: 120,
    "&:last-child": {
      paddingBottom: 20,
    },
  },
  chip: {
    backgroundColor: theme.palette.secondary.xLight,
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.light,
  },
  list: {
    // backgroundColor: theme.palette.secondary.xLight,
    // color: theme.palette.secondary.dark,
  },
  labelListItem: {
    // to have them align on the left, remove the justfyContent here, and also update Popper.placement
    justifyContent: "center",
    padding: "2px 0",
  },
}));

export const CardInfo = React.memo(function Info({
  categories,
  tags,
  productCount,
  doesDelivery,
}) {
  const classes = useInfoStyle();
  const catStr = categories.join(", ");
  const catClass = classnames(
    "clamp-lines flex",
    tags.length ? "clamp-lines--2" : "clamp-lines--3"
  );
  let moreLabels = [];
  let ellipsisLabel;
  if (tags.length > 1) {
    moreLabels = tags.slice(1);
    ellipsisLabel = `+${moreLabels.length} label${
      moreLabels.length > 1 ? "s" : ""
    }`;
  }
  return (
    <CardContent className={classes.content}>
      {/*Product and delvery line*/}
      <Box display="flex" alignItems={"center"}>
        {/* this commented version is to have a simple line layout (no Chip)*/}
        <MenuBookIconSecondary />
        <Box>
          <Typography variant="body2" align="left">
            <span>{getProductCountTextShort(productCount)}</span>
          </Typography>
        </Box>
        {doesDelivery && (
          <Tooltip title="Livraison disponible" placement="bottom">
            <LocalShippingIconSecondary
              fontSize={"small"}
              style={{ marginLeft: "auto" }}
            />
          </Tooltip>
        )}
      </Box>
      {/*Category line*/}
      {catStr && (
        <Box display={"flex"} alignItems={"center"} mt={1}>
          <CategoryIconSecondary />
          <Typography
            variant="body2"
            component="div"
            align="left"
            className={catClass}
            title={catStr}
          >
            {catStr}
          </Typography>
        </Box>
      )}
      {/*LABELS*/}
      {!!tags.length && (
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item>
              <StyledChip label={tags[0]} className={classes.chip} />
            </Grid>
            {ellipsisLabel && (
              <Grid item>
                <PopoverChip label={ellipsisLabel} chipClassName={classes.chip}>
                  <List disablePadding dense className={classes.list}>
                    {moreLabels.map((label, i) => (
                      <ListItem
                        disableGutters
                        key={i}
                        className={classes.labelListItem}
                      >
                        <Chip className={classes.chipOverlay} label={label} />
                      </ListItem>
                    ))}
                  </List>
                </PopoverChip>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </CardContent>
  );
});

function PopoverChip({ children, label, chipClassName }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handlePopoverOpen = (event) => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledChip
        label={label}
        className={chipClassName}
        ref={anchorRef}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popper
        id="simple-menu"
        placement={"top"}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handlePopoverClose}
      >
        <div style={{ marginBottom: 8 }}>{children}</div>
      </Popper>
    </div>
  );
}

const StyledChip = React.memo(
  withStyles((theme) => ({
    root: {
      border: `2px ${theme.palette.success.main} solid`,
      backgroundColor: "white",
      color: theme.palette.success.main,
      fontWeight: "bold",
    },
  }))(Chip)
);
